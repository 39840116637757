import { RootState, VendorRegionType } from '@types'
import { createSelector } from 'reselect'
import { identity } from '@utils/functional'

const vendorRegionsSelector = (
  state: RootState,
): Record<number, VendorRegionType> => state.VendorRegions

export const selectVendorRegions = createSelector(
  vendorRegionsSelector,
  identity,
)

export const selectVendorRegionsList = createSelector(
  vendorRegionsSelector,
  (vendorRegions) => Object.values(vendorRegions),
)
