import Loader from '@shared/loader'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Toolbar from '@shared/toolbar'
import VerticalCardForm from '@pages/vertical-cards/vertical-card-form'
import { Divider, Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { selectVerticalCardById } from '@store/selectors'
import { useAllActions, useSelector } from '@hooks'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  topContent: {
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
  },
  basicInfo: {
    flex: 3,
    marginRight: theme.spacing(2),
  },
}))

const VerticalCard = () => {
  const { classes } = useStyles()
  const { id } = useParams()

  const { getVerticalCard, saveVerticalCard, setFlashMessage } = useAllActions()
  const card = useSelector((state) => selectVerticalCardById(state, id))

  useEffect(() => {
    getVerticalCard(id)
  }, [])

  const submit = async (card) => {
    try {
      await saveVerticalCard(card)
      setFlashMessage('Vertical Scroll Card Saved.', 'success')
    } catch (e) {
      setFlashMessage(e.message, 'error')
    }
  }

  if (!card) return <Loader />

  return (
    <div>
      <div className={classes.topContent}>
        <Paper className={classes.basicInfo}>
          <Toolbar title={card.title} />
          <Divider />
          <VerticalCardForm initialData={card} onSubmit={submit} />
        </Paper>
      </div>
    </div>
  )
}

VerticalCard.propTypes = {
  id: PropTypes.number,
}

export default VerticalCard
