import createCachedSelector from '@utils/cached-selector'
import { createSelector } from 'reselect'

export const selectRoleById = createCachedSelector(
  (s, id) => s.Roles[id],
  (role) => role,
)((_s, id) => id)

export const selectRoles = createSelector(
  (s) => s.Roles,
  (roles) => roles,
)

export const rolesListAlpha = createSelector(
  (s) => s.Roles,
  (roles) => {
    return Object.values(roles).sort((a, b) => {
      return a.role > b.role ? 1 : -1
    })
  },
)
