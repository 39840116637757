import { CLOSE_DIALOG, SHOW_DIALOG } from '@store/action-list'
import { ReactElement } from 'react'

export const showDialog = (component: () => ReactElement): any => {
  return {
    type: SHOW_DIALOG,
    payload: {
      component,
    },
  }
}

export const closeDialog = (): any => {
  return {
    type: CLOSE_DIALOG,
  }
}
