import ErrorBoundary from '@components/shared/error-boundary'
import React, { ReactElement, useEffect } from 'react'
import Table from '@components/shared/table'
import hasPerms from '@utils/has-permission'
import useSelector from '@hooks/use-selector'
import { Add } from '@mui/icons-material'
import { AppDispatch, PromotionType } from '@types'
import { Paper } from '@mui/material'
import { getPromotions } from '@store/actions/promotions'
import { makeStyles } from 'tss-react/mui'
import {
  selectPromotionsOngoing,
  selectPromotionsPast,
  selectPromotionsUpcoming,
} from '@store/selectors/promotions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles()(() => ({
  widgetRow: {
    marginTop: 25,
  },
}))

const renderStartEndDate = (_, dateString) => {
  if (/^0001|9999/.test(dateString)) return 'Permanent'
  const date = new Date(dateString + ' 00:00:00')
  return date.toDateString()
}

const columns = [
  { title: 'ID', field: 'id', editable: false, defaultSort: 'desc' },
  { title: 'Name', field: 'name' },
  { title: 'Type', field: 'type' },
  { title: 'Starts', field: 'start_date', render: renderStartEndDate },
  { title: 'Ends', field: 'end_date', render: renderStartEndDate },
  { title: 'Updated', type: 'datetime', field: 'updated_at' },
]

const Promotions = (): ReactElement => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const pastPromotions = useSelector(selectPromotionsPast)
  const ongoingPromotions = useSelector(selectPromotionsOngoing)
  const upcomingPromotions = useSelector(selectPromotionsUpcoming)

  const canEdit = hasPerms('promotions:update')

  useEffect(() => {
    dispatch(getPromotions())
  }, [])

  return (
    <>
      <p>Manage mobile ordering promotions.</p>

      {ongoingPromotions.length > 0 && (
        <div className={classes.widgetRow}>
          <ErrorBoundary>
            <Paper>
              <Table
                columns={columns}
                data={ongoingPromotions}
                onRowClick={(row: PromotionType) => `/promotions/${row.id}`}
                paginated={false}
                rowKey='id'
                title='Ongoing'
              />
            </Paper>
          </ErrorBoundary>
        </div>
      )}

      <div className={classes.widgetRow}>
        <ErrorBoundary>
          <Paper>
            <Table
              columns={columns}
              data={upcomingPromotions}
              onRowClick={(row: PromotionType) => `/promotions/${row.id}`}
              paginated={false}
              rowKey='id'
              title='Upcoming'
              toolbarButtons={
                canEdit
                  ? [
                      {
                        startIcon: <Add />,
                        title: 'Create Promotion',
                        onClick: () => navigate('/promotions/create'),
                      },
                    ]
                  : undefined
              }
            />
          </Paper>
        </ErrorBoundary>
      </div>

      <div className={classes.widgetRow}>
        <ErrorBoundary>
          <Paper>
            <Table
              columns={columns}
              data={pastPromotions}
              paginated={false}
              rowKey='id'
              title='Past'
            />
          </Paper>
        </ErrorBoundary>
      </div>
    </>
  )
}

export default Promotions
