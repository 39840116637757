import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import SearchBar from './search-bar'
import { makeStyles } from 'tss-react/mui'

import {
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'

import { setFlashMessage } from '@store/reducers/flash-message/actions'

import { getIconForResult, getPathForResult } from '../../../utils/search-utils'
import { search } from '../../../services/search-service'

const useStyles = makeStyles()((theme) => ({
  searchBarContainer: {
    padding: theme.spacing(2),
  },
  emptySearchResults: {
    padding: theme.spacing(2),
  },
  resultsInfoContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  resultCell: {},
}))

const mapStateToProps = (state) => ({
  searchQuery: state.SearchResults.query,
  searchResults: state.SearchResults.results,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    search: search(dispatch),
    ...bindActionCreators(
      {
        setFlashMessage,
      },
      dispatch,
    ),
  },
})

function Search(props) {
  const { actions } = props
  const { classes } = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [redirect, setRedirect] = useState(null)

  if (redirect) return <Navigate to={redirect} />

  const getResultMetadata = (result) => {
    const copy = { ...result }
    delete copy['name']
    const keys = Object.keys(copy)
    let str = ''
    for (let i = 0; i < keys.length; i++) {
      str += `${keys[i]}: ${copy[keys[i]]}`
      if (i < keys.length - 1) {
        str += ' • '
      }
    }

    return str
  }

  return (
    <Paper>
      <div className={classes.searchBarContainer}>
        <SearchBar
          isLoading={isLoading}
          onSearch={(query) => {
            setIsLoading(true)
            actions
              .search(query)
              .then(() => setIsLoading(false))
              .catch((error) => {
                setIsLoading(false)
                actions.setFlashMessage(error.message, 'error')
              })
          }}
        />
      </div>
      <Divider />
      <div className={classes.searchResults}>
        {props.searchQuery != null ? (
          <div>
            <div className={classes.resultsInfoContainer}>
              <Typography>
                Showing results for <strong>{props.searchQuery}</strong>
              </Typography>
              <Typography>{props.searchResults.length} Results</Typography>
            </div>
            {props.searchResults.length > 0 ? (
              <List>
                {props.searchResults.map((result) => (
                  <ListItem
                    button
                    className={classes.resultCell}
                    key={result.name}
                    onClick={() => setRedirect(getPathForResult(result))}
                  >
                    <ListItemIcon>
                      <Icon>{getIconForResult(result)}</Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={result.name}
                      secondary={getResultMetadata(result)}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography className={classes.emptySearchResults}>
                <em>No Results</em>
              </Typography>
            )}
          </div>
        ) : (
          <Typography className={classes.emptySearchResults}>
            <em>Search for something!</em>
          </Typography>
        )}
      </div>
    </Paper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
