import { SET_STORE, SET_STORES } from '@store/action-list'

const initialState = {}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case SET_STORE: {
      const { store } = action

      return {
        ...state,
        [store.id]: store,
      }
    }
    case SET_STORES: {
      const {
        stores: { stores },
      } = action
      return stores.reduce((acc, store) => {
        return { ...acc, [store.id]: store }
      }, state)
    }
    default:
      return state
  }
}
