import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import Tabs from '../../shared/tabs'
import TimePicker from '../../shared/time-picker'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  alpha,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  dialogLoader: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    backgroundColor: alpha('#ffffff', 0.8),
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  },
  closeTodayBtn: {
    color: 'red',
  },
}))

function HoursPickerDialog(props) {
  const { classes } = useStyles()
  const [opens, setOpens] = useState(props.opens)
  const [closes, setCloses] = useState(props.closes)
  const [initOpens, setInitOpens] = useState(props.opens)
  const [initCloses, setInitCloses] = useState(props.closes)

  useEffect(() => {
    setOpens(props.opens)
    setCloses(props.closes)
    setInitOpens(props.opens)
    setInitCloses(props.closes)
  }, [props.opens, props.closes])

  const closeForToday = async () => {
    props.onConfirm('00:00:00', '00:00:00')
  }

  const ableToConfirm = opens && closes

  const clearAndCancel = () => {
    setOpens(initOpens)
    setCloses(initCloses)
    props.onClose()
  }

  return (
    <Dialog
      aria-labelledby='dialog-title'
      onClose={props.onClose}
      open={props.open}
    >
      <DialogTitle id='dialog-title'>{props.title}</DialogTitle>
      <Tabs
        backgroundColor='white'
        indicatorColor='green'
        tabs={[
          {
            title: 'Opens',
            component: <TimePicker onChange={setOpens} time={opens} />,
            padded: false,
          },
          {
            title: 'Closes',
            component: <TimePicker onChange={setCloses} time={closes} />,
            padded: false,
          },
        ]}
        textColor='black'
      />
      <DialogActions>
        <Button onClick={clearAndCancel}>Cancel</Button>
        <Button
          disabled={!ableToConfirm}
          onClick={async () => {
            if (opens === props.opens && closes === props.closes) {
              props.onClose()
            } else {
              props.onConfirm(opens, closes)
            }
          }}
        >
          Confirm
        </Button>
        <Button className={classes.closeTodayBtn} onClick={closeForToday}>
          Close {props.day}
        </Button>
      </DialogActions>
      {props.loading && (
        <div className={classes.dialogLoader}>
          <CircularProgress />
        </div>
      )}
    </Dialog>
  )
}

HoursPickerDialog.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,

  /**
   * Method to be called when the user confirms the new times. It will pass
   * an opening time and a closing time, both formatted HH:mm:ss
   */
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,

  /**
   * The initial times
   */
  opens: PropTypes.string,
  closes: PropTypes.string,
}

export default HoursPickerDialog
