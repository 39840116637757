import createCachedSelector from 're-reselect'
import { RootState } from '@types'
import { createSelector } from 'reselect'

export const selectCurrentUser = createSelector(
  (state: RootState) => state.CurrentUser,
  (user) => user,
)

// TODO: proper types for this selector
export const selectCurrentPermissionNames = createSelector(
  (state: RootState) => state.CurrentUser.user,
  (state: RootState) => state.Permissions,
  (user, perms): string[] => {
    if (!user || !perms) {
      return []
    }

    const currentPerms = Object.values(perms).filter((perm: any) => {
      if (!user.permission_ids) return false

      return user.permission_ids.includes(perm.id)
    })

    return currentPerms.map((perm: any) => perm.permission)
  },
)

export const selectCurrentRoles = createSelector(
  (state: RootState) => state.CurrentUser.user,
  (state: RootState) => state.Roles,
  (user, roles): string[] => {
    if (!user || !roles) {
      return []
    }

    const currentRoles = Object.values(roles).filter((role: any) => {
      if (!user.role_ids) return false

      return user.role_ids.includes(role.id)
    })

    return currentRoles.map((role: any) => role.role)
  },
)

export const selectCurrentStoreId = createSelector(
  (state: RootState) => state.CurrentUser,
  (userInfo) => userInfo.user.store_ids,
)

export const hasPermission = createCachedSelector(
  selectCurrentPermissionNames,
  (_: RootState, permName: string) => permName,
  (allPermNames, permName): boolean => allPermNames.includes(permName),
)((_, permName) => permName)
