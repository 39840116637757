import createCachedSelector from 're-reselect'
import { RootState, TagType } from '@types'
import { createSelector } from 'reselect'
import { identity } from '@utils/functional'

const tagSelector = (state: RootState, id: string): TagType | undefined =>
  state.Tags[id]
const tagsSelector = (state: RootState): Record<string, TagType> => state.Tags

export const selectTags = createSelector(tagsSelector, (tags) =>
  Object.values(tags),
)

export const selectTag = createCachedSelector(
  tagSelector,
  identity,
)((_, id) => id)
