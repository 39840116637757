import {
  SET_TIP_REPORT,
  SET_TIP_REPORT_END,
  SET_TIP_REPORT_START,
} from '../action-list'

const now = new Date()
const initialState = {
  startDate: new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()),
  endDate: now,
  report: [], // List of records, exact same format as the backend sends
}

export default (state = initialState, action) => {
  const { report, date } = action

  switch (action.type) {
    case SET_TIP_REPORT:
      return { ...state, report }
    case SET_TIP_REPORT_END:
      return { ...state, endDate: date }
    case SET_TIP_REPORT_START:
      return { ...state, startDate: date }
    default:
      return state
  }
}
