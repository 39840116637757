import { RecommendedProductsInitialStateType, RootState } from '@types'
import { createSelector } from 'reselect'

const recommendedProductsSelector = (
  state: RootState,
): RecommendedProductsInitialStateType => state.RecommendedProducts

export const selectRecommendedProducts = createSelector(
  recommendedProductsSelector,
  (state) => state.all,
)
