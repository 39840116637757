import React, { ReactElement, useEffect, useState } from 'react'

import Table from '@components/shared/table'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Switch,
} from '@mui/material'

import EditRecommendedProduct from './edit-recommended-product'
import useSelector from '@hooks/use-selector'
import { Add, ExpandLess, ExpandMore } from '@mui/icons-material'
import { AppDispatch, RecommendedProductType } from '@types'
import {
  createRecommendedProduct,
  deleteRecommendedProduct,
  getRecommendedProducts,
  updateRecommendedProduct,
} from '@store/actions/recommended-products'
import { getProductList } from '@queries/products'
import { selectRecommendedProducts } from '@store/selectors/recommended-products'
import { useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'

function RecommendedProducts(): ReactElement {
  const dispatch = useDispatch<AppDispatch>()
  const recommendedProducts = useSelector(selectRecommendedProducts)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [rowForDeletion, setRowForDeletion] = useState(0)
  const newRecommendedProductDefault: RecommendedProductType = {
    id: -1,
    product_id: -1,
    pinned: false,
    recent_locations_only: false,
    sort_order: -1,
    headline: '',
    description: '',
  }
  const [editRecommendedProduct, setEditRecommendedProduct] = useState(
    newRecommendedProductDefault,
  )
  const [newRecommendedProduct] = useState(newRecommendedProductDefault)

  useEffect(() => {
    dispatch(getRecommendedProducts())
  }, [])

  const { data: products } = useQuery({
    queryKey: ['productList'],
    queryFn: getProductList,
    staleTime: 30000,
  })

  const onPinnedChange = (recommendedProduct, event) => {
    const value = event.target.checked
    handleUpdate({
      ...recommendedProduct,
      pinned: value,
    })
  }

  const onRecentLocationsOnlyChange = (recommendedProduct, event) => {
    const value = event.target.checked
    handleUpdate({
      ...recommendedProduct,
      recent_locations_only: value,
    })
  }

  const handleAdd = async (newData: RecommendedProductType) =>
    dispatch(
      createRecommendedProduct({
        ...newData,
        sort_order: Object.keys(recommendedProducts).length,
      }),
    )

  const handleUpdate = async (newData) =>
    dispatch(updateRecommendedProduct(newData))

  const handleDelete = (recommendedProduct) => {
    dispatch(deleteRecommendedProduct(recommendedProduct))
  }

  const sortedRecommendedProducts = (): RecommendedProductType[] => {
    return Object.values(recommendedProducts).sort((a, b) =>
      a.sort_order > b.sort_order ? 1 : -1,
    )
  }

  const onMoveUp = (rowData, currentIndex) => {
    moveRowWithOffset(rowData, currentIndex, -1)
  }

  const onMoveDown = (rowData, currentIndex) => {
    moveRowWithOffset(rowData, currentIndex, 1)
  }

  const moveRowWithOffset = async (rowData, currentIndex, offset) => {
    let newIndex = currentIndex + offset
    if (newIndex < 0) newIndex = 0

    sortedRecommendedProducts().forEach((recommendedProduct, index) => {
      if (rowData.id == recommendedProduct.id) {
        handleUpdate({
          ...recommendedProduct,
          sort_order: newIndex,
        })
      } else if (index == newIndex) {
        handleUpdate({
          ...recommendedProduct,
          sort_order: index + offset * -1,
        })
      }
    })
  }

  const handleCloseEdit = () => setOpenEdit(false)
  const handleEdit = (recommendedProduct: RecommendedProductType) => {
    setEditRecommendedProduct(recommendedProduct)
    setOpenEdit(true)
  }

  const handleSave = async (recommendedProduct: RecommendedProductType) => {
    try {
      if (recommendedProduct.id === -1) {
        await handleAdd(recommendedProduct)
      } else {
        await handleUpdate(recommendedProduct)
      }
    } catch (e) {
      return
    }
    setOpenEdit(false)
  }

  return (
    <>
      <EditRecommendedProduct
        onCancel={handleCloseEdit}
        onSave={handleSave}
        open={openEdit}
        recommendedProduct={editRecommendedProduct}
      />
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        open={openDeleteConfirm}
      >
        <DialogTitle id='alert-dialog-title'>Confirm delete!</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to completely remove this recommended product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color='primary'
            onClick={() => setOpenDeleteConfirm(false)}
          >
            Nevermind
          </Button>
          <Button
            color='primary'
            onClick={() => {
              handleDelete(rowForDeletion)
              setOpenDeleteConfirm(false)
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Paper>
        {/* eslint-disable react/display-name */}
        <Table
          columns={[
            {
              title: 'Sorting',
              field: 'sort_order',
              render: (rowData, _field, _editing, index) => {
                return (
                  <div>
                    <IconButton
                      disabled={index === 0}
                      onClick={() => {
                        onMoveUp(rowData, index)
                      }}
                    >
                      <ExpandLess fontSize='small' />
                    </IconButton>
                    <IconButton
                      disabled={
                        index === Object.keys(recommendedProducts).length - 1
                      }
                      onClick={() => {
                        onMoveDown(rowData, index)
                      }}
                    >
                      <ExpandMore fontSize='small' />
                    </IconButton>
                  </div>
                )
              },
            },
            {
              title: 'Product',
              field: 'product_id',
              render: ({ product_id }) => {
                const product = products?.find((p) => p.id == product_id)
                return product?.name
              },
            },
            {
              title: 'Headline',
              field: 'headline',
            },
            {
              title: 'Description',
              field: 'description',
            },
            {
              title: 'Pinned',
              field: 'pinned',
              render: (rowData) => {
                const { pinned } = rowData
                return (
                  <Switch
                    checked={pinned === true}
                    onChange={(event) => onPinnedChange(rowData, event)}
                  />
                )
              },
            },
            {
              title: 'Recent Locations Only',
              field: 'recent_locations_only',
              render: (rowData) => {
                const { recent_locations_only } = rowData
                return (
                  <Switch
                    checked={recent_locations_only === true}
                    onChange={(event) =>
                      onRecentLocationsOnlyChange(rowData, event)
                    }
                  />
                )
              },
            },
          ]}
          data={sortedRecommendedProducts()}
          editing
          paginated={false}
          rowActions={{
            onRowUpdate: handleEdit,
            onRowDelete: (row) => {
              setRowForDeletion(row.id)
              setOpenDeleteConfirm(true)
            },
          }}
          rowKey='id'
          title='Recommended Products'
          toolbarButtons={[
            {
              startIcon: <Add />,
              title: 'Create Recommended Product',
              onClick: () => {
                setEditRecommendedProduct(newRecommendedProduct)
                setOpenEdit(true)
              },
            },
          ]}
        />
        {/* eslint-enable react/display-name */}
      </Paper>
    </>
  )
}

export default RecommendedProducts
