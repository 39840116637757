import * as api from '@utils/api'
import { UserSearchResult } from '@types'

export const getBaristaPhotos = async (): Promise<any> => {
  const { data: response } = await api.GET('/p4/baristas/photos')

  return response
}

export const searchUsers = async (
  query: string,
): Promise<Record<number, UserSearchResult>> => {
  const { data: response } = await api.POST('/p4/search/users', { query })

  return response
}
