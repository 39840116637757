import * as api from '@utils/api'
import { MenuCategoryDataType, MenuType } from '@types'

export const getMenuCategories = async (
  menu: string,
): Promise<MenuCategoryDataType[]> => {
  const response = await api.GET(`/p4/categories/${menu}`)

  return response.data
}

export const getMenus = async (): Promise<MenuType[]> => {
  const response = await api.GET('/p4/menus')

  return response.data
}
