import createCachedSelectorFn from 're-reselect'
// Custom re-reselect createCachedSelector that checks whether a cache key is
// provided before attempting to fetch selector cache.
//
// This will reduce pattern of guarding against missing cache key before invoking
// selector.

const createCachedSelector = (...selectorFns) => (...keySelectorFns) => (
  s,
  key,
) => {
  if (key) {
    return createCachedSelectorFn(...selectorFns)(...keySelectorFns)(s, key)
  }
  return null
}

export default createCachedSelector
