import {
  ADD_USERS_FAILURE,
  ARCHIVE_PROMOTION_FAILURE,
  ARCHIVE_PROMOTION_SUCCESS,
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_FAILURE,
  CREATE_PROMOTION_FAILURE,
  CREATE_PROMOTION_SUCCESS,
  CREATE_RECOMMENDATION,
  CREATE_RECOMMENDATION_FAILURE,
  CREATE_RECOMMENDED_PRODUCT_FAILURE,
  CREATE_RECOMMENDED_PRODUCT_SUCCESS,
  CREATE_TAG,
  CREATE_TAG_FAILURE,
  DELETE_RECOMMENDATION,
  DELETE_RECOMMENDED_PRODUCT_FAILURE,
  DELETE_RECOMMENDED_PRODUCT_SUCCESS,
  REFUNDED_PRODUCTS_FAILURE,
  REFUNDED_PRODUCTS_SUCCESS,
  SAVE_EMPLOYEE,
  SAVE_EMPLOYEE_FAILURE,
  SET_RECOMMENDED_PRODUCTS_FAILURE,
  SET_SURCHARGES_FAILURE,
  SET_TAG_FAILURE,
  SET_TAX_RATES_FAILURE,
  UPDATED_TAX_RATES,
  UPDATED_TAX_RATES_FAILURE,
  UPDATE_ATTRIBUTE_VALUES_FAILURE,
  UPDATE_MENU_FAILURE,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PROMOTION_FAILURE,
  UPDATE_PROMOTION_SUCCESS,
  UPDATE_RECOMMENDATION,
  UPDATE_RECOMMENDATION_FAILURE,
  UPDATE_RECOMMENDED_PRODUCT_FAILURE,
  UPDATE_RECOMMENDED_PRODUCT_SUCCESS,
  UPDATE_TAG,
  UPDATE_TAG_FAILURE,
} from '@store/action-list'
import {
  CLONE_PRODUCT_FAILURE,
  CLONE_PRODUCT_SUCCESS,
} from '@store/reducers/products/actions'
import { getTags } from '@store/actions/tags'
import { put, takeEvery } from 'redux-saga/effects'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

const notificationMapping = {
  [UPDATE_PRODUCT_SUCCESS]: 'Product Updated',
  UPDATE_PRODUCT_FAILURE,
  [DELETE_RECOMMENDATION]: 'Recommendation Deleted',
  [UPDATE_RECOMMENDATION]: 'Recommendation Updated',
  UPDATE_RECOMMENDATION_FAILURE,
  [CREATE_RECOMMENDATION]: 'Recommendation Created',
  CREATE_RECOMMENDATION_FAILURE,
  [CLONE_PRODUCT_SUCCESS]: 'Product Attributes Cloned',
  CLONE_PRODUCT_FAILURE,
  ADD_USERS_FAILURE,
  CREATE_EMPLOYEE_FAILURE,
  [CREATE_EMPLOYEE]: 'Created Emplyee',
  SAVE_EMPLOYEE_FAILURE,
  [SAVE_EMPLOYEE]: 'Updated Employee',
  UPDATE_ATTRIBUTE_VALUES_FAILURE,
  SET_TAG_FAILURE,
  UPDATE_TAG_FAILURE,
  CREATE_TAG_FAILURE,
  [CREATE_TAG]: 'Tag Created',
  [UPDATE_TAG]: 'Tag Updated',
  [getTags.rejected.type]: 'Failed to get tags',
  [UPDATED_TAX_RATES]: 'Tax Rates Updated',
  [UPDATED_TAX_RATES_FAILURE]: 'Woops we could not update the tax rates!',
  [SET_TAX_RATES_FAILURE]: 'Could not fetch tax rates',
  [SET_SURCHARGES_FAILURE]: 'Could not fetch surcharges',
  [UPDATE_PROMOTION_SUCCESS]: 'Promotion Updated',
  UPDATE_PROMOTION_FAILURE,
  [CREATE_PROMOTION_SUCCESS]: 'Promotion Created',
  CREATE_PROMOTION_FAILURE,
  [ARCHIVE_PROMOTION_SUCCESS]: 'Promotion Archived',
  ARCHIVE_PROMOTION_FAILURE,
  [CREATE_RECOMMENDED_PRODUCT_SUCCESS]: 'Recommended Product Created',
  [UPDATE_RECOMMENDED_PRODUCT_SUCCESS]: 'Recommended Product Updated',
  [DELETE_RECOMMENDED_PRODUCT_SUCCESS]: 'Recommended Product Deleted',
  CREATE_RECOMMENDED_PRODUCT_FAILURE,
  DELETE_RECOMMENDED_PRODUCT_FAILURE,
  SET_RECOMMENDED_PRODUCTS_FAILURE,
  UPDATE_RECOMMENDED_PRODUCT_FAILURE,
  [REFUNDED_PRODUCTS_SUCCESS]: 'Refund Completed',
  REFUNDED_PRODUCTS_FAILURE,
  UPDATE_MENU_FAILURE,
}

// TODO: rename
export default function* watchAddProduct(): any {
  yield takeEvery(Object.keys(notificationMapping), updateNotification)
}

function* updateNotification({ type, response: { message, status } }) {
  const displayedMessage = message || notificationMapping[type]

  const variant = [200, 201].includes(status) ? 'success' : 'error'

  yield put(setFlashMessage(displayedMessage, variant))
}
