import PropTypes from 'prop-types'
import React from 'react'
import {
  AppBar,
  Box,
  Tabs as MaterialTabs,
  Paper,
  Tab,
  useTheme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = (indicatorColor) =>
  makeStyles()(() => ({
    indicator: {
      backgroundColor: indicatorColor,
    },
  }))()

function Tabs(props) {
  const theme = useTheme()
  const { classes } = useStyles(
    props.indicatorColor || theme.palette.secondary.main,
  )
  const [value, setValue] = React.useState(0)

  const propsForTabs = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })

  const onChange = (event, newValue) => {
    if (props.onChangeCallback) {
      props.onChangeCallback()
    }
    setValue(newValue)
  }

  return (
    <Paper>
      <AppBar
        position='static'
        style={{
          backgroundColor: props.backgroundColor || theme.palette.primary.main,
        }}
      >
        <MaterialTabs
          aria-label='user data tabs'
          classes={{ indicator: classes.indicator }}
          onChange={onChange}
          value={value}
        >
          {props.tabs.map((tab, index) => {
            return (
              <Tab
                key={tab.title}
                label={tab.title}
                style={{ color: props.textColor || 'white' }}
                {...propsForTabs(index)}
              />
            )
          })}
        </MaterialTabs>
      </AppBar>
      {props.tabs.map((tab, index) => {
        return (
          <Box
            aria-labelledby={`tab-${index}`}
            display={value !== index ? 'none' : 'block'}
            id={`tabpanel-${index}`}
            key={index}
            p={3}
            style={{
              padding: tab.padded === false && 0,
            }}
          >
            {tab.component}
          </Box>
        )
      })}
    </Paper>
  )
}

Tabs.propTypes = {
  /**
   * Each tab config should look like this:
   *
   * ```
   * {
   *   title: String,
   *   component: JSX.Element
   *   padded: boolean
   * }
   * ```
   */
  tabs: PropTypes.arrayOf(PropTypes.object),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  indicatorColor: PropTypes.string,
  onChangeCallback: PropTypes.func,
}

export default Tabs
