import Category from './category'
import CategoryNameForm from './category-name-form'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import update from 'immutability-helper'
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material'
import { AppDispatch, CategoryType, MenuCategoryDataType } from '@types'
import {
  createCategory,
  deleteCategory,
  updateMenu,
} from '@store/actions/menus'
import { getMenuCategories } from '@queries/menus'
import { useDispatch } from 'react-redux'

enum CategoryEditMode {
  create,
  update,
}

type Props = {
  menuSlug: string
}

const Categories = ({ menuSlug }: Props): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const [selected, setSelected] = useState(0)

  const [categoryNameDialogOpen, setCategoryNameDialogOpen] = useState(false)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [categoryEditIndex, setCategoryEditIndex] = useState<null | number>(
    null,
  )
  const [categoryDeleteIndex, setCategoryDeleteIndex] = useState<null | number>(
    null,
  )
  const [categoryEditDefaultValue, setCategoryEditDefaultValue] = useState('')
  const [categoryEditMode, setCategoryEditMode] = useState<CategoryEditMode>(
    CategoryEditMode.create,
  )

  const [categories, setCategories] = useState<MenuCategoryDataType[]>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      if (menuSlug) {
        setIsLoading(true)
        const categories = await getMenuCategories(menuSlug)
        setCategories(categories)
        setIsLoading(false)
      }
    })()
  }, [menuSlug])

  const handleDrop = useCallback(() => {
    if (categories) {
      dispatch(updateMenu(categories, menuSlug))
    }
  }, [categories])

  const handleUpdateCategoryProductsList = (
    categoryId: number,
    products: number[],
  ) => {
    if (!categories) return
    setCategories((prevCategories) => {
      if (!prevCategories) return
      const newCategories = [...prevCategories]
      newCategories[selected].children[categoryId].products = products
      return newCategories
    })
    handleDrop()
  }

  const updateCategoryEditFields = (
    categoryId: number,
    categoryName: string,
  ) => {
    if (categories === undefined) return
    categories[selected].children[categoryId] = {
      ...categories[selected].children[categoryId],
      name: categoryName,
    }
    setCategories(categories)
    handleDrop()
  }

  const moveCategory = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setCategories((prevCategories) => {
        return update(prevCategories, {
          [selected]: {
            children: {
              $splice: [
                [dragIndex, 1],
                [
                  hoverIndex,
                  0,
                  prevCategories?.[selected].children[
                    dragIndex
                  ] as CategoryType,
                ],
              ],
            },
          },
        })
      })
    },
    [selected],
  )

  const moveProduct = useCallback(
    (
      dragIndex: number,
      hoverIndex: number,
      parentIndex: number,
      dragParentIndex: number,
    ) => {
      if (parentIndex !== dragParentIndex) {
        // remove from dragParent
        // add to parent
        setCategories((prevCategories) => {
          // index + 1 needed for adding to the end of a list
          const updatedIndex = hoverIndex === 0 ? hoverIndex : hoverIndex + 1

          return update(prevCategories, {
            [selected]: {
              children: {
                // add at hover index in new list
                [parentIndex]: {
                  products: {
                    $splice: [
                      [
                        updatedIndex,
                        0,
                        prevCategories?.[selected].children[dragParentIndex]
                          .products[dragIndex] as number,
                      ],
                    ],
                  },
                },
                // remove from other list
                [dragParentIndex]: {
                  products: {
                    $splice: [[dragIndex, 1]],
                  },
                },
              },
            },
          })
        })
      } else {
        // move within category
        setCategories((prevCategories) => {
          return update(prevCategories, {
            [selected]: {
              children: {
                [parentIndex]: {
                  products: {
                    $splice: [
                      [dragIndex, 1],
                      [
                        hoverIndex,
                        0,
                        prevCategories?.[selected].children[parentIndex]
                          .products[dragIndex] as number,
                      ],
                    ],
                  },
                },
              },
            },
          })
        })
      }
    },
    [selected],
  )

  const renderCategory = useCallback(
    (category: CategoryType, index: number) => {
      if (!category) return null

      return (
        <Category
          category={category}
          handleUpdateCategoryProductsList={handleUpdateCategoryProductsList}
          index={index}
          key={category.id}
          moveCategory={moveCategory}
          moveProduct={moveProduct}
          onDrop={handleDrop}
          onEdit={handleCategoryEdit}
          onRemove={handleCategoryRemove}
          parentIndex={selected}
        />
      )
    },
    [moveCategory, moveProduct, selected, handleDrop],
  )

  const handleSaveCategoryUpdate = (categoryName: string) => {
    if (categoryEditMode == CategoryEditMode.update) {
      if (categoryEditIndex === null) return
      updateCategoryEditFields(categoryEditIndex, categoryName)
      return
    }

    if (!categories) return

    dispatch(
      createCategory(categoryName, categories[selected].id, menuSlug),
    ).then((result: MenuCategoryDataType[]) => {
      setCategories(result)
    })
  }

  const handleAddCategory = () => {
    setCategoryEditMode(CategoryEditMode.create)
    setCategoryEditDefaultValue('')
    setCategoryNameDialogOpen(true)
  }

  const handleCategoryEdit = (categoryIndex: number) => {
    if (!categories) return
    setCategoryEditMode(CategoryEditMode.update)
    setCategoryEditIndex(categoryIndex)
    const category = categories[selected].children[categoryIndex]
    setCategoryEditDefaultValue(category.name)
    setCategoryNameDialogOpen(true)
  }

  const handleCategoryRemove = (categoryIndex: number) => {
    if (!categories) return

    setCategoryDeleteIndex(categoryIndex)
    setCategoryEditDefaultValue(
      categories[selected].children[categoryIndex].name,
    )
    setOpenDeleteConfirm(true)
  }

  const handleConfirmDelete = () => {
    if (!categories || categoryDeleteIndex === null) return

    setOpenDeleteConfirm(false)
    dispatch(
      deleteCategory(
        categories[selected].children[categoryDeleteIndex].id,
        menuSlug,
      ),
    ).then((result: MenuCategoryDataType[]) => {
      setCategories(result)
    })
  }

  const handleDeleteConfirmClose = () => {
    setOpenDeleteConfirm(false)
  }

  return (
    <>
      <CategoryNameForm
        changeDialogOpen={setCategoryNameDialogOpen}
        defaultValue={categoryEditDefaultValue}
        dialogOpen={categoryNameDialogOpen}
        onSave={handleSaveCategoryUpdate}
      />
      <Dialog onClose={handleDeleteConfirmClose} open={openDeleteConfirm}>
        <DialogTitle>
          Confirm Delete &quot;{categoryEditDefaultValue}&quot;
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to completely remove this category from the
            Philz database? This will remove this category from the menu and any
            products only in this category will disappear from the menu.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color='primary' onClick={handleDeleteConfirmClose}>
            Nevermind
          </Button>
          <Button color='primary' onClick={handleConfirmDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <AppBar position='static'>
        <Tabs onChange={(_, value) => setSelected(value)} value={selected}>
          {categories?.map((cat, index) => (
            <Tab key={cat.name} label={cat.name} value={index} />
          ))}
        </Tabs>
      </AppBar>
      <div>
        {!isLoading &&
          categories?.[selected].children.map((subCategory, i) =>
            renderCategory(subCategory, i),
          )}
      </div>
      {!isLoading && (
        <Button color='secondary' onClick={handleAddCategory}>
          Add Category
        </Button>
      )}
    </>
  )
}

export default Categories
