import AddTypeDialog from './add-type-dialog'
import CloneFromDialog from './clone-from-dialog'
import React, { ReactElement, useState } from 'react'
import { Add, FilterNone } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { ProductTypeType } from '@types'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
  },
  content: {
    width: '300px',
    marginBottom: theme.spacing(3),
  },
}))

interface Props {
  add(attrName: string): Promise<void>
  clone(cloneId: number): Promise<void>
  productType: ProductTypeType
}

const EmptyAttrs = ({ add, clone, productType }: Props): ReactElement => {
  const [addDialogOpen, setAddDialogOpen]: [boolean, any] = useState(false)
  const [cloneDialogOpen, setCloneDialogOpen]: [boolean, any] = useState(false)
  const { classes } = useStyles()

  const addAttr = (attrName: string): void => {
    setAddDialogOpen(false)
    add(attrName)
  }

  const handleClone = async (value: number) => {
    await clone(value)
    setCloneDialogOpen(false)
  }

  return (
    <div className={classes.container}>
      <AddTypeDialog
        onClose={() => {
          setAddDialogOpen(false)
        }}
        onConfirm={addAttr}
        open={addDialogOpen}
      />
      <CloneFromDialog
        onClose={() => {
          setCloneDialogOpen(false)
        }}
        onConfirm={handleClone}
        open={cloneDialogOpen}
        productType={productType}
      />
      <Typography align='center' className={classes.content}>
        It seems you have not added any attributes.
      </Typography>
      <Button
        onClick={() => setCloneDialogOpen(true)}
        startIcon={<FilterNone />}
        variant='outlined'
      >
        Clone from existing product
      </Button>
      <Typography align='center'>OR</Typography>
      <Button
        onClick={() => setAddDialogOpen(true)}
        startIcon={<Add />}
        variant='outlined'
      >
        Add Attribute Type
      </Button>
    </div>
  )
}

export default EmptyAttrs
