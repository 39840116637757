import createAsyncAction from './create-async-action'
import { VendorRegionType } from '@types'

export const getVendorRegions = createAsyncAction<VendorRegionType[]>(
  'vendorRegions',
  () => ({
    url: '/p4/vendorRegions',
    method: 'GET',
  }),
)
