import * as utc from 'dayjs/plugin/utc'
import dateformat from 'dateformat'
import dayjs from 'dayjs'

dayjs.extend(utc)

const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export function formatDefault(date) {
  return dateformat(date, 'ddd mmm dS, yyyy h:MM TT')
}

export function formatLong(date) {
  return dateformat(date, 'ddd mmm dS, yyyy h:MM:ss TT')
}

export function formatBackend(date) {
  return dateformat(date, 'yyyy-mm-dd')
}

export function formatBackendDateTime(date) {
  return date.utc().format('YYYY-MM-DD HH:mm:ss')
}

export function formatShort(date) {
  return dateformat(date, 'mm/dd/yyyy')
}

export function setTimeZone(date, timeZone) {
  const invDate = new Date(date.toLocaleString('en-US', { timeZone }))
  const diff = date.getTime() - invDate.getTime()
  return new Date(date.getTime() + diff)
}

/**
 * @param {String} time A time string formatted HH:mm:ss
 * @returns {String} A string formatted 'hh:mm E'
 */
export function getFormattedTime(time) {
  if (time == null) return ''

  const components = time.split(':')
  const hour24 = parseInt(components[0], 10)
  const ampm = hour24 > 11 ? 'PM' : 'AM'
  const hour = hour24 > 12 ? hour24 - 12 : hour24

  const minute = parseInt(components[1], 10)
  if (minute === 0) return `${hour} ${ampm}`
  else return `${hour}:${minute.toString().padStart(2, '0')} ${ampm}`
}

/**
 * @param {String} date A date string formatted yyyy-MM-dd
 * @returns {String} A date string formatted 'MMM dd, yyyy'
 */
export function getFormattedDate(date) {
  const components = date.split('-')
  return `${shortMonths[components[1] - 1]} ${components[2]}, ${components[0]}`
}

export function formatTime(time) {
  if (!time) {
    return ''
  }

  const minutes = Math.floor(time / 60)
  const seconds = time - minutes * 60

  return `${minutes || '00'}min ${seconds > 0 ? `${seconds}sec` : ''}`
}

export function getTomorrow() {
  return new Date().setDate(new Date().getDate() + 1)
}

export function format12hrFrom24hr(timeString) {
  return dayjs('0000-01-01 ' + timeString).format('hh:mm a')
}
