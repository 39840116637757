import React, { useState } from 'react'

import FullscreenPage from '../shared/fullscreen-page'
import { Button, TextField, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import useAllActions from '@hooks/use-all-actions'
import { sendPasswordResetEmail } from '../../services/auth-service'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  button: {
    color: 'white',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  field: {
    marginBottom: theme.spacing(3),
  },
}))

function ResetPassword(props) {
  const { classes } = useStyles()
  const [email, setEmail] = useState(props.location.email || '')
  const [linkButtonDisabled, setLinkButtonDisabled] = useState(false)
  const { setFlashMessage } = useAllActions()
  const navigate = useNavigate()

  return (
    <FullscreenPage contentMode>
      <Typography className={classes.title} variant='h3'>
        Reset Password
      </Typography>
      <TextField
        className={classes.field}
        fullWidth
        label='Email'
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <Button
        className={classes.button}
        color='primary'
        disabled={linkButtonDisabled}
        onClick={() => {
          setLinkButtonDisabled(true)
          sendPasswordResetEmail(email)
            .then(() => {
              setFlashMessage('Email sent', 'success')
              setLinkButtonDisabled(false)
            })
            .catch((err) => {
              setFlashMessage(err.message, 'error')
              setLinkButtonDisabled(false)
            })
        }}
        variant='contained'
      >
        Send Link
      </Button>
      <Button color='primary' onClick={() => navigate('/login')}>
        Login
      </Button>
    </FullscreenPage>
  )
}

export default ResetPassword
