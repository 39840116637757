import { SET_EOD_FOR_DAY } from '../action-list'

/** Mapping of date => data */
const initialState = {}

export default (state = initialState, action) => {
  const { date, data } = action

  switch (action.type) {
    case SET_EOD_FOR_DAY:
      console.log(`Redux: Setting EOD for ${date}`)
      return { ...state, [date]: data }
    default:
      return state
  }
}
