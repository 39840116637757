import React from 'react'
import ResponsiveDialog from './shared/responsive-dialog'
import { selectDialogComponent } from '@store/reducers/dialog/selectors'
import { useSelector } from 'react-redux'

export default function Dialog() {
  const DialogComponent = useSelector(selectDialogComponent)

  if (!DialogComponent) return null

  return (
    <ResponsiveDialog open>
      <DialogComponent />
    </ResponsiveDialog>
  )
}
