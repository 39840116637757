import isEqual from 'lodash.isequal'
import { ParametricSelector, Selector } from 'reselect'
import { RootState } from '@types'
import { useSelector as reduxSelector } from 'react-redux'

export function useSelector<
  TState = RootState,
  TSelected = unknown,
  TProps = unknown
>(
  selector:
    | Selector<TState, TSelected>
    | ParametricSelector<TState, TProps, TSelected>,
  props?: TProps,
): TSelected {
  if (props === undefined) {
    // typescritp can't figure out that props being undefined
    // means that we want a normal selector declaration
    const baseSelector = selector as Selector<TState, TSelected>

    return reduxSelector<TState, TSelected>(baseSelector, isEqual)
  }

  return reduxSelector<TState, TSelected>(
    (state) => selector(state, props),
    isEqual,
  )
}

export default useSelector
