import '../../utils/array-utils'

import {
  ADD_STORES,
  SET_ATTRIBUTE_VALUES,
  SET_STORES_COUNT,
  UPDATE_ATTRIBUTE_VALUES,
} from '../action-list'

/**
 * Stores have format:
 *
 * {
 *   id: number,
 *   name: string,
 *   city: string,
 *   region: string,
 *   state: string,
 *   phone: string
 * }
 */
const initialState = {
  stores: {},
  attributeValues: {},
  totalCount: Number.MAX_SAFE_INTEGER,
}

export default (state = initialState, action) => {
  const { payload, stores, count } = action

  switch (action.type) {
    case ADD_STORES: {
      console.log(`Redux: Adding ${stores.length} stores`)
      const storesObj = stores.toObject('id')
      return { ...state, stores: { ...state.stores, ...storesObj } }
    }
    case SET_STORES_COUNT:
      console.log(`Redux: Setting total stores count to ${count}`)
      return { ...state, totalCount: count }
    case UPDATE_ATTRIBUTE_VALUES:
    case SET_ATTRIBUTE_VALUES: {
      const { storeId, ...rest } = payload

      return {
        ...state,
        attributeValues: {
          ...state.attributeValues,
          [storeId]: rest,
        },
      }
    }
    default:
      return state
  }
}
