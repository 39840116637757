import PropTypes from 'prop-types'
import React from 'react'

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
}))

function FormSwitch(props) {
  const { classes } = useStyles()

  return (
    <FormControl
      className={classes.container}
      style={{ marginTop: props.marginTop }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={props.checked}
            onChange={(e) => props.onChange(e.target.checked)}
          />
        }
        disabled={props.disabled}
        label={props.title}
      />
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  )
}

FormSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  helperText: PropTypes.string,
  marginTop: PropTypes.number,
  disabled: PropTypes.boolean,
}

export default FormSwitch
