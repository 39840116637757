import React, { ReactElement } from 'react'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { SurchargeType } from '@types'

type Props = {
  removeFlatRateDialogOpen: boolean
  updateSurcharge: SurchargeType | null
  onCancel: () => void
  onConfirm: (surcharge: SurchargeType) => void
}

const RemoveFlatRateDialog = ({
  removeFlatRateDialogOpen,
  updateSurcharge,
  onCancel,
  onConfirm,
}: Props): ReactElement => {
  if (!updateSurcharge) return <></>

  return (
    <Dialog
      aria-labelledby='dialog-title'
      onClose={() => onCancel()}
      open={removeFlatRateDialogOpen}
    >
      <DialogTitle id='dialog-title'>
        Changing Flat Rate To Calculated Rate
      </DialogTitle>
      <p className='MuiDialogTitle-root MuiTypography-body1'>
        You are changing {updateSurcharge.name} from a{' '}
        <strong>flat rate to a percentage calculated</strong> rate. If you
        confirm please note that any store that already has this surcharge will
        immediately set the rate to{' '}
        <strong>
          {((updateSurcharge.default_rate as number) * 100).toFixed(2)}%
        </strong>
        &nbsp; even if you had previously used a different custom percentage at
        that store historically.
      </p>
      <DialogActions>
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button onClick={() => onConfirm(updateSurcharge)}>
          Confirm New Rate
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RemoveFlatRateDialog
