import createAction from './create-action'
import {
  ADD_DEFAULT_PRODUCTS,
  ADD_STORES,
  SET_ATTRIBUTE_VALUES,
  SET_ATTRIBUTE_VALUES_FAILURE,
  SET_STORES_COUNT,
  UPDATE_ATTRIBUTE_VALUES,
  UPDATE_ATTRIBUTE_VALUES_FAILURE,
} from '@store/action-list'
import {
  ActionType,
  ApiDispatch,
  AttributeValues,
  ProductStoreType,
  Store,
} from '@types'
import { apiAction } from './api'

export const addStore = (store: Store): ActionType => {
  return addStores([store])
}

export const addStores = (stores: Store[]): ActionType => ({
  type: ADD_STORES,
  stores,
})

export const setStoresCount = (count: number): ActionType => ({
  type: SET_STORES_COUNT,
  count,
})

export const getAttributeValues = (storeId: number): ApiDispatch =>
  apiAction({
    url: `/p4/stores/${storeId}/attributes`,
    method: 'GET',
    actionCallbacks: {
      success: createAction(SET_ATTRIBUTE_VALUES, (payload) => ({
        payload: {
          ...payload,
          storeId,
        },
      })),
      failure: createAction(SET_ATTRIBUTE_VALUES_FAILURE),
    },
  })

export const updateAttributeValues = (
  storeId: number,
  attribute: AttributeValues,
): ApiDispatch =>
  apiAction({
    url: `/p4/stores/${storeId}/attributes`,
    method: 'PUT',
    data: { ...attribute, is_disabled: !attribute.is_disabled },
    actionCallbacks: {
      success: createAction(UPDATE_ATTRIBUTE_VALUES, (payload) => ({
        payload: {
          ...payload,
          storeId,
        },
      })),
      failure: createAction(UPDATE_ATTRIBUTE_VALUES_FAILURE),
    },
  })

export const addDefaultProducts = (storeId: number): ApiDispatch =>
  apiAction({
    url: `/p4/stores/${storeId}/syncDefaultMenu`,
    method: 'PATCH',
    actionCallbacks: {
      success: createAction<ProductStoreType[]>(ADD_DEFAULT_PRODUCTS),
      failure: createAction('FAILED'),
    },
  })
