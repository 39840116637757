interface ActionType {
  type?: string
  [key: string]: any
}

export const setFlashMessage = (
  message: string,
  variant: 'success' | 'error',
): any => {
  return {
    type: 'SET_FLASH_MESSAGE',
    payload: {
      message,
      variant,
    },
  }
}

export const clearFlashMessage = (): ActionType => ({
  type: 'CLEAR_FLASH_MESSAGE',
})
