import * as api from '../utils/api'

import { addOrders } from '../store/actions/orders'
import {
  setOrderCountForStore,
  setOrdersForStore,
} from '../store/actions/store-orders'
import { setOrdersForUser } from '../store/actions/user-orders'

/**
 * Fetch a page of orders for a given user
 *
 * @param {Dispatch} dispatch
 */
export const fetchOrdersForUser =
  (dispatch) =>
  async (uid, page, pageSize, setDisplayed = true) => {
    const {
      data: { orders },
    } = await api.GET(`/p4/users/${uid}/orders`, {
      limit: pageSize,
      offset: pageSize * page,
    })

    return Promise.all([
      dispatch(addOrders(orders)),
      setDisplayed &&
        dispatch(
          setOrdersForUser(
            uid,
            orders.map((order) => order.id),
          ),
        ),
    ])
  }

export const fetchOrdersForStore =
  (dispatch) =>
  async (
    storeId,
    page,
    pageSize,
    begin = null,
    end = null,
    setDisplayed = true,
  ) => {
    const {
      data: { orders, total_results },
    } = await api.GET(`/p4/stores/${storeId}/orders`, {
      limit: pageSize,
      offset: page * pageSize,
      begin,
      end,
    })

    return Promise.all([
      dispatch(addOrders(orders)),
      dispatch(setOrderCountForStore(storeId, total_results)),
      setDisplayed &&
        dispatch(
          setOrdersForStore(
            storeId,
            orders.map((order) => order.id),
          ),
        ),
    ])
  }

export const fetchOrder = (dispatch) => async (uid, id) => {
  console.log('Fetching order with id ' + uid, id)

  const { data } = await api.GET(`/p4/users/0/orders/${id}`)

  return Promise.all([
    dispatch(addOrders([data])),
    dispatch(
      setOrdersForUser(
        uid,
        [data].map((order) => order.id),
      ),
    ),
  ])
}
