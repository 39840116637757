import React, { ReactElement } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { getRoleList } from '@queries/roles'
import { sortByKey } from '@utils/array-utils'
import { useQuery } from '@tanstack/react-query'

type Props = {
  value: number
  onChange?(value: number): void
  containerProps?: Record<any, any>
  className?: string
}

const RoleSelector = ({
  value,
  onChange = () => void 0,
  containerProps = {},
  className = '',
}: Props): ReactElement => {
  const { data: roles } = useQuery({
    queryKey: ['roleList'],
    queryFn: getRoleList,
    staleTime: 30000,
  })

  const handleOnChange = (e: SelectChangeEvent<number>) => {
    onChange(e.target.value as number)
  }

  return (
    <FormControl className={className} {...containerProps}>
      <InputLabel id='role-select-label'>Role</InputLabel>
      <Select
        labelId='role-select-label'
        onChange={handleOnChange}
        value={value}
      >
        <MenuItem value={-1}>
          <em>None</em>
        </MenuItem>
        {roles?.sort(sortByKey('role')).map((role) => (
          <MenuItem key={role.id} value={role.id}>
            {role.role}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RoleSelector
