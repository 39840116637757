import createCachedSelector from '@utils/cached-selector'
import { createSelector } from 'reselect'

export const selectVerticalCardById = createCachedSelector(
  (s, id) => s.VerticalCards[id],
  (card) => card,
)((_s, id) => id)

export const selectVerticalCards = createSelector(
  (s) => s.VerticalCards,
  (cards) => cards,
)
