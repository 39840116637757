import omit from 'lodash.omit'
import {
  CREATE_RECOMMENDATION,
  DELETE_RECOMMENDATION,
  SET_RECOMMENDATION,
  SET_RECOMMENDATIONS,
  UPDATE_RECOMMENDATION,
} from '@store/action-list'

const initialState = {}

export default (state = initialState, action) => {
  const { type } = action
  const { payload } = action

  switch (type) {
    case SET_RECOMMENDATION: {
      const { recommendation } = action

      return {
        ...state,
        [recommendation.id]: recommendation,
      }
    }
    case SET_RECOMMENDATIONS: {
      const { recommendations } = action
      return recommendations.reduce((acc, recommendation) => {
        return { ...acc, [recommendation.id]: recommendation }
      }, state)
    }
    case DELETE_RECOMMENDATION: {
      const { recommendationId } = payload

      return omit(state, [recommendationId])
    }
    case UPDATE_RECOMMENDATION: {
      const recommendation = payload

      return {
        ...state,
        [recommendation.id]: recommendation,
      }
    }
    case CREATE_RECOMMENDATION: {
      const recommendation = payload
      return {
        ...state,
        [recommendation.id]: recommendation,
      }
    }
    default:
      return state
  }
}
