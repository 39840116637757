import createCachedSelector from 're-reselect'
import { PromotionType, PromotionsStateType, RootState } from '@types'
import { createSelector } from 'reselect'

const stateSelector = (state: RootState): PromotionsStateType =>
  state.Promotions

export const selectPromotionsPast = createSelector(stateSelector, (state) => {
  return state.past.map((x) => state.all[x])
})

export const selectPromotionsOngoing = createSelector(
  stateSelector,
  (state) => {
    return state.ongoing.map((x) => state.all[x])
  },
)

export const selectPromotionsUpcoming = createSelector(
  stateSelector,
  (state) => {
    return state.upcoming.map((x) => state.all[x])
  },
)

export const selectPromotion = createCachedSelector(
  stateSelector,
  (_, id) => id,
  (promotions, id): PromotionType | null => promotions.all[id] || null,
)((_, id) => id)
