import React, { ReactElement } from 'react'
import { Paper } from '@mui/material'
import { XYCoord, useDragLayer } from 'react-dnd'
import { makeStyles } from 'tss-react/mui'

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }

  const { x, y } = currentOffset

  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
    width: 500,
  }
}

const CustomDragLayer = (): ReactElement | null => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }),
  )

  const { classes } = useStyles()

  if (!isDragging || !item.dragPreview) {
    return null
  }

  return (
    <div className={classes.container}>
      <Paper style={getItemStyles(initialOffset, currentOffset)}>
        {item.dragPreview()}
      </Paper>
    </div>
  )
}

export default CustomDragLayer

const useStyles = makeStyles()({
  container: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
})
