import ProductSelector from '@components/shared/product-selector'
import React, { ReactElement, useState } from 'react'
import Table from '@components/shared/table'
import { Add } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from '@mui/material'
import { getProductList } from '@queries/products'
import { makeStyles } from 'tss-react/mui'
import { useQuery } from '@tanstack/react-query'

type PropsType = {
  productIds: number[]
  onChangeProductIds: (productIds: number[]) => void
  editing: boolean
}

const useStyles = makeStyles()((theme) => ({
  paperWrapper: {
    padding: theme.spacing(2),
  },
}))

const ProductsTrigger = ({
  productIds,
  onChangeProductIds,
  editing,
}: PropsType): ReactElement => {
  const { classes } = useStyles()

  const [addProductDialogOpen, setAddProductDialogOpen] = useState(false)
  const [addProductId, setAddProductId] = useState(-1)

  const { data: products } = useQuery({
    queryKey: ['productList'],
    queryFn: getProductList,
    staleTime: 30000,
  })

  const onAddProductOpen = () => {
    setAddProductDialogOpen(true)
  }

  const onAddProductClose = () => {
    setAddProductDialogOpen(false)
  }

  const handleAddProduct = () => {
    onChangeProductIds([...productIds, addProductId])
    setAddProductDialogOpen(false)
  }

  const handleRemoveProduct = (productId: number) => {
    onChangeProductIds(productIds.filter((x) => x != productId))
  }

  return (
    <>
      <Dialog open={addProductDialogOpen}>
        <DialogTitle id='alert-dialog-title'>
          Add Triggering Product
        </DialogTitle>
        <DialogContent>
          <ProductSelector
            containerProps={{ fullWidth: true }}
            onChange={setAddProductId}
            value={addProductId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onAddProductClose}>Close</Button>
          <Button onClick={handleAddProduct}>Confirm</Button>
        </DialogActions>
      </Dialog>

      {products && (
        <Paper className={classes.paperWrapper}>
          <Table
            columns={[
              {
                title: 'ID',
                field: 'id',
              },
              {
                title: 'Name',
                field: 'name',
              },
            ]}
            data={products.filter((p) => productIds.includes(p.id))}
            rowActions={{
              onRowDelete: ({ id }) => {
                handleRemoveProduct(id)
              },
            }}
            rowKey='id'
            title='Triggered By Products'
            toolbarButtons={
              editing
                ? [
                    {
                      startIcon: <Add />,
                      title: 'Add Product',
                      onClick: onAddProductOpen,
                    },
                  ]
                : undefined
            }
          />
        </Paper>
      )}
    </>
  )
}

export default ProductsTrigger
