import AlertDialog from '@shared/dialogs/alert-dialog'
import React, { ReactElement, useState } from 'react'
import { Button, InputBase } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useAllActions } from '@hooks/use-all-actions'

const useStyles = makeStyles()((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 2,
    border: `1px solid #d5d5d5`,
  },
  output: {
    border: '1px solid gray',
  },
}))

interface Props {
  user: any
}
const CCPA = ({ user }: Props): ReactElement => {
  const { classes } = useStyles()
  const [email, setEmail] = useState('')
  const [CCPAInputDisabled, setCCPAInputDisabled] = useState(false)
  const [exportingConfirmDialogOpen, setExportingConfirmDialogOpen] =
    useState(false)
  const [deletingConfirmDialogOpen, setDeletingConfirmDialogOpen] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState('')
  const { ccpaDelete, ccpaExport, setFlashMessage } = useAllActions()

  const submitDelete = async () => {
    setLoading(true)
    setCCPAInputDisabled(true)

    const results = await ccpaDelete(user.id, email).catch((e) => {
      setFlashMessage(e.message, 'error')
      setResult(`Unable to process deletion request: ${e.message}`)
    })

    setResult(results)
    setEmail('')
    setDeletingConfirmDialogOpen(false)
    setFlashMessage(`User '${user.name}' deleted`, 'success')
    setLoading(false)
    setCCPAInputDisabled(false)
  }

  const submitExport = async () => {
    setLoading(true)
    setCCPAInputDisabled(true)

    const results = await ccpaExport(user.id, email).catch((e) => {
      setResult(`Unable to process export request: ${e.message}`)
      setFlashMessage(e.message, 'error')
    })

    setResult(results)
    setEmail('')
    setFlashMessage(`User '${user.name}' exported`, 'success')
    setExportingConfirmDialogOpen(false)
    setCCPAInputDisabled(false)
    setLoading(false)
  }

  return (
    <div>
      <div>
        <label>Confirm email</label>
        <InputBase
          className={classes.input}
          disabled={CCPAInputDisabled}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <Button
          disabled={CCPAInputDisabled}
          onClick={() => setExportingConfirmDialogOpen(true)}
        >
          Export User
        </Button>
        <Button
          disabled={CCPAInputDisabled}
          onClick={() => setDeletingConfirmDialogOpen(true)}
        >
          Delete User
        </Button>
        <AlertDialog
          closeTitle={'Cancel'}
          confirmTitle={'Export'}
          loading={loading ? loading : undefined}
          message={`Are you sure you want to export user '${
            user ? user.name : ''
          }'?`}
          onClose={() => setExportingConfirmDialogOpen(false)}
          onConfirm={submitExport}
          open={exportingConfirmDialogOpen}
          title={'Export User'}
        />
        <AlertDialog
          closeTitle={'Cancel'}
          confirmTitle={'Delete'}
          loading={loading ? loading : undefined}
          message={`Are you sure you want to delete user '${
            user ? user.name : ''
          }'?`}
          onClose={() => setDeletingConfirmDialogOpen(false)}
          onConfirm={submitDelete}
          open={deletingConfirmDialogOpen}
          title={'Delete User'}
        />
      </div>
      <div>
        <pre className={classes.output}>
          {result ? JSON.stringify(result, null, '\t') : null}
        </pre>
      </div>
    </div>
  )
}

export default CCPA
