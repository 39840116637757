import React, { ReactElement, useState } from 'react'
import Table from '@components/shared/deprecated/table'
import { Button, CircularProgress, TableCell, TableRow } from '@mui/material'
import { OrderProductWithOrderIdType, OrderType } from '@types'
import { Undo } from '@mui/icons-material'

import RefundModal from './refund-modal'
import { formatCurrency } from '@utils/number-utils'
import { formatShort } from '@utils/date-utils'
import { hasPerms, hasPermsAtStore } from '@utils/has-permission'

type Props = {
  order: OrderType
}

const OrderDetails = ({ order }: Props): ReactElement => {
  const [refundOrderProductIds, setRefundOrderProductIds] = useState<number[]>(
    [],
  )
  const [allSubmitting, setAllSubmitting] = useState<boolean>(false)
  const [idSubmitting, setIdSubmitting] = useState<null | number>(null)
  const isSubmitting = idSubmitting !== null || allSubmitting
  const userCanRefund =
    hasPerms('orders:refund') ||
    hasPermsAtStore('stores:refund', order.store_id)

  const getSubtotal = () => {
    if (order == null) return 'N/A'
    return formatCurrency(
      Object.values(order.products).reduce((acc, next) => acc + next.price, 0),
    )
  }

  const orderProducts = Object.keys(order.products).map(
    (id): OrderProductWithOrderIdType => {
      return { ...order.products[id], id }
    },
  )

  const isFullyRefunded = !orderProducts.some(
    (product) => product.refunded_at === null,
  )

  const nonRefundedOrderProductIds = orderProducts.reduce(
    (ids: number[], op) => {
      if (!op.refunded_at) ids.push(parseInt(op.id, 10))
      return ids
    },
    [],
  )

  const handleRefundModalClose = () => {
    setIdSubmitting(null)
    setAllSubmitting(false)
  }

  const toolbarButtons = userCanRefund
    ? [
        {
          disabled: isSubmitting || isFullyRefunded,
          disableTooltip: true,
          title: 'Refund Entire Order',
          variant: 'outlined',
          startIcon: allSubmitting ? <CircularProgress size={20} /> : <Undo />,
          onClick: async () => {
            setAllSubmitting(true)
            setRefundOrderProductIds(nonRefundedOrderProductIds)
          },
        },
      ]
    : []

  const renderRefundedColumn = (
    val: OrderProductWithOrderIdType['refunded_at'],
    row: OrderProductWithOrderIdType,
  ) => {
    const isRefunded = val !== null
    const rowId = parseInt(row.id, 10)

    const onClick = async () => {
      setIdSubmitting(rowId)
      setRefundOrderProductIds([rowId])
    }

    const getRawValue = () => (isRefunded ? formatShort(val) : 'false')

    return isRefunded || !userCanRefund ? (
      getRawValue()
    ) : (
      <Button
        disabled={isSubmitting}
        onClick={onClick}
        startIcon={
          idSubmitting === rowId ? <CircularProgress size={20} /> : <Undo />
        }
        variant='outlined'
      >
        Refund
      </Button>
    )
  }

  return (
    <>
      <RefundModal
        onClose={handleRefundModalClose}
        orderId={order.id}
        orderProductIds={refundOrderProductIds}
      />
      <Table
        data={orderProducts}
        fields={[
          'name',
          'variant',
          'special_instructions',
          {
            field: 'price',
            transform: (price: OrderProductWithOrderIdType['price']) =>
              formatCurrency(price),
          },
          {
            field: 'refunded_at',
            transform: renderRefundedColumn,
          },
        ]}
        headers={[
          'Product Name',
          'Variant',
          'Special Instructions',
          'Price',
          'Refund?',
        ]}
        rowKey='id'
        title={'Order Details'}
        toolbarButtons={toolbarButtons}
      >
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell>Subtotal</TableCell>
          <TableCell>{getSubtotal()}</TableCell>
          <TableCell colSpan={1} />
        </TableRow>
        {Object.keys(order.tax).map((taxKey) => (
          <TableRow key={taxKey}>
            <TableCell colSpan={2} />
            <TableCell>{taxKey}</TableCell>
            <TableCell>{formatCurrency(order.tax[taxKey])}</TableCell>
            <TableCell colSpan={1} />
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell>Tip</TableCell>
          <TableCell>{formatCurrency(order.tip_amount)}</TableCell>
          <TableCell colSpan={1} />
        </TableRow>
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell>Total</TableCell>
          <TableCell>
            {formatCurrency(order.price + order.tip_amount)}
          </TableCell>
          <TableCell colSpan={1} />
        </TableRow>
      </Table>
    </>
  )
}

export default OrderDetails
