/* eslint-disable react/prop-types, react/jsx-key */
import HoursPickerDialog from './hours-picker-dialog'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

import Toolbar from '../../shared/toolbar'
import { Box, Button, Paper, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

import { fetchHours, updateHours } from '../../../services/store-hours-service'

import { getFormattedTime } from '../../../utils/date-utils'

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  hoursDesc: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  hoursRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
  },
  hoursRow: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  editableRow: {
    cursor: 'pointer',
  },
  holidaysButton: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    color: 'white',
  },
}))

const mapStateToProps = (state, ownProps) => ({
  hours: state.StoreHours[ownProps.storeId],
  overrides: state.StoreHoursOverrides[ownProps.storeId],
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    fetchHours: () => fetchHours(dispatch)(ownProps.storeId),
    updateHours: (dayOfWeek, opens, closes) =>
      updateHours(dispatch)(ownProps.storeId, dayOfWeek, opens, closes),
    setFlashMessage: (message, variant) =>
      dispatch(setFlashMessage(message, variant)),
  },
})

function HoursRow(props) {
  const { classes } = useStyles()
  const hours = props.hours || {}
  const fontWeight = props.isToday ? 600 : 400
  const { editable } = props

  const closed =
    hours.opening_time === '00:00:00' && hours.closing_time === '00:00:00'

  return (
    <Typography
      className={`${classes.hoursRow} ${editable ? classes.editableRow : ''}`}
      component='div'
      onClick={editable ? props.onClick : null}
    >
      <Box fontWeight={fontWeight} textAlign='left'>
        {props.title}
      </Box>
      <Box
        className={classes.hoursText}
        fontWeight={fontWeight}
        textAlign='right'
      >
        {closed
          ? 'CLOSED'
          : `${getFormattedTime(hours.opening_time)} - ${getFormattedTime(
              hours.closing_time,
            )}`}
      </Box>
    </Typography>
  )
}

HoursRow.propTypes = {
  title: PropTypes.string,
  isToday: PropTypes.bool,
  hours: PropTypes.object,
  onClick: PropTypes.func,
}

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

/**
 * Store hours widget. Internally, dates are all in the store's timezone by default.
 * For convenience, other timezones can be used, but for UI purposes only.
 */
function HoursWidget(props) {
  const { actions, editable } = props
  const { classes, cx } = useStyles()
  const [hasFetchedData, setHasFetchedData] = useState(false)
  const [redirect, setRedirect] = useState(null)

  // TODO: Try to clean this up. Can we get rid of the 'initial; stuff?
  const [currentlyEditingHoursForDay, setCurrentlyEditingHoursForDay] =
    useState(null)
  const [initialDialogOpeningTime, setInitialDialogOpeningTime] = useState(null)
  const [initialDialogClosingTime, setInitialDialogClosingTime] = useState(null)
  const [isUpdatingHours, setIsUpdatingHours] = useState(false)

  if (!hasFetchedData) {
    setHasFetchedData(true)
    actions
      .fetchHours()
      .catch((error) => actions.setFlashMessage(error.message, 'error'))
  }

  useEffect(() => {
    if (props.hours == null || currentlyEditingHoursForDay == null) return
    const hours = props.hours[currentlyEditingHoursForDay.toLowerCase()]
    if (hours == null) {
      setInitialDialogOpeningTime(null)
      setInitialDialogClosingTime(null)
    } else {
      setInitialDialogOpeningTime(hours.opening_time)
      setInitialDialogClosingTime(hours.closing_time)
    }
  }, [currentlyEditingHoursForDay])

  if (redirect) {
    return <Navigate to={redirect} />
  }

  const dayOfWeek = days[new Date().getDay()]

  const renderHolidayHours = true

  function getTodaysHolidayHours() {
    if (!props.overrides) return null
    const today = new Date()
    const key = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${today
      .getDate()
      .toString()
      .padStart(2, '0')} 00:00:00`
    return props.overrides[key]
  }

  const todaysHolidayHours = getTodaysHolidayHours()

  return (
    <Paper className={cx(classes.container, props.className)}>
      <HoursPickerDialog
        closes={initialDialogClosingTime}
        day={currentlyEditingHoursForDay}
        loading={isUpdatingHours}
        onClose={() => setCurrentlyEditingHoursForDay(null)}
        onConfirm={(opens, closes) => {
          setIsUpdatingHours(true)
          actions
            .updateHours(
              currentlyEditingHoursForDay.toLowerCase(),
              opens,
              closes,
            )
            .then(() => {
              setIsUpdatingHours(false)
              setCurrentlyEditingHoursForDay(null)
              actions.setFlashMessage('Hours Updated', 'success')
            })
            .catch((error) => {
              setIsUpdatingHours(false)
              actions.setFlashMessage(error.message, 'error')
            })
        }}
        open={currentlyEditingHoursForDay != null}
        opens={initialDialogOpeningTime}
        title={`${currentlyEditingHoursForDay} Hours`}
      />
      <Toolbar
        // select={ {
        //     onChange: e => setTimeZone(e.target.value),
        //     value: timeZone,
        //     options: [
        //         {
        //             title: props.storeTimeZone,
        //             value: props.storeTimeZone,
        //         },
        //         shouldShowLocalTZ && {
        //             title: localTimeZone,
        //             value: localTimeZone,
        //         },
        //     ].filter(x => x),
        // } }
        title={'Store Hours'}
      />
      {editable && (
        <Typography className={classes.hoursDesc}>
          <em>Click a day to edit</em>
        </Typography>
      )}
      <div className={classes.hoursRowContainer}>
        {todaysHolidayHours && (
          <HoursRow
            hours={todaysHolidayHours}
            isToday
            title={"Today's Holiday Hours"}
          />
        )}
        {days.map((day) => (
          <HoursRow
            editable={editable}
            hours={(props.hours || {})[day.toLowerCase()]}
            isToday={!todaysHolidayHours && dayOfWeek === day}
            onClick={() => {
              setCurrentlyEditingHoursForDay(day)
            }}
            title={day}
          />
        ))}
      </div>
      {renderHolidayHours && editable && (
        <Button
          className={classes.holidaysButton}
          color='secondary'
          onClick={() => setRedirect(`/stores/${props.storeId}/holidays`)}
          variant='contained'
        >
          Manage Holidays
        </Button>
      )}
    </Paper>
  )
}

HoursWidget.propTypes = {
  storeId: PropTypes.number,
  storeTimeZone: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(HoursWidget)
