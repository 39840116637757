import PropTypes from 'prop-types'
import React from 'react'
import { Button, IconButton, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'

function ToolbarButton(props) {
  const renderButton = () => {
    if (props.icon) {
      return (
        <IconButton
          aria-label={props.title}
          className={props.className}
          disabled={props.disabled}
          onClick={props.onClick}
        >
          {props.icon}
        </IconButton>
      )
    } else {
      return (
        <Button
          aria-label={props.title}
          className={props.className}
          disabled={props.disabled}
          endIcon={props.endIcon}
          onClick={props.onClick}
          startIcon={props.startIcon}
          style={{ color: props.color }}
          variant={props.variant}
        >
          {props.title}
        </Button>
      )
    }
  }

  if (props.route) {
    return (
      <Tooltip key={props.title} title={props.title}>
        <Link to={props.route}>{renderButton()}</Link>
      </Tooltip>
    )
  } else {
    return props.disableTooltip ? (
      renderButton()
    ) : (
      <Tooltip key={props.title} title={props.title}>
        {renderButton()}
      </Tooltip>
    )
  }
}

ToolbarButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  route: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  disableTooltip: PropTypes.bool,
  endIcon: PropTypes.node,
  startIcon: PropTypes.node,
  className: PropTypes.node,
}

ToolbarButton.defaultProps = {
  variant: 'text',
  disableTooltip: false,
}

export default ToolbarButton
