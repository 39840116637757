import createAction, { PayloadAction } from './create-action'
import {
  ADD_USERS,
  ADD_USERS_FAILURE,
  EDIT_USER,
  SET_USER_COUNT,
} from '../action-list'
import { ApiDispatch, AppDispatch, UserType } from '@types'
import { apiAction } from './api'
import { setCurrentlyDisplayedUsers } from './currently-displayed-users'
/**
 * Action creator for adding users to the redux store.
 *
 * @param {[User]} users
 */
export const addUsers = createAction<UserType[]>(ADD_USERS)

/**
 * Action create for adding a single user to the redux store
 */
export const addUser = (user: UserType): PayloadAction<UserType[]> =>
  addUsers([user])

export const updateUser = createAction<{ userId: number; userData: UserType }>(
  EDIT_USER,
)

/**
 * Sets the 'total count' for users
 */
export const setUserCount = createAction<number>(SET_USER_COUNT)

const handleAddUsersSuccess = (setDisplayed: boolean) => ({
  users,
  total_rows: totalRows,
}: {
  users: UserType[]
  total_rows: number
}) => (dispatch: AppDispatch) => {
  dispatch(addUsers(users))
  dispatch(setUserCount(totalRows))
  if (setDisplayed) {
    dispatch(setCurrentlyDisplayedUsers(users.map((user) => user.id)))
  }
}

export const fetchUsers = (
  page: number,
  pageSize: number,
  setDisplayed = true,
): ApiDispatch => {
  return apiAction({
    url: '/p4/users',
    method: 'GET',
    params: {
      limit: pageSize,
      offset: pageSize * page,
    },
    actionCallbacks: {
      success: handleAddUsersSuccess(setDisplayed),
      failure: createAction(ADD_USERS_FAILURE),
    },
  })
}
