import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import MaterialTable from '@material-table/core'

import hasPerms from '@utils/has-permission'
import { fetchStores } from '../../services/store-service'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

const mapStateToProps = (state) => ({
  stores: Object.keys(state.Stores.stores)
    .sort((a, b) => a - b)
    .map((storeId) => {
      return state.Stores.stores[storeId]
    }),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchStores: fetchStores(dispatch),
    ...bindActionCreators(
      {
        setFlashMessage,
      },
      dispatch,
    ),
  },
})

function Stores(props) {
  const { actions } = props
  const [didFetchInitialStores, setDidFetchInitialStores] = useState(false)
  const [redirectToStore, setRedirectToStore] = useState(null)
  const navigate = useNavigate()

  const canCreate = hasPerms('stores:create')

  if (redirectToStore) return <Navigate to={`/stores/${redirectToStore}`} />

  const fetchStores = async () => {
    try {
      await actions.fetchStores()
    } catch (error) {
      actions.setFlashMessage(error.message, 'error')
    }
  }

  if (!didFetchInitialStores) {
    setDidFetchInitialStores(true)
    fetchStores()
  }

  return (
    <MaterialTable
      actions={
        canCreate
          ? [
              {
                tooltip: 'Create Store',
                icon: 'add_box',
                isFreeAction: true,
                onClick: () => navigate('/stores/create-store'),
              },
            ]
          : []
      }
      columns={[
        { title: 'Name', field: 'name', defaultSort: 'asc' },
        { title: 'Region', field: 'region' },
        { title: 'State', field: 'state' },
        { title: 'Phone', field: 'phone' },
      ]}
      data={props.stores}
      onRowClick={(_, row) => setRedirectToStore(row.id)}
      options={{ paging: false }}
      title='Stores'
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Stores)
