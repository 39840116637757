import React, { ReactElement } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { getStoreList } from '@queries/stores'
import { sortByKey } from '@utils/array-utils'
import { useQuery } from '@tanstack/react-query'

type Props = {
  value: number
  onChange?(value: number): void
  containerProps?: Record<any, any>
  className?: string
}

const StoreSelector = ({
  value,
  onChange = () => void 0,
  containerProps = {},
  className = '',
}: Props): ReactElement => {
  const { data: stores } = useQuery({
    queryKey: ['storeList'],
    queryFn: getStoreList,
    staleTime: 30000,
  })

  const handleOnChange = (e: SelectChangeEvent<number>) => {
    onChange(e.target.value as number)
  }

  return (
    <FormControl className={className} {...containerProps}>
      <InputLabel id='store-select-label'>Store</InputLabel>
      <Select
        labelId='store-select-label'
        onChange={handleOnChange}
        value={value}
      >
        <MenuItem value={-1}>
          <em>None</em>
        </MenuItem>
        {stores?.sort(sortByKey('name')).map((store) => (
          <MenuItem key={store.id} value={store.id}>
            {store.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default StoreSelector
