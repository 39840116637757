import createCachedSelector from 're-reselect'
import { RootState, SurchargeType, TaxRateType } from '@types'
import { createSelector } from 'reselect'

type State = {
  TaxRates: Record<number, TaxRateType[]>
  Surcharges: SurchargeType[]
}

const taxRatesSelector = (state: RootState): State => state.TaxRates

export const taxRatesAtStoreSelector = createCachedSelector(
  taxRatesSelector,
  (_: State, storeId: number) => storeId,
  (state, storeId) => {
    return state.TaxRates[storeId] ?? []
  },
)((_, storeId) => storeId)

export const surchargesSelector = createSelector(taxRatesSelector, (state) => {
  return state.Surcharges
})
