import * as api from '../utils/api'

import {
  setHoursForStore,
  setHoursForStoreForDay,
} from '../store/actions/store-hours'
import {
  setOverrides,
  updateOverride,
} from '../store/actions/store-hours-overrides'

export const fetchHours = (dispatch) => async (storeId) => {
  console.log(`Fetching store hours for store ${storeId}`)
  const {
    data: { hours, overrides },
  } = await api.GET(`/p4/stores/${storeId}/hours`)
  return Promise.all([
    dispatch(setOverrides(overrides)),
    dispatch(setHoursForStore(storeId, hours)),
  ])
}

export const setOverrideClosed = (dispatch) => async (
  storeId,
  date,
  isClosed,
) => {
  return updateHoursRecord(dispatch)(storeId, {
    override_date: date,
    is_closed: isClosed,
  })
}

export const setOverrideHoliday = (dispatch) => async (
  storeId,
  date,
  isHoliday,
) => {
  return updateHoursRecord(dispatch)(storeId, {
    override_date: date,
    is_holiday: isHoliday,
  })
}

export const deleteOverride = (dispatch) => async (storeId, date) => {
  return updateHoursRecord(dispatch)(storeId, {
    override_date: date,
    archived_at: true,
  })
}

export const updateHours = (dispatch) => async (
  storeId,
  dayOfWeek,
  opens,
  closes,
) => {
  return updateHoursRecord(dispatch)(storeId, {
    day_of_week: dayOfWeek,
    opening_time: opens,
    closing_time: closes,
    override_date: null,
  })
}

/**
 * For updating a literal record in the database, using the key/values from the data passed
 * in.
 */
export const updateHoursRecord = (dispatch) => async (storeId, hours) => {
  console.log(`Updating store hours for store ${storeId}`)
  const { data } = await api.PATCH(`/p4/stores/${storeId}/hours`, {
    store_hours: [hours],
  })
  // Gives us back a list with one element
  if (data[0].override_date) return dispatch(updateOverride(data[0]))
  else return dispatch(setHoursForStoreForDay(storeId, data[0]))
}
