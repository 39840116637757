import React from 'react'
import { Link } from 'react-router-dom'
import { formatCurrency } from '@utils/number-utils'
import { formatLong } from '@utils/date-utils'

const renderLogItemDefault = (property, value, i) => (
  <li key={i}>
    {property}: {value}
  </li>
)

const renderLogCurrency = (property, value, i) => (
  <li key={i}>
    {property}: {formatCurrency(value)}
  </li>
)

const renderLogUTCString = (property, value, i) => (
  <li key={i}>
    {property}: {formatLong(new Date(value).toISOString())}
  </li>
)

const renderLogStore = (property, store, i) => (
  <li key={i}>
    {property}: <Link to={`/stores/${store.id}`}>{store.name}</Link>
  </li>
)

const renderLogProductList = (property, products_list, i) => {
  return (
    <li key={i}>
      {property}
      <ul>
        {products_list.map((product, x) => {
          return (
            <li key={x}>
              <Link to={`/products/${product.id}`}>{product.name}</Link>
              {product.variants ? <div>{product.variants}</div> : null}
              {product.special_instructions ? (
                <div>
                  Instruction: &quot;{product.special_instructions}&quot;
                </div>
              ) : null}
            </li>
          )
        })}
      </ul>
    </li>
  )
}

const logItemRenderers = {
  tip_amount: renderLogCurrency,
  cancellable_until: renderLogUTCString,
  stripe_payment_time: renderLogUTCString,
  products: renderLogProductList,
  recommendations_added: renderLogProductList,
  store: renderLogStore,
}

function LogChanges({ data }) {
  return Object.keys(data).map((key, i) => {
    const value = data[key]
    const logItemRenderer = logItemRenderers[key]

    const itemRender =
      value && logItemRenderer ? logItemRenderer : renderLogItemDefault

    return itemRender(key.replaceAll('_', ' '), value, i)
  })
}

export default LogChanges
