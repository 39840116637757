import { SET_SEARCH_RESULTS } from '../action-list'

// Initial state
const initialState = {
  query: null,
  results: [],
}

export default (state = initialState, action) => {
  const { query, results } = action

  switch (action.type) {
    case SET_SEARCH_RESULTS:
      console.log(
        `Redux: Setting search results to ${results.length} results for query ${query}`,
      )
      return { query, results }
    default:
      return state
  }
}
