import createAction from './create-action'
import { APIActionType, apiAction } from './api'
import { ApiDispatchReturn } from '@types'
import { PayloadActionCreator } from '@reduxjs/toolkit'

type LifeCycleTypes<R> = {
  pending: PayloadActionCreator
  fulfilled: PayloadActionCreator<R>
  rejected: PayloadActionCreator
}

type CreateAsyncActionR<R, ThunkArg> = {
  (arg: ThunkArg): ApiDispatchReturn<R>
} & LifeCycleTypes<R>

type CreateAsyncActionRNoArgs<R> = {
  (): ApiDispatchReturn<R>
} & LifeCycleTypes<R>

type CreateActionArgsType = Omit<APIActionType, 'actionCallbacks'>

type ApiParamsType<T, ReturnValue = CreateActionArgsType> =
  | (() => ReturnValue)
  | ((arg: T) => ReturnValue)

// typescript doesn't like that return type isn't used here
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createAsyncAction<R>(
  baseActionType: string,
  apiParams: () => CreateActionArgsType,
): CreateAsyncActionRNoArgs<R>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createAsyncAction<R, ThunkArg = unknown>(
  baseActionType: string,
  apiParams: (arg: ThunkArg) => CreateActionArgsType,
): CreateAsyncActionR<R, ThunkArg>

export function createAsyncAction<R, ThunkArg = unknown>(
  baseActionType: string,
  apiParams: ApiParamsType<ThunkArg>,
): CreateAsyncActionR<R, ThunkArg> {
  const pending = createAction(`${baseActionType}/pending`)
  const fulfilled = createAction<R>(`${baseActionType}/fulfilled`)
  const rejected = createAction(`${baseActionType}/rejected`)

  function actionCreator(arg: ThunkArg) {
    const params =
      apiParams instanceof Function ? apiParams(arg as ThunkArg) : apiParams

    return apiAction<R>({
      ...params,
      actionCallbacks: {
        request: pending,
        success: fulfilled,
        failure: rejected,
      },
    })
  }

  return Object.assign(actionCreator, { pending, fulfilled, rejected })
}

export default createAsyncAction
