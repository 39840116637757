import * as api from '@utils/api'
import React, { ReactElement, useState } from 'react'
import ResponsiveDialog from '@shared/responsive-dialog'
import Toolbar from '@shared/toolbar'
import { makeStyles } from 'tss-react/mui'

import {
  Button,
  DialogActions,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'

import { useQuery } from '@tanstack/react-query'

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  fullWidth: {
    width: '100%',
  },
  seperator: {
    marginTop: theme.spacing(2),
  },
}))

interface Props {
  open: boolean
  onClose(): void
  onConfirm(any): void
}

const AddTypeDialog = ({ onClose, onConfirm, open }: Props): ReactElement => {
  const { classes } = useStyles()
  const [newAttr, setNewAttr]: [string, any] = useState('')
  const [selectedAttr, setSelectedAttr]: [string, any] = useState('')
  const { isLoading, data: attrList } = useQuery({
    queryKey: ['attributeList'],
    queryFn: () => {
      return api.GET('/p4/products/attributes-names').then((res) => res.data)
    },
    staleTime: 30000,
  })

  const clearValues = (): void => {
    setNewAttr('')
    setSelectedAttr('')
  }

  const confirm = () => {
    if (newAttr) {
      onConfirm(newAttr)
    } else if (selectedAttr) {
      onConfirm(selectedAttr)
    } else {
      onClose()
    }
    clearValues()
  }

  const close = (): void => {
    clearValues()
    onClose()
  }

  const updateNewAttr = (e) => {
    setNewAttr(e.target.value as string)
  }

  const updateSelectedAttr = (e: SelectChangeEvent<string>) => {
    setSelectedAttr(e.target.value)
  }

  if (isLoading) return <div>loading</div>

  return (
    <ResponsiveDialog
      className={classes.container}
      loading={false}
      onClose={close}
      open={open}
    >
      <div className={classes.container}>
        <Toolbar title='Add Attribute' />
        <Typography align='center'>Select an</Typography>
        <FormControl className={classes.fullWidth}>
          <InputLabel>Existing Attribute</InputLabel>
          <Select onChange={updateSelectedAttr} value={selectedAttr}>
            {attrList.map((attr: string): ReactElement => {
              return (
                <MenuItem key={attr} value={attr}>
                  {attr}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <Typography align='center' className={classes.seperator}>
          OR create a
        </Typography>
        <FormControl className={classes.fullWidth}>
          <InputLabel>New Attribute</InputLabel>
          <Input onChange={updateNewAttr} value={newAttr} />
        </FormControl>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={confirm}>Confirm</Button>
        </DialogActions>
      </div>
    </ResponsiveDialog>
  )
}

export default AddTypeDialog
