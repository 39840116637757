import '../../utils/array-utils'

import { RecommendedProductsInitialStateType } from '@types'
import { arrayToObject } from '@utils/functional'
import {
  creatRecommendedProductSuccess,
  deleteRecommendedProductSuccess,
  setRecommendedProducts,
  updateRecommendedProductSuccess,
} from '@store/actions/recommended-products'
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  all: {},
} as RecommendedProductsInitialStateType

const recommendedProductsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      setRecommendedProducts,
      (state, { payload: recommendedProducts }) => {
        state.all = arrayToObject('id', recommendedProducts)
      },
    )
    .addCase(
      updateRecommendedProductSuccess,
      (state, { payload: recommendedProduct }) => {
        state.all[recommendedProduct.id] = recommendedProduct
      },
    )
    .addCase(
      deleteRecommendedProductSuccess(0),
      (state, { payload: { id } }) => {
        delete state.all[id]
      },
    )
    .addCase(
      creatRecommendedProductSuccess,
      (state, { payload: recommendedProduct }) => {
        state.all[recommendedProduct.id] = recommendedProduct
      },
    )
})

export default recommendedProductsReducer
