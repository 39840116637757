import * as api from '@utils/api'

export const SET_ROLE = 'SET_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'
export const SET_ROLES = 'SET_ROLES'

export const getRoles = () => async (dispatch) => {
  const { data: roles } = await api.GET('/p4/roles')

  return dispatch({
    type: SET_ROLES,
    roles,
  })
}

export const createRole = (role) => async (dispatch) => {
  const { data } = await api.POST('/p4/roles', role)

  return dispatch({
    type: SET_ROLE,
    role: data,
  })
}

export const getRole = (id) => async (dispatch) => {
  const { data: role } = await api.GET(`/p4/roles/${id}`)

  return dispatch({
    type: SET_ROLE,
    role,
  })
}

export const saveRole = (role) => async (dispatch) => {
  const { data } = await api.PATCH(`/p4/roles/${role.id}`, role)

  return dispatch({
    type: SET_ROLE,
    role: data,
  })
}

export const setRole = (role) => async (dispatch) => {
  return dispatch({
    type: SET_ROLE,
    role: role,
  })
}

export const setRoles = (updatedroles) => async (dispatch) => {
  const roles = Object.values(updatedroles)

  return dispatch({
    type: SET_ROLES,
    roles,
  })
}
