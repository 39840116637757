import ApiResponseError from '../models/errors/api-response-error'
import axios from 'axios'
import { store } from '../store'

const api = axios.create()
const defaultEnvironment = 'http://localhost:8000'

api.interceptors.request.use((defaultConfig) => {
  const {
    CurrentUser: { token },
  } = store.getState()
  const headers = {
    ...(token ? { Authorization: `P4 ${token}` } : null),
  }

  return {
    ...defaultConfig,
    mode: 'cors',
    baseURL: getEnvironment(),
    headers,
  }
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error

    if (response == null) {
      return Promise.reject(
        new ApiResponseError({
          message: 'Something went wrong.',
        }),
      )
    } else {
      return Promise.reject(
        new ApiResponseError({
          ...response.data,
          ...response,
        }),
      )
    }
  },
)

export const getEnvironment = () => {
  if (ENV == 'development') {
    return process.env.API_URL || API_URL || defaultEnvironment
  } else {
    return API_URL || defaultEnvironment
  }
}

export const CALL = ({ url, method, params, data }) =>
  api({ url, method, params, data })
export const DELETE = (url, params) => api({ url, method: 'DELETE', params })
export const GET = (url, params) => api({ url, method: 'GET', params })
export const PATCH = (url, data) => api({ url, method: 'PATCH', data })
export const POST = (url, data) => api({ url, method: 'POST', data })
export const PUT = (url, data) => api({ url, method: 'PUT', data })
