import PropTypes from 'prop-types'
import React from 'react'

import {
  CircularProgress,
  Dialog,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  loaderContainer: {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    backgroundColor: alpha('#ffffff', 0.8),
  },
}))

function ResponsiveDialog(props) {
  const theme = useTheme()
  const { classes } = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog fullScreen={fullScreen} {...props}>
      {props.loading && (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}
      {props.children}
    </Dialog>
  )
}

ResponsiveDialog.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

export default ResponsiveDialog
