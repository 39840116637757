/**
 * @fileoverview This service serves as an abstraction layer for authentication
 */
import * as api from '../utils/api'
import { store } from '../store'

import {
  logout as logoutRedux,
  updateUser,
} from '../store/actions/current-user'
import { setLoggedIn } from '../store/actions/login-status'

export const login = (dispatch) => async (email, password) => {
  console.log(`Signing in user ${email}`)
  const { data } = await api.POST('/p4/login', { email, password })
  return Promise.all([dispatch(updateUser(data)), dispatch(setLoggedIn(true))])
}

export const logout = (dispatch) => async () => {
  return Promise.all([dispatch(logoutRedux()), dispatch(setLoggedIn(false))])
}

export const fetchCurrentUserInfo = () => async (dispatch) => {
  console.log(`Fetching current user info`)
  const currentUser = store.getState().CurrentUser
  const { user, token } = currentUser

  if (user.id == null || token == null) {
    dispatch(setLoggedIn(false))

    const err = new Error('no_current_user')
    err.status = 403
    throw err
  }

  const { data } = await api.GET(`/p4/users/${user.id}`)
  return Promise.all([
    dispatch(updateUser({ user: data })),
    dispatch(setLoggedIn(true)),
  ])
}

export const sendPasswordResetEmail = (email) => {
  console.log(`Sending password reset email`)
  return api.POST(`/p4/passwordReset`, { email })
}
