import { SET_INITIAL_INVENTORY, UPDATE_INITIAL_INVENTORY } from '../action-list'

/**
 * The state is formatted { storeId: { productId: {[day]: number} } }
 *
 * For example, a single key/value might be:
 *
 * 5: {
 *  4: { 'monday': 5, 'tuesday': 7 }
 *  6: { 'wednesday': 3 }
 * }
 *
 */
const initialState = {}

const updateInventory = (state, inventory) => {
  const newState = { ...state }
  inventory.forEach((row) => {
    if (newState[row.store_id] == null) newState[row.store_id] = {}

    if (newState[row.store_id][row.product_id] == null)
      newState[row.store_id][row.product_id] = {}

    newState[row.store_id][row.product_id][row.day_of_week] =
      row.initial_quantity
  })
  return newState
}

export default (state = initialState, action) => {
  const { inventory } = action
  switch (action.type) {
    case SET_INITIAL_INVENTORY:
      console.log(
        `Redux: Setting initial inventory with ${inventory.length} items.`,
      )
      return updateInventory({}, inventory)
    case UPDATE_INITIAL_INVENTORY:
      console.log(`Redux: Updating inventory with ${inventory.length} items`)
      return updateInventory(state, inventory)
    default:
      return state
  }
}
