import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { Paper } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: 'white',
    height: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 100000,
  },
  contentContainer: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    minWidth: '400px',
  },
}))

function FullscreenPage(props) {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(
        classes.container,
        props.contentMode ? classes.contentContainer : null,
      )}
    >
      {props.contentMode ? (
        <Paper className={classes.content}>{props.children}</Paper>
      ) : (
        <div style={{ width: '100%', height: '100%' }}>{props.children}</div>
      )}
    </div>
  )
}

FullscreenPage.propTypes = {
  contentMode: PropTypes.bool,
}

export default FullscreenPage
