import AddDefaultProducts from './add-default-products'
import React, { ReactElement } from 'react'
import { Add } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
  },
  content: {
    width: '400px',
    marginBottom: theme.spacing(3),
  },
}))

type Props = {
  storeId: number
  onAddProduct: () => void
}

const EmptyMenu = ({ storeId, onAddProduct }: Props): ReactElement => {
  const { classes } = useStyles()

  return (
    <div className={classes.container}>
      <Typography align='center' className={classes.content}>
        It seems you have not added any menu products.
      </Typography>
      <AddDefaultProducts storeId={storeId} variant='outlined' />
      <Typography align='center'>OR</Typography>
      <Button onClick={onAddProduct} startIcon={<Add />} variant='outlined'>
        Add Product
      </Button>
    </div>
  )
}

export default EmptyMenu
