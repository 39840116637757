import PropTypes from 'prop-types'
import React from 'react'
import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    flex: 1,
  },
}))

function FormInput(props) {
  const { classes, cx } = useStyles()

  return (
    <FormControl
      className={cx(
        props.className,
        props.useStyles !== false && classes.container,
      )}
    >
      {props.title && (
        <InputLabel htmlFor={props.title + '-input'} {...props.titleProps}>
          {props.title}
        </InputLabel>
      )}
      <Input
        aria-describedby={
          props.helperText ? props.title + '-helper-text' : null
        }
        id={props.title + '-input'}
        inputProps={props.inputProps}
        name={props.name}
        onChange={(e) => props.onChangeText(e.target.value, e)}
        placeholder={props.placeholder}
        value={props.value}
      />
      {props.helperText && (
        <FormHelperText id={props.title + '-helper-text'}>
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

FormInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  titleProps: PropTypes.object,
  title: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,

  /**
   * Props for the Input itself
   */
  inputProps: PropTypes.object,

  /**
   * Triggered when the text changes
   * (text: string) => void
   */
  onChangeText: PropTypes.func,
  useStyles: PropTypes.bool,
  value: PropTypes.any,
}

export default FormInput
