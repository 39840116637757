import * as api from '@utils/api'

export const SET_PERMISSION = 'SET_PERMISSION'
export const DELETE_PERMISSION = 'DELETE_PERMISSION'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'

export const getPermissions = () => async (dispatch) => {
  const { data: permissions } = await api.GET('/p4/permissions')

  return dispatch({
    type: SET_PERMISSIONS,
    permissions,
  })
}

export const getPermission = (id) => async (dispatch) => {
  const { data: permission } = await api.GET(`/p4/permissions/${id}`)

  return dispatch({
    type: SET_PERMISSION,
    permission,
  })
}

export const savePermission = (permission) => async (dispatch) => {
  let type = SET_PERMISSION
  const { data } = await api.PATCH(
    `/p4/permissions/${permission.id}`,
    permission,
  )

  if (permission.archived_at !== null) {
    type = DELETE_PERMISSION
  }

  return dispatch({
    type,
    permission: data,
  })
}

export const setPermissions = (updatedPermissions) => async (dispatch) => {
  const permissions = Object.values(updatedPermissions)

  return dispatch({
    type: SET_PERMISSIONS,
    permissions,
  })
}
