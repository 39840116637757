import createAction from './create-action'
import { ApiDispatch, PromotionTypeType } from '@types'
import {
  GET_PROMOTION_TYPES_FAILURE,
  GET_PROMOTION_TYPES_SUCCESS,
} from '@store/action-list'
import { apiAction } from './api'

export const getPromotionTypesSuccess = createAction<PromotionTypeType[]>(
  GET_PROMOTION_TYPES_SUCCESS,
)
const getPromotionTypesFailure = createAction(GET_PROMOTION_TYPES_FAILURE)

export const getPromotionTypes = (): ApiDispatch =>
  apiAction({
    url: `/p4/promotions/types`,
    method: 'GET',
    actionCallbacks: {
      success: getPromotionTypesSuccess,
      failure: getPromotionTypesFailure,
    },
  })
