import { SET_LOGGED_IN } from '../action-list'

/** Just a boolean, or null */
const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGED_IN: {
      const { isLoggedIn } = action
      console.log(`Setting login status to ${isLoggedIn}`)

      return isLoggedIn
    }
    default:
      return state
  }
}

// Selectors
export const getIsLoggedIn = (state) => state
