import DndItem, { ItemTypes } from '@components/pages/menus/dnd-item'
import React, { ReactElement } from 'react'
import { Button, Card } from '@mui/material'
import { Delete, DragIndicator } from '@mui/icons-material'
import { getProductList } from '@queries/products'
import { makeStyles } from 'tss-react/mui'
import { useQuery } from '@tanstack/react-query'

type Props = {
  id: number
  index: number
  parentIndex: number
  moveProduct: (
    dragIndex: number,
    hoverIndex: number,
    parentIndex: number,
    dragParentIndex: number,
  ) => void
  onDrop: () => void
  canDrag: boolean
  onRemove: (index: number) => void
}

const Product = ({
  id,
  index,
  parentIndex,
  moveProduct,
  onDrop,
  canDrag,
  onRemove,
}: Props): ReactElement | null => {
  const { classes } = useStyles()
  const { data } = useQuery({
    queryKey: ['productList'],
    queryFn: getProductList,
    staleTime: Infinity,
  })

  const product = data?.find((product) => product.id === id)

  if (!product) return null

  const handleOnClickRemove = () => {
    onRemove(index)
  }

  return (
    <DndItem
      canDrag={canDrag}
      className={classes.container}
      handleDrop={onDrop}
      handleMove={moveProduct}
      id={id}
      index={index}
      parentIndex={parentIndex}
      type={ItemTypes.PRODUCT}
    >
      <Card className={classes.card} raised>
        <DragIndicator className={classes.drag} />
        {product.name}
        <Button className={classes.remove} onClick={handleOnClickRemove}>
          <Delete />
        </Button>
      </Card>
    </DndItem>
  )
}

export default Product

const useStyles = makeStyles()({
  drag: {
    marginRight: '10px',
    verticalAlign: 'middle',
    cursor: 'move',
  },
  container: {
    width: 400,
    marginTop: '10px',
    marginBottom: '10px',
  },
  card: {
    lineHeight: 2.5,
    padding: '15px',
  },
  remove: {
    float: 'right',
  },
})
