import React, { useState } from 'react'
import { connect } from 'react-redux'

import Loader from '../shared/loader'
import MaterialTable from '@material-table/core'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import hasPerms from '@utils/has-permission'
import {
  createVendor,
  fetchVendors,
  updateVendor,
} from '../../services/vendor-service'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

const mapStateToProps = (state) => ({
  vendors: Object.values(state.Vendors),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createVendor: createVendor(dispatch),
    fetchVendors: fetchVendors(dispatch),
    updateVendor: updateVendor(dispatch),
    setFlashMessage: (message, variant) =>
      dispatch(setFlashMessage(message, variant)),
  },
})

const useStyles = makeStyles()((theme) => ({
  createVendorField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))

function Vendors(props) {
  const { classes } = useStyles()
  const { actions } = props
  const [hasFetchedData, setHasFetchedData] = useState(false)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [createDialogLoading, setCreateDialogLoading] = useState(false)
  const [newVendorName, setNewVendorName] = useState('')

  const editable = hasPerms('products:update')

  if (!hasFetchedData) {
    setHasFetchedData(true)
    actions
      .fetchVendors()
      .catch((e) => actions.setFlashMessage(e.message, 'error'))
  }

  return (
    <div>
      <Dialog
        aria-labelledby='dialog-title'
        onClose={() => setCreateDialogOpen(false)}
        open={createDialogOpen}
      >
        <Loader visible={createDialogLoading} />
        <DialogTitle id='dialog-title'>Create Vendor</DialogTitle>
        <TextField
          className={classes.createVendorField}
          label='Name'
          onChange={(e) => setNewVendorName(e.target.value)}
          value={newVendorName}
        />
        <DialogActions>
          <Button onClick={() => setCreateDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setCreateDialogLoading(true)
              actions
                .createVendor(newVendorName)
                .then(() => {
                  setCreateDialogLoading(false)
                  setCreateDialogOpen(false)
                  actions.setFlashMessage('Vendor created.', 'success')
                })
                .catch((err) => {
                  actions.setFlashMessage(err.message, 'error')
                  setCreateDialogLoading(false)
                })
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialTable
        actions={
          editable
            ? [
                {
                  title: 'Create Vendor',
                  icon: 'add',
                  isFreeAction: true,
                  onClick: () => setCreateDialogOpen(true),
                },
              ]
            : []
        }
        columns={[
          { title: 'ID', field: 'id', editable: false, defaultSort: 'asc' },
          { title: 'Name', field: 'name' },
        ]}
        data={props.vendors}
        editable={
          editable
            ? {
                onRowUpdate: async (rowData) => {
                  return actions
                    .updateVendor(rowData.id, rowData.name)
                    .then(() =>
                      actions.setFlashMessage('Vendor updated.', 'success'),
                    )
                    .catch((e) => actions.setFlashMessage(e.message, 'error'))
                },
              }
            : {}
        }
        options={{
          actionsColumnIndex: 2,
          paging: false,
        }}
        title='Vendors'
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendors)
