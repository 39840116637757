import React from 'react'
import { DragIndicator } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { useDrag, useDrop } from 'react-dnd'

const DND_ITEM_TYPE = 'row'

const Row = ({
  className,
  row,
  layout,
  index,
  moveRow,
  afterDrop,
  onClick,
  rowActions,
}) => {
  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveRow(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      afterDrop()
    },
  })

  const opacity = isDragging ? 0 : 1

  const padding = layout === 'tight' ? 'none' : 'normal'

  const actionClick = (e, row, click) => {
    e.stopPropagation()
    click(row)
  }

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <TableRow className={className} ref={dropRef} style={{ opacity }}>
      <TableCell padding={padding} ref={dragRef}>
        <DragIndicator />
      </TableCell>
      {row.cells.map((cell) => {
        return (
          <TableCell
            key={cell.id}
            {...cell.getCellProps()}
            onClick={() => onClick(row.id)}
          >
            {cell.render('Cell')}
          </TableCell>
        )
      })}
      <TableCell padding={padding}>
        {rowActions.map((action) => {
          return (
            <IconButton
              aria-label={action.title}
              key={`${action.title}-${row.id}`}
              onClick={(e) => actionClick(e, row, action.onClick)}
            >
              {action.icon}
            </IconButton>
          )
        })}
      </TableCell>
    </TableRow>
  )
}

export default Row
