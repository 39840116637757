import '../../utils/string-utils'
import ProductForm from '@pages/products/form'
import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createProduct } from '@services/product-service'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createProduct: createProduct(dispatch),
    ...bindActionCreators(
      {
        setFlashMessage,
      },
      dispatch,
    ),
  },
})

function CreateProduct(props) {
  const { actions } = props
  const [productRedirect, setProductRedirect] = useState(null)

  // Redirect
  if (productRedirect == null) {
    return (
      <ProductForm
        // Gotta use the addons too
        onSubmit={async (product) => {
          try {
            const fullProduct = await actions.createProduct({ ...product })
            setProductRedirect(fullProduct)
          } catch (error) {
            actions.setFlashMessage(error.message, 'error')
          }
        }}
        title='Create Product'
      />
    )
  } else {
    return <Navigate to={`/products/${productRedirect.id}`} />
  }
}

export default connect(null, mapDispatchToProps)(CreateProduct)
