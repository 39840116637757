import PropTypes from 'prop-types'
import React from 'react'

import { CircularProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  container: {
    position: 'absolute',
    zIndex: 100,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

function Loader(props) {
  const { classes } = useStyles()

  if (props.visible)
    return (
      <div className={classes.container}>
        <CircularProgress />
      </div>
    )
  else return null
}

Loader.propTypes = {
  visible: PropTypes.bool,
}

export default Loader
