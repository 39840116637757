import '../../utils/array-utils'

import {
  SET_HOURS_FOR_STORE,
  SET_HOURS_FOR_STORE_FOR_DAY,
} from '../action-list'

/**
 * Mapping of storeId => day => hours record, where each "hours record" has the format:
 *
 * {
 *  day_of_week: string,
 *  store_id: number,
 *  opening_time: 'hh:mm:ss',
 *  closing_time: 'hh:mm:ss',
 * }
 */
const initialState = {}

export default (state = initialState, action) => {
  const { storeId, hours } = action

  switch (action.type) {
    case SET_HOURS_FOR_STORE:
      console.log(`Redux: Setting store hours for store ${storeId}`)
      return { ...state, [storeId]: hours.toObject('day_of_week') }
    case SET_HOURS_FOR_STORE_FOR_DAY:
      console.log(
        `Redux: Setting store hours for store ${storeId} on day ${hours.day_of_week}`,
      )
      return {
        ...state,
        [storeId]: { ...state[storeId], [hours.day_of_week]: hours },
      }
    default:
      return state
  }
}
