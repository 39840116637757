import PropTypes from 'prop-types'
import React from 'react'
import {
  Table as BaseTable,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import LogChanges from './log-changes'
import Toolbar from '../../shared/toolbar'
import omit from 'lodash.omit'
import { Link } from 'react-router-dom'
import { formatLong } from '@utils/date-utils'

const useStyles = makeStyles()((theme) => ({
  spaced: {
    marginTop: theme.spacing(2),
  },
}))

const HistoryTables = ({ history }) => {
  const { classes } = useStyles()

  return (
    Object.keys(history).length > 0 && (
      <>
        <Paper className={classes.spaced}>
          <Toolbar title={'Order Change History'} />
          <BaseTable>
            <TableHead>
              <TableRow>
                <TableCell align='left' key={'updated_at'}>
                  Date/Time
                </TableCell>
                <TableCell align='left' key={'user'}>
                  Action By
                </TableCell>
                <TableCell align='left' key={'id'}>
                  Updates
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.order.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align='left' key={'updated_at'}>
                      {row.updated_at ? formatLong(row.updated_at) : null}
                    </TableCell>
                    <TableCell align='left' key={'user'}>
                      {row.user ? (
                        <Link to={'/users/' + row.user.id}>
                          {row.user.name}
                        </Link>
                      ) : null}
                    </TableCell>
                    <TableCell align='left' key={'id'}>
                      <LogChanges
                        data={omit(row, [
                          'user',
                          'user_id',
                          'updated_at',
                          'barista_id',
                          'idempotency_key',
                          'product_attribute_values',
                        ])}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </BaseTable>
        </Paper>
        <Paper className={classes.spaced}>
          <Toolbar title={'Cart Change History'} />
          <BaseTable>
            <TableHead>
              <TableRow>
                <TableCell align='left' key={'updated_at'}>
                  Date/Time
                </TableCell>
                <TableCell align='left' key={'user'}>
                  Action By
                </TableCell>
                <TableCell align='left' key={'id'}>
                  Updates
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.cart.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align='left' key={'updated_at'}>
                      {row.updated_at ? formatLong(row.updated_at) : null}
                    </TableCell>
                    <TableCell align='left' key={'user'}>
                      {row.user ? (
                        <Link to={'/users/' + row.user.id}>
                          {row.user.name}
                        </Link>
                      ) : null}
                    </TableCell>
                    <TableCell align='left' key={'id'}>
                      <LogChanges
                        data={omit(row, [
                          'user',
                          'user_id',
                          'updated_at',
                          'product_ids',
                          'product_attribute_values',
                          'store_id',
                        ])}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </BaseTable>
        </Paper>
      </>
    )
  )
}

HistoryTables.propTypes = {
  history: PropTypes.shape({
    cart: PropTypes.arrayOf(
      PropTypes.shape({
        updated_at: PropTypes.string,
        user: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    order: PropTypes.arrayOf(
      PropTypes.shape({
        updated_at: PropTypes.string,
        user: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
  }),
}

export default HistoryTables
