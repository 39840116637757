import { LOG_OUT, UPDATE_CURRENT_USER_DATA } from '@store/action-list'

const initialState = {
  token: null,
  user: {},
}

export default (state = initialState, action) => {
  const { data } = action

  switch (action.type) {
    case UPDATE_CURRENT_USER_DATA:
      console.log('Redux: Updating current user')
      return { ...state, ...data }
    case LOG_OUT:
      console.log('Redux: Logging out current user')
      return initialState
    default:
      return state
  }
}
