import React, { useState } from 'react'
import { connect } from 'react-redux'

import { setFlashMessage } from '@store/reducers/flash-message/actions'

import Loader from '../shared/loader'
import StoreForm from '../shared/forms/store-form'

import { createStore } from '../../services/store-service'
import { useNavigate } from 'react-router-dom'

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createStore: createStore(dispatch),
    setFlashMessage: (message, variant) =>
      dispatch(setFlashMessage(message, variant)),
  },
})

function CreateStore(props) {
  const { actions } = props
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  return (
    <div>
      <Loader visible={isLoading} />
      <StoreForm
        onSubmit={(store) => {
          setIsLoading(true)
          actions
            .createStore(store)
            .then((data) => {
              setIsLoading(false)
              actions.setFlashMessage('Store Created', 'success')
              navigate(`/stores/${data.id}`)
            })
            .catch((err) => {
              setIsLoading(false)
              actions.setFlashMessage(err.message, 'error')
            })
        }}
        title='Create Store'
      />
    </div>
  )
}

export default connect(null, mapDispatchToProps)(CreateStore)
