import createAction from './create-action'

import {
  REMOVE_PRODUCTS_STORES,
  UPDATE_PRODUCTS_STORES,
  UPDATE_PRODUCTS_STORES_PRICE,
} from '../action-list'

export const updateProductsStores = createAction(UPDATE_PRODUCTS_STORES)
export const removeProductsStores = createAction(REMOVE_PRODUCTS_STORES)
export const updateProductsStoresPrice = createAction(
  UPDATE_PRODUCTS_STORES_PRICE,
)
