import React, { ReactElement, useEffect, useState } from 'react'
import Toolbar from '@components/shared/toolbar'
import { Paper, TextField } from '@mui/material'
import { PromotionType } from '@types'
import { makeStyles } from 'tss-react/mui'

type PropsType = {
  className: string | null
  promotion: PromotionType | null
  editing: boolean
  onConfigurationChange: (configuration: Record<string, any>) => void
}

type ConfigurationType = {
  number_of_free_bags: number
}

const useStyles = makeStyles()((theme) => ({
  paperWrapper: {
    padding: theme.spacing(2),
  },
  formField: {
    marginRight: theme.spacing(2),
    maxWidth: 350,
  },
}))

const NumberOfBags = ({
  className,
  promotion,
  editing,
  onConfigurationChange,
}: PropsType): ReactElement => {
  const { classes } = useStyles()
  const [numberOfBags, setNumberOfBags] = useState(2)
  const currentConfig = promotion ? promotion.configuration : {}
  const config: ConfigurationType = {
    number_of_free_bags: 2,
    ...currentConfig,
  }

  useEffect(() => {
    setNumberOfBags(2)
  }, [])

  useEffect(() => {
    if (!promotion) return
    setNumberOfBags(config.number_of_free_bags)
  }, [promotion])

  useEffect(() => {
    prepareConfiguration()
  }, [numberOfBags])

  const handleNumberOfBagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10)
    setNumberOfBags(value)
  }

  const prepareConfiguration = () => {
    const newConfig: ConfigurationType = {
      number_of_free_bags: numberOfBags,
    }
    onConfigurationChange(newConfig)
  }

  return (
    <div className={className || ''}>
      <Paper className={classes.paperWrapper}>
        <Toolbar title='Configuration' />
        <TextField
          className={classes.formField}
          disabled={!editing}
          helperText='The maximum number of free bags to give to employee per promotional period.'
          label='Number Of Free Bags'
          onChange={handleNumberOfBagsChange}
          type='number'
          value={numberOfBags}
        />
      </Paper>
    </div>
  )
}

export default NumberOfBags
