import * as api from '../utils/api'

import { setSearchResults } from '../store/actions/search-results'
import { updateSearchSuggestions } from '../store/actions/search-suggestions'

export const fetchSuggestions = (dispatch) => async (query) => {
  console.log(`Fetching suggestions for search query: ${query}`)
  const { data } = await api.GET(
    `/p4/search/autosuggest/${encodeURIComponent(query)}`,
  )
  return dispatch(updateSearchSuggestions(query, data))
}

export const search = (dispatch) => async (query) => {
  console.log(`Searching for query: ${query}`)
  const { data } = await api.GET(`/p4/search/${encodeURIComponent(query)}`)
  return dispatch(setSearchResults(query, data.results))
}
