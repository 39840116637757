import { LOG_OUT, UPDATE_CURRENT_USER_DATA } from '../action-list'

export const updateUser = (data) => ({
  type: UPDATE_CURRENT_USER_DATA,
  data,
})

export const logout = () => ({
  type: LOG_OUT,
})
