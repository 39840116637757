import { SET_ORDERS_FOR_USER, SET_ORDER_COUNT_FOR_USER } from '../action-list'

export const setOrdersForUser = (uid, orderIds) => ({
  type: SET_ORDERS_FOR_USER,
  uid,
  orderIds,
})

export const setOrderCountForUser = (uid, count) => ({
  type: SET_ORDER_COUNT_FOR_USER,
  uid,
  count,
})
