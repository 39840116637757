import createAction from './create-action'
import {
  ApiDispatch,
  NewSurchargeType,
  NewTaxRateType,
  SurchargeType,
  TaxRateType,
} from '@types'
import {
  SET_SURCHARGES,
  SET_SURCHARGES_FAILURE,
  SET_TAX_RATES,
  SET_TAX_RATES_FAILURE,
  UPDATED_TAX_RATES,
  UPDATED_TAX_RATES_FAILURE,
} from '@store/action-list'
import { apiAction } from './api'

export const setTaxRates = createAction<TaxRateType[]>(SET_TAX_RATES)
const setTaxRatesFailure = createAction(SET_TAX_RATES_FAILURE)

export const updatedTaxRates = createAction<TaxRateType[]>(UPDATED_TAX_RATES)
const updatedTaxRatesFailure = createAction(UPDATED_TAX_RATES_FAILURE)

export const getTaxRates = (store_id: number): ApiDispatch =>
  apiAction({
    url: `/p4/stores/${store_id}/taxRates`,
    method: 'GET',
    actionCallbacks: {
      success: setTaxRates,
      failure: setTaxRatesFailure,
    },
  })

export const updateTaxRates = (taxRates: NewTaxRateType[]): ApiDispatch => {
  const store_id = taxRates[0] ? taxRates[0].store_id : 0
  return apiAction({
    url: `/p4/stores/${store_id}/taxRates`,
    method: 'PUT',
    data: { tax_rates: taxRates },
    actionCallbacks: {
      success: updatedTaxRates,
      failure: updatedTaxRatesFailure,
    },
  })
}

export const setSurcharges = createAction<SurchargeType[]>(SET_SURCHARGES)
const setSurchargesFailure = createAction(SET_SURCHARGES_FAILURE)

export const getSurcharges = (): ApiDispatch =>
  apiAction({
    url: '/p4/surcharges',
    method: 'GET',
    actionCallbacks: {
      success: setSurcharges,
      failure: setSurchargesFailure,
    },
  })

export const createSurcharge = (surcharge: NewSurchargeType): ApiDispatch =>
  apiAction({
    url: '/p4/surcharges',
    method: 'POST',
    data: surcharge,
    actionCallbacks: {
      success: setSurcharges,
      failure: setSurchargesFailure,
    },
  })

export const updateSurcharge = (
  surcharge_id: number,
  surcharge: NewSurchargeType,
): ApiDispatch =>
  apiAction({
    url: `/p4/surcharges/${surcharge_id}`,
    method: 'PUT',
    data: surcharge,
    actionCallbacks: {
      success: setSurcharges,
      failure: setSurchargesFailure,
    },
  })

export const deleteSurcharge = (
  confirm: string,
  surcharge_id: number,
): ApiDispatch =>
  apiAction({
    url: `/p4/surcharges/${surcharge_id}`,
    method: 'DELETE',
    data: {
      confirm: confirm,
    },
    actionCallbacks: {
      success: setSurcharges,
      failure: setSurchargesFailure,
    },
  })
