import MultipleSelect from './multiple-select'
import React, { ReactElement, useEffect } from 'react'
import useSelector from '@hooks/use-selector'
import { AppDispatch, TagType } from '@types'
import { CxArg } from 'tss-react/types'
import { getTags } from '@store/actions/tags'
import { selectTags } from '@store/selectors/tags'
import { useDispatch } from 'react-redux'

type Props = {
  onChange: (options: TagType[]) => void
  selectedTags: TagType[]
  className?: CxArg
}

const TagsSelect = ({
  onChange,
  selectedTags,
  className,
}: Props): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const tags = useSelector(selectTags)

  useEffect(() => {
    if (tags.length === 0) {
      dispatch(getTags())
    }
  }, [])

  return (
    <MultipleSelect
      className={className}
      onChange={onChange}
      optionKey='id'
      options={tags}
      optionTitleKey='name'
      optionValueKey='id'
      selectedOptions={selectedTags}
      title='Tags'
    />
  )
}

export default TagsSelect
