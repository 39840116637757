import * as api from '../utils/api'

import { setInitialInventory } from '../store/actions/initial-inventory'

/**
 * Fetches the initial inventory for a store. Currently, the backend doesn't
 * return anything.
 */
export const fetchInventory = (dispatch) => async (storeId) => {
  console.log(`Fetching initial inventory for store ${storeId}`)
  const { data } = await api.GET(`/p4/stores/${storeId}/initialInventory`)
  dispatch(setInitialInventory(data))
}

/**
 * Updates (wholly) the initial inventory for a store. Rows must be an array of
 * objects formatted like:
 *
 * {
 *  product_id: number,
 *  day: string,
 *  quantity: number
 * }
 *
 * @param {Number} storeId ID of the store to be updated
 * @param {Object[]} rows Inventory rows
 */
export const setInventory = (dispatch) => async (storeId, rows) => {
  console.log(`Updating initial inventory for store ${storeId}`)
  const { data } = await api.PATCH(`/p4/stores/${storeId}/initialInventory`, {
    rows,
  })
  return dispatch(setInitialInventory(data))
}
