import { UPDATE_SEARCH_SUGGESTIONS } from '../action-list'

/**
 * A mapping of query ==> suggestion[] where 'suggestion' is an object with format:
 *
 * {
 *  type: string (entity)
 *  id: number,
 *  name: string
 * }
 */
const initialState = {}

export default (state = initialState, action) => {
  const { query, suggestions } = action

  switch (action.type) {
    case UPDATE_SEARCH_SUGGESTIONS:
      console.log(`Redux: Updating search suggestions for query "${query}"`)
      return { ...state, [query]: suggestions }
    default:
      return state
  }
}
