const getImage = async (imageUrl) => {
  // No image URL => no Image
  if (imageUrl == null) return null

  // Get the base 64 image data
  return new Promise((resolve) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64Data = reader.result
          resolve(base64Data)
        }
      })
  })
}

export default getImage
