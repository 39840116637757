import { combineReducers } from 'redux'

import CurrentUser from './current-user'
import CurrentlyDisplayedUsers from './currently-displayed-users'
import CustomContents from './custom-contents'
import Dialog from './dialog'
import Employees from './employees'
import Eod from './eod'
import FlashMessage from './flash-message'
import InitialInventory from './initial-inventory'
import LoginStatus from './login-status'
import Orders from './orders'
import Permissions from './permissions'
import ProductCategories from './product-categories'
import Products from './products'
import ProductsStores from './products-stores'
import PromotionTypes from './promotion-types'
import Promotions from './promotions'
import Recommendations from './recommendations'
import RecommendedProducts from './recommended-products'
import Roles from './roles'
import SearchResults from './search-results'
import SearchSuggestions from './search-suggestions'
import StoreHours from './store-hours'
import StoreHoursOverrides from './store-hours-overrides'
import StoreOnline from './store-online'
import StoreOrders from './store-orders'
import Stores from './stores'
import StoresNew from './stores-new'
import Tags from './tags'
import TaxRates from './tax-rates'
import Tips from './tips'
import TodaysInventory from './todays-inventory'
import UserOrders from './user-orders'
import Users from './users'
import VendorProducts from './vendor-products'
import VendorRegions from './vendor-regions'
import Vendors from './vendors'
import VerticalCards from './vertical-cards'

const reducers = combineReducers({
  CurrentUser,
  CurrentlyDisplayedUsers,
  Employees,
  CustomContents,
  Eod,
  FlashMessage,
  InitialInventory,
  LoginStatus,
  Orders,
  Permissions,
  ProductCategories,
  ProductsStores,
  Products,
  Recommendations,
  RecommendedProducts,
  Roles,
  SearchResults,
  SearchSuggestions,
  StoreHours,
  StoreHoursOverrides,
  StoreOnline,
  StoreOrders,
  Stores,
  StoresNew,
  Tips,
  TodaysInventory,
  UserOrders,
  Users,
  Vendors,
  VerticalCards,
  Tags,
  VendorProducts,
  VendorRegions,
  TaxRates,
  Promotions,
  PromotionTypes,
  Dialog,
})

export default reducers
