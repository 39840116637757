import React from 'react'

import ResponsiveDialog from '../responsive-dialog'
import Toolbar from '../toolbar'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'

function AlertDialog(props) {
  return (
    <ResponsiveDialog
      loading={props.loading}
      onClose={props.onClose}
      open={props.open}
    >
      <Toolbar title={props.title} />
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={props.onClose}>
          {props.cancelTitle || 'Cancel'}
        </Button>
        <Button color='primary' onClick={props.onConfirm}>
          {props.confirmTitle || 'Confirm'}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default AlertDialog
