import createAction from './create-action'
import { ApiDispatch, RecommendedProductType } from '@types'
import {
  CREATE_RECOMMENDED_PRODUCT_FAILURE,
  CREATE_RECOMMENDED_PRODUCT_SUCCESS,
  DELETE_RECOMMENDED_PRODUCT_FAILURE,
  DELETE_RECOMMENDED_PRODUCT_SUCCESS,
  SET_RECOMMENDED_PRODUCTS,
  SET_RECOMMENDED_PRODUCTS_FAILURE,
  UPDATE_RECOMMENDED_PRODUCT_FAILURE,
  UPDATE_RECOMMENDED_PRODUCT_SUCCESS,
} from '@store/action-list'
import { apiAction } from './api'

export const setRecommendedProducts = createAction<RecommendedProductType[]>(
  SET_RECOMMENDED_PRODUCTS,
)
const setRecommendedProductsFailure = createAction(
  SET_RECOMMENDED_PRODUCTS_FAILURE,
)

export const getRecommendedProducts = (): ApiDispatch =>
  apiAction({
    url: '/p4/recommended-products',
    method: 'GET',
    actionCallbacks: {
      success: setRecommendedProducts,
      failure: setRecommendedProductsFailure,
    },
  })

export const updateRecommendedProductSuccess =
  createAction<RecommendedProductType>(UPDATE_RECOMMENDED_PRODUCT_SUCCESS)
const updateRecommendedProductFailure = createAction(
  UPDATE_RECOMMENDED_PRODUCT_FAILURE,
)

export const updateRecommendedProduct = ({
  id,
  headline,
  description,
  product_id,
  pinned,
  recent_locations_only,
  sort_order,
}: RecommendedProductType): ApiDispatch =>
  apiAction({
    url: `/p4/recommended-products/${id}`,
    method: 'PATCH',
    data: {
      headline,
      description,
      product_id,
      pinned,
      recent_locations_only,
      sort_order,
    },
    actionCallbacks: {
      success: updateRecommendedProductSuccess,
      failure: updateRecommendedProductFailure,
    },
  })

export const deleteRecommendedProductSuccess = (id: number): any =>
  createAction(
    DELETE_RECOMMENDED_PRODUCT_SUCCESS,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (...args: any[]) => ({ payload: { id }, response: { success: true } }),
  )

const deleteRecommendedProductFailure = createAction(
  DELETE_RECOMMENDED_PRODUCT_FAILURE,
)

export const deleteRecommendedProduct = (id: number): ApiDispatch =>
  apiAction({
    url: `/p4/recommended-products/${id}`,
    method: 'DELETE',
    data: { id },
    actionCallbacks: {
      success: deleteRecommendedProductSuccess(id),
      failure: deleteRecommendedProductFailure,
    },
  })

export const creatRecommendedProductSuccess =
  createAction<RecommendedProductType>(CREATE_RECOMMENDED_PRODUCT_SUCCESS)

const createRecommendedProductFailure = createAction(
  CREATE_RECOMMENDED_PRODUCT_FAILURE,
)

export const createRecommendedProduct = ({
  headline,
  description,
  product_id,
  pinned,
  recent_locations_only,
  sort_order,
}: RecommendedProductType): ApiDispatch =>
  apiAction({
    url: '/p4/recommended-products',
    method: 'POST',
    data: {
      headline,
      description,
      product_id,
      pinned,
      recent_locations_only,
      sort_order,
    },
    actionCallbacks: {
      success: creatRecommendedProductSuccess,
      failure: createRecommendedProductFailure,
    },
  })
