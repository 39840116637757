import { arrayToObject } from '@utils/functional'
import { createReducer } from '@reduxjs/toolkit'

import { UserType } from '@types'
import { addUsers, setUserCount, updateUser } from '@store/actions/users'

type State = {
  users: Record<string, UserType>
  totalCount: number
}

const initialState = {
  users: {},
  totalCount: Number.MAX_SAFE_INTEGER,
} as State

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addUsers, (state, action) => {
      const usersObject = arrayToObject('id', action.payload)

      state.users = { ...state.users, ...usersObject }
    })
    .addCase(updateUser, (state, action) => {
      const { payload } = action
      const user = state.users[payload.userId]

      state.users[payload.userId] = { ...user, ...payload.userData }
    })
    .addCase(setUserCount, (state, action) => {
      state.totalCount = action.payload
    })
})

export default userReducer
