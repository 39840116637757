import * as api from '@utils/api'
import { PluResponseType } from '@types'

export const getAllPluForProductAtStore = async (
  productId: number,
  storeId: number,
): Promise<PluResponseType> => {
  const { data } = await api.GET(
    `/p4/products/${productId}/prices/${storeId}/allPlu`,
  )
  return data
}
