import createCachedSelector from 're-reselect'
import { AttributeValuesRecord, RootState } from '@types'

const attributeValuesByStoreSelector = (
  state: RootState,
  storeId: number,
): AttributeValuesRecord => state.Stores.attributeValues[storeId] || {}

// account for undefined
export const selectStoreAttributeValues = createCachedSelector(
  attributeValuesByStoreSelector,
  (item) => item,
)((_, storeId) => storeId)
