import {
  DELETE_PERMISSION,
  SET_PERMISSION,
  SET_PERMISSIONS,
} from '@store/reducers/permissions/actions'

const initialState = {}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case SET_PERMISSION: {
      const { permission } = action
      return {
        ...state,
        [permission.id]: permission,
      }
    }
    case SET_PERMISSIONS: {
      const { permissions } = action
      return permissions.reduce((acc, permission) => {
        return { ...acc, [permission.id]: permission }
      }, state)
    }
    case DELETE_PERMISSION: {
      return { ...state }
    }
    default:
      return state
  }
}
