import '@utils/string-utils'
import CustomContentSelector from '@shared/custom-content-selector'
import Form from '@shared/form'
import FormImageInput from '@shared/form-image-input'
import FormInput from '@shared/form-input'
import ProductSelector from '@shared/product-selector'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import StoreSelector from '@shared/store-selector'
import getImage from '@utils/get-image'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { formatDefault } from '@utils/date-utils'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  preview: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  colorDisplay: {
    height: 16,
    width: 16,
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    marginTop: 24,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  typeGroup: {
    display: 'flex',
  },
  typeSelect: {
    marginRight: theme.spacing(1),
  },
  typeLabel: {
    marginBottom: theme.spacing(1),
    width: 'auto',
  },
  control: {
    marginBottom: theme.spacing(1),
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    height: 180,
  },
}))

const cardTypes = {
  basic: { label: 'Custom Content', value: 'basic', slug: 'content' },
  store: { label: 'New Store', value: 'new store', slug: 'stores' },
  product: {
    label: 'Recommended Products',
    value: 'recommended products',
    slug: 'products',
  },
  url: { label: 'URL', value: 'url', slug: '' },
}

function VerticalCardForm({ initialData, onSubmit, title }) {
  const { classes } = useStyles()
  const baseUrl = APP_URL
  const [card, setCard] = useState(initialData)
  const [imageUrl, setImageUrl] = useState(initialData.image_url)
  const [imageName, setImageName] = useState('')
  const initialSelectValue =
    parseInt(card?.url?.substr(card?.url?.lastIndexOf('/') + 1), 10) || -1
  const [selectValue, setSelectValue] = useState(initialSelectValue)
  const [slug, setSlug] = useState('')

  useEffect(() => {
    setCard(initialData)
    syncSlug(initialData.type)
  }, [initialData])

  const submit = async () => {
    if (imageUrl !== card.image_url) {
      const image = await getImage(imageUrl)
      card.image_data = image
      card.image_name = imageName
    }
    onSubmit(card)
  }

  const updateCard = (value, e) => {
    setCard({ ...card, [e.target.name]: value })
  }

  const updateImage = (data) => {
    setImageName(data.name)
    setImageUrl(data.url)
  }

  const updateType = (e) => {
    const value = e.target.value
    setSelectValue(-1)
    setCard({ ...card, type: value, url: '' })
    syncSlug(value)
  }

  const syncSlug = (type) => {
    const currType = Object.values(cardTypes).find((t) => t.value === type)
    setSlug(currType?.slug)
  }

  const syncUrlWithType = (value) => {
    setSelectValue(value)
    setCard({ ...card, url: `${baseUrl}/${slug}/${value}` })
  }

  // eslint-disable-next-line no-unused-vars
  const typeItems = Object.entries(cardTypes).map(([_, type]) => {
    return { label: type.label, value: type.value, diabled: false }
  })

  return (
    <Form title={title}>
      <FormImageInput imageUrl={imageUrl} onChange={updateImage} />
      <FormInput
        name='title'
        onChangeText={updateCard}
        title='Title'
        value={card.title}
      />
      <FormInput
        name='body'
        onChangeText={updateCard}
        title='Body'
        value={card.body}
      />
      <FormInput
        name='url_title'
        onChangeText={updateCard}
        title='Link Title'
        value={card.url_title}
      />
      <div className={classes.typeGroup}>
        <FormControl className={classes.typeSelect}>
          <InputLabel className={classes.typeLabel} id='type-select-label'>
            Type
          </InputLabel>
          <Select
            autoWidth
            labelId='type-select-label'
            onChange={updateType}
            value={card.type}
          >
            {Object.values(typeItems).map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {card.type === cardTypes.product.value && (
          <ProductSelector onChange={syncUrlWithType} value={selectValue} />
        )}
        {card.type === cardTypes.store.value && (
          <StoreSelector
            containerProps={() => {}}
            onChange={syncUrlWithType}
            value={selectValue}
          />
        )}
        {card.type === cardTypes.basic.value && (
          <CustomContentSelector
            onChange={syncUrlWithType}
            value={selectValue}
          />
        )}
        {card.type === cardTypes.url.value && (
          <FormInput
            name='url'
            onChangeText={updateCard}
            title='Link'
            value={card.url}
          />
        )}
      </div>
      <p> {`Created At: ${formatDefault(card.created_at)}`} </p>
      <p> {`Updated At: ${formatDefault(card.updated_at)}`} </p>
      <Button className={classes.submitButton} onClick={submit}>
        Submit
      </Button>
    </Form>
  )
}

VerticalCardForm.propTypes = {
  initialData: PropTypes.object,

  /**
   * @param {Object} productData
   */
  onSubmit: PropTypes.func,
}

export default VerticalCardForm
