import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Box, Divider, TextField } from '@mui/material'
import { Refresh, Save, Search } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'

import ToolbarButton from '../../shared/toolbar-button'

import DayOfWeek from '../../../constants/day-of-week'
import { DataGrid } from '@mui/x-data-grid'
import {
  fetchInventory,
  setInventory,
} from '../../../services/initial-inventory-service'
import { fetchProductsStoresForStoreId } from '../../../services/store-service'
import { getProductsForStore } from '@store/selectors/products-stores'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

const columns = (editable) => {
  return [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 2,
      type: 'number',
    },
    {
      field: DayOfWeek.MONDAY,
      headerName: 'Monday',
      editable: editable,
      type: 'number',
    },
    {
      field: DayOfWeek.TUESDAY,
      headerName: 'Tuesday',
      editable: editable,
      type: 'number',
    },
    {
      field: DayOfWeek.WEDNESDAY,
      headerName: 'Wednesday',
      editable: editable,
      type: 'number',
    },
    {
      field: DayOfWeek.THURSDAY,
      headerName: 'Thursday',
      editable: editable,
      type: 'number',
    },
    {
      field: DayOfWeek.FRIDAY,
      headerName: 'Friday',
      editable: editable,
      type: 'number',
    },
    {
      field: DayOfWeek.SATURDAY,
      headerName: 'Saturday',
      editable: editable,
      type: 'number',
    },
    {
      field: DayOfWeek.SUNDAY,
      headerName: 'Sunday',
      editable: editable,
      type: 'number',
    },
  ]
}

const useStyles = makeStyles()((theme) => ({
  titleBar: {
    height: 54,
    backgroundColor: '#F8F8F8',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  searchField: {
    marginRight: theme.spacing(2),
  },
  weekPicker: {
    display: 'flex',
    alignItems: 'center',
  },
  inventoryWrapper: {
    height: 290,
  },
}))

const mapStateToProps = (state, ownProps) => ({
  inventory: state.InitialInventory[ownProps.storeId],
  products: getProductsForStore(state, ownProps.storeId).filter(
    (x) => x.product.is_inventory_controlled == true,
  ),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    fetchProductsStores: () =>
      fetchProductsStoresForStoreId(dispatch)(ownProps.storeId),
    fetchInventory: () => fetchInventory(dispatch)(ownProps.storeId),
    setInventory: (rows) => setInventory(dispatch)(ownProps.storeId, rows),
    ...bindActionCreators(
      {
        setFlashMessage,
      },
      dispatch,
    ),
  },
})

/* eslint-disable no-alert */

function InventoryTable(props) {
  const { actions } = props
  const { classes } = useStyles()
  const [hasFetchedData, setHasFetchedData] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [filter, setFilter] = useState('')
  const [localInventory, setLocalInventory] = useState({})
  const [rows, setRows] = useState([])

  const fetchData = async () => {
    return actions.fetchProductsStores().catch((error) => {
      actions.setFlashMessage(error.message, 'error')
    })
  }

  useEffect(() => {
    const loadData = async () => {
      await fetchData()
      setHasFetchedData(true)
    }

    if (!hasFetchedData) {
      loadData()
    }
  }, [hasFetchedData])

  // Listen for changes to products/inventory
  useEffect(() => {
    setLocalInventory(props.inventory || {})
    setHasChanges(false)
  }, [props.inventory])

  useEffect(() => {
    if (props.products && localInventory) updateRows()
  }, [localInventory, props.products, filter])

  const updateRows = () => {
    if (!props.products) return setRows([])

    setRows(
      props.products
        .map((data) => {
          if (
            filter !== '' &&
            !data.product.name.toLowerCase().startsWith(filter.toLowerCase())
          )
            return null

          const productId = data.product.id
          // Yes, 'name' will be in here, but it'll be overridden later
          const dayValues = columns(props.editable)
            .map((col) => col.field)
            .reduce((acc, nextDay) => {
              // Filter
              if (localInventory[productId])
                acc[nextDay] = localInventory[productId][nextDay] || 0
              else acc[nextDay] = 0

              return acc
            }, {})

          return {
            ...dayValues,
            id: productId,
            name: data.product.name,
          }
        })
        .filter((x) => x),
    )
  }

  // Gets inventory rows to update backend based on current state
  const getInventoryRows = () => {
    return props.products.reduce((acc, data) => {
      const { product } = data
      const inv = Object.values(DayOfWeek).map((day) => ({
        product_id: product.id,
        day,
        quantity: localInventory[product.id]
          ? localInventory[product.id][day] || 0
          : 0,
      }))
      return [...acc, ...inv]
    }, [])
  }

  // Update local inventory based on row changes
  const handleRowUpdated = (newRow) => {
    if (!hasChanges) setHasChanges(true)

    const newInventory = {
      ...localInventory,
      [newRow.id]: { ...newRow },
    }

    setLocalInventory(newInventory)
    return { ...newRow }
  }

  const saveChanges = () => {
    // Disable the save button
    setHasChanges(false)

    actions
      .setInventory(getInventoryRows())
      .then(() => {
        actions.setFlashMessage(
          'Initial inventory has been updated.',
          'success',
        )
      })
      .catch((error) => {
        setHasChanges(true)
        actions.setFlashMessage(error.message, 'error')
      })
  }

  const handleRefresh = () => {
    fetchData()
    props.actions.fetchInventory()
  }

  return (
    <div className={props.className}>
      <Box
        border={2}
        borderBottom={0}
        borderColor='#E4E4E4'
        className={classes.titleBar}
      >
        <TextField
          className={classes.searchField}
          InputProps={{
            startAdornment: <Search />,
          }}
          onChange={(event) => setFilter(event.target.value)}
          placeholder='Search'
        />
        <Divider orientation='vertical' />
        {props.editable && (
          <>
            <ToolbarButton
              icon={<Refresh />}
              onClick={() => {
                if (
                  !hasChanges ||
                  window.confirm(
                    'You have unsaved changed. Are you sure you want to refresh?',
                  )
                )
                  handleRefresh()
              }}
              title='Refresh'
            />
            <Divider orientation='vertical' />
            <ToolbarButton
              disabled={!hasChanges}
              icon={<Save />}
              onClick={saveChanges}
              title='Save'
            />
          </>
        )}
      </Box>
      <div className={classes.inventoryWrapper}>
        <DataGrid
          autoHeight={false}
          columns={columns(props.editable)}
          density='compact'
          hideFooter
          hideFooterPagination
          hideFooterSelectedRowCount
          processRowUpdate={handleRowUpdated}
          rows={rows}
          showCellVerticalBorder
          showColumnVerticalBorder
        />
      </div>
    </div>
  )
}
/* eslint-enable no-alert */

InventoryTable.propTypes = {
  storeId: PropTypes.number,
  editable: PropTypes.bool,
  actions: PropTypes.shape({
    setFlashMessage: PropTypes.func,
    setInventory: PropTypes.func,
    fetchProductsStores: PropTypes.func,
    fetchInventory: PropTypes.func,
  }),
  products: PropTypes.array,
  inventory: PropTypes.object,
  className: PropTypes.node,
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTable)
