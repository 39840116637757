import MaterialTable from '@material-table/core'
import ProfileWidget from '@shared/profile-widget'
import React, { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  selectEmployees,
  selectPermissionById,
  selectRoles,
} from '@store/selectors'
import { useAllActions, useSelector } from '@hooks'
import { useNavigate, useParams } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  section: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  roles: {
    marginRight: theme.spacing(2),
    width: '430px',
  },
  employees: {
    width: '100%',
  },
}))

const Permission = () => {
  const { id } = useParams()
  const { classes } = useStyles()
  const { getRoles, getPermission, getFilteredEmployees } = useAllActions()

  const permission = useSelector((state) => selectPermissionById(state, id))
  const employees = useSelector(selectEmployees)
  const roles = useSelector(selectRoles)

  const navigate = useNavigate()

  useEffect(() => {
    getRoles()
    getPermission(id)
  }, [])

  useEffect(() => {
    if (permission) {
      getFilteredEmployees({ permission_id: permission.id })
    }
  }, [permission])

  if (!roles || !permission || !employees) return <CircularProgress />

  const permissionRoles = Object.values(roles).filter((role) => {
    return role?.permission_ids?.includes(role.id)
  })

  return (
    <div>
      <ProfileWidget
        subtitle1={permission.description}
        title={permission.permission}
      />
      <div className={classes.section}>
        <div className={classes.roles}>
          <MaterialTable
            columns={[{ title: '', field: 'role', defaultSort: 'asc' }]}
            data={permissionRoles}
            onRowClick={(_, row) => {
              navigate(`/roles/${row.id}`)
            }}
            options={{
              paging: false,
              search: false,
              sorting: true,
            }}
            title='Roles'
          />
        </div>
        <div className={classes.employees}>
          <MaterialTable
            columns={[
              { title: 'Name', field: 'name', defaultSort: 'asc' },
              { title: 'Email', field: 'email' },
            ]}
            data={Object.values(employees)}
            onRowClick={(_, row) => {
              navigate(`/employees/${row.id}`)
            }}
            options={{
              actionsColumnIndex: 4,
              paging: true,
              search: true,
              sorting: true,
            }}
            title='Employees'
          />
        </div>
      </div>
    </div>
  )
}

export default Permission
