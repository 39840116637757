import { FlashMessageType, RootState } from '@types'
import { createSelector } from 'reselect'

const flashMessageSelector = (state: RootState): FlashMessageType =>
  state.FlashMessage

export const selectFlashMessage = createSelector(
  flashMessageSelector,
  (message) => message,
)
