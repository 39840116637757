import * as api from '@utils/api'

export const SET_CUSTOM_CONTENT = 'SET_CUSTOM_CONTENT'
export const DELETE_CUSTOM_CONTENT = 'DELETE_CUSTOM_CONTENT'
export const SET_CUSTOM_CONTENTS = 'SET_CUSTOM_CONTENTS'

export const getCustomContents = () => async (dispatch) => {
  const { data: contents } = await api.GET('/p4/custom_content')

  return dispatch({
    type: SET_CUSTOM_CONTENTS,
    contents,
  })
}

export const createCustomContent = (content) => async (dispatch) => {
  const { data } = await api.POST('/p4/custom_content', content)

  return dispatch({
    type: SET_CUSTOM_CONTENT,
    content: data,
  })
}

export const getCustomContent = (id) => async (dispatch) => {
  const { data: content } = await api.GET(`/p4/custom_content/${id}`)

  return dispatch({
    type: SET_CUSTOM_CONTENT,
    content,
  })
}

export const saveCustomContent = (content) => async (dispatch) => {
  let type = SET_CUSTOM_CONTENT
  const { data } = await api.PATCH(`/p4/custom_content/${content.id}`, content)

  if (content.archived_at !== null) {
    type = DELETE_CUSTOM_CONTENT
  }

  return dispatch({
    type,
    content: data,
  })
}

export const setCustomContents = (updatedContents) => async (dispatch) => {
  const contents = Object.values(updatedContents)

  return dispatch({
    type: SET_CUSTOM_CONTENTS,
    contents,
  })
}
