import createAction from './create-action'
import { ApiDispatch, EmployeeFormType, EmployeeType } from '@types'
import {
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE,
  SAVE_EMPLOYEE,
  SAVE_EMPLOYEE_FAILURE,
  SET_EMPLOYEE,
  SET_EMPLOYEES,
} from '@store/action-list'
import { apiAction } from './api'

export const setEmployee = createAction<EmployeeType>(SET_EMPLOYEE)
export const setEmployees = createAction<EmployeeType[]>(SET_EMPLOYEES)
export const deleteEmployee = createAction<EmployeeType>(DELETE_EMPLOYEE)
export const createEmployeeSuccess = createAction<EmployeeType>(CREATE_EMPLOYEE)
const createEmployeeFailure = createAction(CREATE_EMPLOYEE_FAILURE)
export const saveEmployeeSuccess = createAction<EmployeeType>(SAVE_EMPLOYEE)
const saveEmployeeFailure = createAction(SAVE_EMPLOYEE_FAILURE)

export const getEmployee = (id: string): ApiDispatch =>
  apiAction({
    url: `/p4/users/${id}`,
    method: 'GET',
    actionCallbacks: {
      success: setEmployee,
    },
  })

export const getEmployees = (): ApiDispatch =>
  apiAction({
    url: '/p4/employees',
    method: 'GET',
    actionCallbacks: {
      success: setEmployees,
    },
  })

export const saveEmployee = (employeeData: EmployeeFormType): ApiDispatch =>
  apiAction({
    url: `/p4/users/${employeeData.id}`,
    method: 'PATCH',
    data: employeeData,
    actionCallbacks: {
      success: saveEmployeeSuccess,
      failure: saveEmployeeFailure,
    },
  })

export const uploadPhotoEmployee = (
  id: number,
  imageData: string,
): ApiDispatch =>
  apiAction({
    url: `/p4/users/${id}/photo`,
    method: 'POST',
    data: { image_data: imageData },
    actionCallbacks: {
      success: saveEmployeeSuccess,
      failure: saveEmployeeFailure,
    },
  })

export const createEmployee = (employeeData: EmployeeFormType): ApiDispatch =>
  apiAction({
    url: '/p4/users',
    method: 'POST',
    data: employeeData,
    actionCallbacks: {
      success: createEmployeeSuccess,
      failure: createEmployeeFailure,
    },
  })

export const demoteEmployee = (id: number): ApiDispatch =>
  apiAction({
    url: `/p4/users/${id}`,
    method: 'PATCH',
    data: {
      id,
      role_ids: [],
      permission_ids: [],
    },
    actionCallbacks: {
      success: deleteEmployee,
    },
  })
