import Lapsed from './cohorts/lapsed'
import React, { ReactElement, useEffect, useState } from 'react'
import Toolbar from '@components/shared/toolbar'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { PromotionType } from '@types'
import { makeStyles } from 'tss-react/mui'

type PropsType = {
  className?: string | null
  promotion: PromotionType | null
  editing: boolean
  onConfigurationChange: (object) => void
  cohorts: Record<string, string>
}

type ConfigurationType = {
  cohort: string
  cohort_parameters: any[]
}

const useStyles = makeStyles()((theme) => ({
  paperWrapper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: theme.spacing(2),
  },
  productSelect: {
    margin: theme.spacing(2),
  },
  cohortSelect: {
    minWidth: 200,
    marginRight: theme.spacing(2),
  },
}))

const SizeUp = ({
  className = null,
  promotion,
  editing,
  onConfigurationChange,
  cohorts,
}: PropsType): ReactElement => {
  const { classes } = useStyles()
  const [cohort, setCohort] = useState('-1')
  const [cohortParameters, setCohortParameters] = useState<any[]>([])
  const currentConfig = promotion ? promotion.configuration : {}
  const config: ConfigurationType = {
    cohort: '',
    cohort_parameters: [],
    ...currentConfig,
  }

  useEffect(() => {
    setCohort('-1')
    setCohortParameters([])
  }, [])

  useEffect(() => {
    if (!promotion) return
    setCohort(config.cohort)
    setCohortParameters(config.cohort_parameters)
  }, [promotion])

  useEffect(() => {
    prepareConfiguration()
  }, [cohort, cohortParameters])

  const prepareConfiguration = () => {
    const newConfig: any = {
      cohort,
      cohort_parameters: cohortParameters,
    }
    onConfigurationChange(newConfig)
  }

  const handleCohortChange = (e: SelectChangeEvent<string>) => {
    setCohort(e.target.value as string)
  }

  const handleCohortParametersChange = (parameters: any[]) => {
    setCohortParameters(parameters)
  }

  return (
    <div className={className || ''}>
      <Paper className={classes.paperWrapper}>
        <Toolbar title='Configuration' />
        <div>
          <FormControl>
            <InputLabel id='cohort-select-label'>Triggering Cohort</InputLabel>
            <Select
              className={classes.cohortSelect}
              disabled={!editing}
              labelId='cohort-select-label'
              onChange={handleCohortChange}
              value={cohort}
            >
              <MenuItem key={-1} value={-1}>
                <em>Select Cohort</em>
              </MenuItem>
              {Object.keys(cohorts).map((cohortName, i) => (
                <MenuItem key={i} value={cohortName}>
                  {cohortName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {cohort == 'Lapsed' && (
            <Lapsed
              cohortParameters={cohortParameters}
              editing={editing}
              onParametersChange={handleCohortParametersChange}
            />
          )}
        </div>
      </Paper>
    </div>
  )
}

export default SizeUp
