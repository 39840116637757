import MaterialTable from '@material-table/core'
import React, { ReactElement, useEffect, useState } from 'react'
import omit from 'lodash.omit'
import useSelector from '@hooks/use-selector'
import { AppDispatch, AttributeValues as AttributeValuesType } from '@types'
import { Paper, Switch } from '@mui/material'
import {
  getAttributeValues,
  updateAttributeValues,
} from '@store/actions/stores'
import { selectStoreAttributeValues } from '@store/selectors/stores'
import { styled } from '@mui/material/styles'
import { useDispatch } from 'react-redux'

const Row = styled(Paper)({
  display: 'flex',
})

const AttributesNamesTableWrapper = styled('div')({
  width: 400,
  borderRight: '1px solid rgba(224, 224, 224, 1)',
})

const AttributesValuesTableWrapper = styled('div')({
  flex: 1,
})

type Props = {
  storeId: number
  className: any
  editable: boolean
}

const AttributeValues = ({
  storeId,
  className,
  editable,
}: Props): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const attributeValues = useSelector(selectStoreAttributeValues, storeId)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedAttributes, setSelectedAttributes] = useState<string | null>(
    null,
  )

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      await dispatch(getAttributeValues(storeId))
      setIsLoading(false)
    }

    loadData()
  }, [])

  useEffect(() => {
    if (!selectedAttributes) {
      setSelectedAttributes(Object.keys(attributeValues)[0])
    }
  }, [attributeValues, selectedAttributes])

  return (
    <Row className={className}>
      <AttributesNamesTableWrapper>
        <MaterialTable
          columns={[
            {
              title: 'Name',
              field: 'title',
              cellStyle: (_, rowData) =>
                rowData.value === selectedAttributes
                  ? {
                      background: '#6E86F5',
                      color: 'white',
                      fontWeight: 'bold',
                    }
                  : {},
            },
          ]}
          data={Object.keys(attributeValues).map((key) => ({
            title: key.toUpperCase(),
            value: key,
          }))}
          onRowClick={(_, row: any) => setSelectedAttributes(row.value)}
          options={{
            search: false,
            paging: false,
            showEmptyDataSourceMessage: !isLoading,
          }}
          style={{ boxShadow: 'none' }}
          title='Product Attributes'
        />
      </AttributesNamesTableWrapper>
      <AttributesValuesTableWrapper>
        <MaterialTable
          columns={[
            { title: 'Attribute Name', field: 'attribute_name' },
            {
              title: 'Available',
              render: function renderSwitch(rowData) {
                // material-table thinks rowData is of type 'AttributeValues' when in fact
                // it has an additional property 'tableData'
                const typedData = rowData as {
                  tableData: { id: number }
                } & AttributeValuesType

                return (
                  <Switch
                    checked={!rowData.is_disabled}
                    disabled={!editable}
                    onChange={() => {
                      dispatch(
                        updateAttributeValues(
                          storeId,
                          omit(typedData, ['tableData']),
                        ),
                      )
                    }}
                  />
                )
              },
            },
          ]}
          data={selectedAttributes ? attributeValues[selectedAttributes] : []}
          isLoading={isLoading}
          options={{
            search: true,
            showEmptyDataSourceMessage: !isLoading,
          }}
          style={{ boxShadow: 'none' }}
          title=''
        />
      </AttributesValuesTableWrapper>
    </Row>
  )
}

export default AttributeValues
