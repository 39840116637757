import React, { ChangeEvent, ReactElement, useState } from 'react'
import { Button } from '@mui/material'
import { POST } from '../../../utils/api'
import { makeStyles } from 'tss-react/mui'
import { setFlashMessage } from '@store/reducers/flash-message/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles()((theme) => ({
  downloadSection: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  exampleImage: {
    maxWidth: '100%',
  },
}))

const PriceChange = (): ReactElement => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const [csvUploadFile, setCsvUploadFile] = useState<File>()
  const [uploading, setUploading] = useState(false)
  const [downloadButtonState, setDownloadButtonState] = useState(
    'Download Spreadsheet',
  )
  const [downloadFileState, setDownloadFileState] = useState('')
  const [uploadFileState, setUploadFileState] = useState('')

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) setCsvUploadFile(e.target.files[0])
  }

  const importCsvFile = async () => {
    if (uploading) return
    if (!csvUploadFile) {
      dispatch(setFlashMessage('Select a file to upload.', 'error'))
      return
    }
    setUploading(true)
    setUploadFileState(
      'Your spreadsheet is uploading to the server, do not close your browser.',
    )
    try {
      const data = new FormData()
      data.append('prices', csvUploadFile)
      const {
        data: { email },
      }: { data: { email: string } } = await POST('/p4/prices/import', data)
      dispatch(setFlashMessage('Upload complete, updating prices.', 'success'))
      setUploadFileState(
        `File upload successful. Prices are updating on the server and should be changed within 2 minutes. Check your email ${email} for an update when this is completed.`,
      )
      setUploading(false)
    } catch (error: any) {
      dispatch(setFlashMessage(error.message, 'error'))
    }
  }

  const handleOnDownload = async () => {
    setDownloadButtonState('Starting Download...')

    const {
      data: { filename, email },
    }: { data: { filename: string; email: string } } = await POST(
      '/p4/prices/export',
    )

    setDownloadButtonState('Download Spreadsheet')
    setDownloadFileState(
      `Generating file ${filename} and will email to ${email} in about 5 minutes.`,
    )
  }

  return (
    <>
      <h1>Mass Price Update</h1>
      <div className={classes.downloadSection}>
        <h2>1. Download the current prices list.</h2>
        <Button onClick={handleOnDownload}>{downloadButtonState}</Button>
        <p>{downloadFileState}</p>
      </div>
      <div>
        <h2>2. Update the price column of the spreadsheet.</h2>
        <img
          className={classes.exampleImage}
          src='https://cdn.philz.us/admin/images/example-prices-sheet.png'
        />
        <ul>
          <li>
            Rows with empty values for store_id and attributes represents the
            suggested base price of a product when added to a store menu later.
          </li>
          <li>
            Rows with a store_id set, but empty values for attributes represents
            the base price of a product sold at that specific store.
          </li>
          <li>
            Rows with store_id and attributes set represents the final price of
            an item at a store for that variation of the product.
          </li>
          <li>
            <strong>Do not change any values other than price.</strong>
          </li>
        </ul>
        <h2>
          3. Upload the updated spreadsheet to change to the new prices
          immediately.
        </h2>
        <input onChange={handleFileChange} type='file' />
        <Button onClick={importCsvFile}>
          {uploading ? 'Processing...' : 'Import'}
        </Button>
        <p>{uploadFileState}</p>
      </div>
    </>
  )
}

export default PriceChange
