import ProductTypeTrigger from './parts/product-type-trigger'
import ProductsTrigger from './parts/products-trigger'
import React, { ReactElement, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import { PromotionType } from '@types'

type PropsType = {
  className: string | null
  promotion: PromotionType | null
  editing: boolean
  onConfigurationChange: (object) => void
}

type ConfigurationType = {
  triggering_product_ids: number[]
  triggering_product_type: string | null
  percentage_off: number
  item_limit_per_day: number
}

const useStyles = makeStyles()((theme) => ({
  paperWrapper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: theme.spacing(2),
  },
}));

const Discounted = ({
  className,
  promotion,
  editing,
  onConfigurationChange,
}: PropsType): ReactElement => {
  const { classes } = useStyles()
  const [trigger, setTrigger] = useState('products')
  const [productType, setProductType] = useState('')
  const [productIds, setProductIds] = useState<number[]>([])
  const [percentageOff, setPercentageOff] = useState(0.0)
  const [itemLimitPerDay, setItemLimitPerDay] = useState<number | null>(null)
  const currentConfig = promotion ? promotion.configuration : {}
  const config: ConfigurationType = {
    triggering_product_ids: [],
    triggering_product_type: null,
    percentage_off: 0,
    item_limit_per_day: 1,
    ...currentConfig,
  }

  const resetConfiguration = () => {
    setProductType('')
    setProductIds([])
    setPercentageOff(0.0)
    setItemLimitPerDay(null)
  }

  useEffect(() => {
    resetConfiguration()
  }, [])

  useEffect(() => {
    if (!promotion) return
    if (config.triggering_product_type) {
      setTrigger('type')
      setProductType(config.triggering_product_type)
    } else {
      setProductIds(config.triggering_product_ids)
    }
    setItemLimitPerDay(config.item_limit_per_day)
    setPercentageOff(config.percentage_off)
  }, [promotion])

  useEffect(() => {
    prepareConfiguration()
  }, [productType, productIds, itemLimitPerDay, percentageOff])

  const handleTriggerTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetConfiguration()
    setTrigger(e.target.value)
  }

  const prepareConfiguration = () => {
    const newConfig: any = {
      percentage_off: percentageOff,
      item_limit_per_day: itemLimitPerDay,
    }

    if (trigger == 'type') {
      newConfig.triggering_product_type = productType
    } else {
      newConfig.triggering_product_ids = productIds
    }

    onConfigurationChange(newConfig)
  }

  const handleItemLimitPerDayChange = (e: any) => {
    let value = e.target.value
    value = value ? parseInt(value, 10) : null
    setItemLimitPerDay(value)
  }

  const handlePercentageOffChange = (e: any) => {
    let value = e.target.value
    value = value ? parseFloat(value) : 0.0
    if (value > 100) value = 100.0
    setPercentageOff(value)
  }

  return (
    <div className={className || ''}>
      <Paper className={classes.paperWrapper}>
        <div>
          <TextField
            disabled={!editing}
            label='Percentage Off'
            onChange={handlePercentageOffChange}
            value={percentageOff}
          />

          <p>
            Every eligible item a customer buys will be discounted by this
            percentage of the items base price.
          </p>
        </div>

        <div>
          <TextField
            disabled={!editing}
            label='Item Limit Per Day'
            onChange={handleItemLimitPerDayChange}
            value={itemLimitPerDay}
          />

          <p>
            Limits the total number of items that can be given this discount per
            day. Leaving this field blank will result in infinite potentially
            discounted items.
          </p>
        </div>
        <div>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Promotion Is Triggered By</FormLabel>
            <RadioGroup
              aria-label='type'
              name='type'
              onChange={handleTriggerTypeChange}
              value={trigger}
            >
              <FormControlLabel
                control={<Radio />}
                disabled={!editing}
                label='Trigger By Products'
                value='products'
              />
              <FormControlLabel
                control={<Radio />}
                disabled={!editing}
                label='Trigger By Product Type'
                value='type'
              />
            </RadioGroup>
          </FormControl>
        </div>
      </Paper>

      <div className={classes.paperMargin}>
        {trigger == 'products' && (
          <ProductsTrigger
            editing={editing}
            onChangeProductIds={setProductIds}
            productIds={productIds}
          />
        )}

        {trigger == 'type' && (
          <ProductTypeTrigger
            editing={editing}
            onProductTypeChange={setProductType}
            productType={productType}
          />
        )}
      </div>
    </div>
  )
}

export default Discounted
