import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import {
  FormControl,
  InputLabel,
  Toolbar as MaterialToolbar,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'

import ToolbarButton from './toolbar-button'

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between',
  },
  title: {
    flex: 1,
  },
  formControl: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
}))

function Toolbar(props) {
  const buttons = props.buttons || []
  const { classes } = useStyles()

  return (
    <MaterialToolbar className={classes.toolbar}>
      <div>
        <Typography className={classes.title} color='inherit' variant='h6'>
          {props.title}
        </Typography>
        {props.description && (
          <Typography variant='body2'>{props.description}</Typography>
        )}
      </div>
      <div>
        {buttons.map((buttonProps) => {
          return <ToolbarButton key={buttonProps.title} {...buttonProps} />
        })}
        {props.select && (
          <FormControl className={classes.formControl}>
            {props.select.title && (
              <InputLabel id={props.select.title}>
                {props.select.title}
              </InputLabel>
            )}
            <Select
              autoWidth
              labelId={props.select.title}
              onChange={props.select.onChange}
              value={props.select.value}
            >
              {props.select.options.map((config, index) => {
                return (
                  <MenuItem key={index} value={config.value}>
                    {config.title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
      </div>
    </MaterialToolbar>
  )
}

Toolbar.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,

  /**
   * Array of ToolbarButton prop objects
   */
  buttons: PropTypes.arrayOf(PropTypes.object),

  /**
   * Config for right-side select item. Should be in the format:
   *
   * {
   *  title: string
   *  onChange: (optionKey) => void
   *  value: T
   *  options: { value: T, title: string }
   * }
   */
  select: PropTypes.object,
}

export default Toolbar
