import {
  SET_TODAYS_INVENTORY,
  UPDATE_TODAYS_INVENTORY_FOR_PRODUCT,
} from '../action-list'

export const setTodaysInventory = (storeId, inventory) => ({
  type: SET_TODAYS_INVENTORY,
  storeId,
  inventory,
})

export const updateInventoryForProduct = (storeId, productId, inventory) => ({
  type: UPDATE_TODAYS_INVENTORY_FOR_PRODUCT,
  storeId,
  productId,
  inventory,
})
