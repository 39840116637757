import React, { useState } from 'react'
import { connect } from 'react-redux'

import CreateHolidayDialog from './create-holiday-dialog'
import MaterialTable from '@material-table/core'
import { Switch } from '@mui/material'

import {
  deleteOverride,
  fetchHours,
  setOverrideClosed,
  setOverrideHoliday,
  updateHoursRecord,
} from '../../../services/store-hours-service'
import { fetchStore } from '../../../services/store-service'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

import withRouter from '@utils/legacy/with-router'
import { getFormattedDate, getFormattedTime } from '../../../utils/date-utils'

function getStoreId(props) {
  return parseInt(props.match.params.storeId, 10)
}

const mapStateToProps = (state, ownProps) => ({
  store: state.Stores.stores[getStoreId(ownProps)] || {},
  overrides: state.StoreHoursOverrides[getStoreId(ownProps)] || {},
})

const mapDispatchToProps = (dispatch, ownProps) => {
  const storeId = getStoreId(ownProps)

  return {
    actions: {
      deleteOverride: (date) => deleteOverride(dispatch)(storeId, date),
      fetchStore: () => fetchStore(dispatch)(storeId),
      fetchHours: () => fetchHours(dispatch)(storeId),
      updateHoursRecord: (data) => updateHoursRecord(dispatch)(storeId, data),
      setClosed: (date, closed) =>
        setOverrideClosed(dispatch)(storeId, date, closed),
      setHoliday: (date, holiday) =>
        setOverrideHoliday(dispatch)(storeId, date, holiday),
      setFlashMessage: (message, variant) =>
        dispatch(setFlashMessage(message, variant)),
    },
  }
}

function StoreHolidays(props) {
  const { actions } = props
  const [hasFetchedData, setHasFetchedData] = useState(false)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [dialogLoading, setDialogLoading] = useState(false)

  if (!hasFetchedData) {
    setHasFetchedData(true)
    Promise.all([actions.fetchStore(), actions.fetchHours()]).catch(
      (err) => actions.setFlashMessage(err.message),
      'error',
    )
  }

  return (
    <div>
      <CreateHolidayDialog
        loading={dialogLoading}
        onClose={() => setCreateDialogOpen(false)}
        onSubmit={(data) => {
          setDialogLoading(true)
          actions
            .updateHoursRecord(data)
            .then(() => {
              actions.setFlashMessage('Success', 'success')
              setCreateDialogOpen(false)
              setDialogLoading(false)
            })
            .catch((err) => {
              actions.setFlashMessage(err.message, 'error')
              setDialogLoading(false)
            })
        }}
        open={createDialogOpen}
        storeId={getStoreId(props)}
      />
      <MaterialTable
        actions={[
          {
            icon: 'add',
            isFreeAction: true,
            tooltip: 'Add Holiday',
            onClick: () => setCreateDialogOpen(true),
          },
        ]}
        columns={[
          {
            title: 'Date',
            field: 'override_date',
            defaultSort: 'asc',
            render: (rowData) =>
              getFormattedDate(rowData.override_date.split(' ')[0]),
          },
          {
            title: 'Opens',
            field: 'opening_time',
            render: (rowData) => getFormattedTime(rowData.opening_time),
          },
          {
            title: 'Closes',
            field: 'closing_time',
            render: (rowData) => getFormattedTime(rowData.closing_time),
          },
          {
            title: 'Closed',
            editable: 'never',
            field: 'is_closed',
            render: (rowData) => {
              return (
                <Switch
                  checked={rowData.is_closed}
                  onChange={(event) => {
                    actions.setClosed(
                      rowData.override_date,
                      event.target.checked,
                    )
                  }}
                />
              )
            },
          },
          {
            title: 'Holiday',
            editable: 'never',
            field: 'is_holiday',
            render: (rowData) => {
              return (
                <Switch
                  checked={rowData.is_holiday}
                  onChange={(event) => {
                    actions.setHoliday(
                      rowData.override_date,
                      event.target.checked,
                    )
                  }}
                />
              )
            },
          },
        ]}
        data={Object.values(props.overrides).filter(
          (row) => row.archived_at == null,
        )}
        editable={{
          onRowDelete: (rowData) => {
            return actions.deleteOverride(rowData.override_date)
          },
          onRowUpdate: (rowData) => {
            return actions.updateHoursRecord(rowData)
          },
        }}
        options={{
          actionsColumnIndex: 5,
        }}
        title={`Upcoming Holidays - ${props.store.name}`}
      />
    </div>
  )
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StoreHolidays),
)
