String.prototype.capitalizedFirstLetter = function () {
  return this.charAt(0).toUpperCase() + this.substr(1).toLowerCase()
}

String.prototype.stripPrefix = function (prefix) {
  if (this.indexOf(prefix) === 0) return this.slice(prefix.length)
  else return this
}

export const toTitleCase = (string) => {
  return string.replace(/\w\S*/g, (string) => {
    return string.capitalizedFirstLetter()
  })
}

export const capitalizedFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase()
}

export const stripPrefix = (prefix, string) => {
  if (string.indexOf(prefix) === 0) return string.slice(prefix.length)
  else return string
}

export const getErrorMessage = (error) => {
  if (error instanceof Error) return error.message
  return String(error)
}
