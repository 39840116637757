import * as api from '../utils/api'
import dayjs from 'dayjs'

import { setTipReport } from '../store/actions/tips'

function formatDate(date) {
  return dayjs(date).format('YYYY-MM-DD')
}

export const fetchTipReport = (dispatch) => async (startDate, endDate) => {
  const start = formatDate(startDate)
  const end = formatDate(endDate)
  console.log(`Fetching tip report for dates between ${start} and ${end}`)
  const { data } = await api.GET(
    `/p4/reports/tips?start_date=${start}&end_date=${end}`,
  )
  dispatch(setTipReport(data))
}

/**
 * Fetches a CSV tip report between two dates, and returns a promise that resolves
 * to that CSV data.
 */
export const fetchTipReportCSV = async (startDate, endDate) => {
  const start = formatDate(startDate)
  const end = formatDate(endDate)
  console.log(`Fetching tip report for dates between ${start} and ${end}`)
  const { data } = await api.GET(
    `/p4/reports/tips?format=csv&start_date=${start}&end_date=${end}`,
  )
  return data
}
