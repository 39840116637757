import React, { useEffect, useMemo, useState } from 'react'

import memoize from 'lodash.memoize'
import { Add, DeleteOutlined } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'

import AddRecommendationDialog from './AddRecommendationDialog'
import DndTable from '@shared/dnd-table'
import Loader from '@shared/loader'
import ProductSelector from '@shared/product-selector'

import { getProductList } from '@queries/products'
import { selectRecommendations } from '@store/selectors'
import { useAllActions, useSelector } from '@hooks'
import { useQuery } from '@tanstack/react-query'

const RecommendationEngine = () => {
  const { classes: styles } = useStyles()

  const { isLoading: loadingProducts, data: productList } = useQuery({
    queryKey: ['productList'],
    queryFn: getProductList,
    staleTime: 30000,
  })

  const {
    getRecommendations,
    setRecommendations,
    deleteRecommendation,
    updateRecommendation,
  } = useAllActions()

  const [dialogOpen, setDialogOpen] = useState(false)
  useEffect(() => {
    getRecommendations()
  }, [])

  const recommendations = useSelector(selectRecommendations)

  const reSort = (data) => {
    const newData = data.map(({ id }, index) => {
      return { id, sort_order: index }
    })

    setRecommendations(newData)
  }

  const optionChangeFn = (recommendation, index) => (value) =>
    updateRecommendation(recommendation, index, value)

  const optionColumns = useMemo(() => {
    const columns = [0, 1, 2, 3, 4, 5, 6]

    return columns.map((_, i) => {
      const memoOptionChangeFn = memoize(
        (index, recommendation) => optionChangeFn(recommendation, index),
        (index, recommendation) => `${JSON.stringify(recommendation)}${index}`,
      )

      return {
        Header: 'Option ' + (i + 1),
        accessor: ({ recommended_product_ids, id }) => ({
          recommendedProductId: recommended_product_ids[i] || '',
          handleChange: memoOptionChangeFn(i, { recommended_product_ids, id }),
          disabled: i !== 0 && !recommended_product_ids[i - 1],
        }),
        Cell: function renderProductSelector(props) {
          return (
            <ProductSelector
              className={styles.productSelector}
              disabled={props.value.disabled}
              onChange={props.value.handleChange}
              value={props.value.recommendedProductId}
            />
          )
        },
      }
    })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Trigger',
        accessor: (row) =>
          productList.find((p) => {
            return p.id === row.product_id
          })?.name,
      },
    ].concat(optionColumns)
  }, [optionColumns, productList])

  const actions = useMemo(() => {
    return [
      {
        title: 'Add Recommendation',
        icon: <Add />,
        toolbarAction: true,
        onClick: () => setDialogOpen(true),
      },
      {
        title: 'Delete row',
        icon: <DeleteOutlined />,
        toolbarAction: false,
        onClick: (row) => deleteRecommendation(row.id),
      },
    ]
  }, [deleteRecommendation, setDialogOpen])

  if (loadingProducts) return <Loader />

  return (
    <div>
      <AddRecommendationDialog
        loading={false}
        open={dialogOpen}
        recommendations={recommendations}
        setOpen={setDialogOpen}
      />
      <DndTable
        actions={actions}
        afterDrop={reSort}
        columns={columns}
        data={recommendations}
        sortColumn={['sort_order']}
        sortOrder={['asc']}
        title='Recommendation Engine'
      />
    </div>
  )
}

export default RecommendationEngine

const useStyles = makeStyles()(() => ({
  productSelector: {
    width: '100%',
    minWidth: 100,
  },
}))
