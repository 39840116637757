import { ActionPayloadType, DialogPayloadType } from '@types'
import { CLOSE_DIALOG, SHOW_DIALOG } from '@store/action-list'

const initialState = {
  component: null,
}

export default (
  state = initialState,
  { type, payload }: ActionPayloadType<DialogPayloadType>,
): any => {
  switch (type) {
    case SHOW_DIALOG: {
      const { component } = payload
      return {
        ...state,
        component,
      }
    }
    case CLOSE_DIALOG: {
      return initialState
    }
    default:
      return state
  }
}
