import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { selectCustomContents } from '@store/selectors'
import { useAllActions, useSelector } from '@hooks'

function CustomContentSelector(props) {
  const { getCustomContents } = useAllActions()
  const contents = useSelector(selectCustomContents)

  useEffect(() => {
    getCustomContents()
  }, [])

  if (!Object.keys(contents).length) return ''

  return (
    <FormControl className={props.className} {...props.containerProps}>
      <InputLabel id='content-select-label'>Custom Content</InputLabel>
      <Select
        autoWidth
        labelId='content-select-label'
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
      >
        <MenuItem value={-1}>
          <em>None</em>
        </MenuItem>
        {Object.values(contents).map((content) => (
          <MenuItem key={content.id} value={content.id}>
            {content.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

CustomContentSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  shouldLoadContents: PropTypes.bool,
  containerProps: PropTypes.object,
}

CustomContentSelector.defaultProps = {
  shouldLoadContents: true,
}

export default CustomContentSelector
