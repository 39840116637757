import '../../utils/array-utils'

import {
  ADD_ORDERS,
  REFUNDED_PRODUCTS_SUCCESS,
  SET_HISTORY_FOR_ORDER,
  SET_ORDER_COUNT,
} from '../action-list'

/**
 * Orders have the format:
 *
 * {
 *  id: number,
 *  storeId: number,
 *  name: string (customer name),
 *  placement_time: string (datestring),
 *  pickup_time: string (datestring),
 *  accessible_pickup: boolean,
 *  status: string
 * }
 */
const initialState = {
  orders: {},
  history: {},
  totalCount: Number.MAX_SAFE_INTEGER,
}

export default (state = initialState, action) => {
  const { payload, orders, totalCount } = action

  switch (action.type) {
    case REFUNDED_PRODUCTS_SUCCESS: {
      const orderId = payload.id
      const products = Object.keys(state.orders[orderId].products).reduce(
        (a, id) => {
          const { refunded, refunded_at } = payload.products[id]
          a[id] = {
            ...state.orders[orderId].products[id],
            refunded,
            refunded_at,
          }
          return a
        },
        {},
      )
      return {
        ...state,
        orders: {
          ...state.orders,
          [orderId]: {
            ...state.orders[orderId],
            products,
          },
        },
      }
    }
    case ADD_ORDERS:
      return { ...state, orders: { ...state.orders, ...orders.toObject('id') } }
    case SET_ORDER_COUNT:
      return { ...state, totalCount }
    case SET_HISTORY_FOR_ORDER: {
      const { history } = state
      const { payload } = action

      return { ...state, history: { ...history, [payload.id]: payload } }
    }
    default:
      return state
  }
}
