import ProductsVendorsTable from './products-vendors-table'
import React, { ReactElement, useEffect, useState } from 'react'
import useSelector from '@hooks/use-selector'
import { AppDispatch, TagType } from '@types'
import { CircularProgress } from '@mui/material'
import { getProductsVendors } from '@store/actions/vendor-products'
import { getVendorRegions } from '@store/actions/vendor-regions'
import { selectVendorProductsByProduct } from '@store/selectors/vendor-products'
import { useDispatch } from 'react-redux'

interface Props {
  permissions: string[]
  productId: number
  productTags: TagType[]
}

const ProductsVendors = ({
  permissions,
  productId,
  productTags,
}: Props): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const vendorProducts = useSelector(selectVendorProductsByProduct, productId)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const loadData = async () => {
      setIsLoaded(false)

      await Promise.all([
        dispatch(getProductsVendors(productId)),
        dispatch(getVendorRegions()),
      ])

      setIsLoaded(true)
    }

    if (!isLoaded) loadData()
  }, [productId])

  if (!isLoaded) return <CircularProgress />

  return (
    <ProductsVendorsTable
      permissions={permissions}
      productId={productId}
      productsVendors={vendorProducts}
      productTags={productTags}
    />
  )
}

export default ProductsVendors
