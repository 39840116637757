import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Form from '../form'
import FormInput from '../form-input'
import ProductSelector from '../product-selector'
import StoreSelector from '../store-selector'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  form: {
    paddingTop: 0,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}))

function ProductStoreForm(props) {
  const { classes } = useStyles()
  const initialData = props.initialData || {}
  const [storeId, setStoreId] = useState(initialData.storeId || -1)
  const [productId, setProductId] = useState(initialData.productId || -1)
  const [price, setPrice] = useState(props.price || 0)

  useEffect(() => {
    props.onChange({
      product_id: productId,
      store_id: storeId,
      price: parseInt(price, 10),
    })
  }, [storeId, productId, price])

  return (
    <Form className={classes.form} paper={false}>
      <FormInput
        inputProps={{
          type: 'number',
        }}
        onChangeText={setPrice}
        title='Base Price (in cents)'
        value={price}
      />
      {!props.hideStore && (
        <StoreSelector
          className={classes.marginBottom}
          containerProps={{ fullWidth: true }}
          onChange={setStoreId}
          value={storeId}
        />
      )}
      {!props.hideProduct && (
        <ProductSelector
          className={classes.marginBottom}
          containerProps={{ fullWidth: true }}
          onChange={setProductId}
          value={productId}
        />
      )}
    </Form>
  )
}

ProductStoreForm.propTypes = {
  /**
   * Initial data accepts keys 'storeId', 'productId',
   * 'price'
   */
  initialData: PropTypes.object,
  onChange: PropTypes.func,
  price: PropTypes.number,
}

export default connect()(ProductStoreForm)
