export const getPathForResult = (result) => {
  switch (result.type) {
    case 'product':
      return `/products/${result.id}`
    case 'user':
      return `/users/${result.id}`
    case 'store':
      return `/stores/${result.id}`
    case 'order':
      return `/users/${result.user_id}/orders/${result.id}`
    default:
      return null
  }
}

export const getIconForResult = (result) => {
  const icons = {
    product: 'menubook',
    user: 'people',
    store: 'store',
  }

  return icons[result.type]
}
