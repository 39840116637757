import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'

import '@utils/string-utils'

import { Button, FormLabel, Switch } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Form from '@components/shared/form'
import FormImageInput from '@components/shared/form-image-input'
import FormInput from '@components/shared/form-input'
import getImage from '@utils/get-image'
import { TagType } from '@types'
import { formatDefault } from '@utils/date-utils'

const useStyles = makeStyles()(() => ({
  submitButton: {
    marginTop: 24,
  },
}))

type Props = {
  initialData: TagType
  onSubmit: (tag: TagType) => void
}

const TagForm = ({ initialData, onSubmit }: Props): ReactElement => {
  const { classes } = useStyles()

  const [content, setContent] = useState<TagType>(initialData)
  const [imageUrl, setImageUrl] = useState(initialData.image_url || '')
  const [imageData, setImageData] = useState<string | null>(null)

  useEffect(() => {
    setContent(initialData)
  }, [initialData])

  const submit = async () => {
    if (imageData) {
      return onSubmit({ ...content, image_data: imageData })
    }

    return onSubmit(content)
  }

  const updateContent = (
    value: string | boolean,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setContent({ ...content, [e.target.name]: value })
  }

  const updateSwitch = (
    e: ChangeEvent<HTMLInputElement>,
    value: string | boolean,
  ) => {
    setContent({ ...content, [e.target.name]: value })
  }

  const updateImage = async (data) => {
    setImageUrl(data.url)

    const newImageData = await getImage(data.url)

    setImageData(newImageData)
  }

  return (
    <Form>
      <FormImageInput imageUrl={imageUrl} onChange={updateImage} />
      <FormInput
        name='name'
        onChangeText={updateContent}
        title='Name'
        value={content.name}
      />
      <FormLabel component='legend'>Is Allergen</FormLabel>
      <Switch
        checked={content.is_allergen}
        name='is_allergen'
        onChange={updateSwitch}
      />

      <p> {`Created At: ${formatDefault(content.created_at)}`} </p>
      <p> {`Updated At: ${formatDefault(content.updated_at)}`} </p>
      <Button
        className={classes.submitButton}
        color='secondary'
        onClick={submit}
        variant='contained'
      >
        Submit
      </Button>
    </Form>
  )
}

export default TagForm
