import * as api from '@utils/api'
import createAction from './create-action'
import {
  ARCHIVE_PROMOTION_FAILURE,
  ARCHIVE_PROMOTION_SUCCESS,
  CREATE_PROMOTION_FAILURE,
  CREATE_PROMOTION_SUCCESS,
  GET_PROMOTIONS_FAILURE,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTION_FAILURE,
  GET_PROMOTION_SUCCESS,
  UPDATE_PROMOTION_FAILURE,
  UPDATE_PROMOTION_SUCCESS,
} from '@store/action-list'
import {
  ApiDispatch,
  AppDispatch,
  PromotionType,
  PromotionsResponseType,
} from '@types'
import { apiAction } from './api'

export const getPromotionsSuccess = createAction<PromotionsResponseType>(
  GET_PROMOTIONS_SUCCESS,
)
const getPromotionsFailure = createAction(GET_PROMOTIONS_FAILURE)

export const getPromotions = (): ApiDispatch =>
  apiAction({
    url: '/p4/promotions',
    method: 'GET',
    actionCallbacks: {
      success: getPromotionsSuccess,
      failure: getPromotionsFailure,
    },
  })

export const getPromotionSuccess = createAction<PromotionType>(
  GET_PROMOTION_SUCCESS,
)
const getPromotionFailure = createAction(GET_PROMOTION_FAILURE)

export const getPromotion = (id: number | string): ApiDispatch =>
  apiAction({
    url: `/p4/promotions/${id}`,
    method: 'GET',
    actionCallbacks: {
      success: getPromotionSuccess,
      failure: getPromotionFailure,
    },
  })

type NewPromotionType = {
  name: string
  type: string
  start_date: string
  end_date: string
  start_time: string
  end_time: string
  activation_code: string | null
  limit_uses: number | null
  configuration: Record<string, any>
  stores: { id: number }[]
  roles: { id: number }[]
  users: { id: number }[]
  repeats: string | null
}

type UpdatePromotionType = NewPromotionType & { id: number }

export const updatePromotionSuccess = createAction<PromotionType>(
  UPDATE_PROMOTION_SUCCESS,
)
const updatePromotionFailure = createAction(UPDATE_PROMOTION_FAILURE)

export const updatePromotion = ({
  id,
  name,
  type,
  start_date,
  end_date,
  start_time,
  end_time,
  configuration,
  stores,
  roles,
  activation_code,
  limit_uses,
  users,
  repeats,
}: UpdatePromotionType): ApiDispatch =>
  apiAction({
    url: `/p4/promotions/${id}`,
    method: 'PUT',
    data: {
      name,
      type,
      start_date,
      end_date,
      start_time,
      end_time,
      configuration,
      stores,
      roles,
      activation_code,
      limit_uses,
      users,
      repeats,
    },
    actionCallbacks: {
      success: updatePromotionSuccess,
      failure: updatePromotionFailure,
    },
  })

export const createPromotionSuccess = createAction<PromotionType>(
  CREATE_PROMOTION_SUCCESS,
)
const createPromotionFailure = createAction(CREATE_PROMOTION_FAILURE)

export const createPromotion = ({
  name,
  type,
  start_date,
  end_date,
  start_time,
  end_time,
  configuration,
  stores,
  roles,
  activation_code,
  limit_uses,
  users,
  repeats,
}: NewPromotionType): ApiDispatch =>
  apiAction({
    url: '/p4/promotions',
    method: 'POST',
    data: {
      name,
      type,
      start_date,
      end_date,
      start_time,
      end_time,
      configuration,
      stores,
      roles,
      activation_code,
      limit_uses,
      users,
      repeats,
    },
    actionCallbacks: {
      success: createPromotionSuccess,
      failure: createPromotionFailure,
    },
  })

export const archivePromotionSuccess = createAction<{ id: number }>(
  ARCHIVE_PROMOTION_SUCCESS,
)
export const archivePromotionFailure = createAction(ARCHIVE_PROMOTION_FAILURE)

export const archivePromotion = ((
  success: (payload: any, response: any) => any,
  failure: (_: any, err: Error) => any,
) => {
  return (id: number) =>
    async (dispatch: AppDispatch): Promise<AppDispatch | void> => {
      api
        .CALL({
          url: `/p4/promotions/${id}`,
          method: 'DELETE',
          data: null,
          params: null,
        })
        .then((response) => {
          dispatch(success({ id }, response))
        })
        .catch((error) => {
          dispatch(failure(null, error))
        })
    }
})(archivePromotionSuccess, archivePromotionFailure)
