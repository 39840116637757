import { DELETE_ROLE, SET_ROLE, SET_ROLES } from '@store/reducers/roles/actions'

const initialState = {}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case SET_ROLE: {
      const { role } = action
      return {
        ...state,
        [role.id]: role,
      }
    }
    case SET_ROLES: {
      const { roles } = action
      return roles.reduce((acc, role) => {
        return { ...acc, [role.id]: role }
      }, state)
    }
    case DELETE_ROLE: {
      return { ...state }
    }
    default:
      return state
  }
}
