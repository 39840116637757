import PropTypes from 'prop-types'
import React from 'react'
import dayjs from 'dayjs'
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers'

/**
 * Returns a date (the date components don't matter; it just uses today's date) with the
 * provided time.
 *
 * @param {String} string A timestring formatted hh:mm:ss
 */
function getDateFromTimeString(string) {
  if (string == null) return null
  const [hours, minutes, seconds] = string.split(':')
  const date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(seconds)
  return date
}

function getTimeStringFromDate(date) {
  return dayjs(date).format('HH:mm:ss')
}

/**
 * In-line time picker component. Inputs/outputs are all time strings formatted HH:mm:ss.
 * Time zone agnostic.
 */
function TimePicker(props) {
  return (
    <MuiTimePicker
      onChange={(date) => props.onChange(getTimeStringFromDate(date))}
      openTo='hours'
      value={dayjs(getDateFromTimeString(props.time))}
      variant='static'
    />
  )
}

TimePicker.propTypes = {
  /**
   * A method that fires every time the time changes. It will pass a time string
   * formatted HH:mm:ss
   */
  onChange: PropTypes.func,

  /**
   * A store opens/closes time, formatted HH:mm:ss
   */
  time: PropTypes.string,
}

export default TimePicker
