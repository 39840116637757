import createCachedSelector from '@utils/cached-selector'
import { createSelector } from 'reselect'

export const selectRecommendationById = createCachedSelector(
  (s, id) => s.Recommendations[id],
  (recommendation) => recommendation,
)((_s, id) => id)

export const selectRecommendations = createSelector(
  (s) => s.Recommendations,
  (recommendations) => recommendations,
)
