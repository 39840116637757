import { SET_STORE_ONLINE } from '../action-list'

/**
 * Mapping of storeId => online status (bool)
 */
const initialState = {}

export default (state = initialState, action) => {
  const { storeId, online } = action

  switch (action.type) {
    case SET_STORE_ONLINE:
      console.log(
        `Redux: Setting store ${storeId} online status to ${online.toString()}`,
      )
      return { ...state, [storeId]: online }
    default:
      return state
  }
}
