import MaterialTable from '@material-table/core'
import React, { ReactElement } from 'react'
import { AttributeValueData } from '@types'

interface Props {
  add(AttributeValueData): Promise<void>
  attributeValues: AttributeValueData[]
  className: string
  remove(data): Promise<void>
  update(oldData, newData): Promise<void>
  editable: boolean
}

const AttributeValues = ({
  add,
  attributeValues,
  className,
  remove,
  update,
  editable,
}: Props): ReactElement => {
  return (
    <div className={className}>
      <MaterialTable
        columns={[
          { title: 'Name', field: 'name', defaultSort: 'asc' },
          { title: 'Default', field: 'is_default', type: 'boolean' },
        ]}
        data={attributeValues}
        editable={
          editable
            ? {
                onRowUpdate: update,
                onRowDelete: remove,
                onRowAdd: add,
              }
            : {}
        }
        options={{
          paging: false,
          search: false,
          actionsColumnIndex: 99,
        }}
        title='Values'
      />
    </div>
  )
}

export default AttributeValues
