import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import MaterialTable from '@material-table/core'
import { Switch } from '@mui/material'

import {
  adjustInventory,
  markDoNotSell,
  setQuantity,
} from '../../../services/todays-inventory-service'
import { getProductsStoresForStore } from '@store/selectors/products-stores'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

const mapStateToProps = (state, ownProps) => ({
  inventory: Object.values(getProductsStoresForStore(state, ownProps.storeId))
    .map((productStore) => ({
      inventory:
        (state.TodaysInventory[ownProps.storeId] || {})[
          productStore.product_id
        ] || {},
      product: state.Products[productStore.product_id],
    }))
    .filter((x) => x.product.is_inventory_controlled == true),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    incrementInventory: (productId) =>
      adjustInventory(dispatch)(ownProps.storeId, productId, 1),
    decrementInventory: (productId) =>
      adjustInventory(dispatch)(ownProps.storeId, productId, -1),
    markDoNotSell: (productId, doNotSell) =>
      markDoNotSell(dispatch)(ownProps.storeId, productId, doNotSell),
    setInventoryQuantity: (productId, quantity) =>
      setQuantity(dispatch)(ownProps.storeId, productId, quantity),
    ...bindActionCreators(
      {
        setFlashMessage,
      },
      dispatch,
    ),
  },
})

function TodaysInventoryWidget(props) {
  const { actions, editable, className } = props
  return (
    <div className={className}>
      <MaterialTable
        actions={
          editable
            ? [
                {
                  icon: 'remove',
                  tooltip: 'Decrement',
                  onClick: (_, rowData) => {
                    actions.decrementInventory(rowData.product.id)
                  },
                },
                {
                  icon: 'add',
                  tooltip: 'Increment',
                  onClick: (_, rowData) => {
                    actions.incrementInventory(rowData.product.id)
                  },
                },
              ]
            : []
        }
        columns={[
          {
            title: 'Product',
            editable: 'never',
            field: 'product.name',
          },
          {
            title: 'Inventory',
            field: 'inventory.quantity_available',
            emptyValue: <em>Unlimited</em>,
          },
          {
            title: 'Available',
            field: 'inventory.do_not_sell',
            editable: 'never',
            render: (rowData) => {
              const inventory = rowData.inventory || {}

              return editable ? (
                <Switch
                  checked={!(inventory.do_not_sell === true)}
                  onChange={(event) => {
                    actions.markDoNotSell(
                      rowData.product.id,
                      !event.target.checked,
                    )
                  }}
                />
              ) : (
                <span>{inventory.do_not_sell ? 'No' : 'Yes'}</span>
              )
            },
          },
        ]}
        data={props.inventory}
        editable={
          editable && {
            onRowUpdate: async (newData) => {
              return actions.setInventoryQuantity(
                newData.product.id,
                newData.inventory.quantity_available,
              )
            },
          }
        }
        options={{
          actionsColumnIndex: 3,
        }}
        title={"Today's Inventory"}
      />
    </div>
  )
}

TodaysInventoryWidget.propTypes = {
  storeId: PropTypes.number,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TodaysInventoryWidget)
