import createAction from './create-action'
import { ApiDispatch, MenuCategoryDataType } from '@types'
import {
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  UPDATE_MENU_FAILURE,
  UPDATE_MENU_SUCCESS,
} from '@store/action-list'
import { apiAction } from './api'

const updateMenuSuccess =
  createAction<MenuCategoryDataType[]>(UPDATE_MENU_SUCCESS)
const updateMenuFailure = createAction(UPDATE_MENU_FAILURE)
const createCategorySuccess = createAction<MenuCategoryDataType[]>(
  CREATE_CATEGORY_SUCCESS,
)
const deleteCategorySuccess = createAction<MenuCategoryDataType[]>(
  DELETE_CATEGORY_SUCCESS,
)

export const updateMenu = (
  data: MenuCategoryDataType[],
  menu: string,
): ApiDispatch =>
  apiAction({
    url: `/p4/categories/${menu}`,
    method: 'PUT',
    data,
    actionCallbacks: {
      success: updateMenuSuccess,
      failure: updateMenuFailure,
    },
  })

export const createCategory = (
  categoryName: string,
  parentId: number,
  menu: string,
): ApiDispatch =>
  apiAction({
    url: `/p4/categories/${menu}`,
    method: 'POST',
    data: {
      name: categoryName,
      parent_id: parentId,
    },
    actionCallbacks: {
      success: createCategorySuccess,
      failure: updateMenuFailure,
    },
  })

export const deleteCategory = (categoryId: number, menu: string): ApiDispatch =>
  apiAction({
    url: `/p4/categories/${menu}/${categoryId}`,
    method: 'DELETE',
    data: {},
    actionCallbacks: {
      success: deleteCategorySuccess,
      failure: updateMenuFailure,
    },
  })
