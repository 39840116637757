import React, { ErrorInfo, ReactNode } from 'react'

type Props = {
  children: ReactNode
}

type State = {
  hasError: boolean
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: unknown, errorInfo: ErrorInfo): void {
    // TODO: report in sentry
    console.error(`error: ${error}`)
    console.error(`errorInfo: ${JSON.stringify(errorInfo)}`)
    console.error(`componentStack: ${errorInfo.componentStack}`)
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Oops, something went wrong :(</h1>
    }

    return this.props.children
  }
}
