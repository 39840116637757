import unionWith from 'lodash.unionwith'
import {
  ADD_DEFAULT_PRODUCTS,
  REMOVE_PRODUCTS_STORES,
  UPDATE_PRODUCTS_STORES,
  UPDATE_PRODUCTS_STORES_PRICE,
} from '../action-list'

/**
 * This is a mapping of key => product_store
 */
const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEFAULT_PRODUCTS:
    case UPDATE_PRODUCTS_STORES: {
      const { payload: productsStores } = action
      return unionWith(productsStores, state, (x, y) => {
        return x.store_id === y.store_id && x.product_id === y.product_id
      })
    }
    case REMOVE_PRODUCTS_STORES: {
      const { payload: productsStores } = action
      const keysToRemove = productsStores.map(
        (productStore) => `${productStore.store_id}#${productStore.product_id}`,
      )
      return state.filter(
        (productStore) =>
          !keysToRemove.includes(
            `${productStore.store_id}#${productStore.product_id}`,
          ),
      )
    }
    case UPDATE_PRODUCTS_STORES_PRICE: {
      const { payload: priceUpdate } = action
      const priceStateUpdate = {
        ...state.find(
          (p) =>
            p.product_id == priceUpdate.product_id &&
            p.store_id == priceUpdate.store_id,
        ),
        price: priceUpdate.price,
      }
      return unionWith([priceStateUpdate], state, (x, y) => {
        return x.store_id === y.store_id && x.product_id === y.product_id
      })
    }
    default:
      return state
  }
}
