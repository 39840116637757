import {
  DELETE_VERTICAL_CARD,
  SET_VERTICAL_CARD,
  SET_VERTICAL_CARDS,
} from './actions'

const initialState = {}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case SET_VERTICAL_CARD: {
      const { card } = action

      return {
        ...state,
        [card.id]: card,
      }
    }
    case SET_VERTICAL_CARDS: {
      const { cards } = action
      return cards.reduce((acc, card) => {
        return { ...acc, [card.id]: card }
      }, state)
    }
    case DELETE_VERTICAL_CARD: {
      return { ...state }
    }
    default:
      return state
  }
}
