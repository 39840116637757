import * as api from '../utils/api'

import { setProductCategories } from '../store/actions/product-categories'

export const fetchProductCategories = (dispatch) => async () => {
  const { data: categories } = await api.GET(
    '/p4/products/categories?tree=true',
  )
  dispatch(setProductCategories(categories))
}
