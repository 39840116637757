import Loader from '@components/shared/loader'
import React, { useEffect } from 'react'
import Toolbar from '@components/shared/toolbar'
import { Divider, Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { selectCustomContentById } from '@store/selectors'
import { useAllActions, useSelector } from '@hooks'
import { useParams } from 'react-router-dom'

import CustomContentForm from './custom-content-form'

const useStyles = makeStyles()((theme) => ({
  topContent: {
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
  },
  basicInfo: {
    flex: 3,
    marginRight: theme.spacing(2),
  },
}))

const CustomContent = () => {
  const { classes } = useStyles()
  const { id } = useParams()

  const { getCustomContent, saveCustomContent, setFlashMessage } =
    useAllActions()
  const content = useSelector((state) => selectCustomContentById(state, id))

  useEffect(() => {
    getCustomContent(id)
  }, [])

  const submit = async (content) => {
    try {
      await saveCustomContent(content)
      setFlashMessage('Custom Content Saved', 'success')
    } catch (e) {
      setFlashMessage(e.message, 'error')
    }
  }

  if (!content) return <Loader />

  return (
    <div>
      <div className={classes.topContent}>
        <Paper className={classes.basicInfo}>
          <Toolbar title={content.title} />
          <Divider />
          <CustomContentForm initialData={content} onSubmit={submit} />
        </Paper>
      </div>
    </div>
  )
}

export default CustomContent
