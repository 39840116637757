import createCachedSelector from 're-reselect'
import { RootState, VendorProductType } from '@types'
import { createSelector } from 'reselect'
import { identity } from '@utils/functional'

const vendorProductsSelector = (
  state: RootState,
): Record<number, VendorProductType> => state.VendorProducts

export const selectVendorProducts = createSelector(
  vendorProductsSelector,
  identity,
)

export const selectVendorProductsByProduct = createCachedSelector(
  vendorProductsSelector,
  (_: RootState, id: number) => id,
  (vendorProducts, id) =>
    Object.values(vendorProducts).filter(
      (vendorProduct) => vendorProduct.product_id === id,
    ),
)((_, id) => id)
