import * as api from '../utils/api'

import { formatBackend } from '../utils/date-utils'
import { setEodForDay } from '../store/actions/eod'

export const fetchEod = (dispatch) => async (date) => {
  console.log(`Fetching EOD for ${date}`)
  const dateString = formatBackend(date)
  const { data } = await api.GET(
    `/p4/reports/eod?date=${dateString}&format=json`,
  )
  return dispatch(setEodForDay(dateString, data))
}

export const fetchEodCSV = async (date) => {
  console.log(`Fetching EOD CSV for ${date}`)
  const dateString = formatBackend(date)
  const { data } = await api.GET(
    `/p4/reports/eod?date=${dateString}&format=csv`,
  )
  return data
}
