import MaterialTable from '@material-table/core'
import React, { useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

import { permissionListAlpha, selectRoles } from '@store/selectors'
import { useAllActions, useSelector } from '@hooks'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  section: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexGrow: 4,
  },
  roles: {
    width: '300px',
    marginRight: theme.spacing(2),
  },
  permissions: {
    width: '100%',
  },
}))

const Roles = () => {
  const { classes } = useStyles()
  const { createRole, getRoles } = useAllActions()

  const roles = useSelector(selectRoles)
  const permissions = useSelector(permissionListAlpha)
  const navigate = useNavigate()

  useEffect(() => {
    getRoles()
  }, [])

  return (
    <div>
      <div className={classes.section}>
        <div className={classes.roles}>
          <MaterialTable
            columns={[
              { title: 'Name', field: 'role', defaultSort: 'asc' },
              // { title: 'Permissions', field: 'permission_ids', editable: false },
            ]}
            data={Object.values(roles)}
            editable={{ onRowAdd: createRole }}
            onRowClick={(_, row) => {
              navigate(`/roles/${row.id}`)
            }}
            options={{
              actionsColumnIndex: 1,
              paging: false,
              search: false,
              sorting: true,
            }}
            title='Roles'
          />
        </div>
        <div className={classes.permissions}>
          <MaterialTable
            columns={[
              { title: 'Name', field: 'permission', defaultSort: 'asc' },
              { title: 'Description', field: 'description' },
              // { title: 'Roles', field: 'allowedRolesDesc' },
            ]}
            data={permissions}
            onRowClick={(_, row) => {
              navigate(`/permissions/${row.id}`)
            }}
            options={{
              paging: false,
              search: false,
              sorting: true,
            }}
            title='Permissions'
          />
        </div>
      </div>
    </div>
  )
}

export default Roles
