import PropTypes from 'prop-types'
import React from 'react'
import { Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import InventoryTable from './inventory-table'
import Toolbar from '../../shared/toolbar'

const useStyles = makeStyles()((theme) => ({
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

function InventoryWidget(props) {
  const { classes } = useStyles()

  return (
    <Paper className={props.className}>
      <Toolbar
        description={`Update the daily starting inventory for this store. When you're done, click "save".`}
        title='Starting Inventory (PARS)'
      />
      <div className={classes.content}>
        <InventoryTable editable={props.editable} storeId={props.storeId} />
      </div>
    </Paper>
  )
}

InventoryWidget.propTypes = {
  storeId: PropTypes.number,
}

export default InventoryWidget
