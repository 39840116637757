import {
  SET_TIP_REPORT,
  SET_TIP_REPORT_END,
  SET_TIP_REPORT_START,
} from '../../store/action-list'

export const setTipReport = (report) => ({
  type: SET_TIP_REPORT,
  report,
})

export const setStartDate = (date) => ({
  type: SET_TIP_REPORT_START,
  date,
})

export const setEndDate = (date) => ({
  type: SET_TIP_REPORT_END,
  date,
})
