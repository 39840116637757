import React, { ReactElement, useState } from 'react'
import ResponsiveDialog from '@shared/responsive-dialog'
import Toolbar from '@shared/toolbar'
import ToolbarButton from '@shared/toolbar-button'
import { AppDispatch } from '@types'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { Check, Queue } from '@mui/icons-material'
import { addDefaultProducts } from '@store/actions/stores'
import { useDispatch } from 'react-redux'
import { withStyles } from 'tss-react/mui'

type Props = {
  storeId: number
  variant?: 'outlined' | 'default'
  className?: any
}

const StyledCircularProgress = withStyles(CircularProgress, {
  colorPrimary: {
    color: 'white',
  },
})

const AddDefaultProducts = ({
  storeId,
  variant,
  className,
}: Props): ReactElement => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useDispatch<AppDispatch>()

  const handleCloseDialog = () => setDialogOpen(false)
  const handleOpenDialog = () => setDialogOpen(true)

  const handleAddDefaultProducts = async () => {
    setIsSubmitting(true)

    await dispatch(addDefaultProducts(storeId))

    setIsSubmitting(false)
    handleCloseDialog()
  }

  return (
    <>
      <ResponsiveDialog onClose={handleCloseDialog} open={dialogOpen}>
        <Toolbar title='Add Default Products?' />
        <DialogContent>
          This will add all default products to the store. Please confirm to
          continue.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            color='secondary'
            onClick={handleAddDefaultProducts}
            startIcon={
              isSubmitting ? <StyledCircularProgress size={20} /> : <Check />
            }
            variant='contained'
          >
            Confirm
          </Button>
        </DialogActions>
      </ResponsiveDialog>
      <ToolbarButton
        className={className}
        onClick={handleOpenDialog}
        startIcon={<Queue />}
        title='Add Default Products'
        variant={variant}
      />
    </>
  )
}

export default AddDefaultProducts
