export function formatCurrency(num) {
  return `$${(num / 100).toFixed(2)}`
}

export function formatTime(time) {
  if (!time) {
    return ''
  }

  const minutes = Math.floor(time / 60)
  const seconds = time - minutes * 60

  return `${minutes || '00'}min ${seconds > 0 ? `${seconds}sec` : ''}`
}

export function formatPhoneNumber(str) {
  // Filter only numbers from the input
  const cleaned = ('' + str).replace(/\D/g, '')

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  return null
}
