import ProductsTrigger from './parts/products-trigger'
import React, { ReactElement, useEffect, useState } from 'react'
import Toolbar from '@components/shared/toolbar'
import { Paper, TextField } from '@mui/material'
import { PromotionType } from '@types'
import { makeStyles } from 'tss-react/mui'

type PropsType = {
  className: string | null
  promotion: PromotionType | null
  editing: boolean
  onConfigurationChange: (configuration: Record<string, any>) => void
}

type ConfigurationType = {
  triggering_product_ids: number[]
  minimum_required_quantity: number
  discount_amount: number
}

const useStyles = makeStyles()((theme) => ({
  paperWrapper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: theme.spacing(2),
  },
  productSelect: {
    margin: theme.spacing(2),
  },
  formField: {
    marginRight: theme.spacing(2),
    maxWidth: 350,
  },
}))

const TriggeredDiscount = ({
  className,
  promotion,
  editing,
  onConfigurationChange,
}: PropsType): ReactElement => {
  const { classes } = useStyles()
  const [productIds, setProductIds] = useState<number[]>([])
  const [minimumRequiredQuantity, setMinimumRequiredQuantity] = useState(0)
  const [discountAmount, setDiscountAmount] = useState(0)
  const currentConfig = promotion ? promotion.configuration : {}
  const config: ConfigurationType = {
    triggering_product_ids: [],
    minimum_required_quantity: 0,
    discount_amount: 0,
    ...currentConfig,
  }

  useEffect(() => {
    setProductIds([])
    setMinimumRequiredQuantity(0)
    setDiscountAmount(0)
  }, [])

  useEffect(() => {
    if (!promotion) return
    setProductIds(config.triggering_product_ids)
    setMinimumRequiredQuantity(config.minimum_required_quantity)
    setDiscountAmount(config.discount_amount)
  }, [promotion])

  useEffect(() => {
    prepareConfiguration()
  }, [minimumRequiredQuantity, productIds, discountAmount])

  const handleMinimumRequiredChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = parseInt(e.target.value, 10)
    setMinimumRequiredQuantity(value)
  }

  const handleDiscountAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = parseInt(e.target.value, 10)
    setDiscountAmount(value)
  }

  const prepareConfiguration = () => {
    const newConfig: ConfigurationType = {
      triggering_product_ids: productIds,
      minimum_required_quantity: minimumRequiredQuantity,
      discount_amount: discountAmount,
    }
    onConfigurationChange(newConfig)
  }

  return (
    <div className={className || ''}>
      <Paper className={classes.paperWrapper}>
        <Toolbar title='Configuration' />
        <TextField
          className={classes.formField}
          disabled={!editing}
          helperText='The minimum amount of the triggering products that must be in the cart to activate the promotion.'
          label='Minimum Required Quantity'
          onChange={handleMinimumRequiredChange}
          value={minimumRequiredQuantity}
        />
        <TextField
          className={classes.formField}
          disabled={!editing}
          helperText='The amount to discount the triggered products.'
          label='Discount Amount'
          onChange={handleDiscountAmountChange}
          value={discountAmount}
        />
      </Paper>

      <div className={classes.paperMargin}>
        <ProductsTrigger
          editing={editing}
          onChangeProductIds={setProductIds}
          productIds={productIds}
        />
      </div>
    </div>
  )
}

export default TriggeredDiscount
