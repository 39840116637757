import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, Paper, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import ToolbarButton from './toolbar-button'

const useStyles = makeStyles()((theme) => ({
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  profileContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    margin: theme.spacing(2),
  },
  profileActions: {
    margin: theme.spacing(1),
  },
  avatar: {
    height: 80,
    width: 80,
    marginRight: 20,
  },
}))

function ProfileWidget(props) {
  const { classes } = useStyles()

  return (
    <Paper>
      <div className={classes.profileContainer}>
        <div className={classes.profileContent}>
          {props.imageUrl ? (
            <Avatar
              alt={props.title}
              className={classes.avatar}
              src={`https://${props.imageUrl}`}
            >
              {props.title.match(/\b(\w)/g).join('')}
            </Avatar>
          ) : (
            <div />
          )}
          <div>
            <Typography variant='h6'>{props.title}</Typography>
            <Typography variant='body1'>{props.subtitle1}</Typography>
            <Typography variant='body1'>{props.subtitle2}</Typography>
            <Typography variant='body1'>{props.subtitle3}</Typography>
          </div>
        </div>
        <div className={classes.profileActions}>
          {props.buttons &&
            props.buttons.map((config) => {
              if (config === false) return null
              return <ToolbarButton key={config.title} {...config} />
            })}
        </div>
      </div>
      {props.body && <div>{props.body}</div>}
    </Paper>
  )
}

ProfileWidget.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle1: PropTypes.string,
  subtitle2: PropTypes.string,
  subtitle3: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  ),
  imageUrl: PropTypes.string,
  fgColor: PropTypes.string,
  bgColor: PropTypes.string,
  body: PropTypes.node,
}

export default ProfileWidget
