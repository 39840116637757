import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Breadcrumbs from '@components/breadcrumbs'
import FlashMessage from '@components/flash-message'
import NavBar from '@components/navbar'

// Pages
import CreateProduct from '@components/pages/create-product'
import CreateStore from '@components/pages/create-store'
import CurrentUser from '@components/pages/current-user'
import CustomContent from '@components/pages/custom-contents/custom-content'
import CustomContents from '@components/pages/custom-contents'
import Employee from '@components/pages/employees/employee'
import EmployeeForm from '@components/pages/employees/employee-form'
import Employees from '@components/pages/employees'
import Login from '@components/pages/login'
import Menus from '@components/pages/menus'
import NotFound from '@components/pages/not_found'
import Order from '@components/pages/orders/order'
import Permission from '@components/pages/permissions/permission'
import Product from '@components/pages/product'
import Products from '@components/pages/products'
import RecommendationEngine from '@components/pages/recommendations'
import RecommendedProducts from '@components/pages/recommended-products/recommended-products'
import Reports from '@components/pages/reports'
import ResetPassword from '@components/pages/reset-password'
import Role from '@components/pages/roles/role/'
import Roles from '@components/pages/roles'
import Search from '@components/pages/search/search'
import Store from '@components/pages/store/store'
import StoreHolidays from '@components/pages/store-holidays/store-holidays'
import Stores from '@components/pages/stores'
import Surcharges from '@components/pages/surcharges'
import Tags, { Tag } from '@components/pages/tags'
import Vendors from '@components/pages/vendors'
import VerticalCard from '@components/pages/vertical-cards/vertical-card'
import VerticalCards from '@components/pages/vertical-cards'

import Dialog from '@components/dialog'
import PriceChange from '@components/pages/price-change'
import Promotion from '@components/pages/promotions/promotion'
import Promotions from '@components/pages/promotions/promotions'
import { CssBaseline } from '@mui/material'
import { fetchCurrentUserInfo } from '@services/auth-service'
import { getPermissions } from '@store/reducers/permissions/actions'
import { getUserIsLoggedIn } from '@store/selectors'

const theme = createTheme({
  palette: {
    primary: { main: PRIMARY_COLOR || '#40bcde' },
    secondary: { main: SECONDARY_COLOR || '#ff8f00' },
  },
  components: {
    // todo: remove once the issue is addressed: https://github.com/mui/material-ui/issues/31185
    MuiDialogContent: {
      styleOverrides: { root: { paddingTop: `5px !important` } },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          paddingLeft: '4px',
          paddingRight: '4px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: '#888',
        },
        indicator: {
          backgroundColor: 'rgba(0,0,0,0.6)',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'rgba(0,0,0,0.6)',
          '&.Mui-selected': {
            color: 'rgba(0,0,0,0.6) !important',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
})

const PROTECTED_ROUTES = [
  {
    element: <ResetPassword />,
    exact: true,
    path: '/reset-password',
  },
  {
    element: <Search />,
    exact: true,
    path: '/search',
  },
  {
    element: <CurrentUser />,
    exact: true,
    path: '/current-user',
  },
  {
    element: <CustomContents />,
    exact: true,
    path: '/custom-content',
  },
  {
    element: <CustomContent />,
    exact: true,
    path: '/custom-content/:id',
  },
  {
    element: <Employees />,
    exact: true,
    path: '/employees',
  },
  {
    element: <EmployeeForm />,
    exact: true,
    path: '/employees/create',
  },
  {
    element: <Employee />,
    exact: true,
    path: '/employees/:id',
  },
  {
    element: <EmployeeForm />,
    exact: true,
    path: '/employees/:id/edit',
  },
  {
    element: <Roles />,
    exact: true,
    path: '/roles',
  },
  {
    element: <Role />,
    exact: true,
    path: '/roles/:id',
  },
  {
    element: <Roles />,
    exact: true,
    path: '/permissions',
  },
  {
    element: <Permission />,
    exact: true,
    path: '/permissions/:id',
  },
  {
    element: <Order />,
    exact: true,
    path: '/users/:userId/orders/:orderId',
  },
  {
    element: <Vendors />,
    exact: true,
    path: '/vendors',
  },
  {
    element: <Tags />,
    exact: true,
    path: '/tags',
  },
  {
    element: <Tag />,
    exact: true,
    path: '/tags/create',
  },
  {
    element: <Tag />,
    exact: true,
    path: '/tags/:id/edit',
  },
  {
    element: <Employee />,
    path: '/users/:id',
  },
  {
    element: <CreateStore />,
    exact: true,
    path: '/stores/create-store',
  },
  {
    element: <Order />,
    exact: true,
    path: '/stores/:storeId/orders/:orderId',
  },
  {
    element: <StoreHolidays />,
    exact: true,
    path: '/stores/:storeId/holidays',
  },
  {
    element: <Store />,
    path: '/stores/:storeId',
  },
  {
    element: <Stores />,
    exact: true,
    path: '/stores',
  },
  {
    element: <CreateProduct />,
    exact: true,
    path: '/products/create-product',
  },
  {
    element: <Product />,
    exact: true,
    path: '/products/:productId',
  },
  {
    element: <Products />,
    exact: true,
    path: '/products',
  },
  {
    element: <Reports />,
    exact: true,
    path: '/reports',
  },
  {
    element: <RecommendedProducts />,
    exact: true,
    path: '/recommended-products',
  },
  {
    element: <RecommendationEngine />,
    exact: true,
    path: '/recommendation-engine',
  },
  {
    element: <VerticalCard />,
    exact: true,
    path: '/vertical-cards/:id',
  },
  {
    element: <VerticalCards />,
    exact: true,
    path: '/vertical-cards',
  },
  {
    element: <Surcharges />,
    exact: true,
    path: '/surcharges',
  },
  {
    element: <Promotions />,
    exact: true,
    path: '/promotions',
  },
  {
    element: <Promotion />,
    exact: true,
    path: '/promotions/:id',
  },
  {
    element: <Menus />,
    exact: true,
    path: '/menus',
  },
  {
    element: <PriceChange />,
    exact: true,
    path: '/price-change',
  },
]

const App = (props) => {
  const { isLoggedIn } = props
  const [isLoginStatusFetched, setLoginStatusFetched] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const {
        actions: { fetchCurrentUserInfo, getPermissions },
      } = props

      try {
        if (!isLoggedIn) {
          await fetchCurrentUserInfo()
        } else {
          await getPermissions()
        }
      } catch (error) {
        // Do nothing
      }

      setLoginStatusFetched(true)
    }

    fetchData()
  }, [isLoggedIn])

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NavBar>
          <Breadcrumbs />
          <FlashMessage />
          <Dialog />
          {isLoginStatusFetched ? (
            <Routes>
              <Route element={<Login />} exact path='/login' />
              {isLoggedIn &&
                PROTECTED_ROUTES.map((route, index) => (
                  <Route key={index} {...route} />
                ))}
              <Route
                element={isLoggedIn ? <Search /> : <Login />}
                exact
                path='/'
              />
              <Route element={isLoggedIn ? <NotFound /> : <Login />} path='*' />
            </Routes>
          ) : null}
          {props.children}
        </NavBar>
      </ThemeProvider>
    </Router>
  )
}

App.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.shape({
    fetchCurrentUserInfo: PropTypes.func,
    getPermissions: PropTypes.func,
  }),
  isLoggedIn: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isLoggedIn: getUserIsLoggedIn(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchCurrentUserInfo,
      getPermissions,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
