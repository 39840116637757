import React, { useEffect, useState } from 'react'
import { CSVDownload } from 'react-csv'
import { connect } from 'react-redux'

import MaterialTable, { MTableToolbar } from '@material-table/core'
import Toolbar from '../shared/toolbar'
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { SaveAlt as DownloadIcon } from '@mui/icons-material'
import { Paper, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { fetchAllProducts } from '../../services/product-service'
import { fetchEod, fetchEodCSV } from '../../services/eod-service'
import { fetchStores } from '../../services/store-service'
import { fetchTipReport, fetchTipReportCSV } from '../../services/tip-service'
import { formatBackend, formatShort } from '../../utils/date-utils'
import { setEndDate, setStartDate } from '../../store/actions/tips'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

const useStyles = makeStyles()((theme) => ({
  previewContainer: {
    display: 'flex',
    height: '50vh',
  },
  pickerContainer: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  startDatePicker: {
    marginRight: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  eodToolbarSubheader: {
    paddingLeft: theme.spacing(3),
  },
}))

const mapStateToProps = (state) => ({
  ...state.Tips,
  eod: state.Eod,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchEod: fetchEod(dispatch),
    fetchProducts: fetchAllProducts(dispatch),
    fetchStores: fetchStores(dispatch),
    fetchTipReport: fetchTipReport(dispatch),
    setStartDate: (date) => dispatch(setStartDate(date)),
    setEndDate: (date) => dispatch(setEndDate(date)),
    setFlashMessage: (message, variant) =>
      dispatch(setFlashMessage(message, variant)),
  },
})

function Reports(props) {
  const { actions } = props
  const theme = useTheme()
  const [csvData, setCsvData] = useState('')
  const [csvName, setCsvName] = useState('')

  const [eodDate, setEodDate] = useState(new Date(2020, 0, 3))
  const [eodCsvData, setEodCsvData] = useState('')
  const [eodCsvName, setEodCsvName] = useState('')

  const [download, setDownload] = useState('')

  const { classes } = useStyles()
  const [hasFetchedData, setHasFetchedData] = useState(false)

  useEffect(() => {
    actions.fetchTipReport(props.startDate, props.endDate)
  }, [props.startDate, props.endDate])

  useEffect(() => {
    actions.fetchEod(eodDate)
  }, [eodDate])

  const nameLimit = 3

  if (!hasFetchedData) {
    setHasFetchedData(true)
    Promise.all([actions.fetchProducts(), actions.fetchStores()]).catch((e) =>
      actions.setFlashMessage(e.message, 'error'),
    )
  }

  return (
    <div>
      <Paper className={classes.section}>
        {download == 'tips' && (
          <CSVDownload
            data={csvData}
            enclosingCharacter={``}
            filename={csvName}
          />
        )}
        {download == 'eod' && (
          <CSVDownload
            data={eodCsvData}
            enclosingCharacter={``}
            filename={eodCsvName}
          />
        )}
        <Toolbar
          buttons={[
            {
              icon: <DownloadIcon />,
              onClick: async () => {
                setDownload('')
                // This is async, so copy the start/end dates before continuing
                const { startDate, endDate } = props
                const data = await fetchTipReportCSV(startDate, endDate)
                const fname = `philz_tips_${formatShort(
                  startDate,
                )}-${formatShort(endDate)}.csv`
                setCsvData(data)
                setCsvName(fname)
                setDownload('tips')
              },
              title: 'Download',
            },
          ]}
          title='Tip Report'
        />
        <div className={classes.pickerContainer}>
          <DatePicker
            className={classes.startDatePicker}
            disableToolbar
            format='M/D/YY'
            label='Start Date'
            margin='normal'
            onChange={actions.setStartDate}
            value={dayjs(props.startDate)}
            variant='inline'
          />
          <DatePicker
            disableToolbar
            format='M/D/YY'
            label='End Date'
            margin='normal'
            onChange={actions.setEndDate}
            value={dayjs(props.endDate)}
            variant='inline'
          />
        </div>
        <div className={classes.previewContainer}>
          <ResponsiveContainer>
            <BarChart data={props.report.sort((a, b) => b.tips - a.tips)}>
              <XAxis
                dataKey='name'
                tickFormatter={(name) =>
                  name.length > nameLimit ? name.substring(0, nameLimit) : name
                }
              />
              <YAxis
                domain={[
                  0,
                  Math.max(...props.report.map((t) => t.tips)) || 100,
                ]}
                tickFormatter={(tips) => `$${tips}`}
              />
              <Tooltip formatter={(tips) => `$${tips}`} />
              <Bar dataKey='tips' fill={theme.palette.secondary.main} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Paper>
      <MaterialTable
        actions={[
          {
            icon: 'save_alt',
            isFreeAction: true,
            onClick: async () => {
              setDownload('')
              const data = await fetchEodCSV(eodDate)
              const fname = `philz_eod_${formatShort(eodDate)}.csv`
              setEodCsvData(data)
              setEodCsvName(fname)
              setDownload('eod')
            },
            tooltip: 'EOD Report',
          },
        ]}
        columns={[
          { title: 'Store', field: 'store' },
          { title: 'Time', field: 'time' },
          { title: 'Type', field: 'type' },
          { title: 'Revenue', field: 'revenue' },
        ]}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div className={classes.eodToolbarSubheader}>
                <DatePicker
                  format='M/D/YY'
                  label='Start Date'
                  onChange={setEodDate}
                  value={dayjs(eodDate)}
                  variant='inline'
                />
              </div>
            </div>
          ),
        }}
        data={props.eod[formatBackend(eodDate)] || []}
        title='EOD Report'
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
