import { VendorProductType } from '@types'
import { arrayToObject } from '@utils/functional'
import { createReducer } from '@reduxjs/toolkit'
import {
  removeVendorProduct,
  setVendorProduct,
  setVendorProducts,
} from '@store/actions/vendor-products'

type State = Record<number, VendorProductType>

const initialState = {} as State

const vendorProductsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setVendorProducts, (_, action) => {
      return arrayToObject('id', action.payload)
    })
    .addCase(setVendorProduct, (state, action) => {
      state[action.payload.id] = action.payload
    })
    .addCase(removeVendorProduct.fulfilled, (state, action) => {
      delete state[action.payload.id]
    })
})

export default vendorProductsReducer
