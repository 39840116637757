/* eslint-disable react/display-name */
import React from 'react'
import { useParams } from 'react-router-dom'

const withRouter = (Component) => (props) => {
  const params = useParams()
  return <Component {...props} {...{ match: { params: params } }} />
}

export default withRouter
