import Loader from '@components/shared/loader'
import React, { ReactElement, useState } from 'react'
import { AppDispatch } from '@types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from '@mui/material'
import { deleteSurcharge } from '@store/actions/tax-rates'
import { makeStyles } from 'tss-react/mui'
import { setFlashMessage } from '@store/reducers/flash-message/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles()((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))

type SelectedSurchargeType = {
  name: string
  id: number
}

type Props = {
  deleteDialogOpen: boolean
  currentDeleteSurcharge: SelectedSurchargeType
  // eslint-disable-next-line
  setDeleteDialogOpen: Function
}

const DeleteSurchargeDialog = ({
  deleteDialogOpen,
  currentDeleteSurcharge,
  setDeleteDialogOpen,
}: Props): ReactElement => {
  const { classes } = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const [deleteDialogLoading, setDeleteDialogLoading] = useState(false)
  const [confirmText, setConfirmText] = useState('')

  const handleClose = () => setDeleteDialogOpen(false)

  const handleConfirm = () => {
    setDeleteDialogLoading(true)
    dispatch(deleteSurcharge(confirmText, currentDeleteSurcharge.id))
      .then(() => {
        setDeleteDialogLoading(false)
        setDeleteDialogOpen(false)
        setConfirmText('')
        setFlashMessage('Surcharge delete.', 'success')
      })
      .catch((err) => {
        setFlashMessage(err.message, 'error')
        setDeleteDialogLoading(false)
      })
  }

  return (
    <Dialog
      aria-labelledby='dialog-title'
      onClose={handleClose}
      open={deleteDialogOpen}
    >
      <Loader visible={deleteDialogLoading} />
      <DialogTitle id='dialog-title'>Create Surcharge</DialogTitle>
      <p className='MuiDialogTitle-root MuiTypography-body1'>
        PLEASE CONFIRM!! Are you completely sure you want to delete{' '}
        <strong>{currentDeleteSurcharge.name}</strong>? Confirming will remove
        this surcharge from all stores it is applied to and it will never appear
        again in the admin dashboard.
      </p>
      <TextField
        className={classes.field}
        label='Confirm Surcharge Name'
        onChange={(e) => setConfirmText(e.target.value)}
        value={confirmText}
      />
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteSurchargeDialog
