import createAction from './create-action'
import createAsyncAction from './create-async-action'
import { ApiDispatch, TagType } from '@types'
import {
  CREATE_TAG,
  CREATE_TAG_FAILURE,
  SET_TAG,
  SET_TAG_FAILURE,
  UPDATE_TAG,
  UPDATE_TAG_FAILURE,
} from '@store/action-list'
import { apiAction } from './api'

export const setTag = createAction<TagType>(SET_TAG)
const setTagFailure = createAction(SET_TAG_FAILURE)

export const updateTagAction = createAction<TagType>(UPDATE_TAG)
const updateTagFailureAction = createAction(UPDATE_TAG_FAILURE)

export const createTagAction = createAction<TagType>(CREATE_TAG)
const createTagFailureAction = createAction(CREATE_TAG_FAILURE)

export const getTags = createAsyncAction<TagType[]>('getTags', () => ({
  url: '/p4/tags',
  method: 'GET',
}))

export const getTag = (id: string): ApiDispatch =>
  apiAction({
    url: `/p4/tag/${id}`,
    method: 'GET',
    actionCallbacks: {
      success: setTag,
      failure: setTagFailure,
    },
  })

export const createTag = (tag: TagType): ApiDispatch =>
  apiAction({
    url: '/p4/tags',
    method: 'POST',
    data: tag,
    actionCallbacks: {
      success: createTagAction,
      failure: createTagFailureAction,
    },
  })

export const updateTag = (tag: TagType): ApiDispatch =>
  apiAction({
    url: `/p4/tags/${tag.id}`,
    method: 'PUT',
    data: tag,
    actionCallbacks: {
      success: updateTagAction,
      failure: updateTagFailureAction,
    },
  })
