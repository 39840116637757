import ErrorBoundary from '@components/shared/error-boundary'
import React, { ReactElement, useState } from 'react'
import RoleSelector from '@components/shared/role-selector'
import Table from '@components/shared/table'
import { Add, DeleteSweep, DynamicFeed } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from '@mui/material'
import { getRoleList } from '@queries/roles'
import { useQuery } from '@tanstack/react-query'

type Props = {
  editing: boolean
  roleIds: number[]
  onChange: (roleIds: number[]) => void
}

const Roles = ({ editing, roleIds, onChange }: Props): ReactElement => {
  const [addRoleDialogOpen, setAddRoleDialogOpen] = useState(false)
  const [addRoleId, setAddRoleId] = useState(-1)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const [rowsPerPage, setRowsPerPage]: [number, (rowsPerPage: number) => any] =
    useState(10)
  const [page, setPage]: [number, (page: number) => any] = useState(0)

  const { data: roles } = useQuery({
    queryKey: ['roleList'],
    queryFn: getRoleList,
    staleTime: 30000,
  })

  const indexLastPost = (page + 1) * rowsPerPage
  const indexFirstPost = indexLastPost - rowsPerPage
  const rolesSelected = roles ? roles.filter((s) => roleIds.includes(s.id)) : []
  const roleRows = rolesSelected.slice(indexFirstPost, indexLastPost)

  const handleRoleSelect = (roleId: number) => {
    setAddRoleId(roleId)
  }

  const onAddRoleOpen = () => {
    setAddRoleDialogOpen(true)
  }

  const handleRemoveRole = (roleId: number) => {
    onChange(roleIds.filter((x) => x != roleId))
  }

  const onAddRoleClose = () => {
    setAddRoleDialogOpen(false)
  }

  const handleAddRole = () => {
    onChange([...roleIds, addRoleId])
    setAddRoleDialogOpen(false)
  }

  const onAddAllRoles = () => {
    setConfirmDialogOpen(true)
  }

  const onClearRoles = () => {
    setResetDialogOpen(true)
  }

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false)
  }

  const handleResetDialogClose = () => {
    setResetDialogOpen(false)
  }

  const addAllRoles = () => {
    if (!roles) return
    onChange(roles.map((s) => s.id))
    setConfirmDialogOpen(false)
  }

  const resetRoles = () => {
    onChange([])
    setResetDialogOpen(false)
  }

  return (
    <>
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        onClose={handleConfirmDialogClose}
        open={confirmDialogOpen}
      >
        <DialogTitle id='alert-dialog-title'>Confirm!</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to apply the promotion to all Roles?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color='primary' onClick={handleConfirmDialogClose}>
            Nevermind
          </Button>
          <Button color='primary' onClick={addAllRoles}>
            Add Roles
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        onClose={handleResetDialogClose}
        open={resetDialogOpen}
      >
        <DialogTitle id='alert-dialog-title'>Confirm!</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to clear all the roles from the promotion.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color='primary' onClick={handleResetDialogClose}>
            Nevermind
          </Button>
          <Button color='primary' onClick={resetRoles}>
            Clear Roles
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addRoleDialogOpen}>
        <DialogTitle id='alert-dialog-title'>Add Promotion to Role</DialogTitle>
        <DialogContent>
          <RoleSelector
            containerProps={{ fullWidth: true }}
            onChange={handleRoleSelect}
            value={addRoleId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onAddRoleClose}>Close</Button>
          <Button onClick={handleAddRole}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <ErrorBoundary>
        <Paper>
          <Table
            columns={[
              {
                title: 'ID',
                field: 'id',
              },
              {
                title: 'Role',
                field: 'role',
              },
            ]}
            count={rolesSelected.length}
            data={roleRows}
            description='This promotion will only apply to customers with these specific roles.'
            editing={editing}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            paginated
            rowActions={{
              onRowDelete: ({ id }) => {
                handleRemoveRole(id)
              },
            }}
            rowKey='id'
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            title='Applied To Roles'
            toolbarButtons={
              editing
                ? [
                    {
                      startIcon: <Add />,
                      title: 'Add Role',
                      onClick: onAddRoleOpen,
                    },
                    {
                      title: 'All Roles',
                      startIcon: <DynamicFeed />,
                      onClick: onAddAllRoles,
                    },
                    {
                      title: 'Clear Roles',
                      startIcon: <DeleteSweep />,
                      onClick: onClearRoles,
                    },
                  ]
                : undefined
            }
          />
        </Paper>
      </ErrorBoundary>
    </>
  )
}

export default Roles
