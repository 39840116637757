import '@utils/string-utils'
import React, { useEffect, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Form from '@components/shared/form'
import FormImageInput from '@components/shared/form-image-input'
import FormInput from '@components/shared/form-input'
import ReactMarkdown from 'react-markdown'
import getImage from '@utils/get-image'
import { formatDefault } from '@utils/date-utils'

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  preview: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: '45%',
    height: '100%',
  },
  colorDisplay: {
    height: 16,
    width: 16,
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    marginTop: 24,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  type: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  control: {
    marginBottom: theme.spacing(1),
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    height: 180,
  },
  editor: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const CustomContentForm = ({ initialData, onSubmit, title }) => {
  const { classes } = useStyles()
  const [content, setContent] = useState(initialData)
  const [imageUrl, setImageUrl] = useState(initialData.image_url)
  const [imageName, setImageName] = useState('')

  useEffect(() => {
    setContent(initialData)
  }, [initialData])

  const submit = async () => {
    if (imageUrl !== content.image_url) {
      const image = await getImage(imageUrl)
      content.image_data = image
      content.image_name = imageName
    }
    onSubmit(content)
  }

  const updateContent = (value, e) => {
    setContent({ ...content, [e.target.name]: value })
  }

  const updateContentBody = (e) => {
    updateContent(e.target.value, e)
  }

  const updateImage = (data) => {
    setImageName(data.name)
    setImageUrl(data.url)
  }

  const linkRenderer = (props) => {
    return <a href='#'>{props.children}</a>
  }

  return (
    <Form title={title}>
      <FormImageInput imageUrl={imageUrl} onChange={updateImage} />
      <FormInput
        name='title'
        onChangeText={updateContent}
        title='Title'
        value={content.title}
      />
      <div className={classes.editor}>
        <TextField
          className={classes.preview}
          multiline
          name='body'
          onChange={updateContentBody}
          title='Body'
          value={content.body}
          variant='outlined'
        />
        <ReactMarkdown
          className={classes.preview}
          components={{ link: linkRenderer }}
        >
          {content.body}
        </ReactMarkdown>
      </div>
      <p> {`Created At: ${formatDefault(content.created_at)}`} </p>
      <p> {`Updated At: ${formatDefault(content.updated_at)}`} </p>
      <Button className={classes.submitButton} onClick={submit}>
        Submit
      </Button>
    </Form>
  )
}

export default CustomContentForm
