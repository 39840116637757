import CreateProductStoreDialog from '@components/shared/dialogs/create-product-store-dialog'
import Loader from '@shared/loader'
import MaterialTable from '@material-table/core'
import PriceLookup from './price-lookup'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import VendorProductsSelect from '@components/shared/vendor-products-select'
import useAllActions from '@hooks/use-all-actions'
import useSelector from '@hooks/use-selector'
import { Button } from '@mui/material'
import { LocalAtm } from '@mui/icons-material'
import { checkPerms } from '@utils/has-permission'
import { getStoreList } from '@queries/stores'
import { selectVendorProducts } from '@store/selectors/vendor-products'
import { showDialog as showDialogAction } from '@store/reducers/dialog/actions'
import { useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'

interface Props {
  permissions: string[]
  productsStores: any
  product: any
  setProductStore(id, data): Promise<any>
  createProductStore(data): Promise<any>
  removeProductStore(any): Promise<any>
  addToAllStores(): any
}

const ProductsStoresTable = ({
  permissions,
  productsStores,
  createProductStore,
  setProductStore,
  removeProductStore,
  product,
  addToAllStores,
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const [showDialog, setShowDialog] = useState(false)
  const [showDialogLoading, setShowDialogLoading] = useState(false)
  const [editable, setEditable] = useState(false)
  const { setFlashMessage } = useAllActions()
  const vendors = useSelector(selectVendorProducts)

  const { isLoading: loadingStores, data: stores } = useQuery({
    queryKey: ['storeList'],
    queryFn: getStoreList,
    staleTime: 30000,
  })

  // To add products to stores from the product page you need to be assigned to
  // access ALL stores
  useEffect(() => {
    setEditable(
      checkPerms(permissions, 'stores:update-all') &&
        checkPerms(permissions, 'stores:update-menu'),
    )
  }, [permissions])

  const tableData = useMemo(() => {
    return productsStores.map((p) => ({
      ...p,
      name: stores?.find((store) => store.id === p.store_id)?.name,
    }))
  }, [productsStores, stores])

  if (loadingStores) return <Loader />

  return (
    <>
      <CreateProductStoreDialog
        hideProduct
        hideStore={false}
        initialData={{
          productId: product.id,
        }}
        loading={showDialogLoading}
        onClose={() => setShowDialog(false)}
        onConfirm={(data) => {
          setShowDialogLoading(true)
          createProductStore(data)
            .then(() => {
              setShowDialogLoading(false)
              setShowDialog(false)
              setFlashMessage('Added product to store.', 'success')
            })
            .catch((err) => {
              setShowDialogLoading(false)
              setFlashMessage(err.message, 'error')
            })
        }}
        open={showDialog}
        price={product.price}
      />
      <MaterialTable
        actions={
          editable
            ? [
                {
                  tooltip: 'Add Product to Store',
                  icon: 'add_box',
                  isFreeAction: true,
                  onClick: () => setShowDialog(true),
                },
                {
                  tooltip: 'Add Product to all Stores',
                  icon: 'dynamic_feed',
                  isFreeAction: true,
                  onClick: addToAllStores,
                },
              ]
            : []
        }
        columns={[
          {
            title: 'Store',
            field: 'name',
            editable: 'never',
          },
          {
            title: 'Vendor / Region',
            field: 'vendor_product_id',
            editComponent: function renderVendorProductSelect({
              onChange,
              value,
              rowData: { product_id },
            }) {
              return (
                <VendorProductsSelect
                  id={product_id}
                  onChange={onChange}
                  value={value || ''}
                />
              )
            },
            render: function renderVendor({ vendor_product_id }) {
              if (vendor_product_id == null) return null
              const vendorProduct = vendors[vendor_product_id]
              if (!vendorProduct) return null
              return `${vendorProduct.vendor.name} / ${vendorProduct.vendor_region.name}`
            },
          },
          {
            title: 'Prices (cents)',
            field: 'price',
            emptyValue: function renderEmptyValue() {
              return <em>N/A</em>
            },
            render: function render({
              product_id,
              store_id,
              price,
              total_prices,
            }: {
              product_id: number
              store_id: number
              price: number
              total_prices: number
            }) {
              return (
                <>
                  <div>Base Price: {price}</div>
                  {total_prices > 1 && editable && (
                    <Button
                      onClick={() => {
                        dispatch(
                          showDialogAction(() => (
                            <PriceLookup
                              productId={product_id as number}
                              storeId={store_id as number}
                            />
                          )),
                        )
                      }}
                      startIcon={<LocalAtm />}
                    >
                      Update {total_prices} Prices
                    </Button>
                  )}
                </>
              )
            },
            defaultSort: 'desc',
          },
        ]}
        data={tableData}
        // @ts-expect-error works fine just cannot make typescript happy for the editable params
        editable={
          editable && {
            onRowUpdate: async (newData: any) => {
              // The row update will set it to a string
              const newPrice = parseInt(newData.price, 10)
              if (isNaN(newPrice)) {
                return
              } else {
                await setProductStore(newData.store_id, {
                  price: newPrice,
                  vendor_id: newData.vendor_id,
                  vendor_product_id:
                    newData.vendor_product_id === -1
                      ? null
                      : newData.vendor_product_id,
                })
                  .then(() => setFlashMessage('Price updated.', 'success'))
                  .catch((e) => setFlashMessage(e.message, 'error'))
              }
            },
            onRowDelete: async (newData: any) => {
              await removeProductStore(newData.store_id)
                .then(() => setFlashMessage('Success', 'success'))
                .catch((e) => setFlashMessage(e.message, 'error'))
            },
          }
        }
        options={{
          actionsColumnIndex: 4,
        }}
        title='Stores'
      />
    </>
  )
}

export default ProductsStoresTable
