import { SET_ORDERS_FOR_STORE, SET_ORDER_COUNT_FOR_STORE } from '../action-list'

const initialState = {
  counts: {}, // storeId -> number
  orders: {}, // storeId -> orderId[]
}

/**
 * The currently displayed orders/counts for given stores
 */
export default (state = initialState, action) => {
  const { storeId, orderIds, count } = action

  switch (action.type) {
    case SET_ORDERS_FOR_STORE:
      console.log(
        `Redux: Setting ${orderIds.length} displayed orders for store ${storeId}`,
      )
      return { ...state, orders: { ...state.orders, [storeId]: orderIds } }
    case SET_ORDER_COUNT_FOR_STORE:
      console.log(`Redux: Setting order count to ${count} for store ${storeId}`)
      return { ...state, counts: { ...state.counts, [storeId]: count } }
    default:
      return state
  }
}
