import React, { ChangeEvent, MouseEvent, ReactElement, useState } from 'react'
import ToolbarButton from '@shared/toolbar-button'
import {
  Table as BaseTable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { Link } from 'react-router-dom'

import Toolbar from './toolbar'

type ColumnType<T> = {
  title: string
  field: string
  render?: (
    rowData: T,
    rowField: string | number | boolean,
    isEditing: boolean,
    index: number,
  ) => any
  width?: number
}

export type ColumnsType<T> = ColumnType<T>[]

type Props<T> = {
  data: T[]
  columns: ColumnsType<T>
  rowKey: string
  title?: string
  description?: string
  actionsTitle?: string
  toolbarButtons?: any // TODO: type
  paginated?: boolean
  rowsPerPageOptions?: number[]
  onPageChange?: (page: number) => any
  onRowsPerPageChange?: (rowsPerPage: number) => any
  onRowClick?: (rowData: T) => any
  count?: number
  rowActions?: any
  editing?: boolean
} & typeof defaultProps

const defaultProps = {
  page: 0,
  rowsPerPageOptions: [5, 10, 15, 25],
  paginated: false,
  onPageChange: (page: number) => page,
  onRowsPerPageChange: (rowsPerPage: number) => rowsPerPage,
  editing: false,
}

function Table<T = unknown>({
  title,
  description,
  actionsTitle,
  toolbarButtons,
  data,
  columns,
  paginated,
  rowsPerPageOptions,
  count,
  onPageChange,
  onRowsPerPageChange,
  rowKey,
  onRowClick,
  rowActions,
  editing,
}: Props<T>): ReactElement {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1])

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    onRowsPerPageChange(newRowsPerPage)
  }

  const handlePageChange = (_: MouseEvent | null, pageNumber: number) => {
    setPage(pageNumber)
    onPageChange(pageNumber)
  }

  const handleRowAction = (action: any, row: T) => () => action(row)

  return (
    <div>
      {(title || toolbarButtons) && (
        <Toolbar
          buttons={toolbarButtons}
          description={description ?? undefined}
          title={title ?? ''}
        />
      )}
      <BaseTable>
        <TableHead>
          <TableRow>
            {columns.map(({ title }) => {
              return (
                <TableCell align='left' key={title}>
                  {title}
                </TableCell>
              )
            })}
            {rowActions && editing && (
              <TableCell align='left' key='actions-column-head'>
                {actionsTitle ?? ''}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => {
            return (
              <TableRow
                component={onRowClick ? Link : 'tr'}
                hover={onRowClick && true}
                key={row[rowKey]}
                style={{ textDecoration: 'none' }}
                to={onRowClick && onRowClick(row)}
              >
                {columns.map(({ field, render }, columnIndex) => {
                  return (
                    <TableCell
                      align='left'
                      key={`${row[field]}_${columnIndex}`}
                    >
                      {render
                        ? render(row, row[field], editing, rowIndex)
                        : row[field]}
                    </TableCell>
                  )
                })}
                {rowActions && editing && (
                  <TableCell align='left' key={`actions_${row[rowKey]}`}>
                    {rowActions.onRowUpdate && (
                      <ToolbarButton
                        icon={<Edit />}
                        onClick={handleRowAction(rowActions.onRowUpdate, row)}
                        title='Edit'
                      />
                    )}
                    {rowActions.onRowDelete && (
                      <ToolbarButton
                        icon={<Delete />}
                        onClick={handleRowAction(rowActions.onRowDelete, row)}
                        title='delete'
                      />
                    )}
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </BaseTable>
      {paginated && (
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          component='div'
          count={count || data.length}
          nextIconButtonProps={{ 'aria-label': 'next page' }}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      )}
    </div>
  )
}

Table.defaultProps = defaultProps
export default Table
