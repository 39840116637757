import React, { ReactElement, useMemo } from 'react'
import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material'
import { CxArg } from 'tss-react/types'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectWrapper: {
    minHeight: 24,
  },
  select: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const VISIBLE_SCROLL_ITEMS = 4.5
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * VISIBLE_SCROLL_ITEMS + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(
  optionValue: string | number,
  allOptionsValues: (string | number)[],
  theme: Theme,
) {
  return {
    fontWeight:
      allOptionsValues.indexOf(optionValue) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

const findOptionValue = <
  ValueKey extends string,
  ValueType extends string | number,
  T extends { [key in ValueKey]: ValueType },
>(
  options: T[],
  optionValueKey: ValueKey,
  value: string | number,
) => {
  const foundOption = options.find((option) => option[optionValueKey] === value)

  // This *should* never happen, if this does throw, then there is a discrepancy
  // between options passed to the component, and values being selected by the user
  if (foundOption === undefined) throw new TypeError('Option does not exist')

  return foundOption
}

type Props<ValueKey, TitleKey, OptionKey, T> = {
  title: string
  options: T[]
  selectedOptions: T[]
  onChange: (options: T[]) => void
  optionValueKey: ValueKey
  optionTitleKey: TitleKey
  optionKey: OptionKey
  className?: CxArg
}

const MultipleSelect = <
  ValueKey extends string,
  TitleKey extends string,
  OptionKey extends string,
  T extends { [key in ValueKey]: string | number } & {
    [key in TitleKey]: string | number
  } & { [key in OptionKey]: string | number },
>({
  optionTitleKey,
  optionValueKey,
  optionKey,
  title,
  options,
  onChange,
  selectedOptions,
  className,
}: Props<ValueKey, TitleKey, OptionKey, T>): ReactElement => {
  const { classes, cx } = useStyles()
  const theme = useTheme()

  const selectedOptionsIds = useMemo(
    () => selectedOptions.map((option) => option[optionValueKey]),
    [selectedOptions],
  )

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value
    const eventValue = typeof value == 'string' ? value.split(',') : value
    onChange(
      eventValue.map((value) => {
        return findOptionValue(options, optionValueKey, value)
      }),
    )
  }

  return (
    <FormControl className={cx(classes.formControl, className)}>
      <InputLabel id='demo-mutiple-chip-label'>{title}</InputLabel>
      <Select
        className={cx(classes.selectWrapper, classes.select)}
        id='multiple-chip'
        input={<Input id='select-multiple-chip' />}
        labelId='multiple-chip-label'
        MenuProps={MenuProps}
        multiple
        onChange={handleChange}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                className={classes.chip}
                key={value}
                label={
                  findOptionValue(options, optionValueKey, value)?.[
                    optionTitleKey
                  ]
                }
                size='small'
              />
            ))}
          </div>
        )}
        value={selectedOptionsIds as string[]}
      >
        {options.map((option) => (
          <MenuItem
            key={option[optionKey]}
            style={getStyles(option[optionValueKey], selectedOptionsIds, theme)}
            value={option[optionValueKey]}
          >
            {option[optionTitleKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultipleSelect
