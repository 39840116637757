import * as api from '@utils/api'

export const SET_EMPLOYEE = 'SET_EMPLOYEE'
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'
export const SET_EMPLOYEES = 'SET_EMPLOYEES'

export const getEmployees = () => async (dispatch) => {
  const { data: employees } = await api.GET('/p4/employees')

  return dispatch({
    type: SET_EMPLOYEES,
    payload: employees,
  })
}

export const getFilteredEmployees = (filters) => async (dispatch) => {
  const { data: employees } = await api.GET('/p4/employees', filters)

  return dispatch({
    type: SET_EMPLOYEES,
    payload: employees,
  })
}

export const createEmployee = (employee) => async (dispatch) => {
  const { data } = await api.POST('/p4/users', employee)

  return dispatch({
    type: SET_EMPLOYEE,
    payload: data,
  })
}

export const resetPassword = () => async (email) => {
  const { data } = await api.POST('/p4/passwordReset', { email })

  return data
}

export const getEmployee = (id) => async (dispatch) => {
  const { data: employee } = await api.GET(`/p4/users/${id}`)

  return dispatch({
    type: SET_EMPLOYEE,
    payload: employee,
  })
}

export const saveEmployee = (employeeData) => async (dispatch) => {
  const { data } = await api.PATCH(`/p4/users/${employeeData.id}`, employeeData)

  return dispatch({
    type: SET_EMPLOYEE,
    payload: data,
  })
}

export const demoteEmployee = (employeeData) => async (dispatch) => {
  const id = employeeData.id

  await api.PATCH(`/p4/users/${id}`, employeeData)

  return dispatch({
    type: DELETE_EMPLOYEE,
    payload: { id },
  })
}

export const setEmployees = (updatedEmployees) => async (dispatch) => {
  const employees = Object.values(updatedEmployees)

  return dispatch({
    type: SET_EMPLOYEES,
    payload: employees,
  })
}
