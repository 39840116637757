import {
  ADD_STORE_HOUR_OVERRIDE,
  SET_STORE_HOUR_OVERRIDES,
} from '../action-list'

export const setOverrides = (hours) => ({
  type: SET_STORE_HOUR_OVERRIDES,
  hours,
})

export const updateOverride = (override) => ({
  type: ADD_STORE_HOUR_OVERRIDE,
  override,
})
