import { VendorRegionType } from '@types'
import { arrayToObject } from '@utils/functional'
import { createReducer } from '@reduxjs/toolkit'
import { getVendorRegions } from '@store/actions/vendor-regions'

type State = Record<number, VendorRegionType>

const initialState = {} as State

const vendorRegionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getVendorRegions.fulfilled, (_, action) => {
    return arrayToObject('id', action.payload)
  })
})

export default vendorRegionsReducer
