import createAction from '@store/actions/create-action'
import {
  ADD_ORDERS,
  SET_HISTORY_FOR_ORDER,
  SET_HISTORY_FOR_ORDER_FAILURE,
  SET_ORDER_COUNT,
} from '../action-list'
import { apiAction } from '@store/actions/api'

export const addOrders = (orders) => ({
  type: ADD_ORDERS,
  orders,
})

export const setOrderCount = (totalCount) => ({
  type: SET_ORDER_COUNT,
  totalCount,
})

export const fetchHistory = (id) => {
  return apiAction({
    url: `/p4/orders/${id}/history`,
    method: 'GET',
    actionCallbacks: {
      success: createAction(SET_HISTORY_FOR_ORDER),
      failure: createAction(SET_HISTORY_FOR_ORDER_FAILURE),
    },
  })
}
