import Loader from '@shared/loader'
import MaterialTable from '@material-table/core'
import React, { ReactElement, useEffect } from 'react'
import { Button, Tooltip } from '@mui/material'

import useAllActions from '@hooks/use-all-actions'
import useSelector from '@hooks/use-selector'
import { Add, Delete } from '@mui/icons-material'
import { AppDispatch, EmployeeType } from '@types'

import ErrorBoundary from '@components/shared/error-boundary'
import hasPerms from '@utils/has-permission'
import { demoteEmployee, getEmployees } from '@store/actions/employees'
import { getErrorMessage } from '@utils/string-utils'
import { selectEmployeesList } from '@store/selectors/employees'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function EmployeesAndRoles(): ReactElement {
  const dispatch = useDispatch<AppDispatch>()
  const { setFlashMessage } = useAllActions()
  const navigate = useNavigate()

  const employees = useSelector(selectEmployeesList)

  const canUpdateUsersRolesPermissions = hasPerms('roles:update')

  useEffect(() => {
    dispatch(getEmployees())
  }, [])

  if (!employees.length) return <Loader />

  const removeEmployee = async (employeeInfo: EmployeeType) => {
    try {
      await dispatch(demoteEmployee(employeeInfo.id))

      setFlashMessage('Employee Demoted', 'success')
      getEmployees()
    } catch (error) {
      setFlashMessage(getErrorMessage(error), 'error')
    }
  }

  return (
    <div>
      <ErrorBoundary>
        <MaterialTable
          actions={
            canUpdateUsersRolesPermissions
              ? [
                  {
                    icon: 'add',
                    tooltip: 'Create Employee',
                    onClick: () => navigate('/employees/create'),
                    isFreeAction: true,
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Remove all roles and permissions',
                    onClick: removeEmployee,
                  },
                ]
              : []
          }
          columns={[
            { title: 'Name', field: 'name', defaultSort: 'asc' },
            { title: 'Email', field: 'email' },
            { title: 'Role', field: 'roles' },
          ]}
          components={{
            Action: function renderRemoveButton({ action, data }) {
              return (
                <Tooltip title={action.tooltip}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      action.onClick(data)
                    }}
                    startIcon={action.icon === 'add' ? <Add /> : <Delete />}
                  >
                    {action.icon === 'add' ? 'Create Employee' : 'Remove'}
                  </Button>
                </Tooltip>
              )
            },
          }}
          data={employees.map((employee) => ({ ...employee }))}
          onRowClick={(_, row) => {
            navigate(`/employees/${row?.id}`)
          }}
          options={{
            actionsColumnIndex: 4,
            paging: true,
            search: true,
            sorting: true,
            pageSize: 10,
          }}
          title='Employees'
        />
      </ErrorBoundary>
    </div>
  )
}

export default EmployeesAndRoles
