import createAction from './create-action'
import createAsyncAction from './create-async-action'
import omit from 'lodash.omit'
import { ApiDispatch, VendorProductType } from '@types'
import {
  SET_VENDOR_PRODUCT,
  SET_VENDOR_PRODUCTS,
  SET_VENDOR_PRODUCTS_FAILURE,
} from '@store/action-list'
import { apiAction } from './api'

export const setVendorProduct =
  createAction<VendorProductType>(SET_VENDOR_PRODUCT)

export const setVendorProducts =
  createAction<VendorProductType[]>(SET_VENDOR_PRODUCTS)

export const setVendorProductsFailure = createAction(
  SET_VENDOR_PRODUCTS_FAILURE,
)

export const getAllVendorProducts = (): ApiDispatch =>
  apiAction({
    url: '/p4/vendorProducts',
    method: 'GET',
    actionCallbacks: {
      success: setVendorProducts,
      failure: setVendorProductsFailure,
    },
  })

// TODO: does this name make sense?
export const getProductsVendors = (productId: number): ApiDispatch =>
  apiAction({
    url: '/p4/vendorProducts',
    method: 'GET',
    params: {
      product_id: productId,
    },
    actionCallbacks: {
      success: setVendorProducts,
      failure: setVendorProductsFailure,
    },
  })

export const createVendorProduct = (
  vendorProduct: VendorProductType,
): ApiDispatch =>
  apiAction({
    url: '/p4/vendorProducts',
    method: 'POST',
    data: vendorProduct,
    actionCallbacks: {
      success: setVendorProduct,
    },
  })

export const updateVendorProduct = (
  vendorProduct: VendorProductType,
): ApiDispatch =>
  apiAction({
    url: `/p4/vendorProducts/${vendorProduct.id}`,
    method: 'PUT',
    data: omit(vendorProduct, ['id']),
    actionCallbacks: {
      success: setVendorProduct,
    },
  })

export const removeVendorProduct = createAsyncAction<VendorProductType, number>(
  'deleteVendorProduct',
  (id) => ({
    url: `/p4/vendorProducts/${id}`,
    method: 'DELETE',
  }),
)
