import React, { ReactElement, useEffect, useState } from 'react'
import {
  AttributeValueData,
  ProductAttributeData,
  ProductTypeType,
} from '@types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Paper,
  Switch,
  Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import AttributeValues from './attribute-values'
import Attributes from './attributes'
import EmptyAttrs from './empty-attrs'

interface Props {
  className: string
  clone(cloneId: number): Promise<void>
  productAttributes: ProductAttributeData[]
  productType: ProductTypeType
  update(data): void
  editable: boolean
}

const ProductAttributes = ({
  className,
  clone,
  productAttributes,
  productType,
  update,
  editable,
}: Props): ReactElement => {
  const { classes } = useStyles()
  const [currentAttr, setCurrentAttr]: [
    ProductAttributeData | null,
    (attribute: ProductAttributeData | null) => void,
  ] = useState(productAttributes ? productAttributes[0] : null)
  const [openPricingNotice, setOpenPricingNotice] = useState<boolean | string>(
    false,
  )
  const [pendingRequiredValue, setPendingRequiredValue] = useState(false)
  const [pendingRemoveAttrData, setPendingRemoveAttrData] = useState<
    ProductAttributeData | undefined
  >()
  const [pendingRemoveAttrValue, setPendingRemoveAttrValue] = useState<
    AttributeValueData | undefined
  >()
  const [pendingAddAttrValue, setPendingAddAttrValue] = useState<
    AttributeValueData | undefined
  >()

  useEffect(() => {
    if (!currentAttr) {
      setCurrentAttr(productAttributes ? productAttributes[0] : null)
    } else {
      const updatedAttr = productAttributes?.find(
        (attr: ProductAttributeData): boolean => {
          return attr?.name === currentAttr?.name
        },
      )

      setCurrentAttr(updatedAttr || null)
    }
  }, [productAttributes])

  const handleUpdateIsRequired = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: boolean,
  ): void => {
    setPendingRequiredValue(value)
    setOpenPricingNotice('is_required')
  }

  const updateIsRequired = () => {
    const updatedAttr = currentAttr
      ? { ...currentAttr, is_required: pendingRequiredValue }
      : null
    setCurrentAttr(updatedAttr)
    updateAttribute(updatedAttr)
  }

  const addAttribute = (attrName: string): Promise<void> => {
    const lastSortOrder =
      productAttributes[productAttributes.length - 1]?.sort_order
    const newSortOrder = lastSortOrder ? lastSortOrder + 1 : 0
    const withNewValue = [
      ...productAttributes,
      { name: attrName, sort_order: newSortOrder },
    ]
    return new Promise((resolve) => {
      resolve(update(withNewValue))
    })
  }

  const updateAttribute = (
    data: ProductAttributeData | null,
  ): Promise<void> => {
    const updatedAttrs = productAttributes.map(
      (attr: ProductAttributeData): ProductAttributeData => {
        if (attr.name === data?.name) {
          return { ...attr, ...data }
        } else {
          return attr
        }
      },
    )

    return new Promise((resolve) => {
      resolve(update(updatedAttrs))
    })
  }

  const handleRemoveAttribute = ({
    original: attrData,
  }: {
    original: ProductAttributeData
  }): Promise<void> => {
    if (attrData.is_required) {
      setPendingRemoveAttrData(attrData)
      setOpenPricingNotice('remove_attribute')
      return Promise.resolve()
    } else {
      return doRemoveAttribute(attrData)
    }
  }

  const removeAttribute = () => {
    setOpenPricingNotice(false)
    if (pendingRemoveAttrData) {
      doRemoveAttribute(pendingRemoveAttrData)
    }
  }

  const doRemoveAttribute = (attrData: ProductAttributeData): Promise<void> => {
    const withNewValue = [...productAttributes].filter(
      (data: ProductAttributeData): boolean => {
        return data.id !== attrData.id && data.name !== attrData.name
      },
    )
    return new Promise((resolve) => {
      resolve(update(withNewValue))
    })
  }

  const handleAddAttributeValue = (
    attrData: AttributeValueData,
  ): Promise<void> => {
    if (currentAttr?.is_required) {
      setPendingAddAttrValue(attrData)
      setOpenPricingNotice('add_attribute_value')
      return Promise.resolve()
    } else {
      return doAddAttributeValue(attrData)
    }
  }

  const addAttributeValue = () => {
    setOpenPricingNotice(false)
    if (pendingAddAttrValue) {
      doAddAttributeValue(pendingAddAttrValue)
    }
  }

  const doAddAttributeValue = (attrData: AttributeValueData): Promise<void> => {
    const updatedAttrs = productAttributes.map(
      (attr: ProductAttributeData): ProductAttributeData => {
        if (attr.name === currentAttr?.name) {
          const attrs = attr.attribute_values
            ? [...attr.attribute_values, attrData]
            : [attrData]
          return { ...attr, attribute_values: attrs }
        } else {
          return attr
        }
      },
    )

    return new Promise((resolve) => {
      resolve(update(updatedAttrs))
    })
  }

  const updateAttributeValue = (
    newData: AttributeValueData,
    oldData: AttributeValueData,
  ): Promise<void> => {
    const updatedAttrValues = currentAttr?.attribute_values.map(
      (attrValue: AttributeValueData): AttributeValueData => {
        if (attrValue.name === oldData.name) return { ...attrValue, ...newData }
        if (newData.is_default) return { ...attrValue, is_default: false }
        return attrValue
      },
    )

    const updatedAttrs = productAttributes.map(
      (attr: ProductAttributeData): ProductAttributeData => {
        if (attr.name === currentAttr?.name && updatedAttrValues) {
          return { ...attr, attribute_values: updatedAttrValues }
        } else {
          return attr
        }
      },
    )

    return new Promise((resolve) => {
      resolve(resolve(update(updatedAttrs)))
    })
  }

  const handleRemoveAttributeValue = (
    attrData: AttributeValueData,
  ): Promise<void> => {
    if (currentAttr?.is_required) {
      setPendingRemoveAttrValue(attrData)
      setOpenPricingNotice('remove_attribute_value')
      return Promise.resolve()
    } else {
      return doRemoveAttributeValue(attrData)
    }
  }

  const removeAttributeValue = () => {
    setOpenPricingNotice(false)
    if (pendingRemoveAttrValue) {
      doRemoveAttributeValue(pendingRemoveAttrValue)
    }
  }

  const doRemoveAttributeValue = (
    attrData: AttributeValueData,
  ): Promise<void> => {
    const withoutValue = currentAttr
      ? [...currentAttr.attribute_values].filter(
          (data: AttributeValueData): boolean => {
            return data.name !== attrData.name
          },
        )
      : null

    const updatedAttrs = productAttributes.map(
      (attr: ProductAttributeData): ProductAttributeData => {
        if (attr.name === currentAttr?.name && withoutValue) {
          return { ...attr, attribute_values: withoutValue }
        } else {
          return attr
        }
      },
    )

    return new Promise((resolve) => {
      resolve(resolve(update(updatedAttrs)))
    })
  }

  const reSort = (attrs: ProductAttributeData[]): void => {
    const newData = attrs.map((attr, index) => {
      return { ...attr, sort_order: index }
    })
    update(newData)
  }

  const attrName = (attribute: ProductAttributeData): string => {
    return `${attribute?.name} {{${attribute.product_attribute_group.formatter_label}}}`
  }

  const handlePricingNoticeCancel = () => {
    setOpenPricingNotice(false)
  }

  const handlePricingNoticeConfirm = () => {
    setOpenPricingNotice(false)
    if (openPricingNotice == 'is_required') {
      updateIsRequired()
    } else if (openPricingNotice == 'remove_attribute') {
      removeAttribute()
    } else if (openPricingNotice == 'remove_attribute_value') {
      removeAttributeValue()
    } else if (openPricingNotice == 'add_attribute_value') {
      addAttributeValue()
    }
  }

  return (
    <>
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        open={!!openPricingNotice}
      >
        <DialogTitle id='alert-dialog-title'>Price change notice!</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to change this option? Changing this value
            affects how many pricing variations this product has in total.
            Proceeding will immediately delete all of the current prices and
            reset all price options to the base price of the product on each
            menu. In other words a Large could immediately cost the same as a
            Small if you make this change.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color='primary' onClick={handlePricingNoticeCancel}>
            Nevermind
          </Button>
          <Button color='primary' onClick={handlePricingNoticeConfirm}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <Paper className={`${className} ${classes.styles}`}>
        {!productAttributes || !productAttributes.length || !currentAttr ? (
          editable && (
            <EmptyAttrs
              add={addAttribute}
              clone={clone}
              productType={productType}
            />
          )
        ) : (
          <div className={classes.leftSide}>
            <Attributes
              add={addAttribute}
              attributes={productAttributes}
              className={classes.types}
              editable={editable}
              onClick={setCurrentAttr}
              remove={handleRemoveAttribute}
              reSort={reSort}
            />
            <div className={classes.rightSide}>
              <header className={classes.header}>
                <Typography className={classes.name} variant='h6'>
                  {attrName(currentAttr)}
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={currentAttr?.is_required}
                      color='primary'
                      disabled={!editable}
                      name='isRequired'
                      onChange={handleUpdateIsRequired}
                    />
                  }
                  label='Is Required (Applies Pricing)'
                />
              </header>
              <AttributeValues
                add={handleAddAttributeValue}
                attributeValues={currentAttr.attribute_values}
                className={classes.values}
                editable={editable}
                remove={handleRemoveAttributeValue}
                update={updateAttributeValue}
              />
            </div>
          </div>
        )}
      </Paper>
    </>
  )
}

const useStyles = makeStyles()((theme) => ({
  styles: {
    overflow: 'hidden',
  },
  leftSide: {
    display: 'flex',
    margin: theme.spacing(3),
    border: '1px solid rgba(224, 224, 224, 1)',
    marginBottom: theme.spacing(5),
    overflow: 'hidden',
  },
  types: {
    flexGrow: 1,
  },
  rightSide: {
    flexGrow: 4,
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(3),
  },
  values: {
    margin: `${theme.spacing(3)}px 0`,
  },
  name: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(2),
  },
  header: {
    display: 'flex',
  },
}))

export default ProductAttributes
