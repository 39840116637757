import { toTitleCase } from '@utils/string-utils'

import React, { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Breadcrumbs as MaterialBreadcrumbs } from '@mui/material'

const Breadcrumbs = (): ReactElement | null => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)

  if (pathnames.length === 0) {
    return null
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <MaterialBreadcrumbs aria-label='breadcrumb'>
        {pathnames.map((value, index) => {
          const isLast = index === pathnames.length - 1
          const route = `/${pathnames.slice(0, index + 1).join('/')}`

          return (
            <Link
              color={isLast ? 'textPrimary' : 'inherit'}
              key={route}
              style={{ textDecoration: 'none' }}
              to={route}
            >
              {toTitleCase(value.replace(/-/g, ' '))}
            </Link>
          )
        })}
      </MaterialBreadcrumbs>
    </div>
  )
}

export default Breadcrumbs
