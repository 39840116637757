import DndTable from '@components/shared/dnd-table'
import Loader from '@shared/loader'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Add, Delete } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { selectVerticalCards } from '@store/selectors'
import { useAllActions, useSelector } from '@hooks'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  createVerticalCardField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))

function VerticalCards() {
  const { classes } = useStyles()
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [createDialogLoading, setCreateDialogLoading] = useState(false)
  const [newVerticalCardTitle, setNewVerticalCardTitle] = useState('')
  const {
    createVerticalCard,
    getVerticalCards,
    saveVerticalCard,
    setVerticalCards,
  } = useAllActions()
  const navigate = useNavigate()

  const cards = useSelector(selectVerticalCards)

  const columns = React.useMemo(() => {
    return [
      { Header: 'Title', accessor: 'title' },
      {
        Header: 'Created At',
        accessor: (row) => moment(row.created_at).format('MMMM Do YYYY'),
      },
      {
        Header: 'Updated At',
        accessor: (row) => moment(row.updated_at).format('MMMM Do YYYY'),
      },
    ]
  }, [])

  const archiveCard = (_e, cardData) => {
    saveVerticalCard({ ...cardData, archived_at: true })
  }

  const archiveCardById = (card) => {
    const cardId = card.id
    archiveCard(null, cards[cardId])
  }

  const newVerticalCard = async (title) => {
    await createVerticalCard({ title })

    setCreateDialogLoading(false)
    setCreateDialogOpen(false)
    setNewVerticalCardTitle('')
  }

  useEffect(() => {
    getVerticalCards()
  }, [])

  const reSort = (data) => {
    const newData = data.map((card, index) => {
      return { ...card, sort_order: index }
    })
    setVerticalCards(newData)
  }

  if (!Object.keys(cards).length) return <Loader />

  return (
    <div>
      <Dialog
        aria-labelledby='dialog-title'
        onClose={() => setCreateDialogOpen(false)}
        open={createDialogOpen}
      >
        <Loader visible={createDialogLoading} />
        <DialogTitle id='dialog-title'>Create Vertical Scroll Card</DialogTitle>
        <TextField
          className={classes.createVerticalCardField}
          label='Title'
          onChange={(e) => setNewVerticalCardTitle(e.target.value)}
          value={newVerticalCardTitle}
        />
        <DialogActions>
          <Button onClick={() => setCreateDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setCreateDialogLoading(true)
              newVerticalCard(newVerticalCardTitle)
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <DndTable
        actions={[
          {
            title: 'Create Vertical Card',
            icon: <Add />,
            toolbarAction: true,
            onClick: () => setCreateDialogOpen(true),
          },
          {
            title: 'Delete',
            icon: <Delete />,
            toolbarAction: false,
            onClick: archiveCardById,
          },
        ]}
        afterDrop={reSort}
        columns={columns}
        data={Object.values(cards)}
        rowClick={(cardId) => navigate(`/vertical-cards/${cardId}`)}
        sortColumn={['sort_order']}
        sortDir={['asc']}
        title='Vertical Scroll Cards'
      />
    </div>
  )
}

export default VerticalCards
