import DeleteSurchargeDialog from './delete-surcharge-dialog'
import ErrorBoundary from '@components/shared/error-boundary'
import MaterialTable from '@material-table/core'
import NewSurchargeDialog from './new-surcharge-dialog'
import React, { ReactElement, useEffect, useState } from 'react'
import RemoveFlatRateDialog from './remove-flat-rate-dialog'
import TaxRates from '../store/tax-rates'
import orderBy from 'lodash.orderby'
import useSelector from '@hooks/use-selector'
import { AppDispatch, SurchargeType } from '@types'
import { allStoresSelector, fetchStores } from '../../../services/store-service'
import { getSurcharges, updateSurcharge } from '@store/actions/tax-rates'
import { hasPerms } from '@utils/has-permission'
import { makeStyles } from 'tss-react/mui'
import { setFlashMessage } from '@store/reducers/flash-message/actions'
import { surchargesSelector } from '@store/selectors/tax-rates'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles()(() => ({
  widgetRow: {
    marginTop: 25,
  },
}))

type StoreType = {
  id: number
  name: string
  state: string
  city: string
}

const Surcharges = (): ReactElement => {
  const { classes } = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const surcharges = useSelector(surchargesSelector)
  const stores: Record<string, StoreType> = useSelector(allStoresSelector)
  const editable = hasPerms('tax-rates:update')
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [currentDeleteSurcharge, setCurrentDeleteSurcharge] = useState({
    id: 0,
    name: '',
  })
  const [removeFlatRateDialogOpen, setRemoveFlatRateDialogOpen] =
    useState(false)
  const [currentUpdateSurcharge, setCurrentUpdateSurcharge] =
    useState<SurchargeType | null>(null)

  useEffect(() => {
    dispatch(getSurcharges())
    fetchStores(dispatch)()
  }, [])

  const handleRowUpdate = async (rowData: SurchargeType) => {
    return dispatch(updateSurcharge(rowData.id, rowData)).then(() =>
      setFlashMessage('Vendor updated.', 'success'),
    )
  }

  return (
    <>
      <ErrorBoundary>
        <NewSurchargeDialog
          createDialogOpen={createDialogOpen}
          setCreateDialogOpen={setCreateDialogOpen}
        />
        <DeleteSurchargeDialog
          currentDeleteSurcharge={currentDeleteSurcharge}
          deleteDialogOpen={deleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
        />
        <RemoveFlatRateDialog
          onCancel={() => setRemoveFlatRateDialogOpen(false)}
          onConfirm={async (surcharge: SurchargeType) => {
            setRemoveFlatRateDialogOpen(false)
            await handleRowUpdate(surcharge)
            // For an extreme edge case reload should be fine
            return setTimeout(async () => window.location.reload(), 1000)
          }}
          removeFlatRateDialogOpen={removeFlatRateDialogOpen}
          updateSurcharge={currentUpdateSurcharge}
        />
        <p>
          Adding or editing surcharges will not automatically apply any changes
          to stores. You can edit the charges and rates at each store on this
          page or on each stores own detail page.
        </p>

        {/* eslint-disable-next-line */}
        {/* @ts-ignore: No overload matches this call. */}
        <MaterialTable
          actions={[
            {
              title: 'Create Surcharge',
              icon: 'add',
              isFreeAction: true,
              onClick: () => setCreateDialogOpen(true),
              disabled: !editable,
            },
            {
              icon: 'delete',
              onClick: (_, data: SurchargeType) => {
                setCurrentDeleteSurcharge({ id: data.id, name: data.name })
                setDeleteDialogOpen(true)
              },
              disabled: !editable,
            },
          ]}
          /* tslint:enable */
          columns={[
            { title: 'id', field: 'id', defaultSort: 'asc', editable: 'never' },
            { title: 'Name', field: 'name' },
            { title: 'Description', field: 'description' },
            {
              title: 'Default Rate (rate * price-per-item = charge)',
              field: 'default_rate',
              render: function renderDefaultRate({ flat_rate, default_rate }) {
                if (flat_rate) return 'Flat Rate Charge'
                return `${(default_rate * 100).toFixed(2)}%`
              },
            },
            {
              title: 'Flat Rate (optional - cents to charge per item)',
              field: 'flat_rate',
              render: function renderFlatRate({ flat_rate }) {
                if (!flat_rate) return 'N/A'
                return `${flat_rate}¢`
              },
            },
          ]}
          data={surcharges.map((s) => ({ ...s }))}
          editable={{
            isEditable: () => editable,
            onRowUpdate: editable
              ? async (rowData) => {
                  const before = surcharges.find((s) => s.id == rowData.id)
                  if (
                    rowData.default_rate &&
                    !rowData.flat_rate &&
                    before?.flat_rate
                  ) {
                    // we need to caution the user about switching from flat rate
                    setCurrentUpdateSurcharge(rowData)
                    setRemoveFlatRateDialogOpen(true)
                  } else {
                    return handleRowUpdate(rowData)
                  }
                }
              : null,
          }}
          options={{
            actionsColumnIndex: 5,
            paging: false,
          }}
          title='Surcharges'
        />
      </ErrorBoundary>
      <h2>All Stores</h2>
      {orderBy(Object.values(stores), ['state', 'city', 'name']).map(
        (store) => (
          <div className={classes.widgetRow} key={store.id}>
            <ErrorBoundary>
              <TaxRates
                className={null}
                editable={editable}
                storeId={store.id}
                title={`${store.name} - ${store.state} - ${store.city}`}
              />
            </ErrorBoundary>
          </div>
        ),
      )}
    </>
  )
}

export default Surcharges
