import React, { ReactElement, useState } from 'react'
import { AppDispatch, PriceType } from '@types'
import { Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { closeDialog } from '@store/reducers/dialog/actions'
import { getAllPluForProductAtStore } from '@queries/prices'
import { getProductList } from '@queries/products'
import { getStoreList } from '@queries/stores'
import { makeStyles } from 'tss-react/mui'
import { updateStoreProductPrices } from '@store/actions/prices'
import { useDispatch } from 'react-redux'
import { useQuery, useQueryClient } from '@tanstack/react-query'

interface Props {
  productId: number
  storeId: number
}

const useStyles = makeStyles()((theme) => ({
  header: {
    paddingBottom: theme.spacing(1),
  },
  content: {
    minWidth: '500px',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
  buttonsWrapper: {
    display: 'flex',
  },
  closeButton: {
    flex: 1,
    minHeight: '50px',
  },
  confirmButton: {
    flex: 1,
    minHeight: '50px',
    color: theme.palette.success.main,
  },
}))

const PriceLookup = ({ productId, storeId }: Props): ReactElement => {
  const { classes } = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const [updatedPrices, setUpdatedPrices] = useState<Record<string, PriceType>>(
    {},
  )
  const queryClient = useQueryClient()
  const priceQueryKey = `plu-${productId}-${storeId}`
  const { data: priceData } = useQuery({
    queryKey: [priceQueryKey],
    queryFn: async () => getAllPluForProductAtStore(productId, storeId),
    staleTime: Infinity,
  })

  const { data: stores } = useQuery({
    queryKey: ['storeList'],
    queryFn: getStoreList,
    staleTime: Infinity,
  })

  const { data: products } = useQuery({
    queryKey: ['productList'],
    queryFn: getProductList,
    staleTime: Infinity,
  })

  const storeName = stores?.find((s) => s.id == storeId)?.name
  const productName = products?.find((p) => p.id == productId)?.name

  const atrributeValueFormatter = (value: number[]) => {
    if (!value.length) return 'Base'
    return value
      .map((id) => priceData?.attributes.find((a) => a.id == id)?.name)
      .join(', ')
  }

  const handleClose = () => {
    dispatch(closeDialog())
  }

  const handleConfirm = async () => {
    dispatch(updateStoreProductPrices(Object.values(updatedPrices)))

    queryClient.removeQueries({ queryKey: [priceQueryKey] })
  }

  const onGridRowUpdated = (updatedPrice: PriceType) => {
    const priceKey = updatedPrice.attribute_values.join('-')
    setUpdatedPrices({
      ...updatedPrices,
      [priceKey]: updatedPrice,
    })
    return { ...updatedPrice }
  }

  return (
    <>
      <div className={classes.content}>
        <div className={classes.header}>
          <div>Product: {productName}</div>
          <div>Store: {storeName}</div>
        </div>

        <DataGrid
          columns={[
            {
              field: 'attribute_values',
              editable: false,
              valueFormatter: atrributeValueFormatter,
              headerName: 'Variation',
              flex: 1,
            },
            { field: 'price', editable: true, headerName: 'Price', flex: 1 },
          ]}
          density='compact'
          getRowId={({ attribute_values }) => attribute_values.join(',')}
          hideFooter
          hideFooterPagination
          hideFooterSelectedRowCount
          processRowUpdate={onGridRowUpdated}
          rows={priceData?.prices || []}
          showCellVerticalBorder
          showColumnVerticalBorder
        />
      </div>
      <div className={classes.buttonsWrapper}>
        <Button className={classes.closeButton} onClick={handleClose}>
          Cancel
        </Button>
        <Button className={classes.confirmButton} onClick={handleConfirm}>
          Save Prices
        </Button>
      </div>
    </>
  )
}

export default PriceLookup
