import createCachedSelector from '@utils/cached-selector'
import { createSelector } from 'reselect'

export const selectEmployeeById = createCachedSelector(
  (s, id) => s.Employees[id],
  (employee) => employee,
)((_s, id) => id)

export const selectEmployees = createSelector(
  (s) => s.Employees,
  (employees) => employees,
)
