import * as api from '@utils/api'
import { ProductList } from '@types'

export const getProductList = async (): Promise<ProductList> => {
  const response = await api.GET('/p4/products')

  return response.data.products
}

export const getArchivedProductList = async (): Promise<ProductList> => {
  const response = await api.GET('/p4/products?archived=true')

  return response.data.products
}
