import Categories from './categories'
import CustomDragLayer from './custom-drag-layer'
import React, { ReactElement, useEffect, useState } from 'react'
import { AppBar, Tab, Tabs } from '@mui/material'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { getMenus } from '@queries/menus'
import { makeStyles } from 'tss-react/mui'
import { useQuery } from '@tanstack/react-query'

const useStyles = makeStyles()(() => ({
  menusTabBar: {
    borderBottom: 'solid 5px white',
  },
}))

const Menus = (): ReactElement => {
  const { classes } = useStyles()

  const [selected, setSelected] = useState('')
  const { data: menus } = useQuery({
    queryKey: ['menus'],
    queryFn: getMenus,
    staleTime: Infinity,
  })

  useEffect(() => {
    // begin on the first menu, maybe add deep linking later
    if (menus?.length) {
      setSelected(menus[0].slug)
    }
  }, [menus])

  return (
    <>
      <AppBar position='static'>
        <Tabs
          className={classes.menusTabBar}
          onChange={(_, value) => setSelected(value)}
          value={selected}
        >
          {menus?.map((menu) => (
            <Tab key={menu.slug} label={menu.title} value={menu.slug} />
          ))}
        </Tabs>
      </AppBar>
      <DndProvider backend={HTML5Backend}>
        <Categories menuSlug={selected} />
        <CustomDragLayer />
      </DndProvider>
    </>
  )
}

export default Menus
