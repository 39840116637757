import Loader from '@components/shared/loader'
import React, { ReactElement, useState } from 'react'
import { AppDispatch } from '@types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from '@mui/material'
import { createSurcharge } from '@store/actions/tax-rates'
import { makeStyles } from 'tss-react/mui'
import { setFlashMessage } from '@store/reducers/flash-message/actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles()((theme) => ({
  createSurchargeField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))

type Props = {
  createDialogOpen: boolean
  // eslint-disable-next-line
  setCreateDialogOpen: Function
}

const filterRate = (newValue) => {
  const value = parseFloat(newValue)
  if (isNaN(value) || value > 0.9 || value < 0) return 0
  return value
}

const filterFlatRate = (newValue) => {
  const value = parseInt(newValue, 10)
  if (isNaN(value) || value < 1) return null
  return value
}

const handleTextChange = (setter) => (e) => setter(e.target.value)

const NewSurchargeDialog = ({
  createDialogOpen,
  setCreateDialogOpen,
}: Props): ReactElement => {
  const { classes } = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const [createDialogLoading, setCreateDialogLoading] = useState(false)
  const [newSurchargeName, setNewSurchargeName] = useState('')
  const [newSurchargeDescription, setNewSurchargeDescription] = useState('')
  const [newSurchargeDefaultRate, setNewSurchargeDefaultRate] = useState('0.0')
  const [newSurchargeFlatRate, setNewSurchargeFlatRate] = useState('')

  const handleSurchargeNameChange = handleTextChange(setNewSurchargeName)
  const handleSurchargeDescriptionChange = handleTextChange(
    setNewSurchargeDescription,
  )
  const handleSurchargeDefaultRateChange = handleTextChange(
    setNewSurchargeDefaultRate,
  )

  const handleSurchargeFlatRateChange = handleTextChange(
    setNewSurchargeFlatRate,
  )

  const handleClose = () => setCreateDialogOpen(false)
  const handleConfirm = () => {
    setCreateDialogLoading(true)
    dispatch(
      createSurcharge({
        name: newSurchargeName,
        description: newSurchargeDescription,
        default_rate: filterRate(newSurchargeDefaultRate),
        flat_rate: filterFlatRate(newSurchargeFlatRate),
      }),
    )
      .then(() => {
        setCreateDialogLoading(false)
        setCreateDialogOpen(false)
        setNewSurchargeName('')
        setNewSurchargeDescription('')
        setNewSurchargeDefaultRate('0.0')
        setNewSurchargeFlatRate('')
        setFlashMessage('Surcharge created.', 'success')
      })
      .catch((err) => {
        setFlashMessage(err.message, 'error')
        setCreateDialogLoading(false)
      })
  }

  return (
    <Dialog
      aria-labelledby='dialog-title'
      onClose={handleClose}
      open={createDialogOpen}
    >
      <Loader visible={createDialogLoading} />
      <DialogTitle id='dialog-title'>Create Surcharge</DialogTitle>
      <TextField
        className={classes.createSurchargeField}
        label='Name'
        onChange={handleSurchargeNameChange}
        value={newSurchargeName}
      />
      <TextField
        className={classes.createSurchargeField}
        label='Description'
        onChange={handleSurchargeDescriptionChange}
        value={newSurchargeDescription}
      />
      <TextField
        className={classes.createSurchargeField}
        label='Default Rate (mutliplier to charge on items)'
        onChange={handleSurchargeDefaultRateChange}
        value={newSurchargeDefaultRate}
      />
      <TextField
        className={classes.createSurchargeField}
        label='Flat Rate (overrides the rate and charges this flat rate in cents)'
        onChange={handleSurchargeFlatRateChange}
        value={newSurchargeFlatRate}
      />
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewSurchargeDialog
