// TODO: convert to typescript
import * as api from '@utils/api'
import createAction from '@store/actions/create-action'
import { apiAction } from '@store/actions/api'

export const CLONE_ATTRS = 'CLONE_ATTRS'
export const SET_PRODUCT = 'SET_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const CLONE_PRODUCT_SUCCESS = 'CLONE_PRODUCT_SUCCESS'
export const CLONE_PRODUCT_FAILURE = 'CLONE_PRODUCT_FAILURE'

export const getProducts = () => async (dispatch) => {
  const { data: products } = await api.GET('/p4/products')

  return dispatch({
    type: SET_PRODUCTS,
    products,
  })
}

export const createProduct = (product) => async (dispatch) => {
  const { data } = await api.POST('/p4/products', product)

  return dispatch({
    type: SET_PRODUCT,
    product: data,
  })
}

export const getProduct = (id) => async (dispatch) => {
  const { data: product } = await api.GET(`/p4/products/${id}`)

  return dispatch({
    type: SET_PRODUCT,
    product,
  })
}

export const saveProduct = (product) => async (dispatch) => {
  let type = SET_PRODUCT
  const { data } = await api.PATCH(`/p4/products/${product.id}`, product)

  if (product.archived_at !== null) {
    type = DELETE_PRODUCT
  }

  return dispatch({
    type,
    product: data,
  })
}

export const setProducts = (updatedProducts) => async (dispatch) => {
  const products = Object.values(updatedProducts)

  return dispatch({
    type: SET_PRODUCTS,
    products,
  })
}

const cloneSuccessAction = (product, response) => (dispatch) => {
  dispatch({
    type: SET_PRODUCT,
    product,
  })

  dispatch({
    type: CLONE_PRODUCT_SUCCESS,
    response,
  })
}

export const cloneAttributes = (productId, cloneId) => {
  return apiAction({
    url: `/p4/products/${productId}/cloneAttributeValues`,
    data: {
      source_product_id: cloneId,
    },
    method: 'POST',
    actionCallbacks: {
      success: cloneSuccessAction,
      failure: createAction(CLONE_PRODUCT_FAILURE),
    },
  })
}

export const addToAllStores = (productId) => async () => {
  const {
    data: { errors },
  } = await api.POST(`/p4/products/${productId}/addProductToAllStores`)

  return errors
}
