import React, { ReactElement, useEffect, useState } from 'react'
import useSelector from '@hooks/use-selector'

import { AppDispatch, VendorRegionType } from '@types'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { getVendorRegions } from '@store/actions/vendor-regions'
import { selectVendorRegionsList } from '@store/selectors/vendor-regions'
import { useDispatch } from 'react-redux'

type Props = {
  onChange: (vendorId: number) => void
  value: number
  label?: string
  className?: string
}

function VendorRegionSelect({
  className,
  onChange,
  value,
  label,
}: Props): ReactElement {
  const dispatch = useDispatch<AppDispatch>()

  const vendorRegionsList = useSelector(selectVendorRegionsList) as VendorRegionType[]
  const [loaded, setLoaded] = useState(vendorRegionsList.length > 0)

  useEffect(() => {
    const loadData = async () => {
      setLoaded(false)
      await dispatch(getVendorRegions())
      setLoaded(true)
    }

    if (!loaded) loadData()
  }, [])

  const handleChange = (
    event: SelectChangeEvent<number>,
  ) => {
    const newValue = event.target.value === -1 ? null : event.target.value
    onChange(newValue as number)
  }

  return (
    <FormControl className={className}>
      <InputLabel id='vendors-select-label'>
        {label || 'Vendor Region'}
      </InputLabel>
      <Select
        labelId='vendors-select-label'
        onChange={handleChange}
        style={{ minWidth: 200 }}
        value={value}
      >
        <MenuItem value={-1}>
          <em>None</em>
        </MenuItem>
        {vendorRegionsList.map((vendorRegion: VendorRegionType) => (
          <MenuItem key={vendorRegion.id} value={vendorRegion.id}>
            {vendorRegion.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default VendorRegionSelect
