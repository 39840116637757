import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Form from '../../shared/form'
import FormInput from '../../shared/form-input'
import FormSwitch from '../../shared/form-switch'
import ResponsiveDialog from '../../shared/responsive-dialog'
import { Button, DialogActions } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useAllActions } from '@hooks'

const useStyles = makeStyles()(() => ({
  form: {
    minWidth: 600,
  },
}))

function CreateHolidayDialog(props) {
  const { classes } = useStyles()
  const [date, setDate] = useState('')
  const [closed, setClosed] = useState(false)
  const [holiday, setHoliday] = useState(false)
  const [opens, setOpens] = useState('')
  const [closes, setCloses] = useState('')
  const { setFlashMessage } = useAllActions()

  const getData = () => {
    if (!date) throw new Error('Please enter a date.')

    if (!closed && !opens)
      throw new Error('Please enter an opening time (unless store closed)')

    if (!closed && !closes)
      throw new Error('Please enter a closing time (unless store closed)')

    const data = {
      store_id: props.storeId,
      override_date: date,
      is_closed: closed,
      is_holiday: holiday,
    }

    if (opens) data.opening_time = opens
    if (closes) data.closing_time = closes
    return data
  }

  return (
    <ResponsiveDialog
      loading={props.loading}
      onClose={props.onClose}
      open={props.open}
    >
      <Form className={classes.form} paper={false} title='Create Holiday'>
        <FormInput
          onChangeText={setDate}
          placeholder='e.g. 2020-01-04'
          title='Date (yyyy-MM-dd)'
          value={date}
        />
        {!closed && (
          <div>
            <FormInput
              onChangeText={setOpens}
              placeholder='e.g. 06:30:00'
              title='Opening Time (HH:mm:dd)'
              value={opens}
            />
            <FormInput
              onChangeText={setCloses}
              placeholder='e.g. 18:30:00'
              title='Closing Time (HH:mm:dd)'
              value={closes}
            />
          </div>
        )}
        <FormSwitch
          checked={closed}
          onChange={() => setClosed(!closed)}
          title='Is Closed'
        />
        <FormSwitch
          checked={holiday}
          onChange={() => setHoliday(!holiday)}
          title='Is Holiday'
        />
      </Form>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={() => {
            try {
              const data = getData()
              props.onSubmit(data)
            } catch (error) {
              setFlashMessage(error.message, 'error')
            }
          }}
        >
          Create
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

CreateHolidayDialog.propTypes = {
  storeId: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,

  /**
   * Method called when the form is submitted (with valid data). It will
   * send (full) data for the holiday.
   */
  onCreate: PropTypes.func,
}

export default CreateHolidayDialog
