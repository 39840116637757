import {
  CLONE_ATTRS,
  DELETE_PRODUCT,
  SET_PRODUCT,
  SET_PRODUCTS,
} from '@store/reducers/products/actions'

import { ADD_PRODUCTS } from '@store/action-list'

const initialState = {}

export default (state = initialState, action: { [key: string]: any }): any => {
  const { type } = action

  switch (type) {
    case SET_PRODUCT: {
      const { product } = action
      return {
        ...state,
        [product.id]: product,
      }
    }
    case ADD_PRODUCTS:
    case SET_PRODUCTS: {
      const { products } = action
      // return { ...state, products: { ...state.products, ...products.toObject('id') } }
      return products.reduce((acc, product) => {
        return { ...acc, [product.id]: product }
      }, state)
    }
    case DELETE_PRODUCT: {
      const { product } = action
      const { [product.id]: _deleted, ...newState }: any = state // eslint-disable-line @typescript-eslint/no-unused-vars

      return newState
    }
    case CLONE_ATTRS: {
      const { role } = action
      return {
        ...state,
        [role.id]: role,
      }
    }
    default:
      return state
  }
}
