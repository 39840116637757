import { createSelector } from 'reselect'

import * as fromLoginStatusReducer from '../reducers/login-status'

export * from '@store/reducers/vertical-cards/selectors'
export * from '@store/reducers/custom-contents/selectors'
export * from '@store/reducers/current-user/selectors'
export * from '@store/reducers/employees/selectors'
export * from '@store/reducers/roles/selectors'
export * from '@store/reducers/permissions/selectors'
export * from '@store/reducers/stores-new/selectors'
export * from '@store/reducers/recommendations/selectors'

export const getUserIsLoggedIn = createSelector(
  (s) => fromLoginStatusReducer.getIsLoggedIn(s.LoginStatus),
  (isLoggedIn) => isLoggedIn,
)
