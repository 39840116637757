import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, Paper, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
  },
  content: {
    marginTop: 8,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  link: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  avatar: {
    marginRight: 12,
    height: 48,
    width: 48,
  },
}))

function AvatarInfo(props) {
  const { classes, cx } = useStyles()

  return (
    <Paper className={cx(props.className, classes.container)}>
      <Link
        className={classes.link}
        style={{ textDecoration: 'none' }}
        to={props.route}
      >
        <Typography variant='button'>{props.title}</Typography>
        <div className={classes.content}>
          <Avatar
            alt={props.name}
            className={classes.avatar}
            src={props.avatarSrc}
          >
            {props.icon ? props.icon : props.name.match(/\b(\w)/g).join('')}
          </Avatar>
          <Typography variant='subtitle1'>{props.name}</Typography>
        </div>
      </Link>
    </Paper>
  )
}

AvatarInfo.propTypes = {
  route: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  avatarSrc: PropTypes.string,
  icon: PropTypes.element,
}

export default AvatarInfo
