import createCachedSelector from '@utils/cached-selector'
import { createSelector } from 'reselect'

export const selectStoreById = createCachedSelector(
  (s, id) => s.StoresNew[id],
  (store) => store,
)((_s, id) => id)

export const selectStores = createSelector(
  (s) => s.StoresNew,
  (stores) => stores,
)

export const storesListAlpha = createSelector(
  (s) => s.StoresNew,
  (stores) => {
    return Object.values(stores).sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
  },
)
