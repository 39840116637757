import React, { ReactElement, ReactNode, useEffect, useRef } from 'react'
import { DragSourceMonitor, XYCoord, useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

export const ItemTypes = {
  CATEGORY: 'category',
  PRODUCT: 'product',
} as const

type Keys = keyof typeof ItemTypes
type DragItem = {
  index: number
  id: number
  type: (typeof ItemTypes)[Keys]
  parentIndex: number
}

type Props = {
  className?: string
  id: number
  index: number
  type: string
  parentIndex: number
  canDrag?: boolean
  handleMove: (
    dragIndex: number,
    hoverIndex: number,
    parentIndex: number,
    dragParentIndex: number,
  ) => void
  children: ReactNode
  handleDrop: () => void
  dragPreview?: () => ReactElement
}

const DndItem = ({
  id,
  index,
  parentIndex,
  handleMove,
  handleDrop,
  children,
  type,
  className,
  dragPreview,
  canDrag = true,
}: Props): ReactElement => {
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: string | symbol | null }
  >({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    drop() {
      handleDrop()
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const dragParentIndex = item.parentIndex
      const hoverParentIndex = parentIndex
      const hoverIndex = index

      const isSameParent = dragParentIndex === hoverParentIndex

      // Don't replace items with themselves
      if (dragIndex === hoverIndex && isSameParent) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        isSameParent
      ) {
        return
      }

      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        isSameParent
      ) {
        return
      }

      // Time to actually perform the action
      handleMove(dragIndex, hoverIndex, hoverParentIndex, dragParentIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      if (!isSameParent && hoverIndex !== 0) {
        item.index = hoverIndex + 1
      } else {
        item.index = hoverIndex
      }
      item.parentIndex = hoverParentIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: () => {
      return { id, index, parentIndex, dragPreview }
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging(monitor) {
      return id === monitor.getItem().id
    },
    canDrag() {
      return canDrag
    },
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  useEffect(() => {
    if (dragPreview) {
      preview(getEmptyImage(), { captureDraggingState: true })
    } else {
      preview(null)
    }
  }, [dragPreview])

  return (
    <div
      className={className}
      data-handler-id={handlerId}
      ref={ref}
      style={{ opacity, cursor: canDrag ? 'move' : 'not-allowed' }}
    >
      {children}
    </div>
  )
}

export default DndItem
