import React, { ReactElement } from 'react'
import useSelector from '@hooks/use-selector'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { selectVendorProductsByProduct } from '@store/selectors/vendor-products'

type Props = {
  onChange: (value: number) => void
  value: number
  label?: string
  className?: string
  id: number
}

function VendorProductsSelect({
  className,
  onChange,
  value,
  label,
  id,
}: Props): ReactElement {
  const vendorProducts = useSelector(selectVendorProductsByProduct, id)

  return (
    <FormControl className={className}>
      <InputLabel id='vendors-select-label'>{label || 'Vendor'}</InputLabel>
      <Select
        labelId='vendors-select-label'
        onChange={(event) => {
          onChange(event.target.value as number)
        }}
        style={{ minWidth: 200 }}
        value={value}
      >
        <MenuItem value={-1}>
          <em>None</em>
        </MenuItem>
        {vendorProducts.map((vendorProduct) => (
          <MenuItem key={vendorProduct.id} value={vendorProduct.id}>
            {`${vendorProduct.vendor?.name} / ${vendorProduct.vendor_region?.name}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default VendorProductsSelect
