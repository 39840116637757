import React from 'react'
import { connect } from 'react-redux'

import { Button, Paper, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { logout } from '../../services/auth-service'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  line: {
    marginBottom: theme.spacing(2),
  },
  button: {
    color: 'white',
  },
}))

const mapStateToProps = (state) => ({
  user: state.CurrentUser.user || {},
})

const mapDispatchToProps = (dispatch) => ({
  logout: logout(dispatch),
})

function CurrentUser(props) {
  const { classes } = useStyles()
  const { user } = props
  const navigate = useNavigate()

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.line}>{user.name}</Typography>
      <Typography className={classes.line}>{user.email}</Typography>
      <Button
        className={classes.button}
        color='primary'
        onClick={() => props.logout().then(() => navigate('/login'))}
        variant='contained'
      >
        Log Out
      </Button>
    </Paper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentUser)
