import '../../utils/array-utils'
import { ADD_VENDORS, SET_VENDORS } from '../action-list'

/** Mapping of ID => vendor */
const initialState = {}

export default (state = initialState, action) => {
  const { vendors } = action

  switch (action.type) {
    case ADD_VENDORS:
      console.log(`Redux: Adding ${vendors.length} vendors.`)
      return { ...state, ...vendors.toObject('id') }
    case SET_VENDORS:
      console.log(`Redux: Setting ${vendors.length} vendors.`)
      return vendors.toObject('id')
    default:
      return state
  }
}
