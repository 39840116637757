import ErrorBoundary from './error-boundary'
import React, { ReactElement, useCallback, useState } from 'react'
import debounce from 'lodash.debounce'
import { FormControl, TextField } from '@mui/material'
import { UserSearchResult } from '@types'
import { makeStyles } from 'tss-react/mui'
import { searchUsers } from '@queries/users'

type Props = {
  className?: string
  containerProps?: Record<any, any>
  onSelectUser: (user: UserSearchResult) => void
}

const useStyles = makeStyles()((theme) => ({
  resultList: {
    padding: theme.spacing(2),
  },
  result: {
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F8F8F8',
    },
    '&:active': {
      backgroundColor: '#999999',
    },
  },
  resultName: {
    fontWeight: 'bold',
  },
}))

const UserSelector = ({
  className,
  containerProps,
  onSelectUser,
}: Props): ReactElement => {
  const { classes } = useStyles()

  const [searchText, setSearchText] = useState('')
  const [userResults, setUserResults] = useState<UserSearchResult[]>([])

  const handleSearchChange = (e: any) => {
    const input = e.target.value as string
    setSearchText(input)
    handleSearch(input)
  }

  const performSearch = (query) => {
    searchUsers(query).then((result) => setUserResults(Object.values(result)))
  }

  const handleSearch = useCallback(debounce(performSearch, 600), [])

  const renderUserResult = useCallback(
    (user: UserSearchResult) => (
      <UserResult handleSelectUser={onSelectUser} user={user} />
    ),
    [onSelectUser],
  )

  return (
    <ErrorBoundary>
      <FormControl className={className} {...containerProps}>
        <TextField
          onChange={handleSearchChange}
          placeholder='user@philzcoffee.com'
          value={searchText}
        />
        <ul className={classes.resultList}>
          {userResults.map(renderUserResult)}
        </ul>
      </FormControl>
    </ErrorBoundary>
  )
}

type ResultProps = {
  user: UserSearchResult
  handleSelectUser: (user: UserSearchResult) => void
}
const UserResult = ({ user, handleSelectUser }: ResultProps): ReactElement => {
  const { classes } = useStyles()

  const handleOnClick = () => {
    handleSelectUser(user)
  }

  return (
    <li className={classes.result} key={user.id} onClick={handleOnClick}>
      <span className={classes.resultName}>{user.name}</span>
      <br />
      {user.email}
    </li>
  )
}

export default UserSelector
