import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'

type Props = {
  dialogOpen: boolean
  changeDialogOpen: (open: boolean) => void
  onSave: (categoryName: string) => void
  defaultValue: string | null
}

const CategoryNameForm = ({
  dialogOpen,
  changeDialogOpen,
  onSave,
  defaultValue,
}: Props): ReactElement => {
  const [categoryName, setCategoryName] = useState('')

  useEffect(() => {
    setCategoryName(defaultValue ?? '')
  }, [dialogOpen])

  const handleSave = () => {
    onSave(categoryName)
    changeDialogOpen(false)
  }

  const onCancel = () => {
    changeDialogOpen(false)
  }

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryName(e.target.value)
  }

  return (
    <>
      <Dialog open={dialogOpen}>
        <DialogTitle id='alert-dialog-title'>Set Category Name</DialogTitle>
        <DialogContent>
          <div>
            <TextField onChange={handleChangeName} value={categoryName} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={handleSave}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CategoryNameForm
