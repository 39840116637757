const color = {
  primary: '#40bcde',
  accent: '#ff8f00',
  onlineGreen: '#93D047',
  offlineRed: '#C9291D',
  secondaryGray: '#EFEFEF',
  lightText: '#D3D3D3',
}

export default color
