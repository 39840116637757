import ErrorBoundary from '@components/shared/error-boundary'
import React, { ReactElement, useState } from 'react'
import Table from '@components/shared/table'
import UserSelector from '@components/shared/user-selector'
import { Add, DeleteSweep } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from '@mui/material';

import { PromotionUserType, UserSearchResult } from '@types'

type Props = {
  users: PromotionUserType[]
  editing: boolean
  onAddUser: (user: PromotionUserType) => void
  onRemoveUser: (user: PromotionUserType) => void
  onClearUsers: () => void
}

const useStyles = makeStyles()((theme) => ({
  dialog: {
    padding: theme.spacing(10),
  },
}));

const Users = ({
  users,
  editing,
  onAddUser,
  onRemoveUser,
  onClearUsers,
}: Props): ReactElement => {
  const { classes } = useStyles()

  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false)
  const [clearConfirmDialogOpen, setClearConfirmDialogOpen] = useState(false)

  const handleAddUserClose = () => {
    setAddUserDialogOpen(false)
  }

  const handleAddUserOpen = () => {
    setAddUserDialogOpen(true)
  }

  const handleAddUser = (user: PromotionUserType) => {
    setAddUserDialogOpen(false)
    onAddUser(user)
  }

  const handleClearUsers = () => {
    setClearConfirmDialogOpen(true)
  }

  const handleClearConfirmDialogClose = () => {
    setClearConfirmDialogOpen(false)
  }

  const handleClearConfirm = () => {
    setClearConfirmDialogOpen(false)
    onClearUsers()
  }

  const handleRemoveUser = (user: UserSearchResult) => {
    if (users.length === 1) {
      handleClearUsers()
      return
    }
    onRemoveUser(user)
  }

  return (
    <>
      <Dialog className={classes.dialog} open={addUserDialogOpen}>
        <DialogTitle id='alert-dialog-title'>
          Apply Promotion To Specific User
        </DialogTitle>
        <DialogContent>
          <UserSelector
            containerProps={{ fullWidth: true }}
            onSelectUser={handleAddUser}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddUserClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        open={clearConfirmDialogOpen}
      >
        <DialogTitle id='alert-dialog-title'>
          Enable Promotion For All Users
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you would like to immediately make this promotion
            available to all users. This will clear the list of users the
            promotion is limited to and anyone who qualifies will receive the
            promotion.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color='primary'
            onClick={handleClearConfirmDialogClose}
          >
            Nevermind
          </Button>
          <Button color='primary' onClick={handleClearConfirm}>
            Open For Everyone
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorBoundary>
        <Paper>
          <Table
            columns={[
              {
                title: 'ID',
                field: 'id',
              },
              {
                title: 'Name',
                field: 'name',
              },
              {
                title: 'Email',
                field: 'email',
              },
            ]}
            count={users.length}
            data={users}
            description={
              users.length
                ? 'This promotion will strictly only apply to customers in this list.'
                : 'This promotion is currently available to anyone who meets all of the other criteria.'
            }
            editing={editing}
            rowActions={{
              onRowDelete: handleRemoveUser,
            }}
            rowKey='id'
            title='Limit Promotion To Specific Users'
            toolbarButtons={
              editing
                ? [
                    {
                      startIcon: <Add />,
                      title: 'Add User',
                      onClick: handleAddUserOpen,
                    },
                    {
                      title: 'Open To All Users',
                      startIcon: <DeleteSweep />,
                      onClick: handleClearUsers,
                    },
                  ]
                : undefined
            }
          />
        </Paper>
      </ErrorBoundary>
    </>
  )
}

export default Users
