import { SET_ORDERS_FOR_USER, SET_ORDER_COUNT_FOR_USER } from '../action-list'

const initialState = {
  counts: {}, // Mapping of uid -> number
  orders: {}, // Mapping of uid -> orderId[]
}

/**
 * Reducer for the currently displayed orders/counts of given users
 */
export default (state = initialState, action) => {
  const { orderIds, count, uid } = action

  switch (action.type) {
    case SET_ORDERS_FOR_USER:
      console.log(
        `Redux: Setting ${orderIds.length} displayed orders for user ${uid}`,
      )
      return { ...state, orders: { ...state.orders, [uid]: orderIds } }
    case SET_ORDER_COUNT_FOR_USER:
      console.log(`Redux: Setting order count of ${count} for user ${uid}`)
      return { ...state, counts: { ...state.counts, [uid]: count } }
    default:
      return state
  }
}
