import React, {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react'

import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { canvasPreview, resizeBlob } from './canvas-preview'
import { makeStyles } from 'tss-react/mui'

import './cropped-image-input.css'
import 'react-image-crop/dist/ReactCrop.css'

const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    height: 180,
  },
  dialog: {},
  control: {},
  sourceImage: {
    width: '100%',
  },
  preview: {
    border: '1px solid black',
    objectFit: 'contain',
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

type Props = {
  imageUrl: string
  desiredWidth: number
  desiredHeight: number
  onChange?: (url: string, name: string) => void
  isEditing?: boolean
}

const CroppedImageInput = ({
  imageUrl,
  desiredWidth,
  desiredHeight,
  onChange = () => true,
  isEditing = true,
}: Props): ReactElement => {
  const { classes } = useStyles()

  const [cropDialogOpen, setCropDialogOpen] = useState(false)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)

  const [newImageName, setNewImageName] = useState('')
  const [newImageUrl, setNewImageUrl] = useState('')

  let url = imageUrl || ''
  if (url.startsWith('//')) {
    url = 'https:' + url
  }

  const aspect = desiredWidth / desiredHeight

  const handlePhotoChange = (event: any) => {
    const file = event.target.files[0]
    setNewImageName(file.name)
    setNewImageUrl(URL.createObjectURL(file))
    setCropDialogOpen(true)
    event.target.value = ''
  }

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 100,
          },
          aspect,
          width,
          height,
        ),
        width,
        height,
      ),
    )
    renderPreview()
  }

  const handleCropComplete = async () => {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob(
      async (blob) => {
        if (!blob) {
          throw new Error('Failed to create blob')
        }
        const resizedBlob = await resizeBlob(blob, desiredWidth, desiredWidth)
        onChange(URL.createObjectURL(resizedBlob), newImageName)
        setCropDialogOpen(false)
      },
      'image/jpeg',
      0.9,
    )
  }

  const handleCancel = () => {
    setCropDialogOpen(false)
  }

  const renderPreview = () => {
    const t = setTimeout(() => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    }, 100)

    return () => {
      clearTimeout(t)
    }
  }

  useEffect(renderPreview, [completedCrop])

  return (
    <>
      <Dialog className={classes.dialog} open={cropDialogOpen}>
        <DialogTitle id='crop-dialog-title'>Set image crop</DialogTitle>
        <DialogContent>
          <ReactCrop
            aspect={aspect}
            crop={crop}
            keepSelection
            onChange={setCrop}
            onComplete={setCompletedCrop}
          >
            <img
              alt='crop image'
              className={classes.sourceImage}
              onLoad={onImageLoad}
              ref={imgRef}
              src={newImageUrl}
            />
          </ReactCrop>
          {!!completedCrop && (
            <canvas
              className={classes.preview}
              ref={previewCanvasRef}
              style={{
                width: 300,
                height: (desiredHeight / desiredWidth) * 300,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleCropComplete}>Accept</Button>
        </DialogActions>
      </Dialog>
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          {url ? (
            <img className={classes.image} src={url} />
          ) : (
            <span>No image uploaded</span>
          )}
        </div>
        <input
          accept='image/*'
          id='image-upload-button'
          onChange={handlePhotoChange}
          style={{ display: 'none' }}
          type='file'
        />
        {isEditing && (
          <label htmlFor='image-upload-button'>
            <Button
              className={classes.control}
              component='span'
              variant='contained'
            >
              Upload Image
            </Button>
          </label>
        )}
      </div>
    </>
  )
}

export default CroppedImageInput
