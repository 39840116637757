import * as api from '@utils/api'

export const SET_VERTICAL_CARD = 'SET_VERTICAL_CARD'
export const DELETE_VERTICAL_CARD = 'DELETE_VERTICAL_CARD'
export const SET_VERTICAL_CARDS = 'SET_VERTICAL_CARDS'

export const getVerticalCards = () => async (dispatch) => {
  const { data: cards } = await api.GET('/p4/vertical_cards')

  return dispatch({
    type: SET_VERTICAL_CARDS,
    cards,
  })
}

export const createVerticalCard = (card) => async (dispatch) => {
  const { data } = await api.POST('/p4/vertical_cards', card)

  return dispatch({
    type: SET_VERTICAL_CARD,
    card: data,
  })
}

export const getVerticalCard = (id) => async (dispatch) => {
  const { data: card } = await api.GET(`/p4/vertical_cards/${id}`)

  return dispatch({
    type: SET_VERTICAL_CARD,
    card,
  })
}

export const saveVerticalCard = (card) => async (dispatch) => {
  let type = SET_VERTICAL_CARD
  const { data } = await api.PATCH(`/p4/vertical_cards/${card.id}`, card)

  if (card.archived_at !== null) {
    type = DELETE_VERTICAL_CARD
  }

  return dispatch({
    type,
    card: data,
  })
}

export const setVerticalCards = (updatedCards) => async (dispatch) => {
  const cardsPayload = {
    cards: Object.values(updatedCards),
  }

  const {
    data: { cards },
  } = await api.PATCH('/p4/vertical_cards/bulk', cardsPayload)

  return dispatch({
    type: SET_VERTICAL_CARDS,
    cards,
  })
}
