import createCachedSelector from '@utils/cached-selector'
import { createSelector } from 'reselect'

export const selectCustomContentById = createCachedSelector(
  (s, id) => s.CustomContents[id],
  (content) => content,
)((_s, id) => id)

export const selectCustomContents = createSelector(
  (s) => s.CustomContents,
  (contents) => contents,
)
