import { ApiDispatch, PriceType } from '@types'
import { apiAction } from './api'
import { closeDialog } from '@store/reducers/dialog/actions'
import { setFlashMessage } from '.'
import { updateProductsStoresPrice } from './products-stores'

export const updateStoreProductPrices = (prices: PriceType[]): ApiDispatch => {
  const { store_id: storeId, product_id: productId } = prices[0]
  return apiAction({
    url: `/p4/products/${productId}/prices/${storeId}`,
    method: 'POST',
    data: { prices },
    actionCallbacks: {
      success:
        ({ prices: updated }) =>
        (dispatch) => {
          const updatedBasePrice = updated.find(
            (u) => !u.attribute_values.length,
          )
          if (updatedBasePrice) {
            dispatch(updateProductsStoresPrice(updatedBasePrice))
          }
          dispatch(setFlashMessage('Prices Saved', 'success'))
          dispatch(closeDialog())
        },
      failure: (_: any, error: Error) => (dispatch) => {
        dispatch(setFlashMessage(error.message, 'error'))
      },
    },
  })
}
