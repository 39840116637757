import {
  ADD_STORE_HOUR_OVERRIDE,
  SET_STORE_HOUR_OVERRIDES,
} from '../action-list'

/**
 * Mapping of store_id => override_date => hour record
 */
const initialState = {}

function constructMapping(hours) {
  return hours.reduce((mapping, next) => {
    if (mapping[next.store_id] == null) {
      mapping[next.store_id] = {}
    }

    mapping[next.store_id][next.override_date] = next
    return mapping
  }, {})
}

export default (state = initialState, action) => {
  const { hours, override } = action

  switch (action.type) {
    case SET_STORE_HOUR_OVERRIDES:
      console.log('Redux: Setting overrides')
      return constructMapping(hours)
    case ADD_STORE_HOUR_OVERRIDE:
      console.log(
        `Redux: Updating override for day ${override.override_date} at store ${override.store_id}`,
      )
      return {
        ...state,
        [override.store_id]: {
          ...state[override.store_id],
          [override.override_date]: override,
        },
      }
    default:
      return state
  }
}
