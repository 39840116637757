import { TagType } from '@types'
import { arrayToObject } from '@utils/functional'
import { createReducer } from '@reduxjs/toolkit'
import {
  createTagAction,
  getTags,
  setTag,
  updateTagAction,
} from '@store/actions/tags'

type State = Record<number, TagType>

const initialState = {} as State

const tagsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTags.fulfilled, (_, action) => {
      return arrayToObject('id', action.payload)
    })
    .addCase(setTag, (state, action) => {
      state[action.payload.id] = action.payload
    })
    .addCase(updateTagAction, (state, action) => {
      state[action.payload.id] = action.payload
    })
    .addCase(createTagAction, (state, action) => {
      state[action.payload.id] = action.payload
    })
})

export default tagsReducer
