import * as api from '@utils/api'
import { OrderList } from '@types'

export const getOrdersByUser = async (
  userId: number,
  page: number | null = null,
  pageSize: number | null = null,
  begin: string | null = null,
  end: string | null = null,
): Promise<{ orders: OrderList; total_results: number }> => {
  const response = await api.GET(`/p4/users/${userId}/orders`, {
    limit: pageSize,
    offset: page && pageSize ? page * pageSize : null,
    begin,
    end,
  })

  return response.data
}

export const getOrdersByStore = async (
  storeId: number,
  page: number | null = null,
  pageSize: number | null = null,
  begin: string | null = null,
  end: string | null = null,
): Promise<{ orders: OrderList; total_results: number }> => {
  const response = await api.GET(`/p4/stores/${storeId}/orders`, {
    limit: pageSize,
    offset: page && pageSize ? page * pageSize : null,
    begin,
    end,
  })

  return response.data
}
