import React, { ReactElement, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { formatBackend } from '@utils/date-utils'

type PropTypes = {
  editing: boolean
  onParametersChange: (parameters: any[]) => void
  cohortParameters: any[]
}

const Lapsed = ({
  editing,
  onParametersChange,
  cohortParameters,
}: PropTypes): ReactElement => {
  const [lastPurchase, setLastPurchase] = useState<string>(
    formatBackend(new Date()),
  )

  useEffect(() => {
    if (typeof cohortParameters[0] === 'string') {
      setLastPurchase(cohortParameters[0])
    }
  }, [cohortParameters])

  const handleLastPurchaseDateChange = (date) => {
    if (date == 'Invalid Date') return
    const formattedDate = formatBackend(date)
    setLastPurchase(formattedDate)
    onParametersChange([formattedDate])
  }

  return (
    <>
      <DatePicker
        disabled={!editing}
        format='M/D/Y'
        label='Last Purchase Date'
        onChange={handleLastPurchaseDateChange}
        value={dayjs(lastPurchase + 'T00:00:00')}
      />
    </>
  )
}

export default Lapsed
