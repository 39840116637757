import { Action, PayloadActionCreator } from '@reduxjs/toolkit'

// modified types from @reduxjs/toolkit
export type PayloadAction<P = void, T extends string = string, M = never> = {
  payload: P
  type: T
} & ([M] extends [never]
  ? {} // eslint-disable-line @typescript-eslint/ban-types
  : {
      response: M
    })

type PrepareAction<P> =
  | ((
      ...args: any[]
    ) => {
      payload: P
    })
  | ((
      ...args: any[]
    ) => {
      payload: P
      response: any
    })

export function createAction<P = void, T extends string = string>(
  type: T,
): PayloadActionCreator<P, T>

export function createAction<
  PA extends PrepareAction<any>,
  T extends string = string
>(
  type: T,
  prepareAction: PA,
): PayloadActionCreator<ReturnType<PA>['payload'], T, PA>

// modifed version of redux-toolbox's createAction utility
// see: https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/createAction.ts
export function createAction(
  type: string,
  prepareAction?: (...args: any[]) => any,
): any {
  function actionCreator(...args: any[]) {
    if (prepareAction) {
      const prepared = prepareAction(...args)
      if (!prepared) {
        throw new Error('prepareAction did not return an object')
      }

      return {
        type,
        payload: prepared.payload,
        ...(prepared.response && { response: prepared.response }),
      }
    }

    return {
      type,
      payload: args[0],
      ...(args.length > 1 && { response: args[1] }),
    }
  }

  actionCreator.toString = () => `${type}`
  actionCreator.type = type
  actionCreator.match = (action: Action<unknown>): action is PayloadAction =>
    action.type === type

  return actionCreator
}

export default createAction
