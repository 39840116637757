import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

import Table from '../deprecated/table'

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        setFlashMessage,
      },
      dispatch,
    ),
  },
})

function PagedTable(props) {
  const { actions } = props
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [didFetchInitial, setDidFetchInitial] = useState(false)

  const fetchData = async (page, pageSize) => {
    try {
      await props.fetchData(page, pageSize)
      setPage(page)
      setRowsPerPage(pageSize)
    } catch (error) {
      actions.setFlashMessage(error.message, 'error')
    }
  }

  if (!didFetchInitial) {
    setDidFetchInitial(true)
    fetchData(page, rowsPerPage).catch((error) =>
      actions.setFlashMessage(error.message, 'error'),
    )
  }

  return (
    <Table
      data={props.data}
      fields={props.fields}
      getClickRowRoute={(row) => {
        if (props.getClickRowRoute == null) {
          return
        }
        return props.getClickRowRoute(row)
      }}
      headers={props.headers}
      onChangePage={(_, newPage) => {
        if (newPage === page) {
          return
        }
        fetchData(newPage, rowsPerPage)
      }}
      onChangeRowsPerPage={(event) => {
        const newSize = parseInt(event.target.value, 10)
        if (newSize === rowsPerPage) {
          return
        }
        fetchData(0, parseInt(event.target.value, 10))
      }}
      page={page}
      paginated
      rowsPerPage={rowsPerPage}
      totalRowCount={props.count}
    />
  )
}

PagedTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.array,
  headers: PropTypes.arrayOf(PropTypes.string),
  fetchData: PropTypes.func,
  getClickRowRoute: PropTypes.func,
  count: PropTypes.number,
}

export default connect(null, mapDispatchToProps)(PagedTable)
