import createCachedSelector from 're-reselect'
import { RootState, UserType } from '@types'
import { createSelector } from 'reselect'

const employeesSelector = (state: RootState): Record<string, UserType> =>
  state.Employees

const employeeSelector = (state: RootState, id: number): UserType =>
  state.Employees[id]

export const selectEmployeeById = createCachedSelector(
  employeeSelector,
  (employee) => employee,
)((_s, id) => id)

export const selectEmployees = createSelector(
  employeesSelector,
  (employees) => employees,
)

export const selectEmployeesList = createSelector(
  selectEmployees,
  (employees) => Object.values(employees),
)
