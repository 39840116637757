import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import Toolbar from '@components/shared/toolbar'
import ToolbarButton from '../../shared/toolbar-button'
import find from 'lodash.find'
import useSelector from '@hooks/use-selector'
import { AppDispatch, NewTaxRateType, SurchargeType, TaxRateType } from '@types'
import { Box, Checkbox, Divider, Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Add, Refresh, Save } from '@mui/icons-material'
import {
  DataGrid,
  GridEditInputCell,
  GridRenderEditCellParams,
} from '@mui/x-data-grid'
import {
  getSurcharges,
  getTaxRates,
  updateTaxRates,
} from '@store/actions/tax-rates'
import {
  surchargesSelector,
  taxRatesAtStoreSelector,
} from '@store/selectors/tax-rates'
import { useDispatch } from 'react-redux'

type Props = {
  storeId: number
  className: any
  editable: boolean
  title: string
}

type Row = {
  surchargeId: number
  name: string
  beverage: number
  food: number
  bag: number
  merchandise: number
  bottle: number
  flatRate: number | null
}

const useStyles = makeStyles()(() => ({
  titleBar: {
    height: 54,
    backgroundColor: '#F8F8F8',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  surchargeSelect: {
    height: '90%',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  ratesContainer: {
    width: '100%',
    height: 114,
  },
}))

const getRateForCell = (
  surcharge: SurchargeType,
  taxRates: TaxRateType[],
  productType: string,
) => {
  const currentTaxRate = find(taxRates, {
    surcharge_id: surcharge.id,
    product_type: productType,
  })
  return currentTaxRate
    ? surcharge.flat_rate
      ? surcharge.flat_rate
      : currentTaxRate.rate
    : 0.0
}

const customEditCell = (params: GridRenderEditCellParams<Row>) => {
  if (params.row.flatRate) {
    const { row, value, id, api, field } = params
    return (
      <>
        <Checkbox
          checked={!!value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.checked ? row.flatRate : 0
            api.setEditCellValue(
              {
                id,
                field,
                value: newValue,
                debounceMs: 200,
                unstable_skipValueParser: true,
              },
              event,
            )
          }}
        />
      </>
    )
  }
  return (
    <>
      <GridEditInputCell {...params} />
    </>
  )
}

const displayValueFormatter = (value: number, row: Row) => {
  if (row && row.flatRate) {
    return `${value}¢`
  }
  return `${(value * 100).toFixed(2)}%`
}

const taxValueSetter = (column: string) => (value: number, row: Row) => {
  let newValue = value
  if (row.flatRate) {
    newValue = value ? row.flatRate : 0
  }
  return { ...row, [column]: newValue }
}

const saveChanges =
  (taxChanges: Record<number, Record<string, number>>, storeId: number) =>
  (dispatch) => {
    const newTaxRates: NewTaxRateType[] = []
    for (const surchargeId in taxChanges) {
      const rate_key = taxChanges[surchargeId]['flatRate']
        ? 'flat_rate'
        : 'rate'
      delete taxChanges[surchargeId]['flatRate']
      for (const product_type in taxChanges[surchargeId]) {
        newTaxRates.push({
          surcharge_id: parseInt(surchargeId, 10),
          product_type: product_type,
          [rate_key]: taxChanges[surchargeId][product_type],
          store_id: storeId,
        } as NewTaxRateType)
      }
    }
    if (newTaxRates.length) {
      return dispatch(updateTaxRates(newTaxRates))
    }
  }

const TaxRates = ({
  storeId,
  className,
  editable,
  title,
}: Props): ReactElement => {
  const { classes } = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const taxRates = useSelector(taxRatesAtStoreSelector, storeId)
  const surcharges = useSelector(surchargesSelector)
  const [taxChanges, setTaxChanges] = useState({})
  const [hasChanges, setHasChanges] = useState(false)
  const [rows, setRows] = useState<Row[]>([])
  const [addSurchargeValue, setAddSurchargeValue] = useState(0)
  const [canAddSurcharge, setCanAddSurcharge] = useState(false)
  const [surchargesAvailable, setSurchargesAvailable] = useState<
    SurchargeType[]
  >([])
  const [surchargeIdRows, setSurchargeIdRows] = useState<
    Record<number, number>
  >([])

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        dispatch(getTaxRates(storeId)),
        dispatch(getSurcharges()),
      ])
    }

    loadData()
  }, [])

  const buildRows = useCallback(() => {
    const surcharge_ids = taxRates.map((rate) => rate.surcharge_id)
    const nextSurchargeIdRows = {}
    const newRows: Row[] = surcharges
      .filter((surcharge) => {
        return surcharge_ids.includes(surcharge.id)
      })
      .map((surcharge, i) => {
        nextSurchargeIdRows[i] = surcharge.id
        const type = surcharge.flat_rate ? '¢' : '%'
        return {
          surchargeId: surcharge.id,
          name: `${surcharge.name} - ${type}`,
          beverage: getRateForCell(surcharge, taxRates, 'beverage'),
          food: getRateForCell(surcharge, taxRates, 'food'),
          bag: getRateForCell(surcharge, taxRates, 'bag'),
          merchandise: getRateForCell(surcharge, taxRates, 'merchandise'),
          bottle: getRateForCell(surcharge, taxRates, 'bottle'),
          flatRate: surcharge.flat_rate,
        }
      })
    const leftoverSurcharges = surcharges.filter(
      (surcharge) => !surcharge_ids.includes(surcharge.id),
    )
    if (leftoverSurcharges.length) setCanAddSurcharge(true)
    setSurchargeIdRows(nextSurchargeIdRows)
    setSurchargesAvailable(leftoverSurcharges)
    setHasChanges(false)
    setRows(newRows)
    setTaxChanges({})
  }, [surcharges, taxRates])

  useEffect(() => {
    buildRows()
  }, [buildRows])

  const onGridRowUpdated = (newRow: Row) => {
    setTaxChanges({
      ...taxChanges,
      [newRow.surchargeId]: {
        beverage: newRow['beverage'],
        food: newRow['food'],
        bag: newRow['bag'],
        merchandise: newRow['merchandise'],
        bottle: newRow['bottle'],
        flatRate: newRow.flatRate,
      },
    })
    return { ...newRow }
  }

  const addNewSurcharge = (surcharge_id: number) => {
    if (!surcharge_id) return
    if (hasChanges) {
      // eslint-disable-next-line
      window.confirm(
        'You have unsaved changed. Please save them before adding a new line.',
      )
      return
    }
    const surcharge = find(surcharges, { id: surcharge_id })

    if (!surcharge) return

    const defaultRate = surcharge.flat_rate
      ? surcharge.flat_rate
      : surcharge.default_rate
    const defaultRatesList = {
      beverage: defaultRate,
      food: defaultRate,
      bag: defaultRate,
      merchandise: defaultRate,
      bottle: defaultRate,
      flatRate: surcharge.flat_rate,
    }
    const newRows = [
      ...rows,
      {
        surchargeId: surcharge.id,
        name: surcharge.name,
        ...defaultRatesList,
      },
    ]
    const leftoverSurcharges = surchargesAvailable.filter(
      (s) => s.id != surcharge_id,
    )
    if (!leftoverSurcharges.length) setCanAddSurcharge(false)
    setSurchargesAvailable(leftoverSurcharges)
    setSurchargeIdRows({
      ...surchargeIdRows,
      [Object.keys(surchargeIdRows).length]: surcharge.id,
    })
    setTaxChanges({
      ...taxChanges,
      [surcharge.id]: defaultRatesList,
    })
    setRows(newRows)
    setHasChanges(true)
    setAddSurchargeValue(0)
  }

  const handleAddSurchargeChange = (e) => {
    setAddSurchargeValue(parseInt(e.target.value, 10))
  }

  const handleAddNewSurcharge = () => addNewSurcharge(addSurchargeValue)

  const handleRefresh = () => {
    if (
      !hasChanges ||
      // eslint-disable-next-line
      window.confirm(
        'You have unsaved changed. Are you sure you want to refresh?',
      )
    ) {
      dispatch(getTaxRates(storeId))
      buildRows()
    }
  }

  const handleSave = () => dispatch(saveChanges(taxChanges, storeId))

  const editableCellParams = {
    editable,
    valueFormatter: displayValueFormatter,
    renderEditCell: customEditCell,
    flex: 1,
  }

  return (
    <Paper className={className}>
      <Toolbar
        description={`This will effect the tax burden for this store only. Rows match each surcharge applied to the store and the columns match each product type that the tax rate is applied to. Zeros (0) will apply no tax for a surcharge to it's respective product type.`}
        title={title}
      />
      <div>
        <Box
          border={2}
          borderBottom={0}
          borderColor='#E4E4E4'
          className={classes.titleBar}
        >
          {editable && (
            <>
              {canAddSurcharge && (
                <>
                  <select
                    className={classes.surchargeSelect}
                    onChange={handleAddSurchargeChange}
                    value={addSurchargeValue}
                  >
                    <option value={0}>Select Surcharge</option>
                    {surchargesAvailable.map((surcharge, i) => (
                      <option key={i} value={surcharge.id}>
                        {surcharge.name}
                      </option>
                    ))}
                  </select>
                  <ToolbarButton
                    icon={<Add />}
                    onClick={handleAddNewSurcharge}
                    title='Add'
                  />
                </>
              )}
              <Divider orientation='vertical' />
              <ToolbarButton
                icon={<Refresh />}
                onClick={handleRefresh}
                title='Refresh'
              />
              <Divider orientation='vertical' />
              <ToolbarButton
                disabled={false}
                icon={<Save />}
                onClick={handleSave}
                title='Save'
              />
            </>
          )}
        </Box>
        {/*
        There is an issue where without a fixed size container the grid tries to
        infinitely resize itself, if I could turn off dynamic resizing entirely
        I would because there seesm to be no reason for this in our use case, it
        should just be a basic flex grid.
        https://github.com/mui/mui-x/issues/9362
        */}
        <div className={classes.ratesContainer}>
          <DataGrid
            autoHeight={false}
            columns={[
              { field: 'name', editable: false, flex: 2 },
              {
                field: 'beverage',
                ...editableCellParams,
                valueSetter: taxValueSetter('beverage'),
              },
              {
                field: 'food',
                ...editableCellParams,
                valueSetter: taxValueSetter('food'),
              },
              {
                field: 'bag',
                ...editableCellParams,
                valueSetter: taxValueSetter('bag'),
              },
              {
                field: 'merchandise',
                ...editableCellParams,
                valueSetter: taxValueSetter('merchandise'),
              },
              {
                field: 'bottle',
                ...editableCellParams,
                valueSetter: taxValueSetter('bottle'),
              },
            ]}
            density='compact'
            getRowId={(row) => `${storeId}-${row.surchargeId}`}
            hideFooter
            hideFooterPagination
            hideFooterSelectedRowCount
            processRowUpdate={onGridRowUpdated}
            rows={rows}
            showCellVerticalBorder
            showColumnVerticalBorder
          />
        </div>
      </div>
    </Paper>
  )
}

export default TaxRates
