import FormInput from '@components/shared/form-input'
import ProductSelector from '@components/shared/product-selector'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { RecommendedProductType } from '@types'

type Props = {
  open: boolean
  recommendedProduct: RecommendedProductType
  onCancel: () => void
  onSave: (recommendedProduct: RecommendedProductType) => void
}

function EditRecommendedProduct({
  open = false,
  recommendedProduct,
  onCancel,
  onSave,
}: Props): ReactElement {
  const [isNew, setIsNew] = useState(false)
  const [headline, setHeadline] = useState('')
  const [description, setDescription] = useState('')
  const [product_id, setProductId] = useState(-1)

  useEffect(() => {
    setIsNew(recommendedProduct.id === -1)
    setHeadline(recommendedProduct.headline)
    setDescription(recommendedProduct.description)
    setProductId(recommendedProduct.product_id)
  }, [recommendedProduct, open])

  const handleSave = () => {
    const updatedRecommendedProduct = {
      ...recommendedProduct,
      headline,
      description,
      product_id,
    }
    onSave(updatedRecommendedProduct)
  }

  return (
    <Dialog
      aria-describedby='alert-dialog-description'
      aria-labelledby='alert-dialog-title'
      fullWidth
      open={open}
    >
      <DialogTitle id='alert-dialog-title'>
        {isNew ? 'Create' : 'Edit'} Recommended Product
      </DialogTitle>
      <DialogContent>
        <ProductSelector
          containerProps={{ fullWidth: true }}
          onChange={setProductId}
          value={product_id}
        />
        <br />
        <br />
        <FormInput
          name='headline'
          onChangeText={setHeadline}
          title='Headline'
          value={headline}
        />
        <FormInput
          name='description'
          onChangeText={setDescription}
          title='Description'
          value={description}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus color='primary' onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button color='primary' onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditRecommendedProduct
