import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { useEventListener } from '../../../utils/hooks'

import { makeStyles } from 'tss-react/mui'

import {
  CircularProgress,
  Divider,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'

import { ExitToApp, Search } from '@mui/icons-material'

import { fetchSuggestions } from '../../../services/search-service'

import Color from '../../../constants/color'
import { getPathForResult } from '../../../utils/search-utils'

// CONST STYLES
const kBorderRadius = 24
const kSearchBarHeight = 54

const useStyles = (suggestionsVisible) =>
  makeStyles()((theme) => ({
    container: {
      position: 'relative',
      zIndex: 1,
    },
    searchBar: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      borderRadius: kBorderRadius,
      border: `1px solid ${suggestionsVisible ? 'white' : '#D5D5D5'}`,
      zIndex: 100,
      height: kSearchBarHeight,
      overflow: 'hidden',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    suggestions: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      borderRadius: kBorderRadius,
      zIndex: -1,
      paddingTop: kSearchBarHeight,
      overflow: 'hidden',
    },
    suggestion: {
      alignItems: 'center',
      display: 'flex',
      textDecoration: 'none',
      width: '100%',
    },
    selectedSuggestion: {
      backgroundColor: Color.secondaryGray,
    },
    suggestionsEmptyView: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    loadingIndicator: {
      marginRight: theme.spacing(0.5),
    },
  }))()

const mapStateToProps = (state) => ({
  searchSuggestions: state.SearchSuggestions,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchSuggestions: fetchSuggestions(dispatch),
  },
})

function SearchBar(props) {
  const { actions } = props
  const navigate = useNavigate()
  const [query, setQuery] = useState('')
  const [suggestionsVisible, setSuggestionsVisible] = useState(false)
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(null)

  const { classes } = useStyles(suggestionsVisible, {
    props: suggestionsVisible,
  })

  const currentSuggestions = props.searchSuggestions[query]

  useEventListener(
    'keydown',
    useCallback((event) => {
      if (event == null) return

      switch (event.which) {
        case 13: // Enter key
          if (
            selectedSuggestionIndex != null &&
            selectedSuggestionIndex < currentSuggestions.length
          ) {
            const suggestion = currentSuggestions[selectedSuggestionIndex]
            navigate(getPathForResult(suggestion))
          } else {
            // Search for the search term
            setSuggestionsVisible(false)
            props.onSearch(query)
          }
          break
        case 27: // Esc key
          setSuggestionsVisible(false)
          break
        case 38: // Arrow up
          event.preventDefault()
          if (suggestionsVisible) {
            let i = null
            if (selectedSuggestionIndex === 0) {
              i = null
            } else if (selectedSuggestionIndex > 0) {
              i = selectedSuggestionIndex - 1
            }

            setSelectedSuggestionIndex(i)
          }

          break
        case 40: // Arrow down
          event.preventDefault()
          if (suggestionsVisible) {
            let i = null
            if (selectedSuggestionIndex == null) i = 0
            else if (
              setSelectedSuggestionIndex >=
              currentSuggestions.length - 1
            )
              i = null
            else i = selectedSuggestionIndex + 1

            setSelectedSuggestionIndex(i)
          }

          break
      }
    }),
  )

  // Update the suggestions every time the query/suggestions updates
  useEffect(() => {
    setSuggestionsVisible(!!query)

    if (query) actions.fetchSuggestions(query)
  }, [query])

  const getSuggestionMetadata = (suggestion) => {
    const copy = { ...suggestion }
    delete copy['name']
    const keys = Object.keys(copy)
    let str = ''
    for (let i = 0; i < keys.length; i++) {
      if (!copy[keys[i]]) continue
      if (i != 0) {
        str += ' • '
      }
      str += `${keys[i]}: ${copy[keys[i]]}`
    }

    return str
  }

  return (
    <div className={classes.container}>
      {suggestionsVisible && (
        <Paper className={classes.suggestions} elevation={8}>
          {(currentSuggestions || []).length > 0 ? (
            <List>
              {(currentSuggestions || []).map((suggestion, index) => (
                <ListItem
                  button
                  className={
                    selectedSuggestionIndex === index
                      ? classes.selectedSuggestion
                      : null
                  }
                  key={`${suggestion.name}#${suggestion.id}`}
                  onMouseEnter={() => setSelectedSuggestionIndex(index)}
                >
                  <Link
                    className={classes.suggestion}
                    to={getPathForResult(suggestion)}
                  >
                    <ListItemText
                      primary={suggestion.name}
                      secondary={getSuggestionMetadata(suggestion)}
                    />
                    <ListItemIcon>
                      <ExitToApp />
                    </ListItemIcon>
                  </Link>
                </ListItem>
              ))}
            </List>
          ) : (
            <div className={classes.suggestionsEmptyView}>
              <Typography>
                <em>No Search Suggestions</em>
              </Typography>
            </div>
          )}
        </Paper>
      )}
      <div className={classes.searchBar}>
        <Search />
        <InputBase
          autoFocus
          className={classes.input}
          // onBlur={ () => setSuggestionsVisible(false) }
          onChange={(event) => {
            const value = event.target.value
            setQuery(value)
          }}
          onFocus={() => currentSuggestions && setSuggestionsVisible(true)}
          placeholder='Search P4'
          value={query}
        />
        {props.isLoading === true && (
          <CircularProgress className={classes.loadingIndicator} size={28} />
        )}
      </div>
      {suggestionsVisible && <Divider />}
    </div>
  )
}

SearchBar.propTypes = {
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)
