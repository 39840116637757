import omit from 'lodash.omit'
import { PromotionType, PromotionsStateType } from '@types'
import {
  archivePromotionSuccess,
  createPromotionSuccess,
  getPromotionSuccess,
  getPromotionsSuccess,
  updatePromotionSuccess,
} from '@store/actions/promotions'
import { arrayToObject } from '@utils/functional'
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  past: [],
  ongoing: [],
  upcoming: [],
  all: {},
} as PromotionsStateType

const setOnePromotion = (
  state: PromotionsStateType,
  { payload: promotion }: { payload: PromotionType },
) => {
  state.all[promotion.id] = promotion

  state.past = state.past.filter((x) => x != promotion.id)
  state.ongoing = state.ongoing.filter((x) => x != promotion.id)
  state.upcoming = state.upcoming.filter((x) => x != promotion.id)

  const now = new Date().getTime()

  if (now < Date.parse(promotion.start_date + 'T00:00:00')) {
    state.upcoming.push(promotion.id)
  } else if (now > Date.parse(promotion.end_date + 'T23:59:59')) {
    state.past.push(promotion.id)
  } else {
    state.ongoing.push(promotion.id)
  }
}

const promotionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getPromotionsSuccess,
      (state, { payload: { past, ongoing, upcoming } }) => {
        state.past = past.map((p) => p.id)
        state.ongoing = ongoing.map((p) => p.id)
        state.upcoming = upcoming.map((p) => p.id)
        state.all = arrayToObject('id', past.concat(ongoing, upcoming))
      },
    )
    .addCase(getPromotionSuccess, setOnePromotion)
    .addCase(updatePromotionSuccess, setOnePromotion)
    .addCase(createPromotionSuccess, setOnePromotion)
    .addCase(archivePromotionSuccess, (state, { payload: { id } }) => {
      state.past = state.past.filter((x) => x != id)
      state.ongoing = state.ongoing.filter((x) => x != id)
      state.upcoming = state.upcoming.filter((x) => x != id)
      state.all = omit(state.all, [id])
    })
})

export default promotionsReducer
