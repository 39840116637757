// TODO: convert to typescript
import * as api from '@utils/api'

export const ccpaDelete = (id, email) => async () => {
  const { data: response } = await api.DELETE(`/p4/users/${id}`, {
    email: email,
  })
  return response
}

export const ccpaExport = (id, email) => async () => {
  const { data: response } = await api.POST(`/p4/users/${id}/export`, {
    email: email,
  })
  return response
}
