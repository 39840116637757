import * as api from '../utils/api'

import { addVendors, setVendors } from '../store/actions/vendors'

export const fetchVendors = (dispatch) => async () => {
  console.log('Fetching vendors')
  const { data } = await api.GET('/p4/vendors')
  return dispatch(setVendors(data))
}

export const fetchVendor = (dispatch) => async (id) => {
  console.log(`Fetching vendor with id ${id}`)
  const { data } = await api.GET(`/p4/vendors/${id}`)
  return dispatch(addVendors([data]))
}

export const createVendor = (dispatch) => async (name) => {
  console.log(`Creating vendor '${name}'`)
  const { data } = await api.POST('/p4/vendors', { name })
  return dispatch(addVendors([data]))
}

export const updateVendor = (dispatch) => async (id, name) => {
  console.log(`Updating vendor with ID ${id}`)
  const { data } = await api.PATCH(`/p4/vendors/${id}`, { name })
  return dispatch(addVendors([data]))
}
