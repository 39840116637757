import createCachedSelector from '@utils/cached-selector'
import { createSelector } from 'reselect'

export const selectPermissionById = createCachedSelector(
  (s, id) => s.Permissions[id],
  (permission) => permission,
)((_s, id) => id)

export const selectPermissions = createSelector(
  (s) => s.Permissions,
  (permissions) => permissions,
)

export const permissionListAlpha = createSelector(
  (s) => s.Permissions,
  (permissions) => {
    return Object.values(permissions).sort((a, b) => {
      return a.permission > b.permission ? 1 : -1
    })
  },
)
