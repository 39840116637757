import Loader from '@components/shared/loader'
import React, { useEffect, useState } from 'react'
import { AppDispatch } from '@types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { getAdminRefundReasons } from '@queries/refund-reasons'
import { refundProducts } from '@store/reducers/orders/actions'
import { setFlashMessage } from '@store/actions'
import { useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'

type Props = {
  orderId: number
  orderProductIds: number[]
  onClose?: () => void
}

const RefundModal = ({ orderId, orderProductIds, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [refundReasonId, setRefundReasonId] = useState<null | number>(null)
  const { data: refundReasons } = useQuery({
    queryKey: ['adminRefundReasons'],
    queryFn: getAdminRefundReasons,
    staleTime: Infinity,
  })
  useEffect(() => {
    if (orderProductIds.length) setConfirmationOpen(true)
  }, [orderId, orderProductIds])

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch<AppDispatch>()

  const handleConfirmationClose = () => {
    setIsLoading(false)
    setRefundReasonId(null)
    setConfirmationOpen(false)
    if (onClose) onClose()
  }
  const handleChangeRefundReasonId = (_, id) =>
    setRefundReasonId(parseInt(id, 10))
  const handleRefund = async () => {
    try {
      if (!refundReasonId) throw 'Refund reason is required'

      setIsLoading(true)
      await dispatch(refundProducts(orderId, orderProductIds, refundReasonId))
    } catch (err: any) {
      console.error(err)
      dispatch(setFlashMessage(err.message, 'error'))
    } finally {
      handleConfirmationClose()
    }
  }

  return (
    <Dialog
      aria-describedby='alert-dialog-description'
      fullScreen={fullScreen}
      open={confirmationOpen}
    >
      <Loader visible={isLoading} />
      <DialogTitle id='alert-dialog-description'>
        Confirm the reason for this refund
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel id='refund-radio-buttons-group-label'>
            Explanation
          </FormLabel>
          <RadioGroup
            aria-labelledby='refund-radio-buttons-group-label'
            name='refund-buttons-group'
            onChange={handleChangeRefundReasonId}
            value={refundReasonId}
          >
            {refundReasons?.map((reason) => (
              <FormControlLabel
                control={<Radio />}
                key={reason.id}
                label={reason.explanation}
                value={reason.id}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          disabled={isLoading}
          onClick={handleConfirmationClose}
        >
          Nevermind
        </Button>
        <Button
          color='secondary'
          disabled={refundReasonId == null || isLoading}
          onClick={handleRefund}
        >
          Complete Refund
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RefundModal
