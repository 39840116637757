import * as api from '@utils/api'

export const SET_STORE = 'SET_STORE'
export const DELETE_STORE = 'DELETE_STORE'
export const SET_STORES = 'SET_STORES'

export const getStores = () => async (dispatch) => {
  const { data: stores } = await api.GET('/p4/stores')

  return dispatch({
    type: SET_STORES,
    stores,
  })
}

export const createStore = (store) => async (dispatch) => {
  const { data } = await api.POST('/p4/stores', store)

  return dispatch({
    type: SET_STORE,
    store: data,
  })
}

export const getStore = (id) => async (dispatch) => {
  const { data: store } = await api.GET(`/p4/stores/${id}`)

  return dispatch({
    type: SET_STORE,
    store,
  })
}

export const saveStore = (store) => async (dispatch) => {
  let type = SET_STORE
  const { data } = await api.PATCH(`/p4/stores/${store.id}`, store)

  if (store.archived_at !== null) {
    type = DELETE_STORE
  }

  return dispatch({
    type,
    store: data,
  })
}

export const setStores = (updatedstores) => async (dispatch) => {
  const storesPayload = {
    stores: Object.values(updatedstores),
  }

  const {
    data: { stores },
  } = await api.PATCH('/p4/stores/bulk', storesPayload)

  return dispatch({
    type: SET_STORES,
    stores,
  })
}
