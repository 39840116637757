Array.prototype.toObject = function (key) {
  return this.reduce((acc, next) => {
    acc[next[key]] = next
    return acc
  }, {})
}

Array.prototype.objValues = function (key) {
  return this.map((obj) => obj[key])
}

export const sortByKey = (key) => {
  return (a, b) => {
    if (typeof a[key] == 'undefined' || typeof b[key] == 'undefined') {
      return 0
    }
    const valA = typeof a[key] == 'string' ? a[key].toLowerCase() : a[key]
    const valB = typeof b[key] == 'string' ? b[key].toLowerCase() : b[key]
    if (valA < valB) return -1
    if (valA > valB) return 1
    return 0
  }
}
