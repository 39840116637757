import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import FullscreenPage from '../shared/fullscreen-page'
import { Button, TextField, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { login } from '../../services/auth-service'
import { resetPassword } from '@store/reducers/employees/actions'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

const useStyles = makeStyles()((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  loginButton: {
    color: 'white',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

const mapStateToProps = (state) => ({
  isLoggedIn: state.LoginStatus,
  user: state.CurrentUser.user,
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    login: login(dispatch),
    reset: (email) => resetPassword(dispatch)(email),
    setFlashMessage: (message, variant) =>
      dispatch(setFlashMessage(message, variant)),
  },
})

function Login(props) {
  const { classes } = useStyles()
  const { actions } = props
  const [email, setEmail] = useState(props.user.email || '')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  if (props.isLoggedIn) {
    return <Navigate to='/home' />
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    actions
      .login(email, password)
      .then(() => navigate(`/`))
      .catch((e) => actions.setFlashMessage(e.toString(), 'error'))
  }

  return (
    <FullscreenPage contentMode>
      <Typography className={classes.title} variant='h3'>
        Welcome!
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          className={classes.textField}
          label='Email'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <TextField
          autoComplete='current-password'
          className={classes.textField}
          label='Password'
          onChange={(e) => setPassword(e.target.value)}
          type='password'
          value={password}
        />
        <Button
          className={classes.loginButton}
          color='primary'
          type='submit'
          variant='contained'
        >
          Login
        </Button>
      </form>
      <Button
        color='primary'
        onClick={() => {
          actions
            .reset(email)
            .then(() =>
              actions.setFlashMessage('Password Reset Sent', 'success'),
            )
            .catch((e) => actions.setFlashMessage(e.toString(), 'error'))
        }}
      >
        Forgot password
      </Button>
    </FullscreenPage>
  )
}

Login.propTypes = {
  actions: PropTypes.shape({
    setFlashMessage: PropTypes.func,
    reset: PropTypes.func,
    login: PropTypes.func,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isLoggedIn: PropTypes.bool,
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
