import ErrorBoundary from '@components/shared/error-boundary'
import React, { ReactElement, useState } from 'react'
import StoreSelector from '@components/shared/store-selector'
import Table from '@components/shared/table'
import { Add, DeleteSweep, DynamicFeed } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from '@mui/material'
import { getStoreList } from '@queries/stores'
import { useQuery } from '@tanstack/react-query'

type Props = {
  editing: boolean
  storeIds: number[]
  onChange: (storeIds: number[]) => void
}

const Stores = ({ editing, storeIds, onChange }: Props): ReactElement => {
  const [addStoreDialogOpen, setAddStoreDialogOpen] = useState(false)
  const [addStoreId, setAddStoreId] = useState(-1)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const [rowsPerPage, setRowsPerPage]: [number, (rowsPerPage: number) => any] =
    useState(10)
  const [page, setPage]: [number, (page: number) => any] = useState(0)

  const { data: stores } = useQuery({
    queryKey: ['storeList'],
    queryFn: getStoreList,
    staleTime: 30000,
  })

  const indexLastPost = (page + 1) * rowsPerPage
  const indexFirstPost = indexLastPost - rowsPerPage
  const storesSelected = stores
    ? stores.filter((s) => storeIds.includes(s.id))
    : []
  const storeRows = storesSelected.slice(indexFirstPost, indexLastPost)

  const handleStoreSelect = (storeId: number) => {
    setAddStoreId(storeId)
  }

  const onAddStoreOpen = () => {
    setAddStoreDialogOpen(true)
  }

  const handleRemoveStore = (storeId: number) => {
    onChange(storeIds.filter((x) => x != storeId))
  }

  const onAddStoreClose = () => {
    setAddStoreDialogOpen(false)
  }

  const handleAddStore = () => {
    onChange([...storeIds, addStoreId])
    setAddStoreDialogOpen(false)
  }

  const onAddAllStores = () => {
    setConfirmDialogOpen(true)
  }

  const onClearStores = () => {
    setResetDialogOpen(true)
  }

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false)
  }

  const handleResetDialogClose = () => {
    setResetDialogOpen(false)
  }

  const addAllStores = () => {
    if (!stores) return
    onChange(stores.map((s) => s.id))
    setConfirmDialogOpen(false)
  }

  const resetStores = () => {
    onChange([])
    setResetDialogOpen(false)
  }

  return (
    <>
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        onClose={handleConfirmDialogClose}
        open={confirmDialogOpen}
      >
        <DialogTitle id='alert-dialog-title'>Confirm!</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to apply the promotion to all stores?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color='primary' onClick={handleConfirmDialogClose}>
            Nevermind
          </Button>
          <Button color='primary' onClick={addAllStores}>
            Add Stores
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        onClose={handleResetDialogClose}
        open={resetDialogOpen}
      >
        <DialogTitle id='alert-dialog-title'>Confirm!</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to clear all the stores from the promotion.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color='primary' onClick={handleResetDialogClose}>
            Nevermind
          </Button>
          <Button color='primary' onClick={resetStores}>
            Clear Stores
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addStoreDialogOpen}>
        <DialogTitle id='alert-dialog-title'>
          Add Promotion to Store
        </DialogTitle>
        <DialogContent>
          <StoreSelector
            containerProps={{ fullWidth: true }}
            onChange={handleStoreSelect}
            value={addStoreId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onAddStoreClose}>Close</Button>
          <Button onClick={handleAddStore}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <ErrorBoundary>
        <Paper>
          <Table
            columns={[
              {
                title: 'ID',
                field: 'id',
              },
              {
                title: 'Name',
                field: 'name',
              },
            ]}
            count={storesSelected.length}
            data={storeRows}
            description='This promotion will only apply to customers with carts at these stores. If left blank you must set a promo code to activate.'
            editing={editing}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
            paginated
            rowActions={{
              onRowDelete: ({ id }) => {
                handleRemoveStore(id)
              },
            }}
            rowKey='id'
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            title='Applied To Stores'
            toolbarButtons={
              editing
                ? [
                    {
                      startIcon: <Add />,
                      title: 'Add Store',
                      onClick: onAddStoreOpen,
                    },
                    {
                      title: 'All Stores',
                      startIcon: <DynamicFeed />,
                      onClick: onAddAllStores,
                    },
                    {
                      title: 'Clear Stores',
                      startIcon: <DeleteSweep />,
                      onClick: onClearStores,
                    },
                  ]
                : undefined
            }
          />
        </Paper>
      </ErrorBoundary>
    </>
  )
}

export default Stores
