import clone from 'lodash.clone'
import curry from 'lodash.curry'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const identity = <T>(x: T): T => x

export const updateIndex = (idx: number, x: any, list: any[]): any[] => {
  if (idx >= list.length) return list

  const _list = clone(list)

  _list[idx] = x
  return _list
}

export const updateOrConcat = (idx: number, x: any, list: any[]): any[] => {
  if (idx === list.length) return list.concat(x)

  return updateIndex(idx, x, list)
}

export const removeIndex = (idx: number, list: any[]): any[] => {
  const _list = clone(list)

  _list.splice(idx, 1)

  return _list
}

export const prop = curry(
  (prop: string, object: { [key: string]: any }) => object[prop],
)

export const sortBy = curry(
  (predicate: (...args: any[]) => any, list: any[]) => {
    if (!list) return []

    return list.sort((a: any, b: any) => {
      if (predicate(a) < predicate(b)) return -1
      if (predicate(a) > predicate(b)) return 1
      return 0
    })
  },
)

export function arrayToObject<K extends string | number | symbol, T>(
  key: string,
  array: T[],
): Record<K, T> {
  const initialState = {} as Record<K, T>

  return array.reduce((acc, current) => {
    const keyValue: K = current[key]

    if (keyValue === undefined)
      throw Error('key value pair does not exist on array')

    return {
      ...acc,
      [keyValue]: current,
    }
  }, initialState)
}

export function callIf(condition: boolean, fn: (...args: any[]) => any) {
  if (!condition) return null

  return fn()
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
