import React, { ReactElement } from 'react'
import useSelector from '@hooks/use-selector'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { VendorType } from '@types'
import { selectVendorsList } from '@store/selectors/vendors'

type Props = {
  onChange: (vendorId: number) => void
  value: number
  className?: string
}

function VendorSelect({ className, onChange, value }: Props): ReactElement {
  const vendors = useSelector(selectVendorsList) as VendorType[]

  const handleChange = (event: SelectChangeEvent<number>) => {
    onChange(event.target.value as number)
  }

  return (
    <FormControl className={className}>
      <InputLabel id='vendors-select-label'>Vendor</InputLabel>
      <Select
        labelId='vendors-select-label'
        onChange={handleChange}
        style={{ minWidth: 200 }}
        value={value}
      >
        <MenuItem value={-1}>
          <em>None</em>
        </MenuItem>
        {vendors.map((vendor) => (
          <MenuItem key={vendor.id} value={vendor.id}>
            {vendor.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default VendorSelect
