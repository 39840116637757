import ProductSelector from '@shared/product-selector'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import useAllActions from '@hooks/use-all-actions'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { ProductList, ProductRecommendationObject } from '@types'
import { makeStyles } from 'tss-react/mui'

interface Props {
  open: boolean
  setOpen: (isOpen: boolean) => void
  loading: boolean
  recommendations: ProductRecommendationObject
}

const AddRecommendationDialog = ({
  open,
  setOpen,
  recommendations,
}: Props): ReactElement => {
  const { classes: styles } = useStyles()
  const [productId, setProductId] = useState(undefined)
  const { createRecommendation } = useAllActions()

  const filterProducts = useCallback(
    (productList: ProductList) => {
      const recommendationProductIds = Object.values(recommendations).map(
        (recommendation) => recommendation.product_id,
      )

      return productList.filter(
        (product) => !recommendationProductIds.includes(product.id),
      )
    },
    [recommendations],
  )

  const newRecommendationSortOrder = useMemo(() => {
    const minSortOrder = Object.values(recommendations).reduce(
      (min: number | null, { sort_order: sortOrder }) => {
        if (min === null) return sortOrder

        return Math.min(min, sortOrder)
      },
      null,
    )

    return minSortOrder !== null ? minSortOrder - 1 : 0
  }, [recommendations])

  const handleConfirm = () => {
    createRecommendation(productId, newRecommendationSortOrder)
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
    setProductId(undefined)
  }

  return (
    <Dialog aria-labelledby='dialog-title' onClose={handleCancel} open={open}>
      <DialogTitle id='dialog-title'>Create Recommendation</DialogTitle>
      <ProductSelector
        className={styles.dialogField}
        filter={filterProducts}
        onChange={(e) => setProductId(e)}
        value={productId}
      />
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>

        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddRecommendationDialog

const useStyles = makeStyles()((theme) => ({
  dialogField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))
