import * as api from '../utils/api'

import { addStore, addStores, setStoresCount } from '../store/actions/stores'
import { createSelector } from 'reselect'
import { setStoreOnline as setStoreOnlineRedux } from '../store/actions/store-online'
import { updateProductsStores } from '../store/actions/products-stores'

export const fetchStore = (dispatch) => async (id) => {
  console.log(`Fetching store with ID ${id}`)
  const { data } = await api.GET(`/p4/stores/${id}`)
  return dispatch(addStore(data))
}

export const fetchStores = (dispatch) => async () => {
  console.log('Fetching stores')
  const {
    data: { stores, total_rows },
  } = await api.GET('/p4/stores')
  return Promise.all([
    dispatch(addStores(stores)),
    dispatch(setStoresCount(total_rows)),
  ])
}

export const setStoreOnline = (dispatch) => async (storeId, online) => {
  console.log(`Setting store ${storeId} online status to ${online}`)
  const { data } = await api.POST(`/p4/stores/${storeId}/online`, { online })
  return dispatch(setStoreOnlineRedux(storeId, !data.offline))
}

export const fetchStoreOnline = (dispatch) => async (storeId) => {
  console.log(`Fetching online status for store ${storeId}`)
  const { data } = await api.GET(`/stores/${storeId}/status`)
  return dispatch(setStoreOnlineRedux(storeId, data.online))
}

export const updateStore = (dispatch) => async (storeId, storeData) => {
  console.log(`Updating store ${storeId}`)
  const { data } = await api.PATCH(`/p4/stores/${storeId}`, storeData)
  return dispatch(addStore(data))
}

export const createStore = (dispatch) => async (data) => {
  console.log(`Creating store`)
  const { data: store } = await api.POST(`/p4/stores`, data)
  await dispatch(addStore(store))
  return store
}

export const fetchProductsStoresForStoreId = (dispatch) => async (storeId) => {
  console.log(`Fetching products_stores for store ${storeId}`)
  const { data: productsStores } = await api.GET(
    `/p4/stores/${storeId}/products/atStore`,
  )
  return dispatch(updateProductsStores(productsStores))
}

// the overall store fetching still has to be refactored into selectors
// at that time move this to the selector file
const storesSelector = (rootState) => rootState.Stores

export const allStoresSelector = createSelector(storesSelector, (state) => {
  return state.stores
})
