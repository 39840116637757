import React, { ReactElement, useState } from 'react'
import { AddonI, AddonList } from '@types'
import { Tooltip } from '@mui/material'
import { getAddonList } from '@queries/addons'
import { makeStyles } from 'tss-react/mui'
import { useQuery } from '@tanstack/react-query'

const useStyles = makeStyles()((theme) => ({
  addons: {
    display: 'flex',
    marginTop: theme.spacing(2),
    padding: 0,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'auto 40px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderColor: 'white',
    borderStyle: 'solid',
    borderWidth: 1,
    marginRight: theme.spacing(2),
    height: 50,
    width: 50,
    borderRadius: theme.spacing(1),
  },
  selected: {
    borderColor: theme.palette.secondary.main,
  },
  image: {
    maxHeight: 40,
    maxWidth: 40,
    display: 'block',
  },
}))

interface Props {
  className?: string
  selectedAddons: AddonList
  updateAddons: (addonList: AddonList) => void
}

function AddonSelector({
  className,
  selectedAddons,
  updateAddons,
}: Props): ReactElement {
  const { classes } = useStyles()
  const [selectedIds, setSelectedIds]: [number[], any] = useState(
    selectedAddons?.map((a: AddonI) => a.id) || [],
  )

  const { isLoading, data: allAddons = [] } = useQuery({
    queryKey: ['addonList'],
    queryFn: getAddonList,
    staleTime: 30000,
  })

  const click = (e: any): void => {
    const id = parseInt(e.target.getAttribute('data-id'), 10)
    const newIds = selectedIds.includes(id)
      ? selectedIds.filter((i: number) => i !== id)
      : [...selectedIds, id]

    setSelectedIds(newIds)
    updateAddons(
      allAddons.filter((a: AddonI): boolean => newIds.includes(a.id)),
    )
  }

  const dynamicClass = (selected: boolean): string => {
    const combinedClass = selected ? [classes.selected] : []
    combinedClass.push(classes.button)

    return combinedClass.join(' ')
  }

  if (isLoading || !allAddons) {
    return <div />
  }

  return (
    <div className={className}>
      <ul className={classes.addons}>
        {allAddons?.map((addon) => (
          <Tooltip key={addon.id} title={addon.name}>
            <li
              className={dynamicClass(selectedIds.includes(addon.id))}
              data-id={addon.id}
              onClick={click}
            >
              <img
                className={classes.image}
                data-id={addon.id}
                src={`https://${addon.icon_image_url}`}
              />
            </li>
          </Tooltip>
        ))}
      </ul>
    </div>
  )
}

export default AddonSelector
