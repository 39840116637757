import * as api from '../utils/api'

import {
  setTodaysInventory,
  updateInventoryForProduct,
} from '../store/actions/todays-inventory'

export const fetchInventory = (dispatch) => async (storeId) => {
  console.log(`Fetching today's inventory for store ${storeId}`)
  const { data } = await api.GET(`/p4/stores/${storeId}/inventory`)
  return dispatch(setTodaysInventory(storeId, data))
}

export const adjustInventory = (dispatch) => async (
  storeId,
  productId,
  amount,
) => {
  return updateInventory(dispatch)(storeId, productId, {
    adjust_quantity: amount,
  })
}

export const markDoNotSell = (dispatch) => async (
  storeId,
  productId,
  doNotSell,
) => {
  return updateInventory(dispatch)(storeId, productId, {
    do_not_sell: doNotSell,
  })
}

export const setQuantity = (dispatch) => async (
  storeId,
  productId,
  quantity,
) => {
  return updateInventory(dispatch)(storeId, productId, {
    quantity_available: quantity,
  })
}

export const updateInventory = (dispatch) => async (
  storeId,
  productId,
  data,
) => {
  console.log(`Updating inventory with data ${JSON.stringify(data)}`)
  const { data: inventory } = await api.PATCH(
    `/p4/stores/${storeId}/inventory/${productId}`,
    data,
  )
  return dispatch(updateInventoryForProduct(storeId, productId, inventory))
}
