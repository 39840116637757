import { PromotionTypesStateType } from '@types'
import { arrayToObject } from '@utils/functional'
import { createReducer } from '@reduxjs/toolkit'
import { getPromotionTypesSuccess } from '@store/actions/promotion-types'

const initialState = {
  all: {},
} as PromotionTypesStateType

const promotionTypesReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    getPromotionTypesSuccess,
    (state, { payload: promotionTypes }) => {
      state.all = arrayToObject('type', promotionTypes)
    },
  )
})

export default promotionTypesReducer
