import Form from '@shared/form'
import FormInput from '@shared/form-input'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import hasPerms, { hasAnyRole } from '@utils/has-permission'
import remove from 'lodash.remove'
import uniq from 'lodash.uniq'
import useAllActions from '@hooks/use-all-actions'
import useSelector from '@hooks/use-selector'
import { AppDispatch, EmployeeFormType } from '@types'
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import { callIf } from '@utils/functional'
import {
  createEmployee,
  getEmployee,
  saveEmployee,
} from '@store/actions/employees'
import { makeStyles } from 'tss-react/mui'
import {
  permissionListAlpha,
  rolesListAlpha,
  selectEmployeeById,
  storesListAlpha,
} from '@store/selectors'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

const useSubmitUpdateEmployee = (
  employee: EmployeeFormType,
  isNewUser: boolean,
) => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const saveFn = isNewUser ? createEmployee : saveEmployee

  return async () => {
    const updatedEmployee = await dispatch(saveFn(employee))

    navigate(`/employees/${updatedEmployee.id}`)
  }
}

const useStyles = makeStyles()((theme) => ({
  submitButton: {
    marginTop: theme.spacing(3),
    color: 'white',
  },
  section: {
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  block: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    width: 'auto',
  },
  label: { cursor: 'pointer' },
  stores: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
}))

type Props = {
  className: any
  title?: string
  hideStoresAndPermissions: boolean
}

const newEmployee = {
  name: '',
  email: '',
  phone_number: '',
  role_ids: [],
  permission_ids: [],
  photo_url: null,
  store_ids: [],
  manager_approval_code: '',
} as EmployeeFormType

const EmployeeForm = ({
  className,
  hideStoresAndPermissions,
}: Props): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const { id } = useParams<{ id: string }>()
  const { classes } = useStyles()
  const canUpdateUsersRolesPermissions = hasPerms('roles:update')

  const isNewUser = id === undefined

  // TODO: selector should not be returning a different object on re-render
  const initialEmployee = useSelector((state) => selectEmployeeById(state, id))
  const roles = useSelector(rolesListAlpha)
  const permissions = useSelector(permissionListAlpha)
  const stores = useSelector(storesListAlpha)
  const currentUserId = useSelector((state) => state.CurrentUser.user.id)

  const { setFlashMessage, getRoles, getStores } = useAllActions()

  const [employee, setEmployee] = useState<EmployeeFormType>(
    initialEmployee || newEmployee,
  )
  const [rolePermIds, setRolePermIds] = useState<number[]>([])

  const [isLoaded, setIsLoaded] = useState(false)

  const employeeRoleIds = useMemo(() => employee.role_ids, [employee])

  const submitUpdateEmployee = useSubmitUpdateEmployee(employee, isNewUser)

  const canUpdateManagerApprovalCode =
    (employee?.id && currentUserId == employee?.id) ||
    hasAnyRole(['store-lead', 'area-lead'])

  // updates default role permission ids
  useEffect(() => {
    if (roles.length === 0 || !roles) return

    const permIds = employeeRoleIds.map((roleId: number) => {
      return roles.find((role) => role.id === roleId)?.permission_ids
    })

    setRolePermIds(permIds.flat())
  }, [employeeRoleIds, roles])

  useEffect(() => {
    if (initialEmployee && !isNewUser) {
      setEmployee(initialEmployee)
    }
  }, [initialEmployee])

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        callIf(
          !isNewUser,
          async () => await dispatch(getEmployee(id as string)),
        ),
        getRoles(),
        getStores(),
      ])
      setIsLoaded(true)
    }

    loadData()
  }, [id])

  if (!isLoaded) return <CircularProgress />

  const updateEmployee = (value, e) => {
    const name = e.target.name

    setEmployee({ ...employee, [name]: value })
  }

  const updateManagerApprovalCode = (value, e) => {
    value = value.trim().slice(0, 6)

    updateEmployee(value, e)
  }

  const togglePermission = (e, value: boolean) => {
    const permId = parseInt(e.target.name, 10)
    const permIds = [...employee.permission_ids]

    if (value) {
      permIds.push(permId)
    } else {
      remove(permIds, (i) => i === permId)
    }

    setEmployee({ ...employee, permission_ids: uniq(permIds) })
  }

  const toggleRole = (e, value: boolean) => {
    const roleId = parseInt(e.target.name, 10)
    const roleIds = [...employee.role_ids]

    if (value) {
      roleIds.push(roleId)
    } else {
      remove(roleIds, (i) => i === roleId)
    }

    setEmployee({ ...employee, role_ids: uniq(roleIds) })
  }

  const toggleStore = (e, value: boolean) => {
    const storeId = parseInt(e.target.name, 10)
    const storeIds = [...employee.store_ids]

    if (value) {
      storeIds.push(storeId)
    } else {
      remove(storeIds, (i) => i === storeId)
    }

    setEmployee({ ...employee, store_ids: uniq(storeIds) })
  }

  const handleSubmit = async () => {
    submitUpdateEmployee()
  }

  const allStoresPermission = () => {
    return permissions.find((p) => p.permission == 'stores:update-all')
  }
  const hasAllStoresPermission = employee.permission_ids.includes(
    allStoresPermission()?.id,
  )

  return (
    <Form
      className={className}
      title={isNewUser ? 'Create Employee' : 'Edit Employee'}
    >
      <FormInput
        name='name'
        onChangeText={updateEmployee}
        title='Name'
        value={employee.name}
      />
      <FormInput
        name='email'
        onChangeText={updateEmployee}
        title='Email'
        value={employee.email || ''}
      />
      <FormInput
        inputProps={{
          type: 'number',
        }}
        name='phone_number'
        onChangeText={updateEmployee}
        title='Phone Number'
        value={employee.phone_number || ''}
      />
      {canUpdateManagerApprovalCode && (
        <FormInput
          name='manager_approval_code'
          onChangeText={updateManagerApprovalCode}
          title='Manager Approval Code (6 Characters)'
          value={employee.manager_approval_code ?? ''}
        />
      )}

      <div className={classes.section}>
        <div className={classes.block}>
          <Typography variant='h6'>Roles</Typography>
          {roles.map((role) => {
            return (
              <div key={`edit_role_${role.id}`}>
                <Switch
                  checked={employee.role_ids.includes(role.id)}
                  disabled={
                    !canUpdateUsersRolesPermissions || role.role === 'barista'
                  }
                  id={`edit_role_${role.id}`}
                  name={role.id.toString()}
                  onChange={toggleRole}
                />
                <label
                  className={classes.label}
                  htmlFor={`edit_role_${role.id}`}
                >
                  {role.role}
                </label>
              </div>
            )
          })}
        </div>
        {!hideStoresAndPermissions && (
          <>
            <div className={classes.block}>
              <Typography variant='h6'>Permissions</Typography>
              <List>
                {permissions.map((permission) => {
                  const defaultPerm = rolePermIds.includes(permission.id)
                  const checked =
                    defaultPerm ||
                    employee.permission_ids.includes(permission.id)
                  return (
                    <ListItem key={`edit_permission_${permission.id}`}>
                      <Switch
                        checked={checked}
                        disabled={
                          !canUpdateUsersRolesPermissions || defaultPerm
                        }
                        id={`edit_permission_${permission.id}`}
                        name={permission.id.toString()}
                        onChange={togglePermission}
                      />
                      <Tooltip title={permission.description}>
                        <label
                          className={classes.label}
                          htmlFor={`edit_permission_${permission.id}`}
                        >
                          {permission.permission}
                        </label>
                      </Tooltip>
                    </ListItem>
                  )
                })}
              </List>
            </div>
            <div className={classes.block}>
              <Typography variant='h6'>Stores</Typography>
              <List className={classes.stores}>
                <ListItem key='edit_store_all'>
                  <Switch
                    checked={hasAllStoresPermission}
                    disabled={!canUpdateUsersRolesPermissions}
                    id='edit_store_all'
                    name={allStoresPermission()?.id.toString()}
                    onChange={togglePermission}
                  />
                  <Tooltip title='Apply users permissions to all stores'>
                    <label className={classes.label} htmlFor='edit_store_all'>
                      All Stores
                    </label>
                  </Tooltip>
                </ListItem>
                {stores.map((store) => {
                  return (
                    <ListItem key={`edit_store_${store.id}`}>
                      <Switch
                        checked={
                          hasAllStoresPermission ||
                          employee.store_ids?.includes(store.id)
                        }
                        disabled={
                          !canUpdateUsersRolesPermissions ||
                          hasAllStoresPermission
                        }
                        id={`edit_store_${store.id}`}
                        name={store?.id.toString()}
                        onChange={toggleStore}
                      />
                      <label
                        className={classes.label}
                        htmlFor={`edit_store_${store.id}`}
                      >
                        {store.name}
                      </label>
                    </ListItem>
                  )
                })}
              </List>
            </div>
          </>
        )}
      </div>
      <Button
        className={classes.submitButton}
        color='secondary'
        onClick={async () => {
          try {
            handleSubmit()
          } catch (error) {
            setFlashMessage(error, 'error')
          }
        }}
        variant='contained'
      >
        Submit
      </Button>
    </Form>
  )
}

export default EmployeeForm
