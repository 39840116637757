import ProductTable from '@shared/tables/product-table'
import React, { ReactElement } from 'react'
import hasPerms from '@utils/has-permission'
import { makeStyles } from 'tss-react/mui'
import { useNavigate } from 'react-router-dom'

interface Action {
  icon: string
  isFreeAction: boolean
  tooltip: string
  onClick(): any
}

const useStyles = makeStyles()((theme) => ({
  jumpLink: {
    display: 'block',
    marginBottom: theme.spacing(2),
    textAlign: 'right',
  },
  archivedContainer: {
    paddingTop: theme.spacing(10),
  },
}))

const Products = (): ReactElement => {
  const navigate = useNavigate()
  const { classes } = useStyles()

  const actions: Action[] = hasPerms('products:update')
    ? [
        {
          icon: 'add',
          isFreeAction: true,
          tooltip: 'Create Product',
          onClick: () => navigate('/products/create-product'),
        },
      ]
    : []

  return (
    <div>
      <a className={classes.jumpLink} href='#archived'>
        Archived Products
      </a>
      <ProductTable
        onRowClick={(_: any, rowData: any) =>
          navigate(`/products/${rowData.id}`)
        }
        tableActions={actions}
      />
      <div className={classes.archivedContainer} id='archived'>
        <ProductTable
          archived
          onRowClick={(_: any, rowData: any) =>
            navigate(`/products/${rowData.id}`)
          }
          tableActions={[]}
          title={'Archived Products'}
        />
      </div>
    </div>
  )
}

export default Products
