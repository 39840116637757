import MaterialTable from '@material-table/core'
import ProfileWidget from '@shared/profile-widget'
import React, { useEffect } from 'react'
import remove from 'lodash.remove'
import uniq from 'lodash.uniq'
import {
  CircularProgress,
  Paper,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  permissionListAlpha,
  selectEmployees,
  selectRoleById,
} from '@store/selectors'
import { useAllActions, useSelector } from '@hooks'
import { useNavigate, useParams } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  section: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  permissions: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    width: '430px',
  },
  employees: {
    width: '100%',
  },
  label: { cursor: 'pointer' },
}))

function Role() {
  const { id } = useParams()
  const theme = useTheme()
  const { classes } = useStyles()
  const { getRole, setRole, saveRole, getFilteredEmployees, setFlashMessage } =
    useAllActions()
  const navigate = useNavigate()

  const role = useSelector((state) => selectRoleById(state, id))
  const permissions = useSelector(permissionListAlpha)
  const employees = useSelector(selectEmployees)

  useEffect(() => {
    getRole(id)
  }, [])

  const isBarista = role?.role === 'barista'

  useEffect(() => {
    if (role && !isBarista) {
      getFilteredEmployees({ role_ids: role.id })
    }
  }, [role])

  if (!role || !permissions || (!employees && !isBarista))
    return <CircularProgress />

  const togglePermission = (e, value) => {
    const permId = parseInt(e.target.name, 10)
    const permIds = [...role.permission_ids]

    if (value) {
      permIds.push(permId)
    } else {
      remove(permIds, (i) => i === permId)
    }

    setRole({ ...role, permission_ids: uniq(permIds) })
  }

  const employeesWithCurrentRole = () => {
    return Object.values(employees || []).filter((employee) => {
      return employee.roles?.includes(role.role)
    })
  }

  const updateRole = () => {
    saveRole(role)
      .then(() => setFlashMessage('Role Updated', 'success'))
      .catch((error) => setFlashMessage(error.message, 'error'))
  }

  const disabled = role.role === 'owner'

  return (
    <>
      <ProfileWidget
        buttons={[
          {
            title: 'Save Role',
            onClick: updateRole,
            color: theme.palette.primary.main,
          },
        ]}
        title={role.role}
      />
      <div className={classes.section}>
        <Paper className={classes.permissions}>
          <Typography variant='h6'>Permissions</Typography>
          {permissions.map((permission) => {
            return (
              <div key={permission.id}>
                <Switch
                  checked={role.permission_ids.includes(permission.id)}
                  disabled={disabled}
                  id={permission.id.toString()}
                  name={permission.id.toString()}
                  onChange={togglePermission}
                />
                <Tooltip title={permission.description}>
                  <label
                    className={classes.label}
                    htmlFor={permission.id.toString()}
                  >
                    {permission.permission}
                  </label>
                </Tooltip>
              </div>
            )
          })}
        </Paper>
        {!isBarista && (
          <div className={classes.employees}>
            <MaterialTable
              columns={[
                { title: 'Name', field: 'name', defaultSort: 'asc' },
                { title: 'Email', field: 'email' },
              ]}
              data={employeesWithCurrentRole()}
              onRowClick={(_, row) => {
                navigate(`/employees/${row.id}`)
              }}
              options={{
                actionsColumnIndex: 4,
                paging: true,
                search: true,
                sorting: true,
              }}
              title='Employees'
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Role
