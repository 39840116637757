import {
  selectCurrentPermissionNames,
  selectCurrentRoles,
  selectCurrentStoreId,
} from '@store/selectors'
import { useSelector } from '@hooks'

export const renderIfPermitted = (element, neededPermission) => {
  if (neededPermission && !hasPerms(neededPermission)) {
    return null
  }

  return element
}

export const hasRole = (neededRole) => {
  return hasAnyRole([neededRole])
}

export const hasAnyRole = (desiredRoles) => {
  const roles = useSelector(selectCurrentRoles)

  if (roles?.includes('admin') || roles?.includes('owner')) return true

  return desiredRoles.some((role) => roles?.includes(role))
}

export const hasPerms = (neededPermission) => {
  const permissions = useSelector(selectCurrentPermissionNames)

  return checkPerms(permissions, neededPermission)
}

export const hasPermsAtStore = (neededPermission, storeId) => {
  const permissions = useSelector(selectCurrentPermissionNames)
  const storeIds = useSelector(selectCurrentStoreId)

  return checkPermsAtStore(permissions, neededPermission, storeIds, storeId)
}

export const checkPerms = (permissions, neededPermission) => {
  return permissions?.includes(neededPermission)
}

export const checkPermsAtStore = (
  permissions,
  neededPermission,
  storeIds,
  storeId,
) => {
  return (
    permissions?.includes(neededPermission) &&
    (permissions?.includes('stores:update-all') ||
      storeIds?.includes(parseInt(storeId, 10)))
  )
}

export default hasPerms
