import '../../utils/array-utils'

import { SET_PRODUCT_CATEGORIES } from '../action-list'

/**
 * Mapping of ID to product category. Each product category has the body:
 *
 * {
 *  id: number,
 *  name: string
 * }
 */
const initialState = {}

export default (state = initialState, action) => {
  const { categories } = action

  switch (action.type) {
    case SET_PRODUCT_CATEGORIES:
      console.log(`Redux: Adding ${categories.length} categories to store.`)
      return categories
    default:
      return state
  }
}
