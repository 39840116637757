import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'

import CreateProductStoreDialog from '@shared/dialogs/create-product-store-dialog'
import MaterialTable, { MTableToolbar } from '@material-table/core'
import VendorProductsSelect from '@components/shared/vendor-products-select'
import { Button, Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import {
  createProductStore,
  removeProductAtStore,
  setPriceForProductAtStore,
} from '@services/product-service'
import { fetchProductsStoresForStoreId } from '@services/store-service'
import { fetchVendors } from '@services/vendor-service'

import AddDefaultProducts from './add-default-products'
import EmptyMenu from './empty-menu'
import PriceLookup from '@components/pages/products/products-stores/price-lookup'
import { LocalAtm } from '@mui/icons-material'
import { getAllVendorProducts } from '@store/actions/vendor-products'
import { getProductsForStore } from '@store/selectors/products-stores'
import { getVendorRegions } from '@store/actions/vendor-regions'
import { selectVendorProducts } from '@store/selectors/vendor-products'
import { setFlashMessage } from '@store/reducers/flash-message/actions'
import { showDialog as showDialogAction } from '@store/reducers/dialog/actions'

const useStyles = makeStyles()(() => ({
  row: {
    display: 'flex',
    paddingRight: 8,
    alignItems: 'center',
  },
}))

const mapStateToProps = (state, ownProps) => {
  return {
    products: getProductsForStore(state, ownProps.storeId),
    vendors: state.Vendors,
    vendorProducts: selectVendorProducts(state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    createProductStore: createProductStore(dispatch),
    fetchProductsStores: () =>
      fetchProductsStoresForStoreId(dispatch)(ownProps.storeId),
    fetchVendors: fetchVendors(dispatch),
    removeProductAtStore: (productId) =>
      removeProductAtStore(dispatch)(ownProps.storeId, productId),
    setProductStoreForProduct: (productId, data) =>
      setPriceForProductAtStore(dispatch)(ownProps.storeId, productId, data),
    setFlashMessage: (message, variant) =>
      dispatch(setFlashMessage(message, variant)),
    getVendorProducts: () => dispatch(getAllVendorProducts()),
    getVendorRegions: () => dispatch(getVendorRegions()),
  },
})

function MenuWidget(props) {
  const { actions } = props
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [createDialogLoading, setCreateDialogLoading] = useState(false)
  const [hasFetchedData, setHasFetchedData] = useState(false)
  const { classes: styles } = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
    if (!hasFetchedData) {
      setHasFetchedData(true)

      Promise.all([
        actions.fetchProductsStores(),
        actions.fetchVendors(),
        actions.getVendorProducts(),
        actions.getVendorRegions(),
      ]).catch((error) => actions.setFlashMessage(error.message, 'error'))
    }
  }, [hasFetchedData])

  return (
    <div className={props.className}>
      <CreateProductStoreDialog
        hideProduct={false}
        hideStore
        initialData={{
          storeId: props.storeId,
        }}
        loading={createDialogLoading ? true : undefined}
        onClose={() => setCreateDialogOpen(false)}
        onConfirm={(data) => {
          setCreateDialogLoading(true)
          actions
            .createProductStore(data)
            .then(() => {
              setCreateDialogLoading(false)
              setCreateDialogOpen(false)
              actions.setFlashMessage('Created Product at Store', 'success')
            })
            .catch((err) => {
              setCreateDialogLoading(false)
              actions.setFlashMessage(err.message, 'error')
            })
        }}
        open={createDialogOpen}
      />
      {props.products.length === 0 ? (
        <Paper>
          {props.editable && (
            <EmptyMenu
              onAddProduct={() => setCreateDialogOpen(true)}
              storeId={props.storeId}
            />
          )}
        </Paper>
      ) : (
        <MaterialTable
          actions={
            props.editable
              ? [
                  {
                    icon: 'add',
                    tooltip: 'Add Product',
                    isFreeAction: true,
                    onClick: () => setCreateDialogOpen(true),
                  },
                ]
              : undefined
          }
          columns={[
            {
              title: 'Product',
              field: 'product.name',
              editable: 'never',
            },
            {
              title: 'Type',
              field: 'product.product_type',
              editable: 'never',
            },
            {
              title: 'Vendor / Region',
              field: 'productStore.vendor_product_id',
              editComponent: function renderVendorProductSelect({
                onChange,
                value,
                rowData: {
                  product: { id },
                },
              }) {
                return (
                  <VendorProductsSelect
                    id={id}
                    onChange={onChange}
                    value={value || ''}
                  />
                )
              },
              render: function renderVendor(rowData) {
                const vendorProductId = rowData.productStore.vendor_product_id

                if (vendorProductId === null) return null

                const vendorProduct = props.vendorProducts[vendorProductId]

                return `${vendorProduct.vendor.name} / ${vendorProduct.vendor_region.name}`
              },
            },
            {
              title: 'SKU',
              field: 'product.square_sku',
              editable: 'never',
            },
            {
              title: 'Prices (cents)',
              field: 'productStore.price',
              emptyValue: function renderEmptyValue() {
                return <em>N/A</em>
              },
              render: function render({
                productStore: { store_id, product_id, price, total_prices },
              }) {
                return (
                  <>
                    <div>Base Price: {price}</div>
                    {total_prices > 1 && props.editable && (
                      <Button
                        onClick={() => {
                          dispatch(
                            showDialogAction(() => (
                              <PriceLookup
                                productId={product_id}
                                storeId={store_id}
                              />
                            )),
                          )
                        }}
                        startIcon={<LocalAtm />}
                      >
                        Update {total_prices} Prices
                      </Button>
                    )}
                  </>
                )
              },
            },
          ]}
          components={{
            Toolbar: function renderToolbar(toolbarProps) {
              return (
                <div className={styles.row}>
                  <MTableToolbar {...toolbarProps} />
                  {props.editable && (
                    <AddDefaultProducts storeId={props.storeId} />
                  )}
                </div>
              )
            },
          }}
          data={props.products}
          editable={
            props.editable
              ? {
                  onRowUpdate: async (newData) => {
                    const newPrice = newData.productStore.price
                    if (isNaN(newPrice)) {
                      return
                    } else {
                      const vendorProductId =
                        newData.productStore.vendor_product_id
                      return actions
                        .setProductStoreForProduct(newData.product.id, {
                          price: newPrice,
                          vendor_product_id:
                            vendorProductId === -1 ? null : vendorProductId,
                        })
                        .then(() =>
                          actions.setFlashMessage(
                            'Menu item updated',
                            'success',
                          ),
                        )
                        .catch((e) =>
                          actions.setFlashMessage(e.message, 'error'),
                        )
                    }
                  },
                  onRowDelete: async (newData) => {
                    return actions
                      .removeProductAtStore(newData.product.id)
                      .then(() =>
                        actions.setFlashMessage('Product removed', 'success'),
                      )
                      .catch((e) => actions.setFlashMessage(e.message, 'error'))
                  },
                }
              : undefined
          }
          options={{
            actionsColumnIndex: 5,
          }}
          title='Menu'
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuWidget)
