import Loader from '@components/shared/loader'
import MaterialTable from '@material-table/core'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { selectCustomContents } from '@store/selectors'
import { useAllActions, useSelector } from '@hooks'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  createField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))

function CustomContents() {
  const { classes } = useStyles()
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [createDialogLoading, setCreateDialogLoading] = useState(false)
  const [newTitle, setNewTitle] = useState('')
  const { getCustomContents, createCustomContent, saveCustomContent } =
    useAllActions()
  const contents = useSelector(selectCustomContents)
  const navigate = useNavigate()

  const archiveContent = (e, data) => {
    saveCustomContent({ ...data, archived_at: true })
  }

  const newCustomContent = async (title) => {
    await createCustomContent({ body: title, title })

    setCreateDialogLoading(false)
    setCreateDialogOpen(false)
    setNewTitle('')
  }

  useEffect(() => {
    getCustomContents()
  }, [])

  // if (!Object.keys(contents).length) return <Loader />

  return (
    <div>
      <Dialog
        aria-labelledby='dialog-title'
        onClose={() => setCreateDialogOpen(false)}
        open={createDialogOpen}
      >
        <Loader visible={createDialogLoading} />
        <DialogTitle id='dialog-title'>Create Custom Content</DialogTitle>
        <TextField
          className={classes.createField}
          label='Title'
          onChange={(e) => setNewTitle(e.target.value)}
          value={newTitle}
        />
        <DialogActions>
          <Button onClick={() => setCreateDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setCreateDialogLoading(true)
              newCustomContent(newTitle)
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialTable
        actions={[
          {
            title: 'Create Custom Content',
            icon: 'add',
            isFreeAction: true,
            onClick: () => setCreateDialogOpen(true),
          },
          {
            icon: 'delete',
            tooltip: 'Archive',
            onClick: archiveContent,
          },
        ]}
        columns={[
          { title: 'Title', field: 'title' },
          { title: 'Created At', type: 'datetime', field: 'created_at' },
          {
            title: 'Updated At',
            type: 'datetime',
            field: 'updated_at',
            defaultSort: 'asc',
          },
        ]}
        data={Object.values(contents)}
        onRowClick={(_, rowData) => navigate(`/custom-content/${rowData.id}`)}
        options={{
          actionsColumnIndex: 99,
          paging: false,
        }}
        title='Custom Content'
      />
    </div>
  )
}

export default CustomContents
