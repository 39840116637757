import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import {
  CircularProgress,
  Divider,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import { Store as StoreIcon } from '@mui/icons-material'
import { connect } from 'react-redux'
import { makeStyles } from 'tss-react/mui'

import AvatarInfo from './avatar-info'
import Table from '../../shared/deprecated/table'
import Toolbar from '../../shared/toolbar'

import ErrorBoundary from '@shared/error-boundary'
import HistoryTables from './history-tables'
import OrderDetails from './order-details'
import Refunds from './refunds'
import withRouter from '@utils/legacy/with-router'
import { fetchHistory } from '@store/actions/orders'
import { fetchOrder } from '@services/order-service'
import { fetchStore } from '@services/store-service'
import { formatCurrency } from '@utils/number-utils'
import { formatDefault, formatTime } from '@utils/date-utils'
import { selectOrderHistory } from '@store/selectors/orders'

const useStyles = makeStyles()((theme) => ({
  topContent: {
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
  },
  basicInfo: {
    flex: 3,
    marginRight: theme.spacing(2),
  },
  rightInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  orderInfoTitle: {
    flex: 1,
  },
  rightInfoItem: {
    flex: 1,
  },
  userInfo: {
    marginBottom: theme.spacing(2),
  },
  lineItem: {
    textAlign: 'right',
  },
  spaced: {
    marginTop: theme.spacing(2),
  },
  json: {
    fontSize: '1.2em',
  },
}))

const mapStateToProps = (state, ownProps) => {
  const { orderId } = ownProps.match.params
  const order = state.Orders.orders[orderId]
  const history = selectOrderHistory(state, orderId)

  let store = undefined
  if (order != null) {
    store = state.Stores.stores[order.store_id]
  }

  return { order, store, history, orderId }
}

const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  actions: {
    fetchOrder: () => fetchOrder(dispatch)(params.userId, params.orderId),
    fetchHistory: () => dispatch(fetchHistory(params.orderId)),
    fetchStore: fetchStore(dispatch),
  },
})

function Order(props) {
  const { order, actions, store, history, orderId } = props
  const { classes, cx } = useStyles()

  useEffect(() => {
    if (orderId) {
      actions.fetchHistory()
    }
  }, [orderId])

  if (order == null) {
    // Fetch the single order
    actions.fetchOrder()
    return <CircularProgress />
  }

  if (store == null) {
    actions.fetchStore(order.store_id)
  }

  const orderInfo = [
    ['Status', order.status],
    ['Price', formatCurrency(order.price)],
    ['Accessible Pickup', order.requested_accessible_pickup.toString()],
    ['Placement Time', formatDefault(order.placement_time)],
    ['Pickup Time', formatDefault(order.pickup_time)],
    ['Updated Time', formatDefault(order.updated_at)],
    ['Curbside', order.is_curbside.toString()],
    ['Curbside Vehicle Description', order.vehicle_description],
    ['Curbside Status', order.curbside_status],
    ['Curbside ETA', `${order.eta_direction || ''} ${formatTime(order.eta)}`],
    [
      'Curbside Arrived At',
      order.arrived_at ? formatDefault(order.arrived_at) : null,
    ],
    [
      'Curbside Left At',
      order.checked_out_at ? formatDefault(order.checked_out_at) : null,
    ],
  ]

  return (
    <div>
      <div className={classes.topContent}>
        <Paper className={classes.basicInfo}>
          <Toolbar title={`Order ${order.id}`} />
          <Divider />
          {orderInfo.map((info, i) => {
            return (
              <ListItem key={i}>
                <ListItemText className={classes.orderInfoTitle}>
                  {info[0]}
                </ListItemText>
                <ListItemText>{info[1]}</ListItemText>
              </ListItem>
            )
          })}
        </Paper>
        <div className={classes.rightInfo}>
          <AvatarInfo
            className={cx(classes.rightInfoItem, classes.userInfo)}
            name={order.name}
            route={`/users/${order.user_id}`}
            title='Customer'
          />
          <AvatarInfo
            className={cx(classes.rightInfoItem, classes.userInfo)}
            icon={<StoreIcon />}
            name={store ? store.name : 'Store'}
            route={store && `/stores/${store.id}`}
            title='Store'
          />
          <AvatarInfo
            avatarSrc={order.barista && order.barista.photo_url}
            className={classes.rightInfoItem}
            name={order.barista ? order.barista.name : 'No Barista'}
            title='Barista'
          />
        </div>
      </div>
      <Paper>
        <OrderDetails order={order} />
      </Paper>
      {order.refunds.length ? (
        <Refunds
          className={classes.spaced}
          refunds={order.refunds}
          title={'Refund Action(s)'}
        />
      ) : null}
      <Paper className={classes.spaced}>
        <Table
          data={order.transactions}
          fields={[
            {
              field: 'created_at',
              transform: (created_at) => formatDefault(created_at),
            },
            {
              field: 'amount',
              transform: (amount) => formatCurrency(amount),
            },
          ]}
          headers={['Date/Time', 'Amount']}
          title={'Transactions'}
        />
      </Paper>

      <ErrorBoundary>
        <HistoryTables history={history} />
      </ErrorBoundary>
    </div>
  )
}

Order.propTypes = {
  history: PropTypes.shape({
    cart: PropTypes.arrayOf(
      PropTypes.shape({
        updated_at: PropTypes.string,
        user: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    order: PropTypes.arrayOf(
      PropTypes.shape({
        updated_at: PropTypes.string,
        user: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
  }),
  order: PropTypes.shape({
    price: PropTypes.number,
    id: PropTypes.number,
    store_id: PropTypes.number,
    status: PropTypes.string,
    requested_accessible_pickup: PropTypes.bool,
    placement_time: PropTypes.string,
    pickup_time: PropTypes.string,
    updated_at: PropTypes.string,
    is_curbside: PropTypes.bool,
    vehicle_description: PropTypes.string,
    curbside_status: PropTypes.string,
    eta_direction: PropTypes.string,
    eta: PropTypes.string,
    arrived_at: PropTypes.string,
    checked_out_at: PropTypes.string,
    name: PropTypes.string,
    user_id: PropTypes.number,
    barista: PropTypes.shape({
      photo_url: PropTypes.string,
      name: PropTypes.string,
    }),
    transactions: PropTypes.array,
    refunds: PropTypes.array,
  }),
  actions: PropTypes.shape({
    fetchHistory: PropTypes.func,
    fetchOrder: PropTypes.func,
    fetchStore: PropTypes.func,
  }),
  store: PropTypes.object,
  orderId: PropTypes.string,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order))
