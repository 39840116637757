import { createReducer } from '@reduxjs/toolkit'

import { SurchargeType, TaxRateType } from '@types'
import {
  setSurcharges,
  setTaxRates,
  updatedTaxRates,
} from '@store/actions/tax-rates'

import groupBy from 'lodash.groupby'

type State = {
  TaxRates: Record<number, TaxRateType[]>
  Surcharges: SurchargeType[]
}

const initialState = {
  TaxRates: {},
  Surcharges: [],
} as State

const taxRatesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setTaxRates, (state, action) => {
      const taxRatesAtStores = groupBy(action.payload, 'store_id')
      for (const storeId in taxRatesAtStores) {
        state.TaxRates[storeId] = taxRatesAtStores[storeId]
      }
    })
    .addCase(updatedTaxRates, (state, action) => {
      const taxRatesAtStores = groupBy(action.payload, 'store_id')
      for (const storeId in taxRatesAtStores) {
        state.TaxRates[storeId] = taxRatesAtStores[storeId]
      }
    })
    .addCase(setSurcharges, (state, action) => {
      state.Surcharges = action.payload
    })
})

export default taxRatesReducer
