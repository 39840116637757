import ErrorBoundary from '@components/shared/error-boundary'
import React, { ReactElement, useEffect } from 'react'
import Table, { ColumnsType } from '@components/shared/table'
import hasPerms from '@utils/has-permission'
import useSelector from '@hooks/use-selector'
import { Add } from '@mui/icons-material'
import { AppDispatch, TagType } from '@types'
import { Paper } from '@mui/material'
import { getTags } from '@store/actions/tags'
import { selectTags } from '@store/selectors/tags'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const columns: ColumnsType<TagType> = [
  { field: 'name', title: 'Name' },
  {
    field: 'is_allergen',
    title: 'Is Allergen',
    render: function renderIsAllergen(_, value) {
      return value ? 'Yes' : 'No'
    },
  },
]

const Tags = (): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const tags = useSelector(selectTags)
  const navigate = useNavigate()

  const editable = hasPerms('products:update')

  useEffect(() => {
    dispatch(getTags())
  }, [])

  return (
    <ErrorBoundary>
      <Paper>
        <Table
          columns={columns}
          data={tags}
          onRowClick={(row) => `/tags/${row.id}/edit`}
          paginated
          rowKey='id'
          title='Tags'
          toolbarButtons={
            editable
              ? [
                  {
                    title: 'Create Tag',
                    onClick: () => navigate('/tags/create'),
                    startIcon: <Add />,
                  },
                ]
              : []
          }
        />
      </Paper>
    </ErrorBoundary>
  )
}

export default Tags
