import { SET_CURRENTLY_DISPLAYED_USERS } from '../action-list'

/**
 * State is a list of user IDs of type number[]
 */
export default (state = [], action) => {
  const { users } = action

  switch (action.type) {
    case SET_CURRENTLY_DISPLAYED_USERS:
      console.log(
        `Redux: Setting CurrentlyDisplayedUsers to ${users.length} users`,
      )
      return users
    default:
      return state
  }
}
