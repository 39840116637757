import { SET_INITIAL_INVENTORY, UPDATE_INITIAL_INVENTORY } from '../action-list'

/**
 * Expects a initial inventories based on a list of (Ether) inventory objects
 * formatted:
 *
 * {
 *  day_of_week: string,
 *  initial_quantity: number,
 *  product_id: number,
 *  store_id: number
 * }
 */
export const updateInitialInventory = (inventory) => ({
  type: UPDATE_INITIAL_INVENTORY,
  inventory,
})

export const setInitialInventory = (inventory) => ({
  type: SET_INITIAL_INVENTORY,
  inventory,
})
