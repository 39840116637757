import { EmployeeType } from '@types'
import { arrayToObject } from '@utils/functional'
import {
  createEmployeeSuccess,
  deleteEmployee,
  saveEmployeeSuccess,
  setEmployee,
  setEmployees,
} from '@store/actions/employees'
import { createReducer } from '@reduxjs/toolkit'

type State = Record<string, EmployeeType>

const initialState = {} as State

const employeesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setEmployee, (state, action) => {
      state[action.payload.id] = action.payload
    })
    .addCase(saveEmployeeSuccess, (state, action) => {
      state[action.payload.id] = action.payload
    })
    .addCase(createEmployeeSuccess, (state, action) => {
      state[action.payload.id] = action.payload
    })
    .addCase(setEmployees, (state, action) => {
      const employeeObject = arrayToObject('id', action.payload)

      return { ...state, ...employeeObject }
    })
    .addCase(deleteEmployee, (state, action) => {
      delete state[action.payload.id]
    })
})

export default employeesReducer
