import PropTypes from 'prop-types'
import React from 'react'

import { Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    width: '100%',
  },
  image: {
    height: 180,
  },
}))

function FormImageInput(props) {
  const { classes } = useStyles()

  let url = props.imageUrl || ''
  if (url.startsWith('//')) {
    url = 'https:' + url
  }

  const inputId = Math.floor(Math.random() * Date.now())

  return (
    <div className={classes.container}>
      {url && (
        <div className={classes.imageContainer}>
          <img className={classes.image} src={url} />
        </div>
      )}
      <input
        accept='image/*'
        id={'image-upload-button' + inputId}
        onChange={(event) => {
          const file = event.target.files[0]
          const url = URL.createObjectURL(file)
          props.onChange({ url, name: file.name })
        }}
        style={{ display: 'none' }}
        type='file'
      />
      {props.isEditing && (
        <label htmlFor={'image-upload-button' + inputId}>
          <Button
            className={classes.control}
            component='span'
            variant='contained'
          >
            Upload Image
          </Button>
        </label>
      )}
    </div>
  )
}

FormImageInput.propTypes = {
  imageUrl: PropTypes.string,

  /**
   * Passes an object containing { name, url }
   */
  onChange: PropTypes.func,
  isEditing: PropTypes.bool,
}

FormImageInput.defaultProps = {
  isEditing: true,
}
export default FormImageInput
