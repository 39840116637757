import { CLEAR_FLASH_MESSAGE, SET_FLASH_MESSAGE } from '@store/action-list'

const initialState = {
  message: null,
  variant: 'success',
}

export default (
  state = initialState,
  action: { type: string; payload: { message: string; variant: string } },
): any => {
  const { type } = action

  switch (type) {
    case SET_FLASH_MESSAGE: {
      const { message, variant } = action.payload
      return {
        ...state,
        message,
        variant,
      }
    }
    case CLEAR_FLASH_MESSAGE: {
      return initialState
    }
    default:
      return state
  }
}
