import React, { useEffect, useState } from 'react'
import {
  Avatar,
  CircularProgress,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  Commute,
  Edit as EditIcon,
  PowerSettingsNew as PowerIcon,
} from '@mui/icons-material'
import { connect } from 'react-redux'
import { makeStyles } from 'tss-react/mui'

import HoursWidget from './hours-widget'
import InventoryWidget from './inventory-widget'
import MenuWidget from './menu-widget'
import OrderTable from '../../shared/tables/order-table'
import ProfileWidget from '../../shared/profile-widget'
import StoreForm from '../../shared/forms/store-form'
import TaxRates from './tax-rates'
import TodaysInventoryWidget from './todays-inventory-widget'

import Color from '../../../constants/color'

import { fetchAllProducts } from '../../../services/product-service'
import {
  fetchStore,
  fetchStoreOnline,
  setStoreOnline,
  updateStore,
} from '../../../services/store-service'

import AttributeValues from './attribute-values'
import ErrorBoundary from '@components/shared/error-boundary'
import PropTypes from 'prop-types'
import SimulateOrders from './simulate-orders'
import withRouter from '@utils/legacy/with-router'
import { checkPerms, checkPermsAtStore } from '@utils/has-permission'
import { fetchInventory as fetchInitialInventory } from '@services/initial-inventory-service'
import { fetchInventory } from '@services/todays-inventory-service'
import { format12hrFrom24hr } from '@utils/date-utils'
import { getOrdersByStore } from '@queries/orders'
import { selectCurrentPermissionNames } from '@store/selectors'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

const useStyles = makeStyles()((theme) => ({
  section: {
    marginBottom: theme.spacing(2),
  },
  powerIconOnline: {
    backgroundColor: Color.onlineGreen,
  },
  powerIconOffline: {
    backgroundColor: Color.offlineRed,
  },
  widgetRow: {
    display: 'flex',
    flex: 1,
  },
  widget: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  inventoryWidget: {
    marginTop: theme.spacing(2),
  },
  todaysInventoryWidget: {
    flex: 5,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  hoursWidget: {
    flex: 2,
  },
}))

function getStoreId(props) {
  return parseInt(props.match.params.storeId, 10)
}

const mapStateToProps = (state, ownProps) => ({
  store: state.Stores.stores[getStoreId(ownProps)],
  online: state.StoreOnline[getStoreId(ownProps)],
  permissions: selectCurrentPermissionNames(state),
  storeIds: state.CurrentUser.user.store_ids,
  storeId: getStoreId(ownProps),
})

const mapDispatchToProps = (dispatch, ownProps) => {
  const storeId = getStoreId(ownProps)

  return {
    actions: {
      fetchInitialInventory: () => fetchInitialInventory(dispatch)(storeId),
      fetchInventory: () => fetchInventory(dispatch)(storeId),
      fetchAllProducts: () => fetchAllProducts(dispatch)(),
      fetchStore: () => fetchStore(dispatch)(storeId),
      fetchStoreOnline: () => fetchStoreOnline(dispatch)(storeId),
      setStoreOnline: (online) => setStoreOnline(dispatch)(storeId, online),
      updateStore: (data) => updateStore(dispatch)(storeId, data),
      setFlashMessage: (message, variant) =>
        dispatch(setFlashMessage(message, variant)),
    },
  }
}

function Store(props) {
  const { actions, store, storeId } = props
  const { classes, cx } = useStyles()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

  const [hasFetchedData, setHasFetchedData] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const [orders, setOrders] = useState([])
  const [ordersCount, setOrdersCount] = useState(0)

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        actions.fetchAllProducts(),
        actions.fetchStore(),
        actions.fetchStoreOnline(),
        actions.fetchInitialInventory(),
        actions.fetchInventory(),
      ]).catch((e) => actions.setFlashMessage(e.message, 'error'))

      setHasFetchedData(true)
    }

    if (!hasFetchedData) {
      loadData()
    }
  }, [hasFetchedData])

  if (!hasFetchedData) {
    return <CircularProgress />
  }

  const fetchOrders = async (page, pageSize, begin = null, end = null) => {
    const { orders, total_results: ordersCount } = await getOrdersByStore(
      storeId,
      page,
      pageSize,
      begin,
      end,
    )
    setOrders(orders)
    setOrdersCount(ordersCount)
  }

  const toggleCurbside = () => {
    actions
      .updateStore({ ...store, curbside_online: !store.curbside_online })
      .then(() =>
        actions.setFlashMessage(
          'Store curbside online status updated.',
          'success',
        ),
      )
      .catch((err) => actions.setFlashMessage(err.message, 'error'))
  }

  const curbsideIconColor = () => {
    if (!store.curbside_enabled) {
      return 'disabled'
    } else if (store.curbside_online) {
      return 'secondary'
    } else if (!store.curbside_online) {
      return 'error'
    }
  }

  const buttons = () => {
    if (
      !checkPermsAtStore(
        props.permissions,
        'stores:update',
        props.storeIds,
        storeId,
      )
    )
      return []

    return [
      {
        title: 'Edit Store',
        icon: <EditIcon />,
        onClick: () => setIsEditing(!isEditing),
      },
      {
        title: `Take store ${props.online ? 'offline' : 'online'}`,
        icon: (
          <Avatar
            className={
              props.online ? classes.powerIconOnline : classes.powerIconOffline
            }
          >
            <PowerIcon className={classes.onlineIcon} />
          </Avatar>
        ),
        onClick: () => {
          actions
            .setStoreOnline(!props.online)
            .then(() =>
              actions.setFlashMessage(
                'Store online status updated.',
                'success',
              ),
            )
            .catch((err) => actions.setFlashMessage(err.message, 'error'))
        },
      },
      {
        disabled: !store.curbside_enabled,
        title: `Take store curbside ${
          store.curbside_online ? 'offline' : 'online'
        }`,
        icon: <Commute color={curbsideIconColor()} fontSize='large' />,
        onClick: toggleCurbside,
      },
    ]
  }

  return (
    <div>
      <ProfileWidget
        buttons={buttons()}
        imageUrl={store.image_url}
        subtitle1={store.address}
        subtitle2={`${store.phone}, ${
          store.email_address || 'Email address unavailable'
        }`}
        subtitle3={`Morning: Store Open, Afternoon: ${format12hrFrom24hr(
          store.afternoon_at,
        )}, Evening: ${format12hrFrom24hr(store.evening_at)}`}
        title={store.name}
      />
      {isEditing && (
        <div className={classes.section}>
          <StoreForm
            onSubmit={(store) => {
              actions
                .updateStore(store)
                .then(() => actions.setFlashMessage('Updated Store', 'success'))
                .catch((error) =>
                  actions.setFlashMessage(error.message, 'error'),
                )
            }}
            store={store}
            title='Edit Store'
          />
        </div>
      )}
      <div className={isSmallScreen && classes.widgetRow}>
        <ErrorBoundary>
          <TodaysInventoryWidget
            className={classes.todaysInventoryWidget}
            editable={checkPermsAtStore(
              props.permissions,
              'stores:update-inventory',
              props.storeIds,
              storeId,
            )}
            storeId={storeId}
          />
        </ErrorBoundary>
        <HoursWidget
          className={cx(classes.widget, classes.hoursWidget)}
          editable={checkPermsAtStore(
            props.permissions,
            'stores:update-hours',
            props.storeIds,
            storeId,
          )}
          storeId={storeId}
          storeTimeZone={store.time_zone}
        />
      </div>
      <ErrorBoundary>
        <AttributeValues
          className={classes.section}
          editable={checkPermsAtStore(
            props.permissions,
            'stores:update-inventory',
            props.storeIds,
            storeId,
          )}
          storeId={props.storeId}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <MenuWidget
          className={classes.widget}
          editable={checkPermsAtStore(
            props.permissions,
            'stores:update-menu',
            props.storeIds,
            storeId,
          )}
          storeId={storeId}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <InventoryWidget
          className={cx(classes.widget, classes.inventoryWidget)}
          editable={checkPermsAtStore(
            props.permissions,
            'stores:update-pars',
            props.storeIds,
            storeId,
          )}
          storeId={storeId}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <TaxRates
          className={cx(classes.widget, classes.taxRates)}
          editable={checkPerms(props.permissions, 'tax-rates:update')}
          storeId={storeId}
          title='Tax Rates and Surcharges'
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <Paper elevation={2}>
          <OrderTable
            fetchOrders={fetchOrders}
            includeCustomer
            orders={orders}
            ordersCount={ordersCount}
            routeForOrder={(id) => {
              return `/stores/${storeId}/orders/${id}`
            }}
          />
        </Paper>
      </ErrorBoundary>
      <ErrorBoundary>
        {ENV !== 'production' && <SimulateOrders storeId={storeId} />}
      </ErrorBoundary>
    </div>
  )
}

Store.propTypes = {
  storeIds: PropTypes.array,
  permissions: PropTypes.array,
  online: PropTypes.bool,
  actions: PropTypes.shape({
    fetchAllProducts: PropTypes.func,
    fetchStore: PropTypes.func,
    fetchStoreOnline: PropTypes.func,
    fetchInitialInventory: PropTypes.func,
    fetchInventory: PropTypes.func,
    setFlashMessage: PropTypes.func,
    updateStore: PropTypes.func,
    setStoreOnline: PropTypes.func,
  }),
  store: PropTypes.shape({
    curbside_online: PropTypes.bool,
    curbside_enabled: PropTypes.bool,
    image_url: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    email_address: PropTypes.string,
    name: PropTypes.string,
    permissions: PropTypes.array,
    time_zone: PropTypes.string,
  }),
  storeId: PropTypes.number,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Store))
