import OrderTable from '@shared/tables/order-table'
import ProfileWidget from '@shared/profile-widget'
import React, { useEffect, useState } from 'react'
import Tabs from '@shared/tabs'
import hasPerms, { hasAnyRole } from '@utils/has-permission'
import {
  CircularProgress,
  List,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import { formatPhoneNumber } from '@utils/number-utils'
import { getEmployee, uploadPhotoEmployee } from '@store/actions/employees'
import { getOrdersByUser } from '@queries/orders'
import { makeStyles } from 'tss-react/mui'
import {
  selectEmployeeById,
  selectPermissions,
  selectRoles,
  selectStores,
} from '@store/selectors'
import { useAllActions, useSelector } from '@hooks'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import CCPA from './ccpa'
import CroppedImageInput from '@components/shared/cropped-image-input'
import getImage from '@utils/get-image'

const useStyles = makeStyles()((theme) => ({
  section: {
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  block: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    width: 'auto',
  },
}))

const Employee = () => {
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const { id } = useParams()
  const theme = useTheme()
  const { getRoles, getStores } = useAllActions()
  const navigate = useNavigate()

  const employee = useSelector((state) => selectEmployeeById(state, id))
  const roles = useSelector(selectRoles)
  const permissions = useSelector(selectPermissions)
  const stores = useSelector(selectStores)

  const showCCPA = hasPerms('customers:manage-ccpa')
  const canUpdateUsers = hasPerms('users:update')
  const canEditPhoto = hasAnyRole(['store-lead', 'area-load'])

  const [orders, setOrders] = useState([])
  const [ordersCount, setOrdersCount] = useState(0)

  const fetchOrders = async (page, pageSize, begin = null, end = null) => {
    const { orders, total_results: ordersCount } = await getOrdersByUser(
      id,
      page,
      pageSize,
      begin,
      end,
    )
    setOrders(orders)
    setOrdersCount(ordersCount)
  }

  useEffect(() => {
    getRoles()
    dispatch(getEmployee(id))
    getStores()
  }, [])

  if (!permissions || !employee || !roles || !stores)
    return <CircularProgress />

  const pageTabs = []

  pageTabs.push({
    title: 'Orders',
    component: (
      <OrderTable
        count={ordersCount}
        emptyChild={<h2>No Orders Placed</h2>}
        fetchOrders={fetchOrders}
        includeStore
        orders={orders}
        ordersCount={ordersCount}
        routeForOrder={(orderId) => `/users/${id}/orders/${orderId}`}
      />
    ),
  })

  if (showCCPA) {
    pageTabs.push({
      title: 'CCPA',
      component: <CCPA user={employee} />,
    })
  }

  const employeeRoles = () => {
    const empRoles = employee.role_ids?.map((roleId) => {
      return roles[roleId]
    })

    return empRoles?.sort((a, b) => (a.role > b.role ? 1 : -1))
  }

  const employeeStores = () => {
    const empStores = employee.store_ids?.map((storeId) => {
      return stores[storeId]
    })

    return empStores?.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  const employeePermissions = () => {
    const empPerms = employee.permission_ids?.map((permId) => {
      return permissions[permId]
    })

    return empPerms?.sort((a, b) => (a.permission > b.permission ? 1 : -1))
  }
  const hasAllStoresPermission = !!Object.values(
    employeePermissions() || {},
  ).find((p) => p.permission == 'stores:update-all')

  const handlePhotoChange = async (url) => {
    const imageData = await getImage(url)

    await dispatch(uploadPhotoEmployee(employee.id, imageData))

    navigate(`/employees/${employee.id}`)
  }

  const empBody = (
    <div className={classes.section}>
      <div className={classes.block}>
        <CroppedImageInput
          desiredHeight={600}
          desiredWidth={600}
          imageUrl={employee.photo_url}
          isEditing={canEditPhoto}
          onChange={handlePhotoChange}
        />
      </div>
      <div className={classes.block}>
        <Typography variant='h6'>Roles</Typography>
        <List>
          {employeeRoles()?.map((role) => {
            return (
              role && (
                <ListItemText
                  key={`role_${role?.id || 0}`}
                  primary={role?.role}
                />
              )
            )
          })}
        </List>
      </div>
      <div className={classes.block}>
        <Typography variant='h6'>Permissions</Typography>
        <List>
          {employeePermissions()?.map((perm) => {
            return (
              perm && (
                <ListItemText
                  key={`permission_${perm?.id || Math.random(99)}`}
                  primary={perm?.permission}
                />
              )
            )
          })}
        </List>
      </div>
      <div className={classes.block}>
        <Typography variant='h6'>Stores</Typography>
        <List>
          {hasAllStoresPermission ? (
            <ListItemText key='store_all' primary='All Stores' />
          ) : (
            employeeStores()?.map((store) => {
              return (
                store && (
                  <ListItemText
                    key={`store_${store?.id || Math.random(99)}`}
                    primary={store?.name}
                  />
                )
              )
            })
          )}
        </List>
      </div>
    </div>
  )

  const buttons = canUpdateUsers
    ? [
        {
          title: 'Edit',
          onClick: () => navigate(`/employees/${id}/edit`),
          color: theme.palette.primary.main,
        },
      ]
    : []

  return (
    <div>
      <ProfileWidget
        body={empBody}
        buttons={buttons}
        subtitle1={employee.email || 'Email address unavailable'}
        subtitle2={
          (formatPhoneNumber(employee.phone_number) ||
            'Phone number unavailable') +
          ', ' +
          (employee.employee_id || 'Employee ID not synced')
        }
        title={employee.name}
      />
      {pageTabs.length > 0 && <Tabs tabs={pageTabs} />}
    </div>
  )
}

export default Employee
