import MaterialTable from '@material-table/core'
import React, { ReactElement } from 'react'
import TagsSelect from '@components/shared/tags-select'
import VendorRegionSelect from '@components/shared/vendor-region-select'
import VendorSelect from './vendor-select'
import useSelector from '@hooks/use-selector'
import { AppDispatch, TagType, VendorProductType } from '@types'
import { checkPerms } from '@utils/has-permission'
import {
  createVendorProduct,
  removeVendorProduct,
  updateVendorProduct,
} from '@store/actions/vendor-products'
import { selectVendorRegions } from '@store/selectors/vendor-regions'
import { selectVendors } from '@store/selectors/vendors'
import { useDispatch } from 'react-redux'

interface Props {
  permissions: string[]
  productsVendors: VendorProductType[]
  productTags: TagType[]
  productId: number
}

const ProductsStoresTable = ({
  permissions,
  productsVendors,
  productTags,
  productId,
}: Props): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const editable = checkPerms(permissions, 'stores:update-all')
  const vendors = useSelector(selectVendors)
  const vendorRegions = useSelector(selectVendorRegions)

  return (
    <MaterialTable
      columns={[
        {
          title: 'Vendor',
          field: 'vendor_id',
          render: function renderVendorName({ vendor_id: vendorId }) {
            return vendors[vendorId].name
          },
          editComponent: function renderEditVendor({
            onChange,
            rowData: { vendor_id: vendorId },
          }) {
            return <VendorSelect onChange={onChange} value={vendorId} />
          },
          defaultSort: 'desc',
        },
        {
          title: 'Vendor Region',
          field: 'vendor_region_id',
          render: function renderVendorName({
            vendor_region_id: vendorRegionId,
          }) {
            return vendorRegions[vendorRegionId]?.name
          },
          editComponent: function renderEditVendor({
            onChange,
            rowData: { vendor_region_id: vendorRegionId },
          }) {
            return (
              <VendorRegionSelect onChange={onChange} value={vendorRegionId} />
            )
          },
          defaultSort: 'desc',
        },
        {
          title: 'Tags',
          field: 'tags',
          initialEditValue: productTags,
          render: function renderTags({ tags }) {
            return tags.map((tag) => tag.name).join(', ')
          },
          editComponent: function renderEditTags({ onChange, value }) {
            return <TagsSelect onChange={onChange} selectedTags={value} />
          },
          defaultSort: 'desc',
        },
      ]}
      data={productsVendors.map((pv) => ({ ...pv }))}
      editable={
        editable && {
          onRowAdd: async (newData) => {
            dispatch(createVendorProduct({ ...newData, product_id: productId }))
          },
          onRowUpdate: async (data) => {
            dispatch(updateVendorProduct(data))
          },
          onRowDelete: async (data) => {
            dispatch(removeVendorProduct(data.id))
          },
        }
      }
      options={{
        actionsColumnIndex: 4,
        addRowPosition: 'first',
      }}
      title='Product Vendors'
    />
  )
}

export default ProductsStoresTable
