import {
  DELETE_CUSTOM_CONTENT,
  SET_CUSTOM_CONTENT,
  SET_CUSTOM_CONTENTS,
} from './actions'

const initialState = {}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case SET_CUSTOM_CONTENT: {
      const { content } = action
      return {
        ...state,
        [content.id]: content,
      }
    }
    case SET_CUSTOM_CONTENTS: {
      const { contents } = action
      return contents.reduce((acc, content) => {
        return { ...acc, [content.id]: content }
      }, state)
    }
    case DELETE_CUSTOM_CONTENT: {
      return { ...state }
    }
    default:
      return state
  }
}
