import DndItem, { ItemTypes } from '@components/pages/menus/dnd-item'
import Product from './product'
import ProductSelector from '@components/shared/product-selector'
import React, { ReactElement, useCallback, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import { CategoryType } from '@types'
import {
  Delete,
  DragIndicator,
  Edit,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import { toTitleCase } from '@utils/string-utils'

type Props = {
  category: CategoryType
  index: number
  moveCategory: (
    dragIndex: number,
    hoverIndex: number,
    parentIndex: number,
  ) => void
  moveProduct: (
    dragIndex: number,
    hoverIndex: number,
    parentIndex: number,
    dragParentIndex: number,
  ) => void
  onDrop: () => void
  parentIndex: number
  handleUpdateCategoryProductsList: (
    categoryId: number,
    products: number[],
  ) => void
  onEdit: (categoryId: number) => void
  onRemove: (categoryId: number) => void
}

const Category = ({
  category,
  index,
  moveCategory,
  moveProduct,
  parentIndex,
  onDrop,
  handleUpdateCategoryProductsList,
  onEdit,
  onRemove,
}: Props): ReactElement => {
  const [expanded, setExpanded] = useState(false)
  const { classes } = useStyles()
  const [addProductDialogOpen, setAddProductDialogOpen] = useState(false)
  const [addProductId, setAddProductId] = useState(-1)

  const handleClickAddProduct = () => {
    setAddProductDialogOpen(true)
  }

  const handleAddProduct = () => {
    handleUpdateCategoryProductsList(index, [
      addProductId,
      ...category.products,
    ])
    setAddProductDialogOpen(false)
    setAddProductId(-1)
  }

  const handleRemoveProduct = (productIndex) => {
    handleUpdateCategoryProductsList(
      index,
      category.products.filter((p, i) => productIndex !== i),
    )
  }

  const onAddProductClose = () => {
    setAddProductDialogOpen(false)
  }

  const renderAddProduct = () => (
    <div>
      <Dialog open={addProductDialogOpen}>
        <DialogTitle id='alert-dialog-title'>Add Product To Menu</DialogTitle>
        <DialogContent>
          <ProductSelector
            containerProps={{ fullWidth: true }}
            onChange={setAddProductId}
            value={addProductId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onAddProductClose}>Close</Button>
          <Button onClick={handleAddProduct}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Button color='secondary' onClick={handleClickAddProduct}>
        Add Product
      </Button>
    </div>
  )

  const handleEditName = () => {
    onEdit(index)
  }

  const handleRemoveCategory = () => {
    onRemove(index)
  }

  const renderHeader = useCallback(
    () => (
      <AccordionSummary
        aria-controls={`category-${index}`}
        expandIcon={<ExpandMoreIcon />}
        id={`category-${index}`}
      >
        <h2 className={classes.title}>
          <DragIndicator className={classes.drag} />
          {toTitleCase(category.name)}
        </h2>
        <Button onClick={handleEditName}>
          <Edit />
        </Button>
        <Button className={classes.buttonRight} onClick={handleRemoveCategory}>
          <Delete />
        </Button>
      </AccordionSummary>
    ),
    [index, category],
  )

  return (
    <DndItem
      className={classes.container}
      dragPreview={!expanded ? renderHeader : undefined}
      handleDrop={onDrop}
      handleMove={moveCategory}
      id={category.id}
      index={index}
      parentIndex={parentIndex}
      type={ItemTypes.CATEGORY}
    >
      <Accordion
        expanded={expanded}
        onChange={(_, value) => setExpanded(value)}
      >
        {renderHeader()}
        <AccordionDetails className={classes.details}>
          <div>
            {renderAddProduct()}
            {category.products.map((id, productIndex) => (
              <Product
                canDrag={category.products.length > 1}
                id={id}
                index={productIndex}
                key={id}
                moveProduct={moveProduct}
                onDrop={onDrop}
                onRemove={handleRemoveProduct}
                parentIndex={index}
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </DndItem>
  )
}

export default Category

const useStyles = makeStyles()({
  details: {
    justifyContent: 'center',
  },
  drag: {
    cursor: 'move',
    marginRight: '10px',
    verticalAlign: 'middle',
  },
  title: {
    marginBottom: 0,
    marginTop: 0,
  },
  paper: {
    padding: '20px',
  },
  container: {
    marginTop: '20px',
    marginBottom: '20px',
    width: 500,
  },
  buttonRight: {
    position: 'absolute',
    right: 50,
  },
})
