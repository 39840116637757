import React, { ReactElement, useEffect, useState } from 'react'
import useAllActions from '@hooks/use-all-actions'
import useSelector from '@hooks/use-selector'
import {
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  CheckCircle as SuccessIcon,
} from '@mui/icons-material'
import { IconButton, Snackbar, SnackbarContent } from '@mui/material'
import { green } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'
import { selectFlashMessage } from '@store/reducers/flash-message/selectors'

const variantIcon = {
  success: SuccessIcon,
  info: InfoIcon,
  error: ErrorIcon,
}

const FlashMessage = (): ReactElement => {
  const { clearFlashMessage } = useAllActions()
  const { classes, cx } = useStyles()
  const [visible, setVisible] = useState(false)

  const { message, variant } = useSelector(selectFlashMessage)
  const Icon = variantIcon[variant]

  useEffect(() => {
    if (message) {
      setVisible(true)
    }
  }, [message])

  const onClose = (): void => {
    setVisible(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={variant === 'error' ? null : 3000}
      onClose={onClose}
      open={visible}
      style={{ zIndex: 1000000000000000 }}
      TransitionProps={{ onExited: clearFlashMessage }}
    >
      <SnackbarContent
        action={[
          <IconButton
            aria-label='close'
            color='inherit'
            key='close'
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        aria-describedby='global-flash-snackbar'
        className={cx(classes[variant], classes.margin)}
        message={
          <span className={classes.message} id='global-flash-snackbar'>
            <Icon className={cx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
      />
    </Snackbar>
  )
}

export default FlashMessage

const useStyles = makeStyles()((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))
