import React, { ReactElement } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type PropsType = {
  productType: string
  onProductTypeChange: (productType: string) => void
  editing: boolean
}

const useStyles = makeStyles()((theme) => ({
  paperWrapper: {
    padding: theme.spacing(2),
  },
}))

const ProductTypeTrigger = ({
  productType,
  onProductTypeChange,
  editing,
}: PropsType): ReactElement => {
  const { classes } = useStyles()

  const handleProductTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onProductTypeChange(e.target.value)
  }

  return (
    <Paper className={classes.paperWrapper}>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Product Type</FormLabel>
        <RadioGroup
          aria-label='type'
          name='type'
          onChange={handleProductTypeChange}
          value={productType}
        >
          <FormControlLabel
            control={<Radio />}
            disabled={!editing}
            label='Beverage'
            value='beverage'
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!editing}
            label='Food'
            value='food'
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!editing}
            label='Bag'
            value='bag'
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!editing}
            label='Bottle'
            value='bottle'
          />
          <FormControlLabel
            control={<Radio />}
            disabled={!editing}
            label='Merchandise'
            value='merchandise'
          />
        </RadioGroup>
      </FormControl>
    </Paper>
  )
}

export default ProductTypeTrigger
