import { RootState, VendorType } from '@types'
import { createSelector } from 'reselect'
import { identity } from '@utils/functional'

const vendorsSelector = (state: RootState): Record<number, VendorType> =>
  state.Vendors

export const selectVendors = createSelector(vendorsSelector, identity)

export const selectVendorsList = createSelector(
  vendorsSelector,
  (vendors) => Object.values(vendors),
)
