import { ADD_VENDORS, SET_VENDORS } from '../action-list'

export const addVendors = (vendors) => ({
  type: ADD_VENDORS,
  vendors,
})

export const setVendors = (vendors) => ({
  type: SET_VENDORS,
  vendors,
})
