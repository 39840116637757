import PropTypes from 'prop-types'
import React from 'react'

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

/**
 * Add timezones here. The first in the list is the default.
 */
const timezones = [
  'America/Los_Angeles',
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Phoenix',
  'America/Anchorage',
  'America/Adak',
  'Pacific/Honolulu',
]

export const defaultTimezone = timezones[0]

function TimezoneSelector(props) {
  return (
    <FormControl className={props.className} fullWidth>
      <InputLabel>Time Zone</InputLabel>
      <Select
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
      >
        {timezones.map((tz) => (
          <MenuItem key={tz} value={tz}>
            {tz}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

TimezoneSelector.propTypes = {
  className: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default TimezoneSelector
