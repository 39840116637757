import * as api from '@utils/api'
import ProductSelector from '@shared/product-selector'
import React, { ReactElement, useState } from 'react'
import ResponsiveDialog from '@shared/responsive-dialog'
import Toolbar from '@shared/toolbar'
import { Button, DialogActions, FormControl, Typography } from '@mui/material'
import { ProductList, ProductTypeType } from '@types'
import { makeStyles } from 'tss-react/mui'
import { useQuery } from '@tanstack/react-query'

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  fullWidth: {
    width: '100%',
  },
  seperator: {
    marginTop: theme.spacing(2),
  },
}))

interface Props {
  open: boolean
  onClose(): void
  onConfirm(any): void
  productType: ProductTypeType
}

const CloneFromDialog = ({
  onClose,
  onConfirm,
  open,
  productType,
}: Props): ReactElement => {
  const { classes } = useStyles()
  const [cloneId, setCloneId]: [number, any] = useState(-1)
  const { isLoading } = useQuery({
    queryKey: ['attributeList'],
    queryFn: () => {
      return api.GET('/p4/products/attributes-names').then((res) => res.data)
    },
    staleTime: 30000,
  })

  const confirm = () => {
    if (cloneId !== null) onConfirm(cloneId)
    setCloneId(-1)
  }

  const close = (): void => {
    setCloneId(-1)
    onClose()
  }

  const updateCloneId = (value: number) => {
    setCloneId(value)
  }

  const filter = (products: ProductList): ProductList => {
    return products.filter((product) => product.product_type === productType)
  }

  if (isLoading) return <div>loading</div>

  return (
    <ResponsiveDialog
      className={classes.container}
      loading={false}
      onClose={close}
      open={open}
    >
      <div className={classes.container}>
        <Toolbar title='Add Attribute' />
        <Typography align='center'>Select an</Typography>
        <FormControl className={classes.fullWidth}>
          <ProductSelector
            filter={filter}
            onChange={updateCloneId}
            value={cloneId}
          />
        </FormControl>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={confirm}>Clone</Button>
        </DialogActions>
      </div>
    </ResponsiveDialog>
  )
}

export default CloneFromDialog
