import PropTypes from 'prop-types'
import React from 'react'
import { Paper, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(2),
    flex: 1,
  },
  title: {
    marginBottom: 8,
  },
}))

function Form(props) {
  const { classes, cx } = useStyles()

  const renderBody = (className) => (
    <div className={className}>
      <Typography className={classes.title} component='h1' variant='h6'>
        {props.title}
      </Typography>
      {props.children}
    </div>
  )

  const containerClass = cx(classes.container, props.className)

  if (props.paper === false) {
    return renderBody(containerClass)
  } else {
    return <Paper className={containerClass}>{renderBody()}</Paper>
  }
}

Form.propTypes = {
  title: PropTypes.string,
  paper: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Form
