import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Paper } from '@mui/material'
import { useAllActions } from '@hooks'

import PagedTable from './paged-table'

import { DateTimePicker } from '@mui/x-date-pickers'
import { GridFilterListIcon } from '@mui/x-data-grid'
import { formatBackendDateTime, formatDefault } from '../../../utils/date-utils'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  headingWrapper: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  filters: {
    marginLeft: 'auto',
  },
  picker: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  title: {
    paddingLeft: theme.spacing(2),
    marginTop: 0,
  },
  filterButton: {
    padding: theme.spacing(2),
  },
}))

function OrderTable(props) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [didFetchInitialOrders, setDidFetchInitialOrders] = useState(false)
  const { setFlashMessage } = useAllActions()
  const { classes } = useStyles()
  const [placementBeginFilter, setPlacementBeginFilter] = useState(null)
  const [placementEndFilter, setPlacementEndFilter] = useState(null)

  const fetchOrders = async (page, pageSize) => {
    try {
      await props.fetchOrders(
        page,
        pageSize,
        placementBeginFilter
          ? formatBackendDateTime(placementBeginFilter)
          : null,
        placementEndFilter ? formatBackendDateTime(placementEndFilter) : null,
      )
      setPage(page)
      setRowsPerPage(pageSize)
    } catch (error) {
      setFlashMessage(error.message, 'error')
    }
  }

  const submitFilters = async () => await fetchOrders(0, rowsPerPage)

  if (!didFetchInitialOrders) {
    setDidFetchInitialOrders(true)
    fetchOrders(page, rowsPerPage).catch((error) =>
      setFlashMessage(error.message, 'error'),
    )
  }

  const showEmptyChild =
    (!props.orders || !props.orders.length) && !!props.emptyChild

  if (showEmptyChild) return props.emptyChild

  return (
    <Paper>
      <div className={classes.headingWrapper}>
        <h2 className={classes.title}>Orders</h2>
        <div className={classes.filters}>
          <DateTimePicker
            className={classes.picker}
            disableFuture
            label='Placement Begins'
            onChange={(value) => setPlacementBeginFilter(value)}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept'],
              },
            }}
            value={placementBeginFilter}
          />
          <DateTimePicker
            className={classes.picker}
            disableFuture
            label='Placement Ends'
            onChange={(value) => setPlacementEndFilter(value)}
            slotProps={{
              actionBar: {
                actions: ['clear', 'accept'],
              },
            }}
            value={placementEndFilter}
          />
          <Button
            className={classes.filterButton}
            endIcon={<GridFilterListIcon />}
            onClick={submitFilters}
          >
            Filter
          </Button>
        </div>
      </div>
      <PagedTable
        count={props.ordersCount || 0}
        data={props.orders || []}
        fetchData={fetchOrders}
        fields={[
          'id',
          props.includeStore ? 'store_id' : null,
          props.includeCustomer ? 'name' : null,
          {
            field: 'placement_time',
            transform: (time) => formatDefault(time),
          },
          {
            field: 'pickup_time',
            transform: (time) => formatDefault(time),
          },
          'status',
        ].filter((x) => x)}
        getClickRowRoute={(order) => {
          if (props.routeForOrder == null) {
            return
          }
          return props.routeForOrder(order.id)
        }}
        headers={[
          'ID',
          props.includeStore ? 'Store ID' : null,
          props.includeCustomer ? 'Customer' : null,
          'Placement Time',
          'Pickup Time',
          'Status',
        ].filter((x) => x)}
      />
    </Paper>
  )
}

OrderTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  fetchOrders: PropTypes.func,
  routeForOrder: PropTypes.func,
  includeStore: PropTypes.bool,
  includeCustomer: PropTypes.bool,
  ordersCount: PropTypes.number,
  emptyChild: PropTypes.element,
}

export default OrderTable
