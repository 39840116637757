// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactCrop__drag-handle {
  &:after {
    width: 20px !important;
    height: 20px !important;
  }
}

@media (pointer: coarse) {
  .ReactCrop {
    .ord-ne, .ord-nw, .ord-sw {
      display: none !important;
    }
    .ord-se:after {
      width: 30px !important;
      height: 30px !important;
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/components/shared/cropped-image-input.css"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAsB;IACtB,uBAAuB;EACzB;AACF;;AAEA;EACE;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,sBAAsB;MACtB,uBAAuB;IACzB;EACF;AACF","sourcesContent":[".ReactCrop__drag-handle {\n  &:after {\n    width: 20px !important;\n    height: 20px !important;\n  }\n}\n\n@media (pointer: coarse) {\n  .ReactCrop {\n    .ord-ne, .ord-nw, .ord-sw {\n      display: none !important;\n    }\n    .ord-se:after {\n      width: 30px !important;\n      height: 30px !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
