import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import rootSaga from '../sagas'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'
import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['CurrentUser'],
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedReducer = persistReducer(persistConfig, reducers)
const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware, sagaMiddleware)),
)

sagaMiddleware.run(rootSaga)
export const persistor = persistStore(store)
