import ProductAttributes from '@pages/products/product-attributes'
import ProductForm from '@pages/products/form'
import ProductsStores from '@components/pages/products/products-stores'
import ProductsVendors from '@components/pages/products/products-vendors'
import ProfileWidget from '@shared/profile-widget'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import {
  Edit as EditIcon,
  RestorePage as RestoreIcon,
  Delete as TrashIcon,
} from '@mui/icons-material'
import { connect } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import { removeProductsStores } from '@store/actions/products-stores'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

import { useAllActions } from '@hooks'

import {
  createProductStore,
  fetchProduct,
  fetchProductsStoresForProductId,
  removeProductAtStore,
  setPriceForProductAtStore,
  updateProduct,
} from '@services/product-service'

import { fetchVendors } from '../../services/vendor-service'

import ErrorBoundary from '@components/shared/error-boundary'
import withRouter from '../../utils/legacy/with-router'
import { checkPerms } from '@utils/has-permission'
import { getProductsStoresForProduct } from '@store/selectors/products-stores'
import { getTags } from '@store/actions/tags'
import { selectCurrentPermissionNames } from '@store/selectors'

function getProductId(props) {
  return parseInt(props.match.params.productId, 10)
}

const useStyles = makeStyles()((theme) => ({
  section: {
    marginBottom: theme.spacing(2),
  },
  addonSelector: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  editForm: {
    marginBottom: theme.spacing(2),
  },
  nutritionLabelsToolbar: {
    flex: 1,
  },
  vendorSelector: {
    marginRight: theme.spacing(2),
  },
}))

// TODO: selectors
const mapStateToProps = (state, ownProps) => {
  return {
    product: state.Products[getProductId(ownProps)],
    productsStores: getProductsStoresForProduct(state, getProductId(ownProps)),
    vendors: state.Vendors,
    permissions: selectCurrentPermissionNames(state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const productId = getProductId(ownProps)

  /**
   * @TODO move dispatch to pages/product.js and refactor services/product-service.js
   */
  return {
    actions: {
      createProductStore: createProductStore(dispatch),
      removeProductsStores: (productsStores) =>
        dispatch(removeProductsStores(productsStores)),
      fetchProductsStores: () =>
        fetchProductsStoresForProductId(dispatch)(productId),
      removeProductAtStore: (storeId) =>
        removeProductAtStore(dispatch)(storeId, productId),
      setStoreProduct: (storeId, data) =>
        setPriceForProductAtStore(dispatch)(storeId, productId, data),
      updateProduct: (data) => dispatch(updateProduct(productId, data)),
      fetchProduct: () => fetchProduct(dispatch)(productId),
      fetchVendors: fetchVendors(dispatch),
      setFlashMessage: (message, variant) =>
        dispatch(setFlashMessage(message, variant)),
      getTags: () => dispatch(getTags()),
    },
  }
}

function Product(props) {
  const { classes } = useStyles()
  const { actions, product, permissions, productsStores } = props
  const [loading, setIsLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [openArchiveConfirm, setOpenArchiveConfirm] = useState(false)
  const [openRestoreConfirm, setOpenRestoreConfirm] = useState(false)

  const { cloneAttributes } = useAllActions()

  const clone = (cloneId) => {
    return cloneAttributes(product.id, cloneId)
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)
      await Promise.all([
        actions.fetchProduct(),
        actions.fetchProductsStores(),
        actions.fetchVendors(),
        actions.getTags(),
      ])
    } catch (error) {
      actions.setFlashMessage(error.message, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (loading) return <CircularProgress />

  const editable = checkPerms(permissions, 'products:update')

  let isArchived = !!product.archived_at

  const updateProduct = async (productData) => {
    await actions.updateProduct(productData)
  }

  const updateProductAttributes = (productAttributes) => {
    updateProduct({ ...product, product_attributes: productAttributes })
  }

  const productArchivalChanged = async (wasArchived) => {
    await fetchData()
    isArchived = wasArchived
  }

  const handleTriggerArchive = () => {
    setOpenArchiveConfirm(true)
  }

  const handleOpenArchiveConfirmClose = () => {
    setOpenArchiveConfirm(false)
  }

  const handleConfirmArchive = async () => {
    await updateProduct({ archived_at: true })
    actions.removeProductsStores(productsStores)
    await productArchivalChanged(true)
    setOpenArchiveConfirm(false)
  }

  const handleTriggerRestore = () => {
    setOpenRestoreConfirm(true)
  }

  const handleOpenRestoreConfirmClose = () => {
    setOpenRestoreConfirm(false)
  }

  const handleConfirmRestore = async () => {
    await updateProduct({ archived_at: null })
    await productArchivalChanged(false)
    setOpenRestoreConfirm(false)
  }

  return (
    <div>
      <ProfileWidget
        buttons={
          editable && [
            !isArchived && {
              icon: <EditIcon />,
              onClick: () => setIsEditing(!isEditing),
              title: 'Edit Product',
            },
            !isArchived && {
              icon: <TrashIcon />,
              onClick: () => handleTriggerArchive(),
              title: 'Archive Product',
            },
            isArchived && {
              icon: <RestoreIcon />,
              onClick: () => handleTriggerRestore(),
              title: 'Restore Product',
            },
          ]
        }
        imageUrl={product.image_url}
        subtitle1={product.description}
        subtitle2={product.tags.map((tag) => tag.name).join(', ')}
        title={product.name}
      />
      {isEditing && (
        <div className={classes.section}>
          <ProductForm
            className={classes.editForm}
            initialProductData={product}
            onSubmit={updateProduct}
            title='Edit Product'
          />
        </div>
      )}
      {!isArchived && (
        <div>
          <ProductAttributes
            className={classes.section}
            clone={clone}
            editable={editable}
            productAttributes={product.product_attributes}
            productType={product.product_type}
            update={updateProductAttributes}
          />
          <ProductsStores
            className={classes.section}
            createProductStore={actions.createProductStore}
            fetch={actions.fetchProductsStores}
            permissions={permissions}
            product={product}
            productsStores={productsStores}
            removeProductStore={actions.removeProductAtStore}
            setProductStore={actions.setStoreProduct}
          />
          <ErrorBoundary>
            <ProductsVendors
              permissions={permissions}
              productId={product.id}
              productTags={product.tags}
            />
          </ErrorBoundary>
        </div>
      )}
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        onClose={handleOpenArchiveConfirmClose}
        open={openArchiveConfirm}
      >
        <DialogTitle id='alert-dialog-title'>
          Confirm archival of Product!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to completely remove this product from the
            Philz database? The product will immediately disappear from all
            stores.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color='primary'
            onClick={handleOpenArchiveConfirmClose}
          >
            Nevermind
          </Button>
          <Button color='primary' onClick={handleConfirmArchive}>
            Archive
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        onClose={handleOpenRestoreConfirmClose}
        open={openRestoreConfirm}
      >
        <DialogTitle id='alert-dialog-title'>
          Return product to database
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Restoring this product will recreate the product and most of
            it&apos;s values. However it will not restore a product to stores
            immediately.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color='primary'
            onClick={handleOpenRestoreConfirmClose}
          >
            Nevermind
          </Button>
          <Button color='primary' onClick={handleConfirmRestore}>
            Restore
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

Product.propTypes = {
  actions: PropTypes.shape({
    fetchProduct: PropTypes.func,
    setStoreProduct: PropTypes.func,
    removeProductAtStore: PropTypes.func,
    productsStores: PropTypes.func,
    createProductStore: PropTypes.func,
    removeProductsStores: PropTypes.func,
    setFlashMessage: PropTypes.func,
    fetchVendors: PropTypes.func,
    updateProduct: PropTypes.func,
    fetchProductsStores: PropTypes.func,
    getTags: PropTypes.func,
  }),
  product: PropTypes.shape({
    id: PropTypes.number,
    archived_at: PropTypes.string,
    image_url: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    product_attributes: PropTypes.array,
    product_type: PropTypes.string,
    tags: PropTypes.array,
    square_sku: PropTypes.string,
  }),
  permissions: PropTypes.array,
  productsStores: PropTypes.array,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Product))
