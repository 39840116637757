import React, { ReactElement, useEffect, useState } from 'react'

import Loader from '@components/shared/loader'
import useSelector from '@hooks/use-selector'
import { AppDispatch, TagType } from '@types'
import { Paper } from '@mui/material'
import { createTag, getTags, updateTag } from '@store/actions/tags'
import { makeStyles } from 'tss-react/mui'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import TagForm from './tag-form'
import { selectTag } from '@store/selectors/tags'

const useStyles = makeStyles()((theme) => ({
  topContent: {
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
  },
  basicInfo: {
    flex: 3,
    marginRight: theme.spacing(2),
  },
}))

const newTag = {
  name: '',
  image_url: '',
  is_allergen: true,
} as TagType

const Tag = (): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const isNewTag = id === undefined

  const tag = useSelector(selectTag, id)

  const [loaded, setLoaded] = useState(isNewTag || !!tag)

  useEffect(() => {
    const loadData = async () => {
      setLoaded(false)
      await dispatch(getTags())
      setLoaded(true)
    }

    if (!isNewTag && !tag) {
      loadData()
    }
  }, [id])

  const handleSubmit = async (tag: TagType) => {
    if (isNewTag) {
      const newTag = await dispatch(createTag(tag))
      navigate(`/tags/${newTag.id}/edit`)
    } else {
      dispatch(updateTag(tag))
    }
  }

  if (!loaded) return <Loader />

  return (
    <div>
      <div className={classes.topContent}>
        <Paper className={classes.basicInfo}>
          <TagForm initialData={tag || newTag} onSubmit={handleSubmit} />
        </Paper>
      </div>
    </div>
  )
}

export default Tag
