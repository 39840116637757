import {
  SET_HOURS_FOR_STORE,
  SET_HOURS_FOR_STORE_FOR_DAY,
} from '../action-list'

export const setHoursForStore = (storeId, hours) => ({
  type: SET_HOURS_FOR_STORE,
  storeId,
  hours,
})

export const setHoursForStoreForDay = (storeId, hours) => ({
  type: SET_HOURS_FOR_STORE_FOR_DAY,
  storeId,
  hours,
})
