import PropTypes from 'prop-types'
import React from 'react'
import {
  Table as BaseTable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Link } from 'react-router-dom'

import Toolbar from '../toolbar'

function getKey(row, field) {
  if (typeof field === 'string') {
    return field
  } else if (typeof field === 'object') {
    return field['field']
  }
}

function getValue(row, field) {
  let returnValue = null

  if (typeof field === 'string') {
    returnValue = row[field]
  } else if (typeof field === 'object') {
    returnValue = field['transform'](row[field['field']], row)
  }

  return returnValue == null ? '<null>' : returnValue
}

function Table(props) {
  return (
    <div>
      {(props.title || props.toolbarButtons) && (
        <Toolbar buttons={props.toolbarButtons} title={props.title} />
      )}
      <BaseTable>
        <TableHead>
          <TableRow>
            {props.headers.map((header) => {
              return (
                <TableCell align='left' key={header}>
                  {header}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => {
            return (
              <TableRow
                component={props.getClickRowRoute && Link}
                hover={props.getClickRowRoute && true}
                key={props.rowKey ? row[props.rowKey] : row[props.fields[0]]}
                style={{ textDecoration: 'none' }}
                to={props.getClickRowRoute && props.getClickRowRoute(row)}
              >
                {props.fields.map((field) => {
                  return (
                    <TableCell
                      align='left'
                      key={`${row[props.fields[0]]}.${getKey(row, field)}`}
                    >
                      {getValue(row, field)}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
          {props.children}
        </TableBody>
      </BaseTable>
      {props.paginated && (
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          component='div'
          count={props.totalRowCount || 1000000000000}
          nextIconButtonProps={{ 'aria-label': 'next page' }}
          onPageChange={props.onChangePage}
          onRowsPerPageChange={props.onChangeRowsPerPage}
          page={props.page}
          rowsPerPage={props.rowsPerPage}
          rowsPerPageOptions={props.rowsPerPageOptions || [5, 10, 15, 25]}
        />
      )}
    </div>
  )
}

Table.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),

  /**
   * Fields that are displayed. This can either be a string corresponding to a key in
   * a row (i.e. 'id' would display 'data[x].id') or an object formatted:
   * `{field: String, transform: (val) => String}`. For the latter, the value will be
   * transformed by the passed callback.
   */
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  headers: PropTypes.arrayOf(PropTypes.string),

  getClickRowRoute: PropTypes.func,

  /**
   * Matches the format of the Toolbar's 'buttons' prop
   */
  toolbarButtons: PropTypes.arrayOf(PropTypes.object),

  // Pagination controls
  paginated: PropTypes.bool,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  page: PropTypes.number,
  totalRowCount: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  children: PropTypes.node,
  rowKey: PropTypes.string,
}

export default Table
