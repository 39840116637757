import MaterialTable, { Column } from '@material-table/core'
import React, { ReactElement } from 'react'

import { getArchivedProductList, getProductList } from '@queries/products'
import { useQuery } from '@tanstack/react-query'

interface Props {
  tableActions: any
  title?: string
  onRowClick(_, e): void
  archived?: boolean
}

const ProductTable = ({
  tableActions,
  title,
  onRowClick,
  archived,
}: Props): ReactElement => {
  const { isLoading, data: products } = useQuery({
    queryKey: [archived ? 'archivedProductList' : 'productList'],
    queryFn: archived ? getArchivedProductList : getProductList,
    staleTime: 30000,
  })

  if (isLoading || !products) {
    return <div />
  }

  const columns: Column<any>[] = [
    { title: 'ID', field: 'id', defaultSort: 'asc' },
    { title: 'SKU', field: 'square_sku' },
    { title: 'Name', field: 'name' },
    { title: 'Type', field: 'product_type' },
  ]

  if (archived) {
    columns.push({ title: 'Archived', field: 'archived_at', type: 'datetime' })
  }

  return (
    <MaterialTable
      actions={tableActions}
      columns={columns}
      data={products || []}
      onRowClick={onRowClick}
      options={{
        paging: false,
      }}
      title={title || 'Products'}
    />
  )
}

export default ProductTable
